import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
} from "reactstrap";

import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";

import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import defaultImage from "assets/img/default-avatar.png";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import DeleteDialog from "components/Dialog/DeleteDialog";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import NewPropertyService from "services/new-property.service";
import { PostListContent } from "components/Posts/PostListContent";

const NewProperties = () => {
  const [properties, setProperties] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  // const [clientId, setClientId] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
  const [userState, setUserState] = useAtom(userAtom);
  const [state, setState] = useAtom(selectedClientAtom);
  const [confirmationOpen, setConfirmationOpen] = useState(
    Array(properties.length).fill(false)
  );

  const history = useHistory();

  // // For Review Dialog START
  // const [dialogOpen, setDialogOpen] = useState(false);

  // const handleDialogClose = (e, reason) => {
  //   if (reason != "backdropClick") setDialogOpen(false);
  // };

  // const handleCancel = () => {
  //   // revert stage !!
  //   setDialogOpen(false);
  // };

  // const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //   "& .MuiDialogContent-root": {
  //     padding: theme.spacing(2),
  //   },
  //   "& .MuiDialogActions-root": {
  //     padding: theme.spacing(1),
  //   },
  // }));

  // const handleDrag = (sourcePost, source, destination, postsByStatus) => {
  //   if (destination.status === "for_review") setDialogOpen(true);
  //   else {
  //     // fire request for other stages
  //     NewPropertyService.getAll().then((response) => {
  //       console.log("moving suceeded: ", response);
  //     });
  //   }

  //   console.log(sourcePost, source, destination, postsByStatus);
  // };

  // const handleOnReview = () => {
  //   // fire request for review checklist
  //   NewPropertyService.getAll()
  //     .then((response) => {
  //       // fire request to change the stage

  //       setDialogOpen(false);
  //     })
  //     .catch((error) => {
  //       // revert the property card
  //       // !!!
  //       console.log(error);

  //       // setIsDataLoaded(true);
  //       // setProperties([]);
  //     });
  //   ;
  // };
  // // For Review Dialog END

  useEffect(() => {
    getNewProperties();
  }, [isDataLoaded, state?.clientId]);

  const getNewProperties = async () => {
    console.log("selectedClientAtom: ", userState);
    return NewPropertyService.getAllByBuyerAgent(userState?.userId)
      .then((response) => {
        let newPropertyData = response.data.recordset.map((property) => {
          return {
            id: property.NewPropertyId,
            address: property.Address,
            suburb: property.Suburb,
            postcode: property.Postcode,
            state: property.State,
            stateShortName: property.StateShortName,
            stateId: property.StateId,
            saleOptionId: property.SaleOptionId,
            saleOption: property.SaleOption,
            noOfBedRooms: property.NoOfBathRooms,
            noOfBathRooms: property.NoOfBathRooms,
            landSize: property.LandSize,
            askingPrice: property.AskingPrice,
            comparableValueMin: property.ComparableValueMin,
            comparableValueMax: property.ComparableValueMax,
            expectedRentalIncome: property.ExpectedRentalIncome,
            rentalYieldPercent: property.RentalYieldPercent,
            statusId: property.StatusId,
            status: property.PropertyStatus?.toLowerCase(),
            // firstListed: property.FirstListed.slice(0, 10),
            tenancyId: property.TenancyId,
            tenancy: property.Tenancy,
            // zoningId: property.ZoningId,
            residentialZone: property.ResidentialZone,
            frontage: property.Frontage,
            developmentPotential: property.DevelopmentPotential,
            url: property.URL,
            additionalComments: property.AdditionalComments,
            dueDiligence: property.DueDiligence ? true : false,
            clientId: property.CreatedBy,
            currentBuyerAgent: property.CurrentBuyerAgent,
            currentBuyerAgentId: property.CurrentBuyerAgentId,
            currentClient: property.CurrentClient,
            mandatoryDocumentsNotReceived:
              property.MandatoryDocumentsNotReceived,
            moveToProposed: property.MoveToProposed,
            moveToAccepted: property.MoveToAccepted,
            dealAcceptedMandatoryDocNotReceived:
              property.DealAcceptedMandatoryDocNotReceived,
            isContractIssuedByPAM: property.IsContractIssuedByPAM,
            isNameOfPurchaser: property.IsNameOfPurchaser,
            isReviewedBySolicitor: property.IsReviewedBySolicitor,
            isSubjectToFinance: property.IsSubjectToFinance,
            isInspection: property.IsInspection,
            contractExchange: 0,
            purchaseDate: property.PurchaseDate,
            settlementDate: property.SettlementDate,
            buildingAndPestInspectionDate:
              property.BuildingAndPestInspectionDate,
            financeApprovalDeadline: property.FinanceApprovalDeadline,
            preSettlementInspectionDate: property.PreSettlementInspectionDate,
            purchasePrice: property.PurchasePrice,
            daysToSettlement: property.DaysToSettlement,
            daysToBuildingAndPestInspection:
              property.DaysToBuildingAndPestInspection,
            daysToFinanceApproval: property.DaysToFinanceApproval,
            weeksToPreSettlementInspection:
              property.WeeksToPreSettlementInspection,
          };
        });

        console.log("new property data: ", newPropertyData);

        setProperties(newPropertyData);
        if (response.data.recordsets.length > 0) {
          setIsDataLoaded(true);
        } else {
          setIsDataLoaded(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setProperties([]);
      });
  };

  const deleteProperty = (newPropertyId) => {
    NewPropertyService.delete(newPropertyId)
      .then((response) => {
        getNewProperties();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#66615B", //theme.palette.common.darkgray,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleConfirmationOpen = (index) => {
    const tempOpens = [...confirmationOpen];
    tempOpens[index] = true;
    setConfirmationOpen(tempOpens);
  };

  const handleConfirmationClose = (index) => {
    const tempOpens = [...confirmationOpen];
    tempOpens[index] = false;
    setConfirmationOpen(tempOpens);
  };

  const handleDetails = (e, propertyPortfolioId) => {
    console.log("property portfolio id: ", propertyPortfolioId);
    e.stopPropagation();
    e.preventDefault();
    const path = `/admin/scoreboard/property-portfolio/details/${propertyPortfolioId}`;
    history.push(path);
  };

  const handleAdd = () => {
    const path = "/admin/fulfillment-pipeline/new-properties/add";
    history.push(path);
  };

  const handleDelete = async (propertyId) => {
    console.log("delete property");
    return NewProperService.delete(propertyId).then((response) => {
      console.log("response: ", response.data.recordset);
      getProperties(state?.clientId).then(() => setSuccessDialogOpen(true));
    });
  };

  const handleSuccessDialogClose = () => setSuccessDialogOpen(false);

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSuccessDialogClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <div className="content property-portfolio-content">
      <Snackbar
        open={successDialogOpen}
        autoHideDuration={5000}
        onClose={handleSuccessDialogClose}
        message="Property is deleted."
        action={action}
      />
      {/* <BootstrapDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"For Review"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Review: - A - B
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleOnReview}>OK</Button>
        </DialogActions>
      </BootstrapDialog> */}
      <Container>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">New Properties</CardTitle>
                <div className="card_bt">
                  <Button
                    sx={{ float: "right" }}
                    variant="contained"
                    color="grey"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={handleAdd}
                  >
                    ADD New Property
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                {/* <Row> */}
                {isDataLoaded && (
                  <PostListContent
                    unorderedPosts={properties}
                    fetchProperties={getNewProperties}
                    deleteProperty={deleteProperty}
                  />
                )}
                {/* </Row> */}
              </CardBody>
            </Card>
          </Col>
          {/* <Col md="12">
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default NewProperties;
