import http from "./http-common";

class CompassService {
  // http://localhost:5000/api/v1/clientId/assumptionId
  getClientCompassGraphData(clientId, assumptionId) {
    const path = `/compass/graph/${clientId}/${assumptionId}`;
    console.log("path: ", path);
    return http.get(path);
  }
}

export default new CompassService();
