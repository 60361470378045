import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { userAtom } from "store";
import { selectedClientAtom } from "store";
import YearlyPlanService from "services/yearly-plan.service";
import { useAtom } from "jotai";
import FormWizard from "./form-wizard";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import PropertyPortfolioService from "services/property-portfolio.service";
import { formatCurrency } from "utils/currency";
import { Container } from "@mui/material";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";

const rows = [
  { Year: 2024, Age: 24 },
  { Year: 2025, Age: 25 },
  { Year: 2026, Age: 26 },
  { Year: 2027, Age: 27 },
  { Year: 2028, Age: 28 },
];

export default function ManuallyEnteredSpends() {
  const [clientState] = useAtom(selectedClientAtom);
  const [userState] = useAtom(userAtom);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [resetDialogOpen, setResetDialogOpen] = React.useState(false);
  const [wizardDialogOpen, setWizardDialogOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [spends, setSpends] = React.useState([]);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [properties, setProperties] = React.useState([]);

  const history = useHistory();

  const handleWizardDialogOpen = () => {
    setWizardDialogOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationOpen = () => {
    setConfirmationOpen(true);
  };

  const handleReset = () => {
    console.log("Handle Reset");
    setConfirmationOpen(false);
    resetYearPlanData(clientState?.clientId);
    setResetDialogOpen(true);
  };

  const resetYearPlanData = async (id) => {
    YearlyPlanService.resetYearPlanForId(id)
      .then((response) => {
        YearlyPlanService.generateInitialYearPlanForId(id)
          .then(() => getYearlyPlan(id))
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleWizardDialogClose = () => {
    setWizardDialogOpen(false);
    setSelectedRow({});
    getYearlyPlan(clientState?.clientId);
  };

  const getYearSpend = ({
    motorVehicleSpend,
    holidaySpend,
    educationSpend,
    otherSpend,
  }) => motorVehicleSpend + holidaySpend + educationSpend + otherSpend;

  const getAlterSavings = ({
    changeInSavings,
    changeInCashflow,
    changeInDebt,
  }) =>
    `$${changeInSavings || 0} Savings, $${changeInCashflow || 0} Cashflow, $${
      changeInDebt || 0
    } Debt`;

  React.useEffect(() => {
    // if (userState.userId === 0) {
    const jwtToken = window.sessionStorage.getItem("jwt-token");
    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
    } else {
      getYearlyPlan(clientState?.clientId);
      getProperties(clientState?.clientId);
    }
  }, [clientState?.clientId]);

  const getYearlyPlan = async (id) => {
    YearlyPlanService.getAllByClientId(id)
      .then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = removeDuplicatesByYearlyPlanId(
            response.data.recordset.map((row) =>
              convertFirstLetterToLowerCase(row)
            )
          );
          console.log("result : ", result);
          setSpends(result);
        } else {
          YearlyPlanService.generateInitialYearPlanForId(id)
            .then(() => getYearlyPlan(id))
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProperties = async (clientId) => {
    return PropertyPortfolioService.getAllByClientId(clientId).then(
      (response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset;
          console.log("result : ", result);
          setProperties(result);
        }
      }
    );
  };

  function removeDuplicatesByYearlyPlanId(list) {
    const uniqueIds = {};
    return list.filter((item) => {
      if (!uniqueIds[item.yearlyPlanId]) {
        uniqueIds[item.yearlyPlanId] = true;
        return true;
      }
      return false;
    });
  }

  const convertFirstLetterToLowerCase = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObj[newKey] = obj[key];
      }
    }
    return newObj;
  };

  const getPropertyAddressById = (id) =>
    properties.find((p) => p.ClientPropertyId === id)?.Address;

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  const handleSuccessDialogOpen = () => {
    setSuccessDialogOpen(true);
  };

  const handleResetDialogClose = () => {
    setResetDialogOpen(false);
  };

  const handleResetDialogOpen = () => {
    setResetDialogOpen(true);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSuccessDialogClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const resetAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleResetDialogClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const convertDataForForm = (row) => {
    const dataToSent = {
      ...convertFirstLetterToLowerCase(row),
      changeInSavings: formatCurrency(row.changeInSavings),
      changeInCashflow: formatCurrency(row.changeInCashflow),
      changeInDebt: formatCurrency(row.changeInDebt),
      refinanceLoanAmount: formatCurrency(row.refinanceLoanAmount),
      purchasePrice: formatCurrency(row.purchasePrice),
      purchasePriceTwo: formatCurrency(0),
      motorVehicleSpend: formatCurrency(row.motorVehicleSpend),
      holidaySpend: formatCurrency(row.holidaySpend),
      educationSpend: formatCurrency(row.educationSpend),
      otherSpend: formatCurrency(row.otherSpend),
      renovationSpend: formatCurrency(row.renovationSpend),
      propertyNameTwo: null,
      // reFinanceId: null,
      // refinanceClientPropertyId: null,
      // refinanceLoanAmountOption: null,
      // refinanceInterestRate: null,
      // refinanceLoanRpType: null,
      // refinanceLoanTerm: null,
      // refinanceLVR: null,
      // refinanceLoanAmount: null,
      yearlyPlanSwitchPurposeId: null,
      // purposeSwitchClientPropertyId: null,
      // purposeSwitchType: null,
      // purposeSwitchRentalYield: null,
      yearlyPlanSellingId: null,
      sellingPropertyId: null,
      // isSelling: null,
      renovateProperties: [],
      loanSwitchProperties: [],
      purposeSwitchProperties: [],
      sellingProperties: [],
    };
    setSelectedRow(dataToSent);
  };

  return (
    <div className="content manually-entered-spends">
      <ClientNotSelectedDialog />
      <Snackbar
        open={successDialogOpen}
        autoHideDuration={5000}
        onClose={handleSuccessDialogClose}
        message="Data saved."
        action={action}
      ></Snackbar>

      <Snackbar
        open={resetDialogOpen}
        autoHideDuration={3000}
        onClose={handleResetDialogClose}
        message="Data Reset."
        action={resetAction}
      ></Snackbar>

      <ConfirmationDialog
        open={confirmationOpen}
        title="Reset Manual Spend"
        message="Are you sure to reset all manual spend data?"
        onClose={handleConfirmationClose}
        onConfirm={handleReset}
        onCancel={handleConfirmationClose}
        confirmText="Yes"
        cancelText="No"
      />

      <FormWizard
        key={`Y${selectedRow?.yearlyPlanId}S${currentStep}` || 0}
        open={wizardDialogOpen}
        handleClose={handleWizardDialogClose}
        showSuccessDialog={handleSuccessDialogOpen}
        data={selectedRow}
        currentStep={currentStep}
      />

      <Container>
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row", // Ensures horizontal alignment
              justifyContent: "space-between", // Space between elements
              alignItems: "center", // Vertically center the content
            }}
          >
            {/* h2 for manually entered spends */}
            <h2>
              Manually Entered Spends for{" "}
              {`${clientState?.firstName} ${clientState?.lastName}`}
            </h2>

            {/* Reset button */}
            <Button
              variant="contained"
              color="grey"
              size="medium"
              onClick={handleConfirmationOpen}
            >
              Reset
            </Button>
          </Box>
        </React.Fragment>

        <TableContainer component={Paper}>
          <div className="manually-table-responsive">
            <div className="manually-entered-spends-table">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <div className="table_top">
                  <TableHead>
                    <TableRow>
                      <TableCell>Year</TableCell>
                      <TableCell>Age</TableCell>
                      <TableCell>New Property Value</TableCell>
                      <TableCell>New Property Two Value</TableCell>
                      <TableCell>Renovations</TableCell>
                      <TableCell>Year Spend</TableCell>
                      <TableCell>Alter Savings</TableCell>
                      <TableCell>Loan Switch</TableCell>
                      <TableCell>Purpose Switch</TableCell>
                      <TableCell>Selling</TableCell>
                    </TableRow>
                  </TableHead>
                </div>
                <div className="table_mid">
                  <TableBody>
                    {spends.map((row) => (
                      <TableRow
                        key={row.yearlyPlanId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="table-body-cell" title={row.year}>
                            {row.year}
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="table-body-cell age" title={row.age}>
                            {row.age}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            convertDataForForm(row);
                            setCurrentStep(0);
                            handleWizardDialogOpen();
                          }}
                        >
                          <div
                            className="table-body-cell property_name"
                            title={row.propertyName}
                          >
                            {row.propertyName}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            convertDataForForm(row);
                            setCurrentStep(0);
                            handleWizardDialogOpen();
                          }}
                        >
                          <div
                            className="table-body-cell property_name_two"
                            title={row.propertyNameTwo}
                          >
                            {row.propertyNameTwo}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            convertDataForForm(row);
                            setCurrentStep(1);
                            handleWizardDialogOpen();
                          }}
                        >
                          <div
                            className="table-body-cell renovation_spend"
                            title={row.renovationSpend || 0}
                          >
                            {row.renovationSpend || 0}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            convertDataForForm(row);
                            setCurrentStep(2);
                            handleWizardDialogOpen();
                          }}
                        >
                          <div
                            className="table-body-cell year_spend"
                            title={getYearSpend(row)}
                          >
                            {getYearSpend(row)}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            convertDataForForm(row);
                            setCurrentStep(3);
                            handleWizardDialogOpen();
                          }}
                        >
                          <div
                            className="table-body-cell alter_savings"
                            title={getAlterSavings(row)}
                          >
                            {getAlterSavings(row)}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            convertDataForForm(row);
                            setCurrentStep(4);
                            handleWizardDialogOpen();
                          }}
                        >
                          <div
                            className="table-body-cell loan_which"
                            title={
                              getPropertyAddressById(
                                row.refinanceClientPropertyId
                              )
                                ? `Switch Loan Type
                  ${getPropertyAddressById(row.refinanceClientPropertyId)}`
                                : "None"
                            }
                          >
                            {getPropertyAddressById(
                              row.refinanceClientPropertyId
                            )
                              ? `Switch Loan Type
                  ${getPropertyAddressById(row.refinanceClientPropertyId)}`
                              : "None"}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            convertDataForForm(row);
                            setCurrentStep(5);
                            handleWizardDialogOpen();
                          }}
                        >
                          <div
                            className="table-body-cell purpose_switch"
                            title={
                              getPropertyAddressById(
                                row.purposeSwitchClientPropertyId
                              )
                                ? `Switch Purpose
                  ${getPropertyAddressById(row.purposeSwitchClientPropertyId)}`
                                : "None"
                            }
                          >
                            {getPropertyAddressById(
                              row.purposeSwitchClientPropertyId
                            )
                              ? `Switch Purpose
                  ${getPropertyAddressById(row.purposeSwitchClientPropertyId)}`
                              : "None"}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            convertDataForForm(row);
                            setCurrentStep(6);
                            handleWizardDialogOpen();
                          }}
                        >
                          <div
                            className="table-body-cell selling"
                            title={
                              getPropertyAddressById(row.sellingPropertyId)
                                ? `Selling ${getPropertyAddressById(
                                    row.sellingPropertyId
                                  )}`
                                : "None"
                            }
                          >
                            {getPropertyAddressById(row.sellingPropertyId)
                              ? `Selling ${getPropertyAddressById(
                                  row.sellingPropertyId
                                )}`
                              : "None"}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </div>
              </Table>
            </div>
          </div>
        </TableContainer>
      </Container>
    </div>
  );
}
