import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";

import Button from "@mui/material/Button";
import assumptionService from "services/assumption.service";
import { useAtom } from "jotai";
import { userAtom, selectedClientAtom } from "../../../store";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { formatCurrency } from "utils/currency";
import { currencyToNumber } from "utils/currency";

const INITIAL_DATA = {
  assumptionId: 0,
  assumptionName: "",
  assumptionTypeId: 0,
  userId: 0,
  growthRate: 0,
  rentalYield: 0,
  rentalIncreasePA: 0,
  occupancyRate: 0,
  propertyManagement: 0,
  interestRate: 0,
  inflation: 0,
  newLoanAmount: 0,
  acquisitionCost: 0,
  baExpenses: 0,
  renovationReturn: 0,
  borrowingCapacity: 0,
  wageGrowth: 0,
  offsetSavings: 0,
  newPurchaseRefinance: 0,
  sellingCost: 0,
  cgtRate: 0,
  cgtDiscount: 0,
  costOfHandling: 0,
  costOfHandlingIncrease: 0,
  commercialGrowthRate: 0,
  commercialNetRentalYield: 0,
  commercialInterestRate: 0,
  commercialNewLoanAmount: 0,
  commercialPropertyManagement: 0,
  clientPropertyId: 0,
  clientId: 0,
  savingsPlacedInOffset: 0,
  reFinanceOnNewPurchase: 0,
  payLMIOnFuturePurchases: 0,
  SuperContributionCapPerPerson: formatCurrency(0),
  superSavingsPoolGrowthRate: 0,
  superInterestRate: 0,
  superTransferAge: 0,
};

const AddAssumption = () => {
  const [assumption, setAssumption] = useState(INITIAL_DATA);
  const [state, setState] = useAtom(userAtom);
  const [clientstate, setClientState] = useAtom(selectedClientAtom);
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {}, []);

  const redirectToCompass = () => {
    const path = "/admin/compass/compass";
    history.push(path);
  };

  const handleSave = () => {
    console.log("selected clientP: ");
    console.log("assumption: ", assumption);
    const clientAssumptionData = {
      assumptionId: assumption.assumptionId,
      assumptionName: assumption.assumptionName,
      assumptionTypeId: 2,
      userId: state.userId,
      growthRate: assumption.growthRate,
      rentalYield: assumption.rentalYield,
      rentalIncreasePA: assumption.rentalIncreasePA,
      occupancyRate: assumption.occupancyRate,
      propertyManagement: assumption.propertyManagement,
      interestRate: assumption.interestRate,
      inflation: assumption.inflation,
      newLoanAmount: assumption.newLoanAmount,
      acquisitionCost: assumption.acquisitionCost,
      baExpenses: assumption.baExpenses,
      renovationReturn: assumption.renovationReturn,
      borrowingCapacity: assumption.borrowingCapacity,
      wageGrowth: assumption.wageGrowth,
      offsetSavings: assumption.offsetSavings,
      newPurchaseRefinance: assumption.newPurchaseRefinance,
      sellingCost: assumption.sellingCost,
      cgtRate: assumption.cgtRate,
      cgtDiscount: assumption.cgtDiscount,
      costOfHandling: assumption.costOfHandling,
      costOfHandlingIncrease: assumption.costOfHandlingIncrease,
      commercialGrowthRate: assumption.commercialGrowthRate,
      commercialNetRentalYield: assumption.commercialNetRentalYield,
      commercialInterestRate: assumption.commercialInterestRate,
      commercialNewLoanAmount: assumption.commercialNewLoanAmount,
      commercialPropertyManagement: assumption.commercialPropertyManagement,
      clientPropertyId: assumption.clientPropertyId,
      clientId: clientstate.clientId,
      savingsPlacedInOffset: assumption.savingsPlacedInOffset,
      reFinanceOnNewPurchase: assumption.reFinanceOnNewPurchase,
      payLMIOnFuturePurchases: assumption.payLMIOnFuturePurchases,
      superContributionCapPerPerson: currencyToNumber(
        assumption.superContributionCapPerPerson
      ),
      superSavingsPoolGrowthRate: assumption.superSavingsPoolGrowthRate,
      superInterestRate: assumption.superInterestRate,
      superTransferAge: assumption.superTransferAge,
    };
    console.log("add client assumption: ", clientAssumptionData);
    assumptionService
      .addClientAssumption(clientAssumptionData)
      .then((response) => {
        setOpen(true);
        setTimeout(() => {
          redirectToCompass();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="content add-assumption-content">
      <ClientNotSelectedDialog />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Assumption is saved."
        action={action}
      ></Snackbar>
      {/* <h3>Add Assumption</h3> */}
      <Container>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Add Assumption</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal">
                  <Row>
                    <Col md="6" lg="6" xl="4">
                      <Label>Assumption Name</Label>
                      <FormGroup>
                        <Input
                          placeholder="Assumption Name"
                          type="text"
                          value={assumption.assumptionName}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              assumptionName: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Growth Rate</Label>
                        <Input
                          placeholder="Growth Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.growthRate}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              growthRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Interest Rate</Label>
                        <Input
                          placeholder="Interest Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.interestRate}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              interestRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Borrowing capacity</Label>
                        <Input
                          placeholder="Borrowing capacity"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.borrowingCapacity}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              borrowingCapacity: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Savings placed in offset</Label>
                        <Input
                          placeholder="Savings placed in offset	"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.savingsPlacedInOffset}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              savingsPlacedInOffset: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Re-Finance on New Purchase</Label>
                        <Input
                          placeholder="Re-Finance on New Purchase"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.reFinanceOnNewPurchase}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              reFinanceOnNewPurchase: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Inflation</Label>
                        <Input
                          placeholder="Inflation"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.inflation}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              inflation: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Occupancy Rate</Label>
                        <Input
                          placeholder="Occupancy Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.occupancyRate}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              occupancyRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Rental Yield</Label>
                        <Input
                          placeholder="Rental Yield"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.rentalYield}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              rentalYield: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Rental Increase PA</Label>
                        <Input
                          placeholder="Rental Increase PA"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.rentalIncreasePA}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              rentalIncreasePA: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Property Management</Label>
                        <Input
                          placeholder="Property Management"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.propertyManagement}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              propertyManagement: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Acquisition Costs</Label>
                        <Input
                          placeholder="Acquisition Costs"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.acquisitionCost}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              acquisitionCost: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <Label>Pay LMI on future Purchases</Label>
                      <FormGroup>
                        {/* <Input
                          placeholder="Pay LMI on future Purchases"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.payLMIOnFuturePurchases}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              payLMIOnFuturePurchases: e.target.value,
                            })
                          }
                        /> */}
                        <Input
                          placeholder="Pay LMI on future Purchases"
                          type="checkbox"
                          style={{
                            marginLeft: 5,
                          }}
                          //   min="0"
                          //   oninput="validity.valid||(value='');"
                          value={
                            assumption.payLMIOnFuturePurchases ? true : false
                          }
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              payLMIOnFuturePurchases: e.target.checked ? 1 : 0,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Renovation Return</Label>
                        <Input
                          placeholder="Renovation Return"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.renovationReturn}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              renovationReturn: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>BA Expenses</Label>
                        <Input
                          placeholder="BA Expenses"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.baExpenses}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              baExpenses: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Cost of Holding</Label>
                        <Input
                          placeholder="Cost of Holding"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.costOfHandling}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              costOfHandling: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>New Loan Amount</Label>
                        <Input
                          placeholder="New Loan Amount"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.newLoanAmount}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              newLoanAmount: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Cost of holding Increase</Label>
                        <Input
                          placeholder="Cost of holding Increase"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.costOfHandlingIncrease}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              costOfHandlingIncrease: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Wage Growth</Label>
                        <Input
                          placeholder="Wage Growth"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.wageGrowth}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              wageGrowth: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Selling Costs</Label>
                        <Input
                          placeholder="Selling Costs"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.sellingCost}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              sellingCost: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>CGT Rate</Label>
                        <Input
                          placeholder="CGT Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.cgtRate}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              cgtRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>CGT Discount</Label>
                        <Input
                          placeholder="CGT Discount"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.cgtDiscount}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              cgtDiscount: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Commercial Growth Rate</Label>
                        <Input
                          placeholder="Commercial Growth Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.commercialGrowthRate}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              commercialGrowthRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Commercial Net Rental Yield</Label>
                        <Input
                          placeholder="Commercial Net Rental Yield"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.commercialNetRentalYield}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              commercialNetRentalYield: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Commercial Interest Rate</Label>
                        <Input
                          placeholder="Commercial Interest Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.commercialInterestRate}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              commercialInterestRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Commercial New Loan Amount</Label>
                        <Input
                          placeholder="Commercial New Loan Amount"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.commercialNewLoanAmount}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              commercialNewLoanAmount: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Commercial Property Management</Label>
                        <Input
                          placeholder="Commercial Property Management"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.commercialPropertyManagement}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              commercialPropertyManagement: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Super Contribution Cap Per-Person</Label>
                        <CurrencyInput
                          placeholder="Super Contribution Cap Per-Person"
                          // type="number"
                          // min="0"
                          // oninput="validity.valid||(value='');"
                          // value={assumption.superContributionCapPerPerson}
                          // onChange={(e) =>
                          //   setAssumption({
                          //     ...assumption,
                          //     superContributionCapPerPerson: e.target.value,
                          //   })
                          // }
                          number={assumption.superContributionCapPerPerson}
                          handleNumberChange={(value) =>
                            setAssumption({
                              ...assumption,
                              superContributionCapPerPerson: value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Super Savings Pool Growth Rate</Label>
                        <Input
                          placeholder="Super Savings Pool Growth Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.superSavingsPoolGrowthRate}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              superSavingsPoolGrowthRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Super Interest Rate</Label>
                        <Input
                          placeholder="Super Interest Rate"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.superInterestRate}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              superInterestRate: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="6" xl="4">
                      <FormGroup>
                        <Label>Super Transfer Age</Label>
                        <Input
                          placeholder="Super Transfer Age"
                          type="number"
                          min="0"
                          oninput="validity.valid||(value='');"
                          value={assumption.superTransferAge}
                          onChange={(e) =>
                            setAssumption({
                              ...assumption,
                              superTransferAge: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col>
                    <Button
                      sx={{ float: "right" }}
                      onClick={handleSave}
                      variant="contained"
                      color="grey"
                      size="medium"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddAssumption;
