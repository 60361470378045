import http from "./http-common";

class YearlyPlanService {
  // http://localhost:5000/api/v1/yearly-plan/get/:id
  getAllByClientId(clientId) {
    const path = `/yearly-plan/get/${clientId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/yearly-plan/get/:id
  getYearlyPlanProperty(yearlyPlanPropertyId) {
    const path = `/yearly-plan/property/get/${yearlyPlanPropertyId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/yearly-plan/get/yearly-plan/:id
  getYearlyPlanPropertyByYearlyPlanId(yearlyPlanId) {
    const path = `/yearly-plan/property/get/yearly-plan/${yearlyPlanId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/yearly-plan/initialYearPlan/:id
  generateInitialYearPlanForId(clientId) {
    const path = `/yearly-plan/initialYearPlan/${clientId}`;
    console.log("path: ", path);
    return http.post(path);
  }

  // http://localhost:5000/api/v1/yearly-plan/initialYearPlan/:id
  resetYearPlanForId(clientId) {
    const path = `/yearly-plan/resetYearPlan/${clientId}`;
    console.log("path: ", path);
    return http.post(path);
  }

  // http://localhost:5000/api/v1/yearly-plan/update/:id
  update(yearlyPlanId, data) {
    const path = `/yearly-plan/update/${yearlyPlanId}`;
    console.log("path: ", path);
    return http.put(path, data);
  }

  // http://localhost:5000/api/v1/yearly-plan/updateSpendings/:id
  updateSpendings(yearlyPlanId, data) {
    const path = `/yearly-plan/updateSpendings/${yearlyPlanId}`;
    console.log("path: ", path);
    return http.put(path, data);
  }

  // http://localhost:5000/api/v1/yearly-plan/updateSavings/:id
  updateSavings(yearlyPlanId, data) {
    const path = `/yearly-plan/updateSavings/${yearlyPlanId}`;
    console.log("path: ", path);
    return http.put(path, data);
  }

  // http://localhost:5000/api/v1/yearly-plan/property/addOrUpdate
  addOrUpdateProperty(data) {
    const path = `/yearly-plan/property/addOrUpdate`;
    console.log("path: ", path);
    return http.post(path, data);
  }
}

export default new YearlyPlanService();
