import http from './http-common';

class RiskProfileService {

    // http://localhost:5000/api/v1/scoreboard/risk-profile/add
    add(data) {
        const path = '/scoreboard/risk-profile';
        console.log('data: ', data);
        return http.post(`${path}/add`, data);
    };

    // http://localhost:5000/api/v1/scoreboard/risk-profile/get/client/:id
    getAllByClientId(clientId) {
        const path = `/scoreboard/risk-profile/get/client/${clientId}`;
        console.log('path: ', path);
        return http.get(path);
    };
    // http://localhost:5000/api/v1/scoreboard/risk-profile/get/:id
    update(id, data) {
        const path = `/scoreboard/risk-profile/update/${id}`;
        return http.put(path, data);
    }

}

export default new RiskProfileService();
