import http from "./http-common";

class MoneyManagementService {
  // http://localhost:5000/api/v1/scoreboard/money-management/add
  add(data) {
    const path = "/scoreboard/money-management";
    console.log("data: ", data);
    return http.post(`${path}/add`, data);
  }

  // http://localhost:5000/api/v1/scoreboard/money-management/get/client/:id
  getAllByClientId(clientId) {
    const path = `/scoreboard/money-management/get/client/${clientId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/money-management/get/member/:id
  getByFamilyMemberId(familyMemberId) {
    const path = `/scoreboard/money-management/get/member/${familyMemberId}`;
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/money-management/get/names/:id
  getFamilyMemberNamesByClientId(clientId) {
    const path = `/scoreboard/money-management/get/names/${clientId}`;
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/money-management/get/:id
  update(id, data) {
    const path = `/scoreboard/money-management/update/${id}`;
    return http.put(path, data);
  }

  // http://localhost:5000/api/v1/scoreboard/money-management/delete/:id
  delete(id, data) {
    const path = `/scoreboard/money-management/delete/${id}`;
    return http.delete(path, data);
  }
}

export default new MoneyManagementService();
