import http from "./http-common";

class ContentAndCourseService {
  // http://localhost:5000/api/v1/course-and-content/client/:clientId
  getByClientId(clientId) {
    const path = `/course-and-content/client/${clientId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/course-and-content/client/add
  addClientContentAndCourse(data) {
    const path = `/course-and-content/client/add`;
    console.log("path: ", path);
    return http.post(path, data);
  }

  // http://localhost:5000/api/v1/course-and-content/client/delete/:id
  deleteClientContentAndCourse(clientContentAndCourseId, data) {
    const path = `/course-and-content/client/delete/${clientContentAndCourseId}`;
    console.log("path: ", path);
    return http.delete(path, data);
  }

  // http://localhost:5000/api/v1/course-and-content/module
  getModules() {
    const path = `/course-and-content/module`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/course-and-content/group
  getGroups() {
    const path = `/course-and-content/group`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/course-and-content
  getContentAndCourses() {
    const path = `/course-and-content/`;
    console.log("path: ", path);
    return http.get(path);
  }
}

export default new ContentAndCourseService();
