import http from "./http-common";

class AssetSelectionService {
  // http://localhost:5000/api/v1/scoreboard/asset-selection/add
  add(data) {
    const path = "/scoreboard/asset-selection";
    // console.log('data: ', data);
    return http.post(`${path}/add`, data);
  }

  // http://localhost:5000/api/v1/scoreboard/asset-selection/get/client/:id
  getAllByClientId(clientId) {
    const path = `/scoreboard/asset-selection/get/client/${clientId}`;
    // console.log('path: ', path);
    return http.get(path);
  }
  // http://localhost:5000/api/v1/scoreboard/asset-selection/get/:id
  update(id, data) {
    const path = `/scoreboard/asset-selection/update/${id}`;
    return http.put(path, data);
  }
}

export default new AssetSelectionService();
