import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import "react-datetime/css/react-datetime.css";
import "./contract-exchange.css";
import ReactDatetime from "react-datetime";
import Moment from "moment";
import "moment/locale/en-gb";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";

import Select from "react-select";
import Button from "@mui/material/Button";
import { Button as ReactButton } from "reactstrap";
import { formatCurrency } from "utils/currency";
import ClientService from "services/client.service";
import PropertySummary from "./PropertySummary";
import { Container } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddBuyerAgentDialog from "ui/client/add-buyer-agent-dialog";

import NewPropertyService from "services/new-property.service";
import propertyStageService from "services/property-stage.service";
import ContractExchangeService from "services/contract-exchange.service";

const setDateForInsert = (selectedDate) => {
  return selectedDate.split("/").reverse().join("-");
};

export default function ContractExchange({
  property,
  setUpdatedProperty,
  setShowContractExchangeDialog,
  fetchProperties,
  handleCancel,
  setCurrentPost,
}) {
  const [open, setOpen] = useState(true);
  const [userState, setUserState] = useAtom(userAtom);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [SubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    purchaseDate: property.purchaseDate,
    settlementDate: property.settlementDate,
    buildingAndPestInspectionDate: property.buildingAndPestInspectionDate,
    financeApprovalDeadline: property.financeApprovalDeadline,
    preSettlementInspectionDate: property.preSettlementInspectionDate,
    purchasePrice: property.purchasePrice,
    daysToSettlement: property.daysToSettlement,
    daysToBuildingAndPestInspection: property.daysToBuildingAndPestInspection,
    daysToFinanceApproval: property.daysToFinanceApproval,
    weeksToPreSettlementInspection: property.weeksToPreSettlementInspection,
  });

  function handleContractExchangeDialogClose() {
    setOpen(false);
    setShowContractExchangeDialog(false);
    // handleCancel();
  }

  function validateForm() {
    const errors = {};
    if (!formData.purchaseDate) errors.purchaseDate = "* "; //"Purchase Date is required";
    if (!formData.settlementDate) errors.settlementDate = "* "; //"Settlement Date is required";
    if (!formData.buildingAndPestInspectionDate)
      errors.buildingAndPestInspectionDate = "* "; //"Building and Pest Inspection Date is required";
    if (!formData.financeApprovalDeadline)
      errors.financeApprovalDeadline = "* "; //"Finance Approval Deadline is required";
    if (!formData.preSettlementInspectionDate)
      errors.preSettlementInspectionDate = "* "; //"Pre-Settlement Inspection Date is required";
    if (!formData.purchasePrice) errors.purchasePrice = "* "; //"Purchase Price is required";
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  }

  function handleContractExchangeDialogSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      updateContractExchangedata();
    }
  }

  function handlePropertyMoveToContractExchange() {
    console.log("handlePropertyMoveToContractExchange Called");
    const updateData = {
      propertyId: property.id,
      propertyStatusId: 6, //statusId[destination.status],
      comments: "",
      userId: userState.userId,
    };

    console.log(
      "Handle Property - Move to Contract Exchange, Form Data",
      updateData
    );

    // update the status in property table
    NewPropertyService.updateStatus(updateData, updateData.propertyId)
      .then((response) => {
        // update the record in status history table
        propertyStageService
          .add(updateData)
          .then((response) => {
            fetchProperties();
            console.log("moving suceeded: to Contract Exchange ", response);
          })
          .catch((error) => {
            // revert the property card
            revertPostToPreviousStage();
            console.log(error);
          });
      })
      .catch((error) => {
        // revert the property card
        revertPostToPreviousStage();
        console.log(error);
      });
  }

  // Update Form Data to DB
  function updateContractExchangedata() {
    setSubmitButtonDisabled(true);

    const dataToUpdate = {
      ...formData,
      newPropertyId: property.id,
      userId: userState.userId,
      purchaseDate: setDateForInsert(formData.purchaseDate),
      settlementDate: setDateForInsert(formData.settlementDate),
      buildingAndPestInspectionDate: setDateForInsert(
        formData.buildingAndPestInspectionDate
      ),
      financeApprovalDeadline: setDateForInsert(
        formData.financeApprovalDeadline
      ),
      preSettlementInspectionDate: setDateForInsert(
        formData.preSettlementInspectionDate
      ),

      weeksToPreSettlementInspection: 0, // Need to Changes
    };

    console.log("Data to Update - Data of Update", dataToUpdate);

    ContractExchangeService.addContractExchangeData(dataToUpdate)
      .then((response) => {
        console.log("handlePropertyMoveToContractExchange Called");
        handlePropertyMoveToContractExchange();
        setFormData({});
        handleContractExchangeDialogClose();
      })

      .catch((error) => {
        console.log(error);
        handleContractExchangeDialogClose();
        handleCancel();
      });

    setOpen(false);
    setSnackBarOpen(true);
    setUpdatedProperty({
      ...property,
      ...formData,
    });
    setCurrentPost({ ...property, ...formData });

    console.log("Data to Update - Property", property);

    // if (
    //   formData.purchaseDate &&
    //   formData.settlementDate &&
    //   formData.buildingAndPestInspectionDate &&
    //   formData.financeApprovalDeadline &&
    //   formData.preSettlementInspectionDate
    // ) {
    //   // call service to update data to db
    // } else {
    //   handleCancel();
    // }

    // NewPropertyService.updateBuyerAgent(dataToUpdate)
    //   .then(() => {
    //     setOpen(false);
    //     setSnackBarOpen(true);
    //     // onHandleSetBuyerAgentDialog(false);
    //     fetchProperties();
    //     setUpdatedProperty({
    //       ...property,
    //       ...formData,
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  const updateDays = () => {
    //Calculation of Settlment Days
    if (formData.purchaseDate && formData.settlementDate) {
      const daysToSettlement = Math.round(
        (Moment(formData.settlementDate, "DD/MM/YYYY").toDate().getTime() -
          Moment(formData.purchaseDate, "DD/MM/YYYY").toDate().getTime()) /
          (1000 * 60 * 60 * 24)
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        daysToSettlement: daysToSettlement,
      }));
    }

    //Calculation of InspectionDays
    if (formData.purchaseDate && formData.buildingAndPestInspectionDate) {
      const days = Math.round(
        (Moment(formData.buildingAndPestInspectionDate, "DD/MM/YYYY")
          .toDate()
          .getTime() -
          Moment(formData.purchaseDate, "DD/MM/YYYY").toDate().getTime()) /
          (1000 * 60 * 60 * 24)
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        daysToBuildingAndPestInspection: days,
      }));
    }

    if (formData.purchaseDate && formData.financeApprovalDeadline) {
      const days = Math.round(
        (Moment(formData.financeApprovalDeadline, "DD/MM/YYYY")
          .toDate()
          .getTime() -
          Moment(formData.purchaseDate, "DD/MM/YYYY").toDate().getTime()) /
          (1000 * 60 * 60 * 24)
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        daysToFinanceApproval: days,
      }));
    }
    if (formData.settlementDate && !formData.preSettlementInspectionDate) {
      const preSettleDate = Moment(formData.settlementDate, "DD/MM/YYYY")
        .clone()
        .subtract(7, "days")
        .format("DD/MM/YYYY");

      setFormData((prevFormData) => ({
        ...prevFormData,
        preSettlementInspectionDate: Moment(preSettleDate, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
      }));
    }
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      purchaseDate: property.purchaseDate,
      settlementDate: property.SettlementDate,
      buildingAndPestInspectionDate: property.BuildingAndPestInspectionDate,
      financeApprovalDeadline: property.FinanceApprovalDeadline,
      preSettlementInspectionDate: property.PreSettlementInspectionDate,
      purchasePrice: property.PurchasePrice,
      daysToSettlement: property.daysToSettlement,
      daysToBuildingAndPestInspection: property.DaysToBuildingAndPestInspection,
      daysToFinanceApproval: property.DaysToFinanceApproval,
      weeksToPreSettlementInspection: property.WeeksToPreSettlementInspection,
    }));
    console.log("Form Loading - ", formData);
    console.log("Form Loading - ", property);
  }, []);

  useEffect(() => {
    updateDays();
    console.log(formData);
  }, [
    formData.purchaseDate,
    formData.settlementDate,
    formData.buildingAndPestInspectionDate,
    formData.financeApprovalDeadline,
    formData.preSettlementInspectionDate,
  ]);

  function handleUpdateFormDataonChange(fieldName, fieldValue) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: fieldValue,
    }));
  }

  return (
    <Dialog
      open={open}
      onClose={handleContractExchangeDialogClose}
      PaperProps={{
        style: { width: "60%", maxWidth: "none", height: "85%" },
      }}
      disableEscapeKeyDown
    >
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleContractExchangeDialogClose}
        message="Data updated sucessfully...."
      ></Snackbar>

      {/* <ConfirmationDialog
        open={confirmationOpen}
        title="Update Buyer Agent"
        message="Are you sure you to change the buyer agent of the property?"
        onClose={handleConfirmationClose}
        onConfirm={updateBuyerAgent}
        onCancel={handleConfirmationClose}
        confirmText="Yes"
        cancelText="No"
      /> */}

      <DialogTitle>
        <span
          style={{ color: "#114253", fontSize: "20px", fontWeight: "bold" }}
        >
          Contract Exchange
        </span>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleContractExchangeDialogClose();
            handleCancel();
          }}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleContractExchangeDialogSubmit}>
        <DialogContent>
          <Card>
            <CardHeader>
              <CardBody className="new-property-card-body">
                <Container style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      flex: 1,
                      minWidth: "345px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      marginRight: "5px",
                      height: "440px",
                    }}
                  >
                    <PropertySummary property={property} />
                  </div>

                  <div
                    style={{
                      flex: 2,
                      minWidth: "400px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      height: "440px",
                    }}
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          {validationErrors.purchaseDate && (
                            <span style={{ color: "red" }}>
                              {validationErrors.purchaseDate}
                            </span>
                          )}
                          <Label>Purchase Date</Label>

                          <ReactDatetime
                            locale="en-gb"
                            inputProps={{
                              className: "form-control",
                              placeholder: "",
                            }}
                            className="custom-date-picker"
                            value={formData.purchaseDate}
                            onChange={(e) => {
                              Moment.locale("en-au");
                              handleUpdateFormDataonChange(
                                "purchaseDate",
                                Moment(e).format("DD/MM/yyyy")
                              );
                            }}
                            timeFormat={false}
                            closeOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          {validationErrors.purchasePrice && (
                            <span style={{ color: "red" }}>
                              {validationErrors.purchasePrice}
                            </span>
                          )}
                          <Label>Purchase Value</Label>
                          <Input
                            placeholder="Purchase Value"
                            type="number"
                            value={formData.purchasePrice}
                            onChange={(e) => {
                              handleUpdateFormDataonChange(
                                "purchasePrice",
                                e.target.value
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          {validationErrors.settlementDate && (
                            <span style={{ color: "red" }}>
                              {validationErrors.settlementDate}
                            </span>
                          )}
                          <Label>Settlement Date</Label>
                          <ReactDatetime
                            locale="en-gb"
                            inputProps={{
                              className: "form-control",
                              placeholder: "",
                            }}
                            className="custom-date-picker"
                            value={formData.settlementDate}
                            onChange={(e) => {
                              Moment.locale("en-au");
                              const selectedDate =
                                Moment(e).format("DD/MM/yyyy");
                              handleUpdateFormDataonChange(
                                "settlementDate",
                                selectedDate
                              );
                            }}
                            timeFormat={false}
                            closeOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Days to Settlement</Label>
                          <Input
                            placeholder="Days"
                            type="number"
                            value={formData.daysToSettlement}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          {validationErrors.buildingAndPestInspectionDate && (
                            <span style={{ color: "red" }}>
                              {validationErrors.buildingAndPestInspectionDate}
                            </span>
                          )}
                          <Label>Building & Pest Ins. on</Label>
                          <ReactDatetime
                            locale="en-gb"
                            inputProps={{
                              className: "form-control",
                              placeholder: "",
                            }}
                            className="custom-date-picker"
                            value={formData.buildingAndPestInspectionDate}
                            onChange={(e) => {
                              Moment.locale("en-au");
                              const selectedDate =
                                Moment(e).format("DD/MM/yyyy");
                              handleUpdateFormDataonChange(
                                "buildingAndPestInspectionDate",
                                selectedDate
                              );
                            }}
                            timeFormat={false}
                            closeOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Days</Label>
                          <Input
                            placeholder="Days"
                            type="number"
                            value={formData.daysToBuildingAndPestInspection}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          {validationErrors.financeApprovalDeadline && (
                            <span style={{ color: "red" }}>
                              {validationErrors.financeApprovalDeadline}
                            </span>
                          )}
                          <Label>Finance Approval Deadline</Label>
                          <ReactDatetime
                            locale="en-gb"
                            inputProps={{
                              className: "form-control",
                              placeholder: "",
                            }}
                            className="custom-date-picker"
                            value={formData.financeApprovalDeadline}
                            onChange={(e) => {
                              Moment.locale("en-au");
                              const selectedDate =
                                Moment(e).format("DD/MM/yyyy");
                              handleUpdateFormDataonChange(
                                "financeApprovalDeadline",
                                selectedDate
                              );
                            }}
                            timeFormat={false}
                            closeOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Days</Label>
                          <Input
                            placeholder="Days"
                            type="number"
                            value={formData.daysToFinanceApproval}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          {validationErrors.preSettlementInspectionDate && (
                            <span style={{ color: "red" }}>
                              {validationErrors.preSettlementInspectionDate}
                            </span>
                          )}
                          <Label>Pre-Settlement Inspection</Label>
                          <ReactDatetime
                            locale="en-gb"
                            inputProps={{
                              className: "form-control",
                              placeholder: "",
                            }}
                            className="custom-date-picker"
                            value={formData.preSettlementInspectionDate}
                            onChange={(e) => {
                              Moment.locale("en-au");
                              const selectedDate =
                                Moment(e).format("DD/MM/yyyy");
                              handleUpdateFormDataonChange(
                                "preSettlementInspectionDate",
                                selectedDate
                              );
                            }}
                            timeFormat={false}
                            closeOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Weeks</Label>
                          <Input
                            placeholder="Weeks"
                            type="number"
                            value={formData.weeksToPreSettlementInspection}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    {/* <div className="card_bt"> */}
                    <Button
                      // sx={{ float: "right" }}
                      sx={{ marginTop: "20px" }}
                      variant="contained"
                      color="grey"
                      size="medium"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Submit
                    </Button>
                    {/* </div> */}
                  </Col>
                </Container>
              </CardBody>
            </CardHeader>
          </Card>
        </DialogContent>
      </form>
    </Dialog>
  );
}
