import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Moment from "moment";

import PropertyDetails from "./wizard-steps/property-details";
import PropertySize from "./wizard-steps/property-size";
import OwnerDetails from "./wizard-steps/owner-details";
import PropertyCost from "./wizard-steps/property-cost";
import PropertyLoanDetails from "./wizard-steps/property-loan-details";
import SplitLoan from "./wizard-steps/split-loan";
import RenovationDetails from "./wizard-steps/renovation-details";
import PropertyRentalDetails from "./wizard-steps/property-rental-details";
import PropertyExpenses from "./wizard-steps/property-expenses";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import { currencyToNumber } from "utils/currency";
import { formatCurrency } from "utils/currency";
import { MoveDownSharp } from "@mui/icons-material";
import { Container } from "@mui/material";

import AssumptionService from "../../../services/assumption.service";
import PropertyPortfolioService from "../../../services/property-portfolio.service";

const INITIAL_DATA = {
  id: 0,
  address: "",
  propertyType: "",
  isDefaultPropertyType: "1",
  dwellingType: "",
  noOfBeds: "",
  noOfBaths: "",
  noOfCarSpaces: "",
  currentMarketValue: formatCurrency(0),
  excludeFromCalculation: false,
  yearBuild: "",
  isYearBuildSelected: false,
  landSize: 0,
  floorSize: 0,
  ownerType: "",
  ownerName: "",
  ownershipPercent: 0,
  underSeparateEntity: false,
  dateSettled: "",
  isDefaultDateSettled: "1",
  isDateSettledSelected: false,
  purchasePrice: formatCurrency(0),
  stampDutyCost: formatCurrency(0),
  lenderMortgageInsuranceCost: formatCurrency(0),
  conveyencingCost: formatCurrency(0),
  additionalCost: formatCurrency(0),
  depreciationScheduleAvailable: false,
  annualDepreciation: 0,
  currentLoanAmount: formatCurrency(0),
  mostRecentLoanCommencedYear: "",
  isMostRecentLoanCommencedYear: "1",
  isDefaultMostRecentLoanCommencedYear: "1",
  isMostRecentLoanCommencedYearSelected: false,
  lender: "",
  currentInterestRate: 0,
  isDefaultInterestRate: "1",
  originalLoanTerm: 0,
  isDefaultOriginalLoanTerm: "1",
  loanType: "",
  isDefaultLoanType: "1",
  interestOnlyTerm: -1,
  interestRateStructure: "",
  fixedTerm: -1,
  autoCalculateMortgageRepyment: false,
  monthlyRepayment: 0,
  currentRepaymentAmount: formatCurrency(0),
  currentRepaymentFrequency: "",
  splitLoan: "",
  isDefaultSplitLoan: "0",
  splitLoanAmount: formatCurrency(0),
  splitLoanDateBorrowed: "",
  isDefaultSplitLoanDateBorrowed: "1",
  isSplitLoanDateBorrowedSelected: false,
  splitLoanCurrentInterestRate: 0,
  isDefaultSplitLoanCurrentInterestRate: "1",
  splitLoanTerm: 0,
  isDefaultSplitLoanTerm: "1",
  splitLoanType: "",
  isDefaultSplitLoanType: "1",
  splitLoanInetrOnlyTerm: "",
  splitLoanInterestRateStructure: "",
  splitLoanFixedTerm: "",
  autoCalculateSplitLoanMortgageRepyment: false,
  splitLoanMonthlyRepayment: 0,
  splitLoanCurrentRepaymentAmount: formatCurrency(0),
  splitLoanRepaymentFrequency: "",
  lastRenovationAmount: formatCurrency(0),
  lastRenovationDate: "",
  isLastRenovationDateSelected: false,
  lastRenovationDesc: "",
  currentPropertyManager: "",
  managementPercentage: 0,
  rentalIncomePerWeek: formatCurrency(0),
  externalLink: "",
  propertyExpensesCalculationMethod: "",
  isDefaultPropertyExpensesCalculationMethod: "1",
  strataCost: formatCurrency(0),
  strataCostFrequency: "",
  councilCost: formatCurrency(0),
  councilCostFrequency: "",
  insuranceCost: formatCurrency(0),
  insuranceCostFrequency: "",
  maintenanceCost: formatCurrency(0),
  maintenanceCostFrequency: "",
  landTaxAmount: formatCurrency(0),
  landTaxAmountFrequency: "",
  accountingCost: formatCurrency(0),
  accountingCostFrequency: "",
  asicCost: formatCurrency(0),
  asicCostFrequency: "",
  waterCost: formatCurrency(0),
  waterCostFequency: "",
  electricalCost: formatCurrency(0),
  electricalCostFrequency: "",
  gasCost: formatCurrency(0),
  gasCostFrequency: "",
  internetCost: formatCurrency(0),
  internetCostFrequency: "",
  totalExpenses: formatCurrency(0),
  totalExpensesFrequency: "",
  isDefaultTotalExpensesFrequency: "1",

  clientId: 0,
  userId: 0,
  currentYear: {
    label: new Date().getFullYear(),
    value: new Date().getFullYear(),
  },
  isUpdate: false,
};

const PropertyPlanAdd = () => {
  const [formData, setFormData] = useState(INITIAL_DATA);
  const [open, setOpen] = useState(false);
  // const [clientId, setClientId] = useState(0);
  // const [userId, setUserId] = useState(0);
  const [isUpdate, setIsUpdate] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);

  const [defaultAssumptions, setDefaultAssumptions] = useState({});

  const history = useHistory();

  const getDefaultAssumptions = async () => {
    let defaultAssumptions = {};
    AssumptionService.getDefaultAssumption()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          let data = response.data.recordset[0];
          defaultAssumptions = { ...data };
          setDefaultAssumptions(defaultAssumptions);
          setIsDataLoaded(true);
          console.log("Assumption-", defaultAssumptions);
        }
      })
      .catch((error) => {
        console.log(error);
        setDefaultAssumptions(defaultAssumptions);
      });
  };

  const [validationErrorsPropertyDetails, setValidationErrorsPropertyDetails] =
    useState({});
  const [validationErrorsOwnerDetails, setValidationErrorsOwnerDetails] =
    useState({});
  const [validationErrorsCostDetails, setValidationErrorsCostDetails] =
    useState({});
  const [validationErrorsLoanDetails, setValidationErrorsLoanDetails] =
    useState({});
  const [
    validationErrorsSplitLoanDetails,
    setValidationErrorsSplitLoanDetails,
  ] = useState({});

  const getPropertyId = () => {
    console.log(
      "Property id",
      window.location.pathname
        .substr(1, window.location.pathname.length)
        .split("/")[4]
    );
    return window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/")[4];
  };

  // Property Detail Form Validation
  function validateFormPropertyDetails() {
    const errorsPropertyDetails = {};
    if (!formData.address) errorsPropertyDetails.address = "* "; //"Address is required";
    if (!formData.currentMarketValue || formData.currentMarketValue === "$0")
      errorsPropertyDetails.currentMarketValue = "* "; //"Market Value is required";
    setValidationErrorsPropertyDetails(errorsPropertyDetails);
    return Object.keys(errorsPropertyDetails).length === 0;
  }

  //Owner Details Form Validation
  function validateFormOwnerDetails() {
    const errorsOwnerDetails = {};
    if (!formData.ownerType) errorsOwnerDetails.ownerType = "* "; //"Owner Type is required";
    setValidationErrorsOwnerDetails(errorsOwnerDetails);
    return Object.keys(errorsOwnerDetails).length === 0;
  }

  //Property Cost Details Form Validation
  function validateFormCostDetails() {
    const errorsCostDetails = {};
    if (!formData.purchasePrice || formData.purchasePrice === "$0")
      errorsCostDetails.purchasePrice = "* ";
    setValidationErrorsCostDetails(errorsCostDetails);
    return Object.keys(errorsCostDetails).length === 0;
  }

  //Loan Details Form Validation
  function validateFormLoanDetails() {
    const errorsLoanDetails = {};
    if (!formData.currentLoanAmount || formData.currentLoanAmount === "$0")
      errorsLoanDetails.currentLoanAmount = "* ";
    setValidationErrorsLoanDetails(errorsLoanDetails);
    return Object.keys(errorsLoanDetails).length === 0;
  }

  //Split Loan Details Form Validation
  function validateFormSplitLoanDetails() {
    // console.log("Validate Split Loan Details -", formData);
    const errorsSplitLoanDetails = {};
    if (formData.splitLoan === "Split loan") {
      if (
        !formData.splitLoanAmount ||
        formData.splitLoanAmount === "$0" ||
        formData.splitLoanAmount === 0
      )
        errorsSplitLoanDetails.splitLoanAmount = "* ";
    }
    setValidationErrorsSplitLoanDetails(errorsSplitLoanDetails);
    console.log("Validation Form Loan Detail - ", errorsSplitLoanDetails);
    return Object.keys(errorsSplitLoanDetails).length === 0;
  }

  const stepLabels = [
    "Property details",
    "Property size",
    "Owner details",
    "Property cost",
    "Property loan",
    "Split loan",
    "Renovation details",
    "Property rental details",
    "Property expenses",
  ];

  const steps = isDataLoaded && [
    <PropertyDetails
      formData={formData}
      setFormData={setFormData}
      validationErrorsPropertyDetails={validationErrorsPropertyDetails}
      defaultAssumptions={defaultAssumptions}
    />,

    <PropertySize formData={formData} setFormData={setFormData} />,

    <OwnerDetails
      formData={formData}
      setFormData={setFormData}
      validationErrorsOwnerDetails={validationErrorsOwnerDetails}
      defaultAssumptions={defaultAssumptions}
    />,

    <PropertyCost
      formData={formData}
      setFormData={setFormData}
      validationErrorsCostDetails={validationErrorsCostDetails}
    />,

    <PropertyLoanDetails
      formData={formData}
      setFormData={setFormData}
      validationErrorsLoanDetails={validationErrorsLoanDetails}
      defaultAssumptions={defaultAssumptions}
    />,

    <SplitLoan
      formData={formData}
      setFormData={setFormData}
      validationErrorsSplitLoanDetails={validationErrorsSplitLoanDetails}
      defaultAssumptions={defaultAssumptions}
    />,

    <RenovationDetails formData={formData} setFormData={setFormData} />,

    <PropertyRentalDetails
      formData={formData}
      setFormData={setFormData}
      defaultAssumptions={defaultAssumptions}
    />,

    <PropertyExpenses
      formData={formData}
      setFormData={setFormData}
      defaultAssumptions={defaultAssumptions}
    />,
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const goToStep = (step) => {
    setActiveStep(step);
  };

  const redirectToList = () => {
    const path = "/admin/scoreboard/property-portfolio/list";
    history.push(path);
  };

  const redirectToTracker = () => {
    const path = "/admin/progress-tracker/index";
    history.push(path);
  };

  const getPropertyDetails = () => {
    const propertyId = getPropertyId();
    PropertyPortfolioService.getByPropertyId(propertyId)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const propertyPortfolio = response.data.recordset[0];
          console.log("property details: ", propertyPortfolio);
          const propertyData = {
            id: propertyPortfolio.ClientPropertyId,
            address: propertyPortfolio.Address,
            propertyType: propertyPortfolio.PropertyType,
            isDefaultPropertyType: propertyPortfolio.IsDefaultPropertyType
              ? "1"
              : "0",
            dwellingType: propertyPortfolio.DwellingType,
            noOfBeds: propertyPortfolio.Beds,
            noOfBaths: propertyPortfolio.Baths,
            noOfCarSpaces: propertyPortfolio.Car,
            currentMarketValue: formatCurrency(
              propertyPortfolio.CurrentMarketValue
            ),
            excludeFromCalculation: propertyPortfolio.ExcludeFromCalc,
            yearBuild: {
              label: `${new Date(propertyPortfolio.YearBuild).getFullYear()}`,
              value: `${new Date(propertyPortfolio.YearBuild).getFullYear()}`,
            },
            landSize: propertyPortfolio.LandSize,
            floorSize: propertyPortfolio.FloorSize,
            ownerType: propertyPortfolio.OwnerType,
            ownerName: propertyPortfolio.OwnerName,
            ownershipPercent: propertyPortfolio.CombinedOwnershipPercentage,
            underSeparateEntity:
              propertyPortfolio.PlacePropertyUnderSeparateEntity,

            dateSettled: {
              label: `${new Date(propertyPortfolio.DateSettled).getFullYear()}`,
              value: `${new Date(propertyPortfolio.DateSettled).getFullYear()}`,
            },
            isDefaultDateSettled: propertyPortfolio.IsDefaultDateSettled
              ? "1"
              : "0",

            purchasePrice: formatCurrency(propertyPortfolio.PurchasePrice),
            stampDutyCost: formatCurrency(propertyPortfolio.StampDutyCost),
            lenderMortgageInsuranceCost: formatCurrency(
              propertyPortfolio.LenderMortgageInsuranceCost
            ),
            conveyencingCost: formatCurrency(
              propertyPortfolio.ConveyencingCost
            ),
            additionalCost: formatCurrency(propertyPortfolio.AdditionalCost),
            depreciationScheduleAvailable:
              propertyPortfolio.DepreciationScheduleAvailable,
            annualDepreciation: propertyPortfolio.AnnualDepreciation,
            currentLoanAmount: formatCurrency(propertyPortfolio.CurrentLoan),
            mostRecentLoanCommencedYear: {
              label: `${new Date(
                propertyPortfolio.MostRecentLoanCommencedYear
              ).getFullYear()}`,
              value: `${new Date(
                propertyPortfolio.MostRecentLoanCommencedYear
              ).getFullYear()}`,
            },
            // mostRecentLoanCommencedYear: new Date(
            //   propertyPortfolio.MostRecentLoanCommencedYear
            // ).getFullYear(),

            isDefaultMostRecentLoanCommencedYear:
              propertyPortfolio.IsDefaultMostRecentLoanCommencedYear
                ? "1"
                : "0",

            lender: propertyPortfolio.Lender,
            currentInterestRate: propertyPortfolio.CurrentInterestRate,
            isDefaultInterestRate: propertyPortfolio.IsDefaultInterestRate
              ? "1"
              : "0",

            originalLoanTerm: propertyPortfolio.OriginalLoanTerm,
            isDefaultOriginalLoanTerm:
              propertyPortfolio.IsDefaultOriginalLoanTerm ? "1" : "0",

            loanType: propertyPortfolio.LoanType,
            isDefaultLoanType: propertyPortfolio.IsDefaultLoanType ? "1" : "0",

            interestOnlyTerm: propertyPortfolio.InterestOnlyTerm,
            interestRateStructure: `${propertyPortfolio.InterestRateStructure}`,
            fixedTerm: propertyPortfolio.FixedTerm,
            autoCalculateMortgageRepyment:
              propertyPortfolio.AutoCalculateMortgageRepyment,
            monthlyRepayment: propertyPortfolio.MonthlyRepayment,
            currentRepaymentAmount: formatCurrency(
              propertyPortfolio.CurrentRepaymentAmount
            ),
            currentRepaymentFrequency: `${propertyPortfolio.CurrentRepaymentFrequency}`,
            splitLoan:
              propertyPortfolio.SplitLoan === true
                ? "Split loan"
                : "No split loan",
            isDefaultSplitLoan: propertyPortfolio.IsDefaultSplitLoan
              ? "1"
              : "0",
            splitLoanAmount: formatCurrency(propertyPortfolio.SplitLoanAmount),
            splitLoanDateBorrowed: {
              label: `${new Date(
                propertyPortfolio.SplitDateBorrowed
              ).getFullYear()}`,
              value: `${new Date(
                propertyPortfolio.SplitDateBorrowed
              ).getFullYear()}`,
            },
            isDefaultSplitLoanDateBorrowed:
              propertyPortfolio.IsDefaultSplitLoanDateBorrowed ? "1" : "0",

            isDefaultSplitLoanCurrentInterestRate:
              propertyPortfolio.IsDefaultSplitLoanCurrentInterestRate
                ? "1"
                : "0",

            splitLoanCurrentInterestRate:
              propertyPortfolio.SplitCurrentInterestRate,

            isDefaultSplitLoanTerm: propertyPortfolio.IsDefaultSplitLoanTerm
              ? "1"
              : "0",
            splitLoanTerm: propertyPortfolio.SplitOriginalTerm,

            isDefaultSplitLoanType: propertyPortfolio.IsDefaultSplitLoanType
              ? "1"
              : "0",
            splitLoanType: propertyPortfolio.SplitLoanType,

            splitLoanInetrOnlyTerm:
              propertyPortfolio.SplitOriginalIOPeriodYears,
            splitLoanInterestRateStructure:
              propertyPortfolio.SplitLoanInterestRateStructure,
            splitLoanFixedTerm: propertyPortfolio.SplitOriginalFixedTermYears,
            autoCalculateSplitLoanMortgageRepyment:
              propertyPortfolio.AutoCalculateSplitLoanMortgageRepyment,
            // splitLoanMonthlyRepayment: propertyPortfolio.SplitCurrentRepaymentAmount,
            splitLoanCurrentRepaymentAmount: formatCurrency(
              propertyPortfolio.SplitCurrentRepaymentAmount
            ),
            splitLoanRepaymentFrequency: `${propertyPortfolio.SplitRepaymentFrequency}`,
            lastRenovationAmount: formatCurrency(
              propertyPortfolio.LastRenovationAmount
            ),
            lastRenovationDate: {
              label: `${new Date(
                propertyPortfolio.LastRenovationDate
              ).getFullYear()}`,
              value: `${new Date(
                propertyPortfolio.LastRenovationDate
              ).getFullYear()}`,
            },
            lastRenovationDesc: propertyPortfolio.LastRenovationDesc,
            currentPropertyManager: propertyPortfolio.CurrentPropertyManager,
            managementPercentage: propertyPortfolio.ManagementPercentage,
            rentalIncomePerWeek: formatCurrency(
              propertyPortfolio.RentalIncomePerWeek
            ),
            externalLink: propertyPortfolio.ExternalLink,
            isDefaultPropertyExpensesCalculationMethod:
              propertyPortfolio.IsDefaultPropertyExpensesCalculationMethod
                ? "1"
                : "0",

            propertyExpensesCalculationMethod:
              propertyPortfolio.PropertyExpensesCalculationMethod,
            strataCost: formatCurrency(propertyPortfolio.StrataCost),
            strataCostFrequency: `${propertyPortfolio.StrataCostFrequency}`,
            councilCost: formatCurrency(propertyPortfolio.CouncilCost),
            councilCostFrequency: `${propertyPortfolio.CouncilCostFrequency}`,
            insuranceCost: formatCurrency(propertyPortfolio.InsuranceCost),
            insuranceCostFrequency: `${propertyPortfolio.InsuranceCostFrequency}`,
            maintenanceCost: formatCurrency(propertyPortfolio.MaintenanceCost),
            maintenanceCostFrequency: `${propertyPortfolio.MaintenanceCostFrequency}`,
            landTaxAmount: formatCurrency(propertyPortfolio.LandTaxAmount),
            landTaxAmountFrequency: `${propertyPortfolio.LandTaxAmountFrequency}`,
            accountingCost: formatCurrency(propertyPortfolio.AccountingCost),
            accountingCostFrequency: `${propertyPortfolio.AccountingCostFrequency}`,
            asicCost: formatCurrency(propertyPortfolio.AsicCost),
            asicCostFrequency: `${propertyPortfolio.AsicCostFrequency}`,
            waterCost: formatCurrency(propertyPortfolio.WaterCost),
            waterCostFequency: `${propertyPortfolio.WaterCostFequency}`,
            electricalCost: formatCurrency(propertyPortfolio.ElectricalCost),
            electricalCostFrequency: `${propertyPortfolio.ElectricalCostFrequency}`,
            gasCost: formatCurrency(propertyPortfolio.GasCost),
            gasCostFrequency: propertyPortfolio.GasCostFrequency,
            internetCost: formatCurrency(propertyPortfolio.InternetCost),
            internetCostFrequency: `${propertyPortfolio.InternetCostFrequency}`,
            totalExpenses: formatCurrency(propertyPortfolio.TotalExpenses),
            totalExpensesFrequency: propertyPortfolio.ExpensesFrequency,
            isDefaultTotalExpensesFrequency:
              propertyPortfolio.IsDefaultTotalExpensesFrequency ? "1" : "0",

            clientId: propertyPortfolio?.clientId,
          };
          console.log("property details: ", propertyData);
          setFormData(propertyData);
          setIsUpdate(1);
        } else {
          setFormData([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setFormData([]);
      });
  };

  useEffect(() => {
    const isUpdate =
      window.location.pathname
        .substr(1, window.location.pathname.length)
        .split("/").length === 5
        ? true
        : false;
    if (isUpdate) {
      getPropertyDetails();
    }
  }, [clientState?.clientId]);

  useEffect(() => {
    if (activeStep != 0) {
      document
        ?.getElementById(`step${activeStep}`)
        ?.scrollIntoView({ behavior: "smooth" });
    }

    return () => {};
  }, [activeStep]);

  useEffect(() => {
    getDefaultAssumptions();
    console.log("Assumption", defaultAssumptions);
  }, []);

  const [propertyPortfolioData, setPropertyPortfolioData] = useState({});

  const UpdatePortfolioData = () => {
    return {
      address: formData.address,

      isDefaultPropertyType: formData.isDefaultPropertyType,
      propertyType:
        !formData.propertyType || formData.propertyType === ""
          ? defaultAssumptions.PropertyType
          : formData.propertyType,

      dwellingType: formData.dwellingType,
      noOfBeds: formData.noOfBeds,
      noOfBaths: formData.noOfBaths,
      noOfCarSpaces: formData.noOfCarSpaces,
      currentMarketValue: currencyToNumber(formData.currentMarketValue),
      excludeFromCalculation: formData.excludeFromCalculation,
      yearBuild:
        formData.yearBuild?.value === "" || !formData.yearBuild?.value
          ? {
              label: `${new Date().getFullYear()}`,
              value: `${new Date().getFullYear()}`,
            }
          : formData.yearBuild,
      landSize: formData.landSize,
      floorSize: formData.floorSize,
      ownerType: formData.ownerType,
      ownerName: formData.ownerName,
      ownershipPercent: formData.ownershipPercent,
      underSeparateEntity: formData.underSeparateEntity,

      dateSettled:
        !formData.dateSettled?.value || formData.dateSettled?.value === ""
          ? {
              label: `${
                new Date().getFullYear() - defaultAssumptions.DateSettled
              }`,
              value: `${
                new Date().getFullYear() - defaultAssumptions.DateSettled
              }`,
            }
          : formData.dateSettled,

      isDefaultDateSettled: formData.isDefaultDateSettled,

      purchasePrice: currencyToNumber(formData.purchasePrice),
      stampDutyCost: currencyToNumber(formData.stampDutyCost),
      lenderMortgageInsuranceCost: currencyToNumber(
        formData.lenderMortgageInsuranceCost
      ),
      conveyencingCost: currencyToNumber(formData.conveyencingCost),
      additionalCost: currencyToNumber(formData.additionalCost),
      depreciationScheduleAvailable: formData.depreciationScheduleAvailable,
      annualDepreciation: formData.annualDepreciation,
      currentLoanAmount: currencyToNumber(formData.currentLoanAmount),

      mostRecentLoanCommencedYear:
        !formData.mostRecentLoanCommencedYear?.value ||
        formData.mostRecentLoanCommencedYear?.value === ""
          ? {
              label: `${
                new Date().getFullYear() -
                defaultAssumptions.MostRecentLoanCommencedYear
              }`,
              value: `${
                new Date().getFullYear() -
                defaultAssumptions.MostRecentLoanCommencedYear
              }`,
            }
          : formData.mostRecentLoanCommencedYear,

      isDefaultMostRecentLoanCommencedYear:
        formData.isDefaultMostRecentLoanCommencedYear,

      lender: formData.lender,

      currentInterestRate:
        !formData.currentInterestRate || formData.currentInterestRate === 0
          ? defaultAssumptions.InterestRate
          : formData.currentInterestRate,

      isDefaultInterestRate: formData.isDefaultInterestRate,

      originalLoanTerm: !formData.originalLoanTerm
        ? defaultAssumptions.OriginalLoanTerm
        : formData.originalLoanTerm,

      isDefaultOriginalLoanTerm: formData.isDefaultOriginalLoanTerm,

      isDefaultLoanType: formData.isDefaultLoanType,

      loanType:
        !formData.loanType || formData.loanType === ""
          ? defaultAssumptions.LoanType
          : formData.loanType,

      interestOnlyTerm: formData.interestOnlyTerm,
      interestRateStructure: `${formData.interestRateStructure}`,
      fixedTerm: formData.fixedTerm,
      autoCalculateMortgageRepyment: formData.autoCalculateMortgageRepyment,
      currentRepaymentAmount: currencyToNumber(formData.currentRepaymentAmount),
      currentRepaymentFrequency: `${formData.currentRepaymentFrequency}`,

      isDefaultSplitLoan: formData.isDefaultSplitLoan,

      splitLoan:
        formData.splitLoan === "" || !formData.splitLoan
          ? defaultAssumptions.SplitLoan === false
            ? "No split loan"
            : "Split loan"
          : formData.splitLoan,

      splitLoanAmount: formData.splitLoanAmount,

      isDefaultSplitLoanDateBorrowed: formData.isDefaultSplitLoanDateBorrowed,

      splitLoanDateBorrowed:
        !formData.splitLoanDateBorrowed?.value ||
        formData.splitLoanDateBorrowed?.value === ""
          ? {
              label: `${
                new Date().getFullYear() -
                defaultAssumptions.MostRecentLoanCommencedYear
              }`,
              value: `${
                new Date().getFullYear() -
                defaultAssumptions.MostRecentLoanCommencedYear
              }`,
            }
          : formData.splitLoanDateBorrowed,

      isDefaultSplitLoanCurrentInterestRate:
        formData.isDefaultSplitLoanCurrentInterestRate,

      splitLoanCurrentInterestRate:
        !formData.splitLoanCurrentInterestRate ||
        formData.splitLoanCurrentInterestRate === 0
          ? defaultAssumptions.InterestRate
          : formData.splitLoanCurrentInterestRate,

      isDefaultSplitLoanTerm: formData.isDefaultSplitLoanTerm,
      splitLoanTerm:
        !formData.splitLoanTerm || formData.splitLoanTerm === 0
          ? defaultAssumptions.OriginalLoanTerm
          : formData.splitLoanTerm,

      isDefaultSplitLoanType: formData.isDefaultSplitLoanType,

      splitLoanType:
        !formData.splitLoanType || formData.splitLoanType === ""
          ? defaultAssumptions.LoanType
          : formData.splitLoanType,

      splitLoanInetrOnlyTerm: formData.splitLoanInetrOnlyTerm,
      splitLoanInterestRateStructure: formData.splitLoanInterestRateStructure,
      splitLoanFixedTerm: formData.splitLoanFixedTerm,
      autoCalculateSplitLoanMortgageRepyment:
        formData.autoCalculateSplitLoanMortgageRepyment,
      splitLoanMonthlyRepayment: 0,
      splitLoanCurrentRepaymentAmount: currencyToNumber(
        formData.splitLoanCurrentRepaymentAmount
      ),
      splitLoanRepaymentFrequency: formData.splitLoanRepaymentFrequency,
      lastRenovationAmount: currencyToNumber(formData.lastRenovationAmount),
      // lastRenovationDate: `${formData.lastRenovationDate?.value}`,
      lastRenovationDate:
        formData.lastRenovationDate?.value === "" ||
        !formData.lastRenovationDate?.value
          ? {
              label: `${new Date().getFullYear()}`,
              value: `${new Date().getFullYear()}`,
            }
          : formData.lastRenovationDate,
      lastRenovationDesc: formData.lastRenovationDesc,
      currentPropertyManager: formData.currentPropertyManager,
      managementPercentage: formData.managementPercentage,
      // rentalIncomePerWeek: currencyToNumber(formData.rentalIncomePerWeek),

      rentalIncomePerWeek:
        currencyToNumber(formData.currentMarketValue) === 0 &&
        currencyToNumber(formData.rentalIncomePerWeek) === 0
          ? (currencyToNumber(formData.currentMarketValue) *
              defaultAssumptions.RentalYield) /
            100 /
            52
          : currencyToNumber(formData.rentalIncomePerWeek),

      externalLink: formData.externalLink,

      isDefaultPropertyExpensesCalculationMethod:
        formData.isDefaultPropertyExpensesCalculationMethod,

      propertyExpensesCalculationMethod:
        !formData.propertyExpensesCalculationMethod ||
        formData.propertyExpensesCalculationMethod === ""
          ? defaultAssumptions.PropertyExpensesCalculationMethod
          : formData.propertyExpensesCalculationMethod,

      strataCost: currencyToNumber(formData.strataCost),
      strataCostFrequency: `${formData.strataCostFrequency}`,
      councilCost: currencyToNumber(formData.councilCost),
      councilCostFrequency: `${formData.councilCostFrequency}`,
      insuranceCost: currencyToNumber(formData.insuranceCost),
      insuranceCostFrequency: `${formData.insuranceCostFrequency}`,
      maintenanceCost: currencyToNumber(formData.maintenanceCost),
      maintenanceCostFrequency: `${formData.maintenanceCostFrequency}`,
      landTaxAmount: currencyToNumber(formData.landTaxAmount),
      landTaxAmountFrequency: `${formData.landTaxAmountFrequency}`,
      accountingCost: currencyToNumber(formData.accountingCost),
      accountingCostFrequency: `${formData.accountingCostFrequency}`,
      asicCost: currencyToNumber(formData.asicCost),
      asicCostFrequency: `${formData.asicCostFrequency}`,
      waterCost: currencyToNumber(formData.waterCost),
      waterCostFequency: `${formData.waterCostFequency}`,
      electricalCost: currencyToNumber(formData.electricalCost),
      electricalCostFrequency: `${formData.electricalCostFrequency}`,
      gasCost: currencyToNumber(formData.gasCost),
      gasCostFrequency: `${formData.gasCostFrequency}`,
      internetCost: currencyToNumber(formData.internetCost),
      internetCostFrequency: `${formData.internetCostFrequency}`,
      totalExpenses: currencyToNumber(formData.totalExpenses),
      isDefaultTotalExpensesFrequency: `${formData.isDefaultTotalExpensesFrequency}`,
      totalExpensesFrequency: `${formData.totalExpensesFrequency}`,

      totalExpensesFrequency:
        !formData.totalExpensesFrequency ||
        formData.totalExpensesFrequency === ""
          ? defaultAssumptions.TotalExpensesFrequency
          : formData.totalExpensesFrequency,

      clientId: clientState?.clientId,
      userId: userState.userId,
    };
  };

  const handleNext = () => {
    const propertyId = formData.id;
    console.log("property portfolio id: ", formData.id);
    console.log("property form data: ", formData);

    const propertyPortfolioData = UpdatePortfolioData();

    console.log("property data: ", propertyPortfolioData);
    if (activeStep === stepLabels.length - 1) {
      if (propertyId === undefined || propertyId === 0) {
        console.log("Property plan Id: ", propertyId);
        PropertyPortfolioService.add(propertyPortfolioData)
          .then((response) => {
            setOpen(true);
            setTimeout(() => {
              redirectToTracker();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        PropertyPortfolioService.update(propertyId, propertyPortfolioData)
          .then((response) => {
            setOpen(true);
            setTimeout(() => {
              redirectToList();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    if (activeStep === 0) {
      // Property Detail
      if (!validateFormPropertyDetails()) {
        return;
      }
    }
    if (activeStep === 2) {
      // Owner Detail
      if (!validateFormOwnerDetails()) {
        return;
      }
    }
    if (activeStep === 3) {
      // Cost Detail
      if (!validateFormCostDetails()) {
        return;
      }
    }
    if (activeStep === 4) {
      // Loan Detail
      if (!validateFormLoanDetails()) {
        return;
      }
    }
    if (activeStep === 5) {
      // Split Loan Detail
      if (!validateFormSplitLoanDetails()) {
        return;
      }
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleFinish = () => {
    const propertyPortfolioId = formData.id;
    console.log("form data: ", formData);
    const propertyPortfolioData = UpdatePortfolioData();

    console.log("property portfolio id: ", propertyPortfolioId);
    console.log("property portfolio: ", propertyPortfolioData);
    PropertyPortfolioService.update(propertyPortfolioId, propertyPortfolioData)
      .then((response) => {
        setOpen(true);
        setTimeout(() => {
          redirectToList();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content portfolio-add">
      <Container>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message="Property is saved."
          action={action}
        ></Snackbar>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {stepLabels.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step
                  id={`step${index}`}
                  key={label}
                  {...stepProps}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "#114253", // circle color (COMPLETED)
                    },
                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                      {
                        color: "#114253", // Just text label (COMPLETED)
                      },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "#e61a4b", // circle color (ACTIVE)
                    },
                    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                      {
                        color: "white", // Just text label (ACTIVE)
                      },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "white", // circle's number (ACTIVE)
                    },
                  }}
                  onClick={() => goToStep(index)}
                  style={{ cursor: "pointer" }}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>{steps[activeStep]}</div>

          {activeStep === stepLabels.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  variant="contained"
                  color="grey"
                  size="medium"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              <div className="back-next-buttons">
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    className="back_btn"
                    // color="inherit"
                    variant="contained"
                    color="grey"
                    size="medium"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />

                  <Button
                    className="next_btn"
                    variant="contained"
                    color="grey"
                    size="medium"
                    onClick={handleNext}
                  >
                    {activeStep === stepLabels.length - 1 ? "Finish" : "Next"}
                  </Button>
                  <Button
                    // onClick={handleFinish}
                    onClick={handleFinish}
                    variant="contained"
                    color="grey"
                    size="medium"
                    sx={{
                      display:
                        isUpdate && activeStep !== stepLabels.length - 1
                          ? "block"
                          : "none",
                    }}
                  >
                    Finish
                  </Button>
                </Box>
              </div>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default PropertyPlanAdd;
