import axios from "axios";
export default axios.create({
  // baseURL: "http://localhost:5000/api/v1",
  baseURL: "https://trbsimplenodeapi.azurewebsites.net/api/v1",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // Authorization: "!2345",
  },
});
