import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { selectedClientAtom } from "store";
import { useState } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function PopupWithClose(props) {
  // const history = useHistory();

  return (
    <React.Fragment>
      <BootstrapDialog
        // className set to this to apply style
        className="client-not-selected-popup"
        open={props.open}
        onClose={props.handleClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="popup-with-close-title"
        aria-describedby="popup-with-close-description"
      >
        <DialogTitle id="popup-with-close-title">{props.title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="popup-with-close-description">
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Ok</Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
