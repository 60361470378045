import http from "./http-common";

class FamilyMemberService {
  // http://localhost:5000/api/v1/scoreboard/family-member/add
  add(data) {
    const path = "/scoreboard/family-member";
    console.log("data: ", data);
    return http.post(`${path}/add`, data);
  }

  // http://localhost:5000/api/v1/scoreboard/family-member/get/:id
  getAllByClientId(clientId) {
    const path = "/scoreboard/family-member";
    console.log("path: ", `${path}/get/${clientId}`);
    return http.get(`${path}/get/${clientId}`);
  }

  // http://localhost:5000/api/v1/scoreboard/family-member/get/:id
  getByFamilyMemberId(familyMemberId) {
    const path = `/scoreboard/family-member/get/member/${familyMemberId}`;
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/family-member/get/names/:id
  getFamilyMemberNamesByClientId(clientId) {
    const path = `/scoreboard/family-member/get/names/${clientId}`;
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/family-member/get/:id
  update(id, data) {
    const path = "/scoreboard/family-member";
    return http.put(`${path}/update/${id}`, data);
  }

  // http://localhost:5000/api/v1/scoreboard/family-member/delete/:id
  delete(id) {
    const path = "/scoreboard/family-member";
    return http.delete(`${path}/delete/${id}`);
  }
}

export default new FamilyMemberService();
