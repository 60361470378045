import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
  Container,
} from "reactstrap";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "react-datetime/css/react-datetime.css";
import "dayjs/locale/en-gb";
import Moment from "moment";
// import Select from "react-select";
import Select from "@mui/material/Select";
import { selectedClientAtom } from "store";
import { useAtom } from "jotai";
import "moment/locale/en-gb";
import PropertyPortfolioService from "services/property-portfolio.service";
import YearlyPlanRenovationService from "services/yearly-plan-renovation.service";
import RefinanceService from "services/refinance.service";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { formatCurrency } from "utils/currency";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LoanSwitchStep = ({ formData, setFormData }) => {
  const [clientState] = useAtom(selectedClientAtom);

  const [properties, setProperties] = useState([]);
  const [existingLoanSwitches, setExistingLoanSwitches] = useState([]);

  const loanAmountOptions = [
    { key: 1, value: "Outstanding balance" },
    { key: 2, value: "LVR" },
    { key: 3, value: "Set amount" },
  ];
  const loanTypes = ["Interest only", "Principal & interest"];
  const loanTerms = [25, 30];
  const ratios = [0, 25, 50, 60, 70, 80, 85, 90, 95, 100, 105, 110];

  const gridBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    // padding: '20px 20px 20px 32px',
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  useEffect(() => {
    getProperties(clientState.clientId);
    getLoanSwitches(formData?.yearlyPlanId);
  }, [clientState.clientId, formData?.yearlyPlanId]);

  const getProperties = async (clientId) => {
    return PropertyPortfolioService.getNonExcludedFromCalcForLoanSwitchDropdownByClientId(
      clientId
    ).then((response) => {
      console.log("response: ", response.data.recordset);
      if (response.data.recordset.length > 0) {
        const result = response.data.recordset.map((property) => ({
          propertyId: property.value,
          propertyName: property.label,
        }));
        console.log("result : ", result);
        setProperties(result);
      }
    });
  };

  const getLoanSwitches = async (yearlyPlanId) => {
    return RefinanceService.getAllByYearlyPlanId(yearlyPlanId).then(
      (response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset;
          console.log("result : ", result);
          console.log(
            "list of strings",
            result
              .map(({ ClientPropertyId, PropertyLabel }) => ({
                propertyId: ClientPropertyId,
                propertyName: PropertyLabel,
              }))
              .map((p) => p.propertyName)
          );
          setFormData({
            ...formData,
            loanSwitchProperties: result.map(
              ({ ClientPropertyId, PropertyLabel }) => ({
                propertyId: ClientPropertyId,
                propertyName: PropertyLabel,
              })
            ),
          });
          setExistingLoanSwitches(result);
        }
      }
    );
  };

  const handlePropertyChange = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        const currProperty = properties.find(
          (p) => p.propertyName === options[i].value
        );
        value.push({
          propertyId: currProperty.propertyId,
          propertyName: options[i].value,
        });
      }
    }
    console.log("property change:  ", value);
    setFormData({
      ...formData,
      // On autofill we get a stringified value.
      loanSwitchProperties:
        typeof value === "string" ? value.split(",") : value,
    });
    // console.log("property change:  ", property);
    // console.log("Existing Loan Switches:  ", existingLoanSwitches);
    // if (!(property.value === formData.refinanceClientPropertyId)) {
    //   const existingDataIndex = existingLoanSwitches.findIndex(
    //     (r) => r.ClientPropertyId === property.value
    //   );
    //   if (existingDataIndex > -1) {
    //     console.log("setting: ", {
    //       ...formData,
    //       reFinanceId: existingLoanSwitches[existingDataIndex].ReFinanceId,
    //       refinanceClientPropertyId: property.value,
    //       refinanceLoanAmountOption:
    //         existingLoanSwitches[existingDataIndex].RefinanceLoanAmountOption,
    //       refinanceInterestRate:
    //         existingLoanSwitches[existingDataIndex].RefinanceInterestRate,
    //       refinanceLoanRpType:
    //         existingLoanSwitches[existingDataIndex].RefinanceLoanRpType,
    //       refinanceLoanTerm:
    //         existingLoanSwitches[existingDataIndex].RefinanceLoanTerm,
    //       refinanceLVR: existingLoanSwitches[existingDataIndex].RefinanceLVR,
    //       refinanceLoanAmount:
    //         existingLoanSwitches[existingDataIndex].RefinanceLoanAmount,
    //     });
    //     setFormData({
    //       ...formData,
    //       reFinanceId: existingLoanSwitches[existingDataIndex].ReFinanceId,
    //       refinanceClientPropertyId: property.value,
    //       refinanceLoanAmountOption:
    //         existingLoanSwitches[existingDataIndex].RefinanceLoanAmountOption,
    //       refinanceInterestRate:
    //         existingLoanSwitches[existingDataIndex].RefinanceInterestRate,
    //       refinanceLoanRpType:
    //         existingLoanSwitches[existingDataIndex].RefinanceLoanRpType,
    //       refinanceLoanTerm:
    //         existingLoanSwitches[existingDataIndex].RefinanceLoanTerm,
    //       refinanceLVR: existingLoanSwitches[existingDataIndex].RefinanceLVR,
    //       refinanceLoanAmount: formatCurrency(
    //         existingLoanSwitches[existingDataIndex].RefinanceLoanAmount
    //       ),
    //     });
    //   } else {
    //     setFormData({
    //       ...formData,
    //       reFinanceId: null,
    //       refinanceClientPropertyId: property.value,
    //       refinanceLoanAmountOption: null,
    //       refinanceInterestRate: null,
    //       refinanceLoanRpType: null,
    //       refinanceLoanTerm: null,
    //       refinanceLVR: null,
    //       refinanceLoanAmount: formatCurrency(0),
    //     });
    //   }
    // }
  };

  console.log("form data: ", formData);
  return (
    <div className="loan-switch-modal main-pad">
      <Card>
        <CardHeader className="modal-year-pad">
          <CardTitle tag="h4">{formData?.year}</CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Container>
              <Row>
                <Col md="12">
                  <Label>Switch property loan type</Label>
                  <FormGroup>
                    {/* <Select
                      name="switch-property"
                      className="react-select  select-option-control"
                      placeholder=""
                      classNamePrefix="react-select"
                      value={properties.find(
                        (p) => p.value === formData?.refinanceClientPropertyId
                      )}
                      onChange={handlePropertyChange}
                      options={properties}
                    /> */}
                    <Select
                      // labelId="switch-property-label"
                      inputProps={{
                        id: "switch-property",
                      }}
                      // className="react-select  select-option-control"
                      // classNamePrefix="react-select"
                      // placeholder=""
                      native
                      multiple
                      sx={{
                        width: "70vw",
                      }}
                      value={formData?.loanSwitchProperties?.map(
                        (p) => p.propertyName
                      )}
                      onChange={handlePropertyChange}
                      // input={<OutlinedInput style={{ width: "100%" }} />}
                    >
                      {properties.map(({ propertyId, propertyName }) => (
                        <option key={propertyId} value={propertyName}>
                          {propertyName}
                        </option>
                        // <MenuItem key={value} value={value}>
                        //   <Checkbox
                        //     checked={
                        //       formData?.loanSwitchProperties.indexOf(value) > -1
                        //     }
                        //   />
                        //   <ListItemText primary={label} />
                        // </MenuItem>
                      ))}
                    </Select>
                    {/* <Autocomplete
                      multiple
                      value={formData?.loanSwitchProperties}
                      onChange={(_event, value) => {
                        // console.log(value);
                        handlePropertyChange(value);
                        // setValue(typeof value === 'string' ? value.split(',') : value);
                      }}
                      id="loan-switch-property"
                      options={properties}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.propertyName}
                      isOptionEqualToValue={(option, value) =>
                        option.propertyId === value.propertyId
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.propertyName}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField {...params} label="" placeholder="" />
                      )}
                    /> */}
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Switch interest rate</Label>
                  <FormGroup>
                    <Input
                      placeholder="Switch interest rate"
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      value={formData?.refinanceInterestRate || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          refinanceInterestRate: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Switch loan amount</Label>
                  <FormGroup>
                    <CurrencyInput
                      placeholder="Switch loan amount"
                      number={formData?.refinanceLoanAmount}
                      handleNumberChange={(value) =>
                        setFormData({ ...formData, refinanceLoanAmount: value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Label>Switch option</Label>
                  <FormGroup>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                      className="big_box-group"
                    >
                      {loanAmountOptions.map((loanAmountObj) => (
                        <Grid
                          sx={{ flexDirection: "column", padding: "4px" }}
                          key={loanAmountObj.key}
                          className="dialog_big_box"
                        >
                          <Box
                            onClick={() =>
                              setFormData({
                                ...formData,
                                refinanceLoanAmountOption: loanAmountObj.key,
                              })
                            }
                            key={loanAmountObj.key}
                            sx={gridBoxSx}
                            style={{
                              backgroundColor:
                                formData?.refinanceLoanAmountOption ===
                                loanAmountObj.key
                                  ? "#e61a4b"
                                  : "#114253",
                            }}
                          >
                            {loanAmountObj.value}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Label>Switch loan type</Label>
                  <FormGroup>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                      className="big_box-group"
                    >
                      {loanTypes.map((loanType) => (
                        <Grid
                          sx={{ flexDirection: "column", padding: "4px" }}
                          key={loanType}
                          className="dialog_big_box"
                        >
                          <Box
                            onClick={() =>
                              setFormData({
                                ...formData,
                                refinanceLoanRpType: loanType,
                              })
                            }
                            key={loanType}
                            sx={gridBoxSx}
                            style={{
                              backgroundColor:
                                formData?.refinanceLoanRpType === loanType
                                  ? "#e61a4b"
                                  : "#114253",
                            }}
                          >
                            {loanType}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Label>Switch loan term</Label>
                  <FormGroup>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {loanTerms.map((loanTerm) => (
                        <Grid
                          className="small_box"
                          sx={{ flexDirection: "column", padding: "4px" }}
                          key={loanTerm}
                        >
                          <Box
                            onClick={() =>
                              setFormData({
                                ...formData,
                                refinanceLoanTerm: loanTerm,
                              })
                            }
                            key={loanTerm}
                            sx={gridBoxSx}
                            style={{
                              backgroundColor:
                                formData?.refinanceLoanTerm === loanTerm
                                  ? "#e61a4b"
                                  : "#114253",
                            }}
                          >
                            {loanTerm}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Label>Switch loan to value ratio</Label>
                  <FormGroup>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {ratios.map((lvr) => (
                        <Grid
                          className="small_box"
                          sx={{ flexDirection: "column", padding: "4px" }}
                          key={lvr}
                        >
                          <Box
                            onClick={() =>
                              setFormData({
                                ...formData,
                                refinanceLVR: lvr,
                              })
                            }
                            key={lvr}
                            sx={gridBoxSx}
                            style={{
                              backgroundColor:
                                formData?.refinanceLVR === lvr
                                  ? "#e61a4b"
                                  : "#114253",
                            }}
                          >
                            {lvr.toLocaleString(navigator.language, {
                              minimumFractionDigits: 0,
                            })}
                            %
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
};

export default LoanSwitchStep;
