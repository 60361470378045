/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ClientService from "services/client.service";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import moment from "moment";
import { IconButton, Snackbar } from "@mui/material";
import { Container } from "@mui/material";

const ClientList = () => {
  const [rowData, setRowData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [isExternalPartner, setIsExternalPartner] = useState(false);
  const [isInternalUser, setIsInternaluser] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedClientState, setSelectedClientState] =
    useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const history = useHistory();
  const [loginUser, setLoginUser] = useState({});
  const [searchText, setSearchText] = useState("");
  const [registerUrl, setRegisterUrl] = useState("");
  const [copied, setCopied] = useState(false);
  // const userType = () => window.localStorage.getItem('UserType');

  // const [hoveredRow, setHoveredRow] = useState(null);

  // const handleRowHover = (params) => {
  //   setHoveredRow(params.row.id);
  // };
  useEffect(() => {
    const jwtToken = window.sessionStorage.getItem("jwt-token");
    const loginUser = JSON.parse(window.localStorage.getItem("login-user"));
    const selectedClient = JSON.parse(
      window.localStorage.getItem("selected-client")
    );

    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
    } else {
      // read from user state
      // if userId === 0  means it has lost the value
      // then push the value from localstorage to user state
      setLoginUser(loginUser);
      // if (userState.userId === 0) {
      //   setUserState(loginUser);
      // }
      getClients();
      getUserInfo(userState.userId);
    }
  }, [isDataLoaded, userState.userId]);

  const getClients = async () => {
    // get the clients if userType is Advisor
    // get all the clients if userType is Admin
    // do not show this page if userType is Client
    // const user = JSON.parse(window.localStorage.getItem('User'));
    console.log("User State -", userState);
    if (userState.isAdmin) {
      setIsAdmin(true);
      setIsPartner(false);
      setIsExternalPartner(false);
      setIsInternaluser(false);
      ClientService.getAll()
        .then((response) => {
          let clients;
          if (response.data.recordset.length > 0) {
            clients = response.data.recordset.map((client) => {
              return {
                id: client.ClientId,
                firstName: client.ClientFirstName,
                lastName: client.ClientLastName,
                email: client.ClientEmail,
                clientType: client.ClientTypeDesc,
                score: client.ClientScore || 0,
                mobileNumber: client.MobileNumber,
                fullAccess: client.FullAccess === true ? "Yes" : "No",
                dateCreated: client.DateCreated
                  ? moment(client.DateCreated).format("DD MMM YY")
                  : "",
              };
            });
          }
          if (response.data.recordsets.length > 0) {
            setClientData(clients);
            setRowData(clients);
            console.log("client data: ", clients);
            setIsDataLoaded(true);
          } else {
            setIsDataLoaded(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setClientData([]);
          setRowData([]);
        });
    } else if (userState.isPartner) {
      setIsAdmin(false);
      setIsPartner(true);
      setIsExternalPartner(false);
      setIsInternaluser(false);

      ClientService.getAllByPartnerId(userState.userId)
        .then((response) => {
          let clients = response.data.recordset.map((client) => {
            return {
              id: client.ClientId,
              firstName: client.ClientFirstName,
              lastName: client.ClientLastName,
              email: client.ClientEmail,
              clientType: client.ClientTypeDesc,
              score: client.ClientScore || 0,
              mobileNumber: client.MobileNumber,
              fullAccess: client.FullAccess === true ? "Yes" : "No",
              dateCreated: client.DateCreated
                ? moment(client.DateCreated).format("DD MMM YY")
                : "",
            };
          });
          if (response.data.recordsets.length > 0) {
            setClientData(clients);
            setRowData(clients);
            console.log("response: ", response);
            setIsDataLoaded(true);
          } else {
            setIsDataLoaded(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setClientData([]);
          setRowData([]);
        });
    } else if (userState.isExternalPartner) {
      setIsAdmin(false);
      setIsPartner(false);
      setIsExternalPartner(true);
      setIsInternaluser(false);

      ClientService.getAllByPartnerId(userState.userId)
        .then((response) => {
          console.log(
            "Client List - response.data.recordset",
            response.data.recordset
          );
          let clients = response.data.recordset.map((client) => {
            return {
              id: client.ClientId,
              firstName: client.ClientFirstName,
              lastName: client.ClientLastName,
              email: client.ClientEmail,
              clientType: client.ClientTypeDesc,
              score: client.ClientScore || 0,
              mobileNumber: client.MobileNumber,
              fullAccess: client.FullAccess === true ? "Yes" : "No",
              dateCreated: client.DateCreated
                ? moment(client.DateCreated).format("DD MMM YY")
                : "",
            };
          });
          if (response.data.recordsets.length > 0) {
            setClientData(clients);
            setRowData(clients);
            console.log("response: ", response);
            setIsDataLoaded(true);
          } else {
            setIsDataLoaded(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setClientData([]);
          setRowData([]);
        });
    } else if (userState.isInternalUser) {
      setIsAdmin(false);
      setIsPartner(false);
      setIsExternalPartner(false);
      setIsInternaluser(true);

      ClientService.getAllByInternalUserId(userState.userId)
        .then((response) => {
          console.log(
            "Client List - response.data.recordset",
            response.data.recordset
          );
          let clients = response.data.recordset.map((client) => {
            return {
              id: client.ClientId,
              firstName: client.ClientFirstName,
              lastName: client.ClientLastName,
              email: client.ClientEmail,
              clientType: client.ClientTypeDesc,
              score: client.ClientScore || 0,
              mobileNumber: client.MobileNumber,
              fullAccess: client.FullAccess === true ? "Yes" : "No",
              dateCreated: client.DateCreated
                ? moment(client.DateCreated).format("DD MMM YY")
                : "",
            };
          });
          if (response.data.recordsets.length > 0) {
            setClientData(clients);
            setRowData(clients);
            console.log("response: ", response);
            setIsDataLoaded(true);
          } else {
            setIsDataLoaded(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setClientData([]);
          setRowData([]);
        });
    } else {
      // just return
    }
  };

  const getUserInfo = async (userId) => {
    ClientService.getByClientId(userId)
      .then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset[0];
          setRegisterUrl(
            `${window.location.origin}/auth/client/register-client?uuid=${result.Uuid}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setClientData([]);
        setRowData([]);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 150 },
    { field: "lastName", headerName: "Last name", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "clientType", headerName: "Client Type", width: 140 },
    { field: "score", headerName: "Score", width: 120 },
    { field: "mobileNumber", headerName: "Mobile Number", width: 160 },
    { field: "fullAccess", headerName: "Full Access", width: 100 },
    { field: "dateCreated", headerName: "Date Created", width: 200 },
  ];

  const handleRowClick = (client) => {
    console.log("row: ", client.row);
    const selectedClient = {
      clientId: client.row.id,
      firstName: client.row.firstName,
      lastName: client.row.lastName,
      fullAccess: client.row.fullAccess === "Yes",
    };
    setSelectedClientState({
      clientId: selectedClient.clientId,
      firstName: selectedClient.firstName,
      lastName: selectedClient.lastName,
      fullAccess: selectedClient.fullAccess,
    });
    // window.localStorage.setItem('SelectedClient', JSON.stringify(selectedClient));
    history.push("/admin/scoreboard/index");
  };

  const handleAdd = () => {
    const path = "/admin/client/add-client";
    history.push(path);
  };

  const handleSearch = (text) => {
    // const textToSearch = text !== undefined ? text : searchText;
    // console.log("text ", text, searchText, textToSearch);
    setRowData(
      clientData.filter(
        (client) =>
          client.firstName.toLowerCase().includes(text) ||
          client.lastName.toLowerCase().includes(text)
      )
    );
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(registerUrl); // Copies the link to the clipboard
    setCopied(true);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setCopied(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content client-list-content">
      {(isAdmin || isPartner || isExternalPartner || isInternalUser) && (
        <Container>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Client List</CardTitle>
                  {!isInternalUser && (
                    <div className="card_bt">
                      <Button
                        sx={{ float: "right" }}
                        variant="contained"
                        color="grey"
                        size="medium"
                        startIcon={<SaveIcon />}
                        onClick={handleAdd}
                      >
                        ADD
                      </Button>
                    </div>
                  )}
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="client-lost-header-fields">
                        {userState.isPartner && (
                          <div className="client-list-url-copy">
                            <TextField
                              id="register-url"
                              variant="standard"
                              value={registerUrl}
                              disabled
                              fullWidth
                            />

                            <Button
                              variant="contained"
                              color="grey"
                              size="medium"
                              startIcon={<FileCopyIcon />}
                              onClick={handleCopy}
                            >
                              Copy
                            </Button>
                            <Snackbar
                              open={copied}
                              autoHideDuration={5000}
                              onClose={() => setCopied(false)}
                              message="Copied!"
                              action={action}
                            />
                          </div>
                        )}
                        <div
                          className={`client-list-search ${
                            userState.isPartner ? "" : "client-list-url-hidden"
                          }`}
                        >
                          <TextField
                            sx={{ width: "25%" }}
                            id="search-text"
                            label="Search"
                            variant="standard"
                            value={searchText}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                              handleSearch(e.target.value.toLowerCase());
                            }}
                          />
                          <Button
                            // style={{ top: 7, left: 5 }}
                            variant="contained"
                            color="grey"
                            size="medium"
                            startIcon={<SearchIcon />}
                            onClick={(e) => {
                              handleSearch(searchText.toLowerCase());
                            }}
                          >
                            Search
                          </Button>
                        </div>
                      </div>
                      <div
                        className={`${
                          userState.isPartner ? "clinet-list-row-data" : ""
                        }`}
                      >
                        <Row>
                          <Col md="12">
                            <div style={{ height: 525, width: "100%" }}>
                              <DataGrid
                                rows={rowData}
                                columns={columns}
                                initialState={{
                                  pagination: {
                                    paginationModel: { page: 0, pageSize: 25 },
                                  },
                                }}
                                pageSizeOptions={[5, 10]}
                                onRowClick={handleRowClick}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};
export default ClientList;
