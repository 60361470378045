import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Row,
  Col,
  Label,
} from "reactstrap";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import PortfolioAnalysisService from "../../../services/portfolio-analysis.service";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import { Container } from "@mui/material";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

const PortfolioAnalysis = () => {
  const [requiredState, setrequiredState] = useState("");
  const investmentPlan = ["Sit & hold", "Renovate", "Sell"];
  const portfolioStatus = ["Very happy", "Happy with some", "Very unhappy"];
  const areaOfAttention = [
    "Growth performance",
    "Cashflow performance",
    "Renovation",
  ];

  let yellow = "#fbc658";
  const history = useHistory();
  const [selectedInvestmentPlan, setSelectedInvestmentPlan] = useState("");
  const [selectedPortfolioStatus, setSelectedPortfolioStatus] = useState("");
  const [selectedAreaOfAttention, setSelectedAreaOfAttention] = useState("");
  const [open, setOpen] = useState(false);
  const [portfolioAnalysisId, setPortfolioAnalysisId] = useState(0);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);

  const getPortfolioAnalysisByClientId = async (id) => {
    let portfolioAnalysis = {};
    PortfolioAnalysisService.getByClientId(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const portfolioAnalysisData = response.data.recordset[0];
          console.log("portfolio analysis: ", portfolioAnalysisData);
          portfolioAnalysis = {
            id: portfolioAnalysisData?.PortfolioAnalysisId,
            investmentPlan: portfolioAnalysisData?.InvestmentPlan,
            portfolioStatus: portfolioAnalysisData?.PortfolioStatus,
            areaOfAttention: portfolioAnalysisData?.AreaOfAttention,
          };
          setPortfolioAnalysis(portfolioAnalysis);
        }
      })
      .catch((error) => {
        console.log(error);
        setPortfolioAnalysis(portfolioAnalysis);
      });
  };

  useEffect(() => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    // console.log('selectedClient: ', selectedClient);
    // setClientId(selectedClient.clientId);
    getPortfolioAnalysisByClientId(clientState.clientId);
  }, [clientState.clientId]);

  const typeClick = () => {
    if (requiredState === "") {
      setrequiredState("has-danger");
    }
    if (emailState === "") {
      setemailState("has-danger");
    }
    if (numberState === "") {
      setnumberState("has-danger");
    }
    if (urlState === "") {
      seturlState("has-danger");
    }
    if (sourceState === "" || destinationState === "") {
      setsourceState("has-danger");
      setdestinationState("has-danger");
    }
  };

  const portfolioAnalysisBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const areaOfAttentionBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setInvestmentPlan = (ip) => {
    setSelectedInvestmentPlan(ip);
  };

  const setPortfolioStatus = (ps) => {
    setSelectedPortfolioStatus(ps);
  };

  const setAreaOfAttention = (ai) => {
    setSelectedAreaOfAttention(ai);
  };

  const redirectToTracker = () => {
    const path = "/admin/progress-tracker/index";
    history.push(path);
  };

  const savePortfolioAnalysis = () => {
    const portfolioAnalysisData = {
      clientId: clientState.clientId,
      investmentPlan: selectedInvestmentPlan,
      portfolioStatus: selectedPortfolioStatus,
      areaOfAttention: selectedAreaOfAttention,
      userId: userState.userId,
    };
    if (portfolioAnalysisId === 0) {
      PortfolioAnalysisService.add(portfolioAnalysisData)
        .then((response) => {
          if (response.data.recordset.length > 0) {
            setPortfolioAnalysisId(
              response.data.recordset[0].PortfolioAnalysisId
            );
            setOpen(true);
            setTimeout(() => {
              redirectToTracker();
            }, 3000);
          } else {
            setPortfolioAnalysisId(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("portfolio analysis id: ", portfolioAnalysisId);
      PortfolioAnalysisService.update(
        portfolioAnalysisData,
        portfolioAnalysisId
      )
        .then(() => {
          setOpen(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const setPortfolioAnalysis = (portfolioAnalysis) => {
    console.log("portfolioAnalysis: ", portfolioAnalysis);
    setInvestmentPlan(portfolioAnalysis.investmentPlan);
    setPortfolioStatus(portfolioAnalysis.portfolioStatus);
    setSelectedAreaOfAttention(portfolioAnalysis.areaOfAttention);
    setPortfolioAnalysisId(portfolioAnalysis.id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content portfolio-analysis-content">
      <Container>
        <ClientNotSelectedDialog />
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message="Portolio Analysis is saved."
          action={action}
        ></Snackbar>
        <Row>
          <Col md="12">
            <Form className="form-horizontal" id="TypeValidation">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Portfolio Analysis for {clientState.firstName}{" "}
                    {clientState.lastName}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <Label variant="body2" gutterBottom>
                        What plans do you have for your existing investments?
                      </Label>
                      <FormGroup className={requiredState}>
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                          {investmentPlan.map((ip) => (
                            <Grid
                              sx={{ flexDirection: "column", padding: "4px" }}
                              key={ip}
                            >
                              <Box
                                onClick={() => setInvestmentPlan(ip)}
                                key={ip}
                                sx={portfolioAnalysisBoxSx}
                                style={{
                                  backgroundColor:
                                    selectedInvestmentPlan === ip
                                      ? "#e61a4b"
                                      : "#114253",
                                }}
                              >
                                {ip}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="8">
                      <Label variant="body2" gutterBottom>
                        Are you happy with the portfolio so far?
                      </Label>
                      <FormGroup className={requiredState}>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {portfolioStatus.map((ps) => (
                            <Grid sx={{ padding: "4px" }} key={ps}>
                              <Box
                                onClick={() => setPortfolioStatus(ps)}
                                key={ps}
                                sx={portfolioAnalysisBoxSx}
                                style={{
                                  backgroundColor:
                                    selectedPortfolioStatus === ps
                                      ? "#e61a4b"
                                      : "#114253",
                                }}
                              >
                                {ps.toLocaleString(navigator.language, {
                                  minimumFractionDigits: 0,
                                })}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="8">
                      <Label variant="body2" gutterBottom>
                        What areas do you feel need the most attention?
                      </Label>
                      <FormGroup className={requiredState}>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {areaOfAttention.map((ai) => (
                            <Grid
                              sx={{ flexDirection: "column", padding: "4px" }}
                              key={ai}
                            >
                              <Box
                                onClick={() => setAreaOfAttention(ai)}
                                key={ai}
                                sx={areaOfAttentionBoxSx}
                                style={{
                                  backgroundColor:
                                    selectedAreaOfAttention === ai
                                      ? "#e61a4b"
                                      : "#114253",
                                }}
                              >
                                {ai.toLocaleString(navigator.language, {
                                  minimumFractionDigits: 0,
                                })}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-right">
                  <Button
                    variant="contained"
                    color="grey"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={() => savePortfolioAnalysis()}
                  >
                    SAVE
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PortfolioAnalysis;
