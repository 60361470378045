import http from './http-common';

class MortgageStrategyService {

    // http://localhost:5000/api/v1/scoreboard/mortgage-strategy/add
    add(data) {
        console.log('data: ', data);
        const path = '/scoreboard/mortgage-strategy';
        console.log('data: ', data);
        return http.post(`${path}/add`, data);
    };

    // http://localhost:5000/api/v1/scoreboard/mortgage-strategy/get/:id
    getByClientId(clientId) {
        const path = '/scoreboard/mortgage-strategy';
        return http.get(`${path}/get/${clientId}`);
    };

    // http://localhost:5000/api/v1/scoreboard/mortgage-strategy/get/:id
    update(data, clientId) {
        const path = '/scoreboard/mortgage-strategy';
        return http.put(`${path}/update/${clientId}`, data);
    }

}

export default new MortgageStrategyService();
