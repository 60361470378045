import http from "./http-common";

class NewPropertyService {
  // http://localhost:5000/api/v1/new-property/all
  getAll() {
    const path = `/new-property/all`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/all
  getAllByBuyerAgent(buyerAgentId) {
    const path = `/new-property/buyer-agent/${buyerAgentId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/:id
  getById(newPropertyId) {
    const path = `/new-property/${newPropertyId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/status
  getPropertyStatus() {
    const path = `/new-property/status`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/tenancy
  getTenancy() {
    const path = `/new-property/tenancy`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/zoning
  getZoning() {
    const path = `/new-property/zoning`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/sale-options
  getSaleOptions() {
    const path = `/new-property/sale-options`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/state
  getStates() {
    const path = `/new-property/state`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/add
  add(data) {
    const path = "/new-property/add";
    console.log("data: ", data);
    return http.post(`${path}`, data);
  }

  // http://localhost:5000/api/v1/new-property/update/:id
  update(data, id) {
    const path = `/new-property/update/${id}`;
    return http.put(path, data);
  }

  // http://localhost:5000/api/v1/new-property/update-status/:id
  updateStatus(data, id) {
    const path = `/new-property/update-status/${id}`;
    return http.put(path, data);
  }

  // http://localhost:5000/api/v1/new-property/delete/:id
  delete(id) {
    const path = `/new-property/delete/${id}`;
    return http.delete(path);
  }

  // http://localhost:5000/api/v1/new-property/buyer-agent/add
  updateBuyerAgent(data) {
    const path = `/new-property/buyer-agent/add`;
    console.log(path);
    return http.post(`${path}`, data);
  }
  // http://localhost:5000/api/v1/new-property/assign-client/add
  assignClient(data) {
    const path = `/new-property/assign-client/add`;
    console.log(path);
    return http.post(`${path}`, data);
  }
  // http://localhost:5000/api/v1/new-property/property-document/add
  addPropertyDocument(data) {
    const path = "/new-property/property-document/add";
    console.log("data: ", data);
    return http.post(`${path}`, data);
  }

  // http://localhost:5000/api/v1/new-property/document-type/all
  getDocumentTypes() {
    const path = `/new-property/document-type/all`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/property-document/:propertyId
  getPropertyDocumentsByPropertyId(propertyId) {
    const path = `/new-property/property-document/${propertyId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/property-document/delete/:id
  deletePropertyDocumentById(propertyDocumentId) {
    const path = `/new-property/property-document/delete/${propertyDocumentId}`;
    return http.delete(path);
  }

  // http://localhost:5000/api/v1/new-property/duplication
  checkPropertyDuplication(data) {
    const path = `/new-property/duplication`;
    console.log("path: ", path);
    return http.post(`${path}`, data);
  }

  // http://localhost:5000/api/v1/new-property/all
  checkDocumentStatusforProposed(newPropertyId) {
    const path = `/new-property/deals-proposed/document-check/${newPropertyId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/all
  checkDocumentStatusforAccepted(newPropertyId) {
    const path = `/new-property/deals-accepted/document-check/${newPropertyId}`;
    console.log("path: ", path);
    return http.get(path);
  }
}

export default new NewPropertyService();
