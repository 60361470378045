import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
  Container,
} from "reactstrap";

import "react-datetime/css/react-datetime.css";
import "dayjs/locale/en-gb";
import Moment from "moment";
// import Select from "react-select";
import Select from "@mui/material/Select";
import "moment/locale/en-gb";
import { selectedClientAtom } from "store";
import { useAtom } from "jotai";
import PropertyPortfolioService from "services/property-portfolio.service";
import YearlyPlanSellingService from "services/yearly-plan-selling.service";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SellingStep = ({ formData, setFormData }) => {
  const [clientState] = useAtom(selectedClientAtom);

  const [properties, setProperties] = useState([]);
  const [existingSellingData, setExistingSellingData] = useState([]);

  useEffect(() => {
    getProperties(clientState.clientId);
    getSellingData(formData?.yearlyPlanId);
  }, [clientState.clientId, formData?.yearlyPlanId]);

  const getProperties = async (clientId) => {
    return PropertyPortfolioService.getNonExcludedFromCalcForRenovationDropdownByClientId(
      clientId
    ).then((response) => {
      console.log("response: ", response.data.recordset);
      if (response.data.recordset.length > 0) {
        const result = response.data.recordset.map((property) => ({
          propertyId: property.value,
          propertyName: property.label,
        }));
        console.log("result : ", result);
        setProperties(result);
      }
    });
  };

  const getSellingData = async (yearlyPlanId) => {
    if (yearlyPlanId) {
      return YearlyPlanSellingService.getAllByYearlyPlanId(yearlyPlanId).then(
        (response) => {
          console.log("response: ", response.data.recordset);
          if (response.data.recordset.length > 0) {
            const result = response.data.recordset;
            console.log("result : ", result);

            setFormData({
              ...formData,
              sellingProperties: result.map(
                ({ SellingPropertyId, PropertyLabel }) => ({
                  propertyId: SellingPropertyId,
                  propertyName: PropertyLabel,
                })
              ),
            });

            setExistingSellingData(result);
          }
        }
      );
    }
  };

  const handlePropertyChange = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        const currProperty = properties.find(
          (p) => p.propertyName === options[i].value
        );
        value.push({
          propertyId: currProperty.propertyId,
          propertyName: options[i].value,
        });
      }
    }
    console.log("property change:  ", value);
    setFormData({
      ...formData,
      // On autofill we get a stringified value.
      sellingProperties: typeof value === "string" ? value.split(",") : value,
    });
    // if (!(property.value === formData.sellingPropertyId)) {
    //   const existingDataIndex = existingSellingData.findIndex(
    //     (r) => r.SellingPropertyId === property.value
    //   );
    //   if (existingDataIndex > -1) {
    //     setFormData({
    //       ...formData,
    //       yearlyPlanSellingId:
    //         existingSellingData[existingDataIndex].YearlyPlanSellingId,
    //       sellingPropertyId: property.value,
    //       isSelling: existingSellingData[existingDataIndex].IsSelling,
    //     });
    //   } else {
    //     setFormData({
    //       ...formData,
    //       yearlyPlanSellingId: null,
    //       sellingPropertyId: property.value,
    //       isSelling: 1,
    //     });
    //   }
    // }
  };

  console.log("form data: ", formData);
  return (
    <div className="selling-step-modal main-pad">
      <Container>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">{formData?.year}</CardTitle>
          </CardHeader>
          <CardBody>
            <Form className="form-horizontal">
              <Row>
                <Col md="9">
                  <Label>Sell properties</Label>
                  <FormGroup>
                    <Select
                      // labelId="switch-property-label"
                      inputProps={{
                        id: "selling-property",
                      }}
                      // className="react-select  select-option-control"
                      // classNamePrefix="react-select"
                      // placeholder=""
                      native
                      multiple
                      sx={{
                        width: "70vw",
                      }}
                      value={formData?.sellingProperties?.map(
                        (p) => p.propertyName
                      )}
                      onChange={handlePropertyChange}
                      // input={<OutlinedInput style={{ width: "100%" }} />}
                    >
                      {properties.map(({ propertyId, propertyName }) => (
                        <option key={propertyId} value={propertyName}>
                          {propertyName}
                        </option>
                        // <MenuItem key={value} value={value}>
                        //   <Checkbox
                        //     checked={
                        //       formData?.loanSwitchProperties.indexOf(value) > -1
                        //     }
                        //   />
                        //   <ListItemText primary={label} />
                        // </MenuItem>
                      ))}
                    </Select>
                    {/* <Autocomplete
                      multiple
                      value={formData?.sellingProperties}
                      onChange={(_event, value) => {
                        // console.log(value);
                        handlePropertyChange(value);
                        // setValue(typeof value === 'string' ? value.split(',') : value);
                      }}
                      id="switch-property-purpose"
                      options={properties}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.propertyName}
                      isOptionEqualToValue={(option, value) =>
                        option.propertyId === value.propertyId
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.propertyName}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField {...params} label="" placeholder="" />
                      )}
                    /> */}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default SellingStep;
