import http from "./http-common";

class YearlyPlanRenovationService {
  // http://localhost:5000/api/v1/yearly-plan/get/:id
  getAllByYearlyPlanId(yearlyPlanId) {
    const path = `/yearly-plan/renovation/get/yearly-plan/${yearlyPlanId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/yearly-plan/renovation/update/:id
  update(renovationId, data) {
    const path = `/yearly-plan/renovation/update/${renovationId}`;
    console.log("path: ", path);
    return http.put(path, data);
  }

  // http://localhost:5000/api/v1/yearly-plan/renovation/add
  add(data) {
    const path = "/yearly-plan/renovation";
    console.log("data: ", data);
    return http.post(`${path}/add`, data);
  }

  // http://localhost:5000/api/v1/yearly-plan/renovation/delete/:id
  deleteAllByYearlyPlanId(yearlyPlanId) {
    const path = "/yearly-plan/renovation";
    console.log("id: ", yearlyPlanId);
    return http.delete(`${path}/delete/yearly-plan/${yearlyPlanId}`);
  }
}

export default new YearlyPlanRenovationService();
