import React from "react";
import googleIcon from "../../assets/img/google-icon.svg";
import facebookIcon from "../../assets/img/facebook-icon.svg";

const AuthWithSocial = ({ type, className, title, onClick }) => {
  return (
    <>
      {type === "google" ? (
        <div className="auth-social-button google-social">
          <button
            className={`social-button ${className}`}
            type="button"
            onClick={onClick}
          >
            <img alt="..." className="auth-social-icon" src={googleIcon} />{" "}
            {title}
          </button>
        </div>
      ) : type === "facebook" ? (
        <div className="auth-social-button facebook-social">
          <button
            className={`social-button ${className}`}
            type="button"
            onClick={onClick}
          >
            <img alt="..." className="auth-social-icon" src={facebookIcon} />{" "}
            {title}
          </button>
        </div>
      ) : null}
    </>
  );
};

export default AuthWithSocial;
