import { Menu, MenuItem } from "@material-ui/core"; // RAK

export default function SubMenu({ anchorEl, open, handleClose, menuItems }) {
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose} size="sm">
      {menuItems.map((item, index) => (
        <MenuItem key={index} onClick={item.onClick} style={item.style}>
          <item.Icon style={item.iconStyle} />
          {item.text}
        </MenuItem>
      ))}
    </Menu>
  );
}
