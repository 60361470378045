import { useState, useEffect } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";

import Typography from "@mui/material/Typography";

const CarDetails = ({ formData, setFormData, defaultAssumptions }) => {
  const [selectedChangeInNext5Years, setSelectedChangeInNext5Years] =
    useState("");
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const nextCarPurchaseYear = [
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
  ];
  const newCarFrequency = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
  ];
  // const [nextCarCashContribution, setNextCarCashContribution] = useState(0);

  const changeInPurposeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  useEffect(() => {
    console.log("Form Data - ", formData);
  }, []);

  const setNextCarPurchaseYear = (nextCarPurchaseYear) => {
    setFormData({
      ...formData,
      nextCarPurchaseYear,
      isDefaultNextCarPurchaseYear: "0",
    });
  };

  const setHowOftenNewCar = (newCarFrequency) => {
    setFormData({
      ...formData,
      newCarFrequency,
      isDefaultNextCarPurchaseFrequency: "0",
    });
  };

  const setCashContribution = (e) => {
    // setNextCarCashContribution(e.target.value);
    setFormData({
      ...formData,
      nextCarCashContribution: e.target.value,
      isDefaultNextCarCashContribution: "0",
    });
  };

  return (
    <div className="car-detail-step">
      <Card>
        <CardHeader>
          <CardTitle tag="h4" className="card-margin">
            Set your property plan for {clientState.firstName}{" "}
            {clientState.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="12">
                <Label>Which year will your next vehicle purchase be?</Label>
                <FormGroup>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>
                      {new Date().getFullYear() +
                        defaultAssumptions.NextCarPurchaseYear}
                    </strong>{" "}
                    would be selected as default)
                  </Typography>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {nextCarPurchaseYear.map((year) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={year}
                      >
                        <Box
                          onClick={() => setNextCarPurchaseYear(year)}
                          key={year}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.nextCarPurchaseYear === year
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {year}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>
                  How often would you like to purchase a new vehicle? (Years)
                </Label>
                <FormGroup>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>
                      {defaultAssumptions.NextCarPurchaseFrequency}
                    </strong>{" "}
                    would be selected as default)
                  </Typography>

                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {newCarFrequency.map((often) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={often}
                      >
                        <Box
                          onClick={() => setHowOftenNewCar(often)}
                          key={often}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.newCarFrequency === often
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {often}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Label>
                  What would the cash contribution be per vehicle? If you are
                  financing this vehicle purchase, only enter the amount you
                  will be personally contributing outside of what you have
                  already considered in your monthly savings.
                </Label>
                <Typography variant="caption" gutterBottom>
                  (Note: If not selected{" "}
                  <strong>{defaultAssumptions.NextCarCashContribution}</strong>{" "}
                  contribution would be selected as default)
                </Typography>
                <FormGroup className="left-m fg-margin">
                  <Slider
                    defaultValue={0}
                    value={formData.nextCarCashContribution}
                    step={500}
                    min={0}
                    max={500000}
                    valueLabelDisplay="auto"
                    onChange={(e) => {
                      setCashContribution(e);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default CarDetails;
