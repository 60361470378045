import http from "./http-common";

class YearlyPlanPurposeSwitchService {
  // http://localhost:5000/api/v1/yearly-plan/purpose-switch/get/yearly-plan/:id
  getAllByYearlyPlanId(yearlyPlanId) {
    const path = `/yearly-plan/purpose-switch/get/yearly-plan/${yearlyPlanId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/yearly-plan/purpose-switch/update/:id
  update(purposeSwitchId, data) {
    const path = `/yearly-plan/purpose-switch/update/${purposeSwitchId}`;
    console.log("path: ", path);
    return http.put(path, data);
  }

  // http://localhost:5000/api/v1/yearly-plan/purpose-switch/add
  add(data) {
    const path = "/yearly-plan/purpose-switch";
    console.log("data: ", data);
    return http.post(`${path}/add`, data);
  }

  // http://localhost:5000/api/v1/yearly-plan/purpose-switch/delete/yearly-plan/:id
  deleteAllByYearlyPlanId(yearlyPlanId) {
    const path = `/yearly-plan/purpose-switch/delete/yearly-plan/${yearlyPlanId}`;
    console.log("path: ", path);
    return http.delete(path);
  }
}

export default new YearlyPlanPurposeSwitchService();
