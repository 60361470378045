import http from "./http-common";

class SecurityService {
  addClient(data) {
    return http.post("/security/register/add", data);
  }
  Login(data) {
    return http.post("/security/login", data);
  }
  ChangePassword(data) {
    return http.post("/security/user/changePassword", data);
  }

  getClientDetails(clientId) {
    return http.get(`/security/user/details/${clientId}`);
  }
}

export default new SecurityService();
