/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Buttons from "views/components/Buttons.js";
import Calendar from "views/Calendar.js";
import Charts from "views/Charts.js";
import Dashboard from "views/Dashboard.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import GridSystem from "views/components/GridSystem.js";
import Icons from "views/components/Icons.js";
import LockScreen from "views/pages/LockScreen.js";
import Login from "views/pages/Login.js";
import Notifications from "views/components/Notifications.js";
import Panels from "views/components/Panels.js";
import ReactTables from "views/tables/ReactTables.js";
import ChangePassword from "./views/pages/change-password";
import RegularForms from "views/forms/RegularForms.js";
import RegularTables from "views/tables/RegularTables.js";
import SweetAlert from "views/components/SweetAlert.js";
import Timeline from "views/pages/Timeline.js";
import Typography from "views/components/Typography.js";
import UserProfile from "views/pages/user-profile";
import ValidationForms from "views/forms/ValidationForms.js";
import VectorMap from "views/maps/VectorMap.js";
import Widgets from "views/Widgets.js";
import Wizard from "views/forms/Wizard.js";
import Assumptions from "ui/profile-detail/assumption/assumptions";
import Completion from "ui/profile-detail/completion/completion";
import Questions from "ui/profile-detail/questions/questions";
import StrategyHighlights from "ui/profile-detail/strategy-highlights/strategy-highlights";
import Orders from "ui/profile-detail/orders/orders";
import GoalAlignment from "ui/scoreboard/goal-alignment/goal-alignment";
// import FamilyMembers from "ui/scoreboard/family-members/family-members";
// import Employment from "ui/scoreboard/employment/employment";
import PropertyPortfolio from "ui/scoreboard/property-portfolio/property-portfolio-add";
import RiskProfileAdd from "ui/scoreboard/risk-profile/risk-profile-add";
import MortgageStrategy from "ui/scoreboard/mortgage-strategy/mortgage-strategy";
import AssetSelectionAdd from "ui/scoreboard/asset-selection/asset-selection-add";
import PortfolioAnalysis from "ui/scoreboard/portfolio-analysis/portfolio-analysis";
import PropertyPlanAdd from "ui/scoreboard/property-plan/property-plan-add";
import Advisors from "ui/scoreboard/advisors/advisors";
import PropertyGrowth from "ui/map/property-growth/property-growth";
import PropertyYields from "ui/map/property-yields/property-yields";
import PropertyCashflow from "ui/map/property-cashflow/property-cashflow";
import BorrowableEquity from "ui/map/borrowable-equity/borrowable-equity";
import LoanToValueRatio from "ui/map/loan-value-ratio/loan-value-ratio";
import Buckets from "ui/map/buckets/buckets";
import SafetyBuffer from "ui/map/safety-buffer/safety-buffer";
import MonthsLeftSavings from "ui/map/months-left-savings/months-left-savings";
import OffsetAndAnnualSpend from "ui/compass/offset-and-annual-spend/offset-and-annual-spend";
import EquityPosition from "ui/compass/equity-position/equity-position";
import PassiveIncome from "ui/compass/passive-income/passive-income";
import PropertySummary from "ui/compass/property-summary/property-summary";
import ManuallyEnteredSpends from "ui/compass/manually-entered-spends/manually-entered-spends";
import BorrowCapacity from "ui/compass/borrow-capacity/borrow-capacity";
import RentalYield from "ui/compass/rental-yield/rental-yield";
import InterestRateMovement from "ui/compass/interest-rate-movement/interest-rate-movement";
import Scoreboard from "ui/scoreboard/scoreboard";
import FamilyMembersAdd from "ui/scoreboard/family-members/family-members-add";
import PropertyList from "ui/scoreboard/property-portfolio/property-portfolio";
import EmploymentAdd from "ui/scoreboard/employment/employment-add";
import Employment from "ui/scoreboard/employment/employment";
import MoneyManagementAdd from "ui/scoreboard/money-management/money-management-add";
import MoneyManagement from "ui/scoreboard/money-management/money-management";
import FamilyMembersList from "ui/scoreboard/family-members/family-members";
import Register from "views/pages/Register";
import PropertyFinancialDetails from "ui/scoreboard/property-portfolio/property-financial-details";
import Compass from "ui/compass/compass";
import ContentAndCourse from "ui/content-and-course/content-and-course";
import ClientPayment from "ui/payment/client-payment";
import ProgressTracker from "./ui/progress-tracker/progress-tracker";
import AdvisorsAdd from "ui/scoreboard/advisors/advisors-add";
import Compare from "ui/compass/compare/compare";
import ClientAssumptions from "ui/profile-detail/assumption/client-assumptions";

const clientRoutes = [
  {
    redirect: true,
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: [
      {
        redirect: true,
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
      },
      {
        redirect: true,
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth",
      },
      {
        redirect: true,
        path: "/changePassword",
        name: "changePassword",
        mini: "CP",
        component: ChangePassword,
        layout: "/auth",
      },

      {
        redirect: true,
        path: "/user-profile",
        name: "UserProfile",
        mini: "UP",
        component: UserProfile,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Progress Tracker",
    icon: "nc-icon nc-pin-3",
    state: "progressTrackerCollapse",
    views: [
      {
        path: "/progress-tracker/index",
        name: "Progress Tracker",
        mini: "SB",
        component: ProgressTracker,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/content-and-course",
    name: "Content & Course",
    icon: "nc-icon nc-single-copy-04",
    component: ContentAndCourse,
    layout: "/admin",
    redirect: true,
  },

  {
    collapse: true,
    name: "Detail",
    icon: "nc-icon nc-layout-11",
    state: "detailCollapse",
    views: [
      {
        path: "/detail/assumption",
        name: "Default Assumption",
        mini: "A",
        component: Assumptions,
        layout: "/admin",
      },
      {
        path: "/detail/client-assumption",
        name: "Client Assumption",
        mini: "CA",
        component: ClientAssumptions,
        layout: "/admin",
      },
      // {
      //   path: "/detail/completion",
      //   name: "Completion",
      //   mini: "C",
      //   component: Completion,
      //   layout: "/admin",
      // },
      {
        path: "/detail/questions",
        name: "Questions",
        mini: "Q",
        component: Questions,
        layout: "/admin",
      },
      // {
      //   path: "/detail/strategy-highlights",
      //   name: "Strategy highlights",
      //   mini: "SH",
      //   component: StrategyHighlights,
      //   layout: "/admin",
      // },
      {
        path: "/detail/orders",
        name: "Orders",
        mini: "O",
        component: Orders,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Scoreboard",
    icon: "nc-icon nc-chart-bar-32",
    state: "scoreboardCollapse",
    views: [
      {
        path: "/scoreboard/index",
        name: "Scoreboard",
        mini: "SB",
        component: Scoreboard,
        layout: "/admin",
      },
      {
        path: "/scoreboard/goal-alignment",
        name: "Goal Alignment",
        mini: "GA",
        component: GoalAlignment,
        layout: "/admin",
      },
      {
        path: "/scoreboard/family-members/list",
        name: "Family Members",
        mini: "FM",
        component: FamilyMembersList,
        layout: "/admin",
      },
      {
        path: "/scoreboard/family-members/add",
        name: "Add Family Member",
        mini: "AFM",
        component: FamilyMembersAdd,
        layout: "/admin",
        redirect: true,
      },
      {
        path: "/scoreboard/employment/list",
        name: "Employments",
        mini: "E",
        component: Employment,
        layout: "/admin",
      },
      {
        path: "/scoreboard/employment/add",
        name: "Add Employment",
        mini: "AE",
        component: EmploymentAdd,
        layout: "/admin",
        redirect: true,
      },
      {
        path: "/scoreboard/property-portfolio/list",
        name: "Property List",
        mini: "FM",
        component: PropertyList,
        layout: "/admin",
      },
      {
        path: "/scoreboard/property-portfolio/details",
        name: "Property Details",
        mini: "FM",
        component: PropertyFinancialDetails,
        layout: "/admin",
        redirect: true,
      },
      {
        path: "/scoreboard/property-portfolio/add",
        name: "Portfolio Add",
        mini: "P",
        component: PropertyPortfolio,
        layout: "/admin",
        redirect: true,
      },
      {
        path: "/scoreboard/risk-profile/add",
        name: "Risk Profile",
        mini: "RP",
        component: RiskProfileAdd,
        layout: "/admin",
      },

      {
        path: "/scoreboard/money-management/list",
        name: "Money Management",
        mini: "MM",
        component: MoneyManagement,
        layout: "/admin",
      },
      {
        path: "/scoreboard/money-management/add",
        name: "Add Money Management",
        mini: "AMM",
        component: MoneyManagementAdd,
        layout: "/admin",
        redirect: true,
      },
      {
        path: "/scoreboard/mortgage-strategy",
        name: "Mortgage Strategy",
        mini: "MS",
        component: MortgageStrategy,
        layout: "/admin",
      },
      {
        path: "/scoreboard/asset-selection/add",
        name: "Asset Selection",
        mini: "AS",
        component: AssetSelectionAdd,
        layout: "/admin",
      },
      {
        path: "/scoreboard/portfolio-analysis",
        name: "Portfolio Analysis",
        mini: "PA",
        component: PortfolioAnalysis,
        layout: "/admin",
      },
      {
        path: "/scoreboard/property-plan/add",
        name: "Property Plan",
        mini: "PP",
        component: PropertyPlanAdd,
        layout: "/admin",
      },
      {
        path: "/scoreboard/advisors/list",
        name: "advisors",
        mini: "A",
        component: Advisors,
        layout: "/admin",
      },
      {
        path: "/scoreboard/advisors/add",
        name: "Advisors Add",
        mini: "AA",
        component: AdvisorsAdd,
        layout: "/admin",
        redirect: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Portfolio Health Check",
    icon: "nc-icon nc-chart-pie-36",
    state: "graphCollapse",
    views: [
      // {
      //   path: "/graph/property-growth",
      //   name: "Map/Graphs",
      //   mini: "PG",
      //   component: PropertyGrowth,
      //   layout: "/admin",
      // },
      {
        path: "/graph/property-yields",
        name: "Portfolio Health Check",
        mini: "PHC",
        component: PropertyYields,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Property Strategy",
    icon: "nc-icon nc-compass-05",
    state: "compassCollapse",
    views: [
      {
        path: "/compass/compass",
        name: "Property Strategy",
        mini: "PS",
        component: Compass,
        layout: "/admin",
      },
      // {
      //   path: "/compass/compare",
      //   name: "Compare",
      //   mini: "CMP",
      //   component: Compare,
      //   layout: "/admin",
      // },
      // {
      //   path: "/compass/offset-and-annual-spend",
      //   name: "Offset & Annual Spend",
      //   mini: "OAS",
      //   component: OffsetAndAnnualSpend,
      //   layout: "/admin",
      // },
      // {
      //   path: "/compass/equity-position",
      //   name: "Equity Position",
      //   mini: "EP",
      //   component: EquityPosition,
      //   layout: "/admin",
      // },
      // {
      //   path: "/compass/passive-income",
      //   name: "Passive Income",
      //   mini: "PI",
      //   component: PassiveIncome,
      //   layout: "/admin",
      // },
      // {
      //   path: "/compass/property-summary",
      //   name: "Property Summary",
      //   mini: "PS",
      //   component: PropertySummary,
      //   layout: "/admin",
      // },
      {
        path: "/compass/manually-entered-spends",
        name: "Manually Entered Spends",
        mini: "MES",
        component: ManuallyEnteredSpends,
        layout: "/admin",
      },

      {
        path: "/compass/property-summary",
        name: "Property Summary",
        mini: "PS",
        component: PropertySummary,
        layout: "/admin",
        redirect: true,
      },
      // {
      //   path: "/compass/borrow-capacity",
      //   name: "Borrowing Capacity",
      //   mini: "BC",
      //   component: BorrowCapacity,
      //   layout: "/admin",
      // },
      // {
      //   path: "/compass/rental-yield",
      //   name: "Rental Yield",
      //   mini: "RY",
      //   component: RentalYield,
      //   layout: "/admin",
      // },
      // {
      //   path: "/compass/interest-rate-movement",
      //   name: "Interest Rate Movements",
      //   mini: "IRM",
      //   component: InterestRateMovement,
      //   layout: "/admin",
      // },
    ],
  },
];

export default clientRoutes;
