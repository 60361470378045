import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../store";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col,
  Collapse,
} from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import assumptionService from "services/assumption.service";
import compassService from "services/compass.service";
import YearlyPlanService from "services/yearly-plan.service";
import { Alert, Container, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useHistory } from "react-router-dom";
import { Line, Bar, Pie, Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import EnlargeView from "components/EnlargeView/EnlargeView";
import propertyPortfolioService from "services/property-portfolio.service";
import moment from "moment";
import { formatCurrency } from "utils/currency";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";
import {
  dummyOffsetGraphOptions,
  dummyMarketValueGraphOptions,
  dummyPassiveIncomeGraphOptions,
  dummyBorrowingCapacityGraphOptions,
  dummyStrategyRentalYieldGraphOptions,
  dummyInterestRateMovementGraphOptions,
} from "utils/dummy-data";

const Compass = () => {
  const [state, setState] = useAtom(selectedClientAtom);
  const hasFullAccess = state.fullAccess;
  const [assumptions, setAssumptions] = useState([]);
  const [assumptionNames, setAssumptionNames] = useState([]);
  const [selectedAssumption, setSelectedAssumption] = useState({});
  const [offsetGraphOptions, setOffsetGraphOptions] = useState({});
  const [marketValueGraphOptions, setMarketValueGraphOptions] = useState({});
  const [passiveIncomeGraphOptions, setPassiveIncomeGraphOptions] = useState(
    {}
  );
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [borrowingCapacityGraphOptions, setBorrowingCapactiyGraphOptions] =
    useState({});
  const [rentalYieldGraphOptions, setRentalYieldGraphOptions] = useState({});
  const [
    interestRateMovementGraphOptions,
    setInterestRateMovementGraphOptions,
  ] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [spends, setSpends] = React.useState([]);
  const [openedCollapses, setOpenedCollapses] = React.useState([
    "collapseOne",
    "collapse1",
  ]);
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenedCollapses(openedCollapses.filter((item) => item !== collapse));
    } else {
      openedCollapses.push(collapse);
      setOpenedCollapses([...openedCollapses, collapse]);
    }
  };

  const assumptionBoxSx = {
    fontWeight: "500",
    padding: "4px",
    cursor: "pointer",
    border: "1px solid #DDDDDD",
    borderRadius: "15%",
    height: "95px",
    width: "125px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#fbc658",
    },
  };

  const borderColor = [
    "#77BFC7",
    "#FFFF00",
    "#808000",
    "#0000FF",
    "#B3446C",
    "#86608E",
    "#DA8A67",
    "#008080",
    "#7F5217",
    "#C24641",
    "#B2C248",
    "#9F8C76",
    "#915F6D",
    "#D4A017",
    "#4682B4",
  ];
  const backgroundColor = [
    "#77BFC7",
    "#FFFF00",
    "#808000",
    "#0000FF",
    "#B3446C",
    "#86608E",
    "#DA8A67",
    "#008080",
    "#7F5217",
    "#C24641",
    "#B2C248",
    "#9F8C76",
    "#915F6D",
    "#D4A017",
    "#4682B4",
  ];

  const hoverBorderColor = ["#fcc468", "#4cbdd7"];

  const history = useHistory();

  useEffect(() => {
    if (hasFullAccess) {
      getClientAssumptions(state.clientId);
      getYearlyPlan(state.clientId);
      getProperties(state.clientId);
    } else {
      getClientAssumptions(0);
      setOffsetGraphOptions(dummyOffsetGraphOptions);
      setMarketValueGraphOptions(dummyMarketValueGraphOptions);
      setPassiveIncomeGraphOptions(dummyPassiveIncomeGraphOptions);
      setBorrowingCapactiyGraphOptions(dummyBorrowingCapacityGraphOptions);
      setRentalYieldGraphOptions(dummyStrategyRentalYieldGraphOptions);
      setInterestRateMovementGraphOptions(
        dummyInterestRateMovementGraphOptions
      );
      setIsDataLoaded(true);
    }
  }, [state.clientId]);

  const getYearlyPlan = async (id) => {
    YearlyPlanService.getAllByClientId(id)
      .then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = removeDuplicatesByYearlyPlanId(
            response.data.recordset.map((row) =>
              convertFirstLetterToLowerCase(row)
            )
          );
          console.log("spend result : ", result);
          setSpends(result);
        } else {
          YearlyPlanService.generateInitialYearPlanForId(id)
            .then(() => getYearlyPlan(id))
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProperties = async (clientId) => {
    return propertyPortfolioService
      .getAllByClientId(clientId)
      .then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset.map((row) =>
            convertFirstLetterToLowerCase(row)
          );
          console.log("result : ", result);
          setPropertyDetails(result);
        }
      });
  };

  const getYearSpend = ({
    motorVehicleSpend,
    holidaySpend,
    educationSpend,
    otherSpend,
  }) => motorVehicleSpend + holidaySpend + educationSpend + otherSpend;

  const getClientAssumptions = async (id) => {
    assumptionService
      .getDefaultAndClientAssumptions(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const assumptions = response.data.recordset;
          console.log("assumptions: ", assumptions);
          let optionItems = assumptions.map((item) => ({
            label: `${item.AssumptionName}`,
            value: item.AssumptionId,
          }));
          setAssumptionNames(optionItems);
          setAssumptions(assumptions);
          setSelectedAssumption(assumptions[0]);
          applyAssumption(state.clientId, assumptions[0].AssumptionId);
          if (hasFullAccess) {
            getCompassGraphData(state.clientId, assumptions[0].AssumptionId);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const convertDateTimeToYear = (dateTime) => moment(dateTime).format("YYYY");

  const redirectToAssumption = () => {
    let path = "";
    if (selectedAssumption.AssumptionId === 0) {
      path = "/admin/detail/assumption";
    } else {
      path = `/admin/detail/client-assumption/${selectedAssumption.AssumptionId}`;
    }
    history.push(path);
  };

  const applyAssumption = async (clientId, assumptionId) => {
    const data = {
      clientId,
      assumptionId,
    };
    assumptionService
      .applyAssumption(data)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const assumptions = response.data.recordset;
          console.log("apply assumptions: ", assumptions);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function removeDuplicatesByYearlyPlanId(list) {
    const uniqueIds = {};
    return list.filter((item) => {
      if (!uniqueIds[item.yearlyPlanId]) {
        uniqueIds[item.yearlyPlanId] = true;
        return true;
      }
      return false;
    });
  }

  const convertFirstLetterToLowerCase = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObj[newKey] = obj[key];
      }
    }
    return newObj;
  };

  const getCompassGraphData = async (clientId, assumptionId) => {
    compassService
      .getClientCompassGraphData(clientId, assumptionId)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const compassGraphData = response.data.recordset;
          console.log("compass graph data: ", compassGraphData);
          const offsetData = compassGraphData.map((item) => {
            return [item.CalculationYear, item.Savings];
          });
          console.log("offsetDataValue: ", offsetData);

          // const label = compassGraphData.map((item) => {
          //   return [item.CalculationYear, item.CalculationYear];
          // });

          const marketValueData = compassGraphData.map((item) => {
            return [item.CalculationYear, item.MarketValue];
          });

          const equityData = compassGraphData.map((item) => {
            return [item.CalculationYear, item.Equity];
          });

          const offsetDebtData = compassGraphData.map((item) => {
            return [item.CalculationYear, item.OffsetDebt];
          });

          console.log("offset value data: ", offsetDebtData);

          const passiveIncomeData = compassGraphData.map((item) => {
            return [
              item.CalculationYear,
              item.PassiveIncome === null ? 0 : item.PassiveIncome,
            ];
          });

          const TargetInflationAdjusted = compassGraphData.map((item) => {
            return [
              item.CalculationYear,
              item.TargetRetirementIncome === null
                ? 0
                : item.TargetRetirementIncome,
            ];
          });

          const borrowingCapacityData = compassGraphData.map((item) => {
            return [item.CalculationYear, item.TotalBorrowingCapacity];
          });

          const TotalDebtData = compassGraphData.map((item) => {
            return [item.CalculationYear, item.OutstandingLoan];
          });

          const rentalYieldData = compassGraphData.map((item) => {
            return [item.CalculationYear, item.RentalYield];
          });

          const rentalYieldOnPurchasePriceData = compassGraphData.map(
            (item) => {
              return [item.CalculationYear, item.RentalYieldOnPurchaseAmount];
            }
          );

          const interestRateMovementUpData = compassGraphData.map((item) => {
            return [item.CalculationYear, item.InterestRateUpPassiveIncome];
          });

          const interestRateMovementDownData = compassGraphData.map((item) => {
            return [item.CalculationYear, item.InterestRateDownPassiveIncome];
          });

          const offSetDataLabel = compassGraphData.map(
            (item) => item.CalculationYear
          );

          const offsetGraphOptions: Options = {
            title: {
              text: "",
            },
            yAxis: {
              title: {
                text: "$ Amount",
              },
            },

            xAxis: {
              accessibility: {
                // rangeDescription: "Range: 2010 to 2020",
              },
            },

            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "middle",
            },

            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
                marker: {
                  enabled: false,
                },
                // pointStart: 2010,
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  this.x +
                  "<br/>" +
                  "$ " +
                  Highcharts.numberFormat(this.y, 0, "", ",")
                );
              },
            },
            colors: ["#5BC2AE", "#FF4E4E", "#795BD0"],
            series: [
              {
                type: "area",
                name: "Savings",
                data: offsetData,
              },
            ],

            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          };

          const marketValueGraphOptions: Options = {
            title: {
              text: "",
            },
            yAxis: {
              title: {
                text: "$ Amount",
              },
            },

            xAxis: {
              accessibility: {
                // rangeDescription: "Range: 2010 to 2020",
              },
            },

            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "middle",
            },

            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
                marker: {
                  enabled: false,
                },
                // pointStart: 2010,
              },
            },
            tooltip: {
              formatter: function () {
                const series = ["Market Value", "Equity", "Offset Debt"];
                return (
                  this.x +
                  "<br/>" +
                  "$ " +
                  Highcharts.numberFormat(this.y, 0, "", ",")
                );
              },
            },
            colors: ["#795BD0", "#F3BE31", "#142a37", "#FF4E4E"],
            series: [
              {
                type: "area",
                name: "Market Value",
                data: marketValueData,
              },
              {
                type: "area",
                name: "Equity",
                data: equityData,
              },
              {
                type: "area",
                name: "Offset Debt",
                data: offsetDebtData,
              },
            ],

            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          };

          const passiveIncomeGraphOptions: Options = {
            title: {
              text: "",
            },
            yAxis: {
              title: {
                text: "$ Amount",
              },
            },

            xAxis: {
              accessibility: {
                // rangeDescription: "Range: 2010 to 2020",
              },
            },

            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "middle",
            },

            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
                marker: {
                  enabled: false,
                },
                // pointStart: 2010,
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  this.x +
                  "<br/>" +
                  "$ " +
                  Highcharts.numberFormat(this.y, 0, "", ",")
                );
              },
            },
            colors: ["#F3BE31", "#795BD0", "#2bb1ff"],
            series: [
              {
                type: "area",
                name: "Passive Income",
                data: passiveIncomeData,
              },
              {
                type: "area",
                name: "PPOR Passive Income",
                data: [],
              },
              {
                type: "area",
                name: "Target Inflation Adjusted",
                data: TargetInflationAdjusted,
              },
            ],

            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          };

          const borrowingCapacityGraphOptions: Options = {
            lang: {
              decimalPoint: ".",
              thousandsSep: ",",
            },
            title: {
              text: "",
            },
            yAxis: {
              title: {
                text: "$ Amount",
              },
            },
            xAxis: {
              accessibility: {
                // rangeDescription: "Range: 2010 to 2020",
              },
            },

            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "middle",
            },

            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
                marker: {
                  enabled: false,
                },
                //pointStart: 2010,
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  this.x +
                  "<br/>" +
                  "$ " +
                  Highcharts.numberFormat(this.y, 0, "", ",")
                );
              },
            },
            colors: ["#FF4E4E", "#5BC2AE", "#795BD0"],
            //colors: ["#5BC2AE", "#F3BE31", "#795BD0"],
            series: [
              {
                type: "area",
                name: "Borrowing Capacity",
                data: borrowingCapacityData,
              },
              {
                type: "area",
                name: "Offset Debt",
                data: offsetDebtData,
              },
              {
                type: "area",
                name: "Total Debt",
                data: TotalDebtData,
              },
            ],

            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          };

          const rentalYieldGraphOptions: Options = {
            title: {
              text: "",
            },
            yAxis: {
              title: {
                text: "$ Amount",
              },
            },

            xAxis: {
              accessibility: {
                // rangeDescription: "Range: 2010 to 2020",
              },
            },

            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "middle",
            },

            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
                marker: {
                  enabled: false,
                },
                // pointStart: 2010,
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  this.x +
                  "<br/>" +
                  Highcharts.numberFormat(this.y, 2, ".", ",")
                );
              },
            },
            colors: ["#F3BE31", "#795BD0"],
            // colors: ["#5BC2AE", "#F3BE31", "#795BD0"],
            series: [
              {
                type: "area",
                name: "Rental Yield",
                data: rentalYieldData,
              },
              {
                type: "area",
                name: "Rental Yield On Purchase Price",
                data: rentalYieldOnPurchasePriceData,
              },
            ],

            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          };

          const interestRateMovementGraphOptions: Options = {
            title: {
              text: "",
            },
            yAxis: {
              title: {
                text: "$ Amount",
              },
            },

            xAxis: {
              accessibility: {
                // rangeDescription: "Range: 2010 to 2020",
              },
            },

            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "middle",
            },

            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
                marker: {
                  enabled: false,
                },
                // pointStart: 2010,
              },
            },
            tooltip: {
              formatter: function () {
                const series = ["Market Value", "Equity", "Offset Debt"];
                return (
                  this.x +
                  "<br/>" +
                  "$ " +
                  Highcharts.numberFormat(this.y, 0, ".", ",")
                );
              },
            },
            colors: ["#5BC2AE", "#F3BE31", "#795BD0"],
            series: [
              {
                type: "area",
                name: "Interest Rate up +2%",
                data: interestRateMovementUpData,
              },
              {
                type: "area",
                name: "Interest Rate down -2%",
                data: interestRateMovementDownData,
              },
            ],

            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          };

          setOffsetGraphOptions(offsetGraphOptions);
          setMarketValueGraphOptions(marketValueGraphOptions);
          setPassiveIncomeGraphOptions(passiveIncomeGraphOptions);
          setBorrowingCapactiyGraphOptions(borrowingCapacityGraphOptions);
          setRentalYieldGraphOptions(rentalYieldGraphOptions);
          setInterestRateMovementGraphOptions(interestRateMovementGraphOptions);

          // setRentalYieldData(rentalYieldGraphData);
          setIsDataLoaded(true);
        } else {
          setOffsetGraphOptions({});
          setMarketValueGraphOptions({});
          setPassiveIncomeGraphOptions({});
          setBorrowingCapactiyGraphOptions({});
          setRentalYieldGraphOptions({});
          setInterestRateMovementGraphOptions({});
          setIsDataLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setOffsetGraphOptions({});
        setMarketValueGraphOptions({});
        setBorrowingCapactiyGraphOptions({});
        setRentalYieldGraphOptions({});
        setInterestRateMovementGraphOptions({});
        setIsDataLoaded(false);
      });
  };

  const handleAssumptionChange = (e) => {
    console.log(e);
    console.log("assumptions: ", assumptions);
    const selectedAssumption = assumptions.find(
      (i) => i.AssumptionName === e.label
    );
    console.log("selected assumption: ", selectedAssumption);
    setSelectedAssumption(selectedAssumption);
    applyAssumption(state.clientId, selectedAssumption.AssumptionId);
    getCompassGraphData(state.clientId, selectedAssumption.AssumptionId);
  };

  var cardStyle = {
    display: "block",
    width: "135px",
    transitionDuration: "0.3s",
    height: "220px",
  };

  const handleAdd = () => {
    const path = "/admin/assumption/add";
    history.push(path);
  };

  const handleCompare = () => {
    const path = "/admin/compass/compare";
    history.push(path);
  };

  return (
    <div className="content compass-content">
      <ClientNotSelectedDialog />
      <Container>
        {!hasFullAccess && state.clientId ? (
          <>
            <Alert severity="info">
              Dummy data is being used to generate these graphs. Please pay to
              get your customised graphs
            </Alert>
            <br />
          </>
        ) : (
          <></>
        )}

        <h3>
          Property Strategy for {`${state?.firstName} ${state?.lastName}`}{" "}
        </h3>
        <Row>
          <Col md="12">
            <Form className="form-horizontal" id="TypeValidation">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Assumption </CardTitle>
                  {hasFullAccess && state.clientId ? (
                    <div>
                      <Button
                        sx={{ float: "right" }}
                        variant="contained"
                        color="grey"
                        size="medium"
                        startIcon={<SaveIcon />}
                        onClick={handleAdd}
                      >
                        ADD
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12" lg="12">
                      <Label md="3">Select Assumption</Label>
                      <FormGroup>
                        <Select
                          name=""
                          className="react-select select-option-control compass-select"
                          placeholder=""
                          classNamePrefix="react-select"
                          value={{
                            label: selectedAssumption.AssumptionName,
                            value: selectedAssumption.AssumptionId,
                          }}
                          onChange={handleAssumptionChange}
                          options={assumptionNames}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <br />
                  </Row>
                </CardBody>
                {selectedAssumption?.AssumptionName && (
                  <CardFooter>
                    <Row>
                      <Col className="text-center" lg="2" md="2" xs="2">
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                          <Grid
                            sx={{
                              flexDirection: "column",
                              padding: "8px",
                            }}
                          >
                            <Box
                              onClick={redirectToAssumption}
                              sx={assumptionBoxSx}
                            >
                              <Typography>
                                {selectedAssumption.GrowthRate}%
                                <br />
                                <Typography sx={{ fontSize: "14px" }}>
                                  Growth Rate
                                </Typography>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Col>
                      <Col className="text-center" lg="2" md="2" xs="2">
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                          <Grid
                            sx={{
                              flexDirection: "column",
                              padding: "4px",
                            }}
                          >
                            <Box
                              onClick={redirectToAssumption}
                              sx={assumptionBoxSx}
                            >
                              <Typography>
                                {selectedAssumption.InterestRate}%
                                <br />
                                <Typography sx={{ fontSize: "14px" }}>
                                  Interest Rate
                                </Typography>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Col>
                      <Col className="text-center" lg="2" md="2" xs="2">
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                          <Grid
                            sx={{
                              flexDirection: "column",
                              padding: "4px",
                            }}
                          >
                            <Box
                              onClick={redirectToAssumption}
                              sx={assumptionBoxSx}
                            >
                              <Typography>
                                {selectedAssumption.BorrowingCapacity}%
                                <br />
                                <Typography sx={{ fontSize: "14px" }}>
                                  Borrowing capacity
                                </Typography>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Col>
                      <Col className="text-center" lg="2" md="2" xs="2">
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                          <Grid
                            sx={{
                              flexDirection: "column",
                              padding: "4px",
                            }}
                          >
                            <Box
                              onClick={redirectToAssumption}
                              sx={assumptionBoxSx}
                            >
                              <Typography>
                                {selectedAssumption.SavingsPlacedInOffset}
                                %
                                <br />
                                <Typography sx={{ fontSize: "14px" }}>
                                  {" "}
                                  Savings placed in offset
                                </Typography>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Col>
                      <Col className="text-center" lg="2" md="2" xs="2">
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                          <Grid
                            sx={{
                              flexDirection: "column",
                              padding: "4px",
                            }}
                          >
                            <Box
                              onClick={redirectToAssumption}
                              sx={assumptionBoxSx}
                            >
                              <Typography>
                                {selectedAssumption.ReFinanceOnNewPurchase}
                                %
                                <br />
                                <Typography sx={{ fontSize: "14px" }}>
                                  Re-Finance on New Purchase
                                </Typography>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Col>
                      <Col className="text-center" lg="2" md="2" xs="2">
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                          <Grid
                            sx={{
                              flexDirection: "column",
                              padding: "4px",
                            }}
                          >
                            <Box
                              onClick={redirectToAssumption}
                              sx={assumptionBoxSx}
                            >
                              <Typography>
                                {selectedAssumption.Inflation}%
                                <br />
                                <Typography sx={{ fontSize: "14px" }}>
                                  Inflation
                                </Typography>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Col>
                    </Row>

                    <Card>
                      <CardBody>
                        <div
                          aria-multiselectable={true}
                          className="card-collapse"
                          id="accordion"
                          role="tablist"
                        >
                          <Card className="card-plain">
                            <CardHeader role="tab">
                              <a
                                className="expand-assumptions-toggle"
                                aria-expanded={openedCollapses.includes(
                                  "collapseOne"
                                )}
                                href="#pablo"
                                data-parent="#accordion"
                                data-toggle="collapse"
                                onClick={() => collapsesToggle("collapseOne")}
                              >
                                Expand Assumptions
                                <i className="nc-icon nc-minimal-down" />
                              </a>
                            </CardHeader>
                            <Collapse
                              role="tabpanel"
                              isOpen={openedCollapses.includes("collapseOne")}
                            >
                              <CardBody className="expand-assumptions">
                                <Row>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "8px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {selectedAssumption.OccupancyRate}
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Occupancy Rate
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {selectedAssumption.RentalYield}
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Rental Yield
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {
                                              selectedAssumption.RentalIncreasePA
                                            }
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Rental Increase PA
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {selectedAssumption.AcquisitionCost}
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Acquisition Cost
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {selectedAssumption.PayLMIOnFuturePurchases
                                              ? "TRUE"
                                              : "FALSE"}
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Pay LMI on future Purchases
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {
                                              selectedAssumption.RenovationReturn
                                            }
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Renovation Return
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>

                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {selectedAssumption.BAExpenses}
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              BA Expenses
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {selectedAssumption.NewLoanAmount}
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              {" "}
                                              New Loan Amount
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {selectedAssumption.WageGrowth}
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Wage Growth
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {selectedAssumption.SellingCost}
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              {" "}
                                              Selling Cost
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {selectedAssumption.CGTRate}%
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              CGT Rate
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {selectedAssumption.CGTDiscount}
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              CGT Discount
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>

                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {
                                              selectedAssumption.CommercialGrowthRate
                                            }
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Commercial Growth Rate
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {
                                              selectedAssumption.CommercialNetRentalYield
                                            }
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Commercial Net Rental Yield
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {
                                              selectedAssumption.CommercialInterestRate
                                            }
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Commercial Interest Rate
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {
                                              selectedAssumption.CommercialNewLoanAmount
                                            }
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              {" "}
                                              Commercial New Loan Amount
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {
                                              selectedAssumption.CommercialPropertyManagement
                                            }
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Commercial Property Management
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {formatCurrency(
                                              selectedAssumption.SuperContributionCapPerPerson
                                            )}
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Super Contribution Cap Per-Person
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>

                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {
                                              selectedAssumption.SuperSavingsPoolGrowthRate
                                            }
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Super Savings Pool Growth Rate
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {
                                              selectedAssumption.SuperInterestRate
                                            }
                                            %
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Super Interest Rate
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                  <Col
                                    className="text-center"
                                    lg="2"
                                    md="2"
                                    xs="2"
                                  >
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          flexDirection: "column",
                                          padding: "4px",
                                        }}
                                      >
                                        <Box
                                          onClick={redirectToAssumption}
                                          sx={assumptionBoxSx}
                                        >
                                          <Typography>
                                            {
                                              selectedAssumption.SuperTransferAge
                                            }
                                            <br />
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              Super Transfer Age
                                            </Typography>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Collapse>
                          </Card>
                        </div>
                      </CardBody>
                    </Card>
                  </CardFooter>
                )}
              </Card>
            </Form>
          </Col>
        </Row>
        <div className="compass-chart-section">
          <Row>
            <Col lg="4" md="6">
              <Card className="card-chart-section">
                <CardHeader>
                  <CardTitle tag="h4">Offset & Annual Spends</CardTitle>
                  {/* <p className="card-category">All products including Taxes</p> */}
                </CardHeader>
                <CardBody>
                  {isDataLoaded && (
                    <EnlargeView title="Offset & Annual Spends">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={offsetGraphOptions}
                      />
                    </EnlargeView>
                  )}
                </CardBody>
                <CardFooter>
                  <div className="legend"></div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-chart-section">
                <CardHeader>
                  <CardTitle tag="h4">Equity Position</CardTitle>
                  {/* <p className="card-category">All products including Taxes</p> */}
                </CardHeader>
                <CardBody>
                  {isDataLoaded && (
                    <EnlargeView title="Equity Position">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={marketValueGraphOptions}
                      />
                    </EnlargeView>
                  )}
                </CardBody>
                <CardFooter>
                  <div className="legend"></div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-chart-section">
                <CardHeader>
                  <CardTitle tag="h4">Passive Income</CardTitle>
                  {/* <p className="card-category">All products including Taxes</p> */}
                </CardHeader>
                <CardBody>
                  {isDataLoaded && (
                    <EnlargeView title="Passive Income">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={passiveIncomeGraphOptions}
                      />
                    </EnlargeView>
                  )}
                </CardBody>
                <CardFooter>
                  <div className="legend"></div>
                </CardFooter>
              </Card>
            </Col>

            <Col lg="4" md="6">
              <Card className="card-chart-section">
                <CardHeader>
                  <CardTitle tag="h4">Borrowing Capacity</CardTitle>
                  {/* <p className="card-category">All products including Taxes</p> */}
                </CardHeader>
                <CardBody>
                  {isDataLoaded && (
                    <EnlargeView title="Borrowing Capacity">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={borrowingCapacityGraphOptions}
                      />
                    </EnlargeView>
                  )}
                </CardBody>
                <CardFooter>
                  <div className="legend"></div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-chart-section">
                <CardHeader>
                  <CardTitle tag="h4">Rental Yield</CardTitle>
                  {/* <p className="card-category">All products including Taxes</p> */}
                </CardHeader>
                <CardBody>
                  {isDataLoaded && (
                    <EnlargeView title="Rental Yield">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={rentalYieldGraphOptions}
                      />
                    </EnlargeView>
                  )}
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-chart-section">
                <CardHeader>
                  <CardTitle tag="h4">Interest Rate Movements</CardTitle>
                  {/* <p className="card-category">All products including Taxes</p> */}
                </CardHeader>
                <CardBody>
                  {isDataLoaded && (
                    <EnlargeView title="Interest Rate Movements">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={interestRateMovementGraphOptions}
                      />
                    </EnlargeView>
                  )}
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
            {hasFullAccess && state.clientId ? (
              <>
                <Col lg="6" md="12">
                  <Card className="card-compass-table">
                    <Link to={`./property-summary`}>
                      <CardHeader className="pad_zero">
                        <CardTitle tag="h4">Property Summary</CardTitle>
                        {/* <p className="card-category">All products including Taxes</p> */}
                      </CardHeader>
                      <CardBody className="compass_tabb">
                        <TableContainer component={Paper}>
                          <Table
                            // sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Year</TableCell>
                                <TableCell>Purchase Price</TableCell>
                                <TableCell>Compound Growth</TableCell>
                                <TableCell>Rental Yield</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {propertyDetails.slice(0, 6).map((row) => (
                                <TableRow
                                  key={row.clientPropertyId}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.yearBuild
                                      ? convertDateTimeToYear(row.yearBuild)
                                      : ""}
                                  </TableCell>
                                  <TableCell align="left">
                                    {" "}
                                    {formatCurrency(row.purchasePrice)}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.growthRate}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.rentalYield}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardBody>
                      <CardFooter>
                        <div className="legend">
                          {/* <i className="fa fa-circle text-info" />
                                Tesla Model S <i className="fa fa-circle text-danger" />
                                BMW 5 Series */}
                        </div>
                      </CardFooter>
                    </Link>
                  </Card>
                </Col>

                <Col lg="6" md="12">
                  <Card className="card-compass-table">
                    <Link to={`./manually-entered-spends`}>
                      <CardHeader className="pad_zero">
                        <CardTitle tag="h4">Manually Entered Spends</CardTitle>
                        {/* <p className="card-category">All products including Taxes</p> */}
                      </CardHeader>
                      <CardBody className="compass_tabb">
                        <TableContainer component={Paper}>
                          <Table
                            // sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Year</TableCell>
                                <TableCell>Age</TableCell>
                                <TableCell>New Property Value</TableCell>
                                <TableCell>Renovations</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {spends.slice(0, 6).map((row) => (
                                <TableRow
                                  key={row.yearlyPlanId}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.year}
                                  </TableCell>
                                  <TableCell align="left">{row.age}</TableCell>
                                  <TableCell align="left">
                                    {row.propertyName}
                                  </TableCell>
                                  <TableCell align="left">
                                    {formatCurrency(row.renovationSpend) ||
                                      formatCurrency(0)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardBody>
                      <CardFooter>
                        <div className="legend">
                          {/* <i className="fa fa-circle text-info" />
                                Tesla Model S <i className="fa fa-circle text-danger" />
                                BMW 5 Series */}
                        </div>
                      </CardFooter>
                    </Link>
                  </Card>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Compass;
