import { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import TextareaAutosize from '@mui/base/TextareaAutosize';
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/system";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";

import Typography from "@mui/material/Typography";

const OtherExpenseDetails = ({ formData, setFormData, defaultAssumptions }) => {
  const [state, setState] = useAtom(selectedClientAtom);
  const otherExpenseFrequency = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
  ];
  const secondEducationYear = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
  ];
  const whenSecondEducationExpenseStops = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
  ];
  //  const [otherExpenseSpendingAmount, setOtherExpenseSpendingAmount] = useState(0);

  const changeInPurposeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setOtherExpenseFrequency = (otherExpenseFrequency) => {
    setFormData({
      ...formData,
      otherExpenseFrequency,
      isDefaultOtherExpenseFrequency: "0",
    });
  };

  const setOtherExpenseYear = (otherExpenseYear) => {
    setFormData({
      ...formData,
      otherExpenseYear,
      isDefaultOtherExpenseYear: "0",
    });
  };

  const setYearOtherExpenseStops = (yearOtherExpenseStops) => {
    setFormData({
      ...formData,
      yearOtherExpenseStops,
      isDefaultYearOtherExpenseStops: "0",
    });
  };

  const setOtherExpenseSpending = (e) => {
    // setOtherExpenseSpendingAmount(e.target.value);
    setFormData({
      ...formData,
      otherExpenseSpending: e.target.value,
      isDefaultOtherExpenseSpending: "0",
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4" className="card-margin">
            Set your property plan for {state.firstName} {state.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="8">
                <Label>
                  Are there any other large upcoming/ongoing expenses we should
                  account for?
                </Label>
                <FormGroup>
                  <Input
                    placeholder="Upcoming expense"
                    type="text"
                    value={formData.otherExpense}
                    onChange={(e) =>
                      setFormData({ ...formData, otherExpense: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>
                  How frequently do you pay for these expenses? (Years)
                </Label>
                <FormGroup>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>{defaultAssumptions.OtherExpenseFrequency}</strong>{" "}
                    would be selected as default )
                  </Typography>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {otherExpenseFrequency.map((often) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={often}
                      >
                        <Box
                          onClick={() => setOtherExpenseFrequency(often)}
                          key={often}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.otherExpenseFrequency === often
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {often}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>Which year will this other expense be?</Label>
                <FormGroup>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>
                      {new Date().getFullYear() +
                        defaultAssumptions.OtherExpenseYear}
                    </strong>{" "}
                    would be selected as default )
                  </Typography>

                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {secondEducationYear.map((year) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={year}
                      >
                        <Box
                          onClick={() => setOtherExpenseYear(year)}
                          key={year}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.otherExpenseYear === year
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {year}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>Which year will this other expense stop?</Label>
                <FormGroup>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>
                      {new Date().getFullYear() +
                        defaultAssumptions.YearOtherExpenseStops}
                    </strong>{" "}
                    would be selected as default )
                  </Typography>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {secondEducationYear.map((year) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={year}
                      >
                        <Box
                          onClick={() => setYearOtherExpenseStops(year)}
                          key={year}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.yearOtherExpenseStops === year
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {year}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Label>How much would you spend per cycle?</Label>
                <br />
                <Typography variant="caption" gutterBottom>
                  (Note: If not selected{" "}
                  <strong>{defaultAssumptions.OtherExpenseSpending}</strong>{" "}
                  would be selected as default spending )
                </Typography>

                <FormGroup className="left-m fg-margin">
                  <Slider
                    defaultValue={0}
                    value={formData.otherExpenseSpending}
                    step={100}
                    min={0}
                    max={500000}
                    valueLabelDisplay="auto"
                    onChange={(e) => {
                      setOtherExpenseSpending(e);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default OtherExpenseDetails;
