/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { Container } from "@mui/material";

function Footer(props) {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    scrollToTop();

    return () => {};
  }, []);

  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container>
        <Row>
          <Col lg="8" md="12">
            <nav className="footer-nav">
              <ul>
                <li>
                  {/* <a href="">Powered by AB&T Technology</a> */}
                  <a href="">Powered by Investor Partner Group</a>
                </li>

                {/* <li>
                <a href="https://www.creative-tim.com/license" target="_blank">
                  Licenses
                </a>
              </li> */}
              </ul>
            </nav>
          </Col>
          <Col lg="4" md="12">
            <div className="credits ml-auto">
              <span className="copyright">
                &copy; {1900 + new Date().getYear()}, The Property Plan
                {/* <i className="fa fa-heart heart" /> by Creative Tim */}
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
