import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import PaymentService from "../../../services/payment.service";
const stripePromise = loadStripe(
  "pk_test_51OSbf6LYKrA9eUfTVsuLekkWfP9s7cuqC6ZRz9Q8eUxTwDqPjgOSJEC2ja5CuHLVyMva8l9bKyS2rDZxE239ctaK002bW4Tit9"
);

const CheckoutForm = ({ data, setToggle, payNow }) => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    if (payNow) {
      PaymentService.createEmbeddedCheckoutSession().then((data) => {
        setClientSecret(data.clientSecret);
        setToggle(false);
      });
    }
    // eslint-disable-next-line
  }, [payNow]);

  return (
    <div className="content">
      <div id="checkout">
        {clientSecret ? (
          <>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
            <br />
            {/* <button
              className="text-right px-3 fs-6 bg-dark rounded-1"
              onClick={() => {
                setToggle(true);
              }}
            >
              Cancel
            </button> */}
          </>
        ) : (
          <>
            <box>
              <p>Loading Payment form ...</p>
            </box>
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutForm;
