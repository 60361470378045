import http from "./http-common";

class ClientService {
  // // http://localhost:5000/api/v1/scoreboard/property-portfolio/add
  add(data) {
    const path = "/client/add";
    console.log("data: ", data);
    return http.post(`${path}`, data);
  }

  // // http://localhost:5000/api/v1/scoreboard/property-portfolio/get/:id
  // getByPropertyId(id) {
  //     const path = `/scoreboard/property-portfolio/get/${id}`;
  //     console.log('path: ', path);
  //     return http.get(path);
  // };

  // http://localhost:5000/api/v1/scoreboard/property-portfolio/get/client/:id

  getAll() {
    const path = `/client/all`;
    console.log("path: ", path);
    return http.get(path);
  }

  getAllByPartnerId(id) {
    const path = `/client/${id}`;
    console.log("path: ", path);
    return http.get(path);
  }

  getAllByInternalUserId(id) {
    const path = `/client/internal-user/${id}`;
    console.log("path: ", path);
    return http.get(path);
  }

  getClientTypes() {
    const path = `/client/types`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/property-portfolio/update/:id
  update(id, data) {
    const path = `/scoreboard/property-portfolio/update/${id}`;
    return http.put(path, data);
  }

  getClientByUuid(uuid) {
    const path = `/client/get/uuid/${uuid}`;
    console.log("path: ", path);
    return http.get(path);
  }

  getByClientId(clientId) {
    const path = `/client/get/client/${clientId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  getAllBuyerAgentsForList() {
    const path = `/client/buyer-agents/list`;
    console.log("path: ", path);
    return http.get(path);
  }

  getAllByClientType(clientType) {
    const path = `/client/all/${clientType}`;
    console.log("path: ", path);
    return http.get(path);
  }
}

export default new ClientService();
