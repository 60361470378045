// import { registerInDevtools, Store } from "pullstate";

// export interface FormStep1 {
//     fullName?: string | undefined;
//     age?: string | undefined;
// }

// export interface FormStep2 {
//     birthPlace: string | undefined;
//     maidenName: string | undefined;
// }

// export interface FormStep3 {
//     termsAccepted: string | undefined;
//     privacyAccepted: string | undefined;
// }
// export type FormValues = {
//     progress: number;
// } & FormStep1 & FormStep2 & FormStep3;

// export const WizardStore = new Store < FormValues > ({
//     fullName: "",
//     age: "",
//     birthPlace: "",
//     maidenName: "",
//     termsAccepted: "",
//     privacyAccepted: "",
//     progress: 0,
// });

// registerInDevtools({
//     WizardStore,
// });

import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

const storage = createJSONStorage(() => sessionStorage);
export const userAtom = atomWithStorage(
  "userAtomState",
  {
    userId: 0,
    firstName: "",
    lastName: "",
    isAdmin: false,
    isPartner: false,
    isClient: false,
    partnerId: 0,
    userType: "",
    companyLogo: null,
  },
  storage
);

export const sidebarAtom = atom({
  sidebarOpen: false,
});
export const selectedClientAtom = atomWithStorage(
  "selectedAtomClient",
  {
    clientId: 0,
    firstName: "",
    lastName: "",
    fullAccess: false,
  },
  storage
);
