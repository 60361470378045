import * as React from "react";
import "./upload-document-dialog.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { userAtom } from "store";
import { useState } from "react";
import Select from "react-select";
import PDFUpload from "components/CustomUpload/PDFUpload";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import newPropertyService from "services/new-property.service";
import PopupWithClose from "components/Dialog/PopupWithClose";
import FileHistoryTable from "./tables/file-history-table";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// const options = [
//   { label: "Property report", value: 1 },
//   { label: "Suburb/LGA report", value: 2 },
//   { label: "Dial before you dig report", value: 3 },
//   { label: "Walkthough Video", value: 5 },
//   { label: "Walkthough Photo", value: 6 },
// ];

// ,"Water, drainage, sewerage report, shows easements and manholes on or around the property."
// ,"Storm water connections – legal point of discharge"
// ,"NBN connections and pits"
// ,"Telephone lines and Telstra connections"
// ,"Electricity cables"
// ,"Walkthrough videos / pictures"

const normalizeFolderName = (str) => {
  return str
    .replace(/\s+/g, "-")
    .replace(/,/g, "-")
    .replace(/[\/\\]/g, "-")
    .replace(/-+/g, "-");
};

export default function UploadDocumentDialog({
  propertyId,
  propertyAddress,
  dialogOpen,
  handleDialogClose,
  setShowDealProposedCheckedDialog,
}) {
  const history = useHistory();
  const [userState] = useAtom(userAtom);
  const [filename, setFilename] = React.useState("");
  const [selectedDocumentType, setSelectedDocumentType] = React.useState({});
  const [noFileErrorOpen, setNoFileErrorOpen] = React.useState(false);
  const [documentTypes, setDocumentTypes] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);

  propertyAddress = normalizeFolderName(propertyAddress);

  React.useEffect(() => {
    getDocumentTypes();
    getPropertyDocumentsByPropertyId(propertyId);
  }, []);

  const handleFilenameChange = async (filename, foldername) => {
    console.log("file: ", filename);
    setFilename(filename);
    await addPropertyDocument(filename, foldername);
  };

  const handleDocumentTypeChange = (e) => {
    console.log("document type: ", e);
    setSelectedDocumentType(e);
  };

  const handleUploadDocumentDialogClose = () => {
    setSelectedDocumentType({});
    setFilename("");
    handleDialogClose();
    //setShowDealProposedCheckedDialog(false); // need to update
  };

  const validateFileCategory = () => {
    const isFileSelected = Object.keys(selectedDocumentType).length > 0;
    if (!isFileSelected) {
      setNoFileErrorOpen(true);
    }
    return isFileSelected;
  };

  const handleSave = async (e) => {
    if (!filename) {
      setNoFileErrorOpen(true);
      return;
    }
    console.log("filename: ", filename);
    await addPropertyDocument();
  };

  const addPropertyDocument = async (
    propertyDocumentName,
    propertyDocumentLocation
  ) => {
    const data = {
      newPropertyId: propertyId,
      propertyDocumentTypeId: selectedDocumentType.value,
      propertyDocumentName: propertyDocumentName,
      // Location is currently the foldername
      propertyDocumentLocation: propertyDocumentLocation,
      userId: userState.userId,
    };
    console.log("Property document location - ", propertyDocumentLocation);
    return newPropertyService
      .addPropertyDocument(data)
      .then((response) => {
        console.log("added property: ", response);
        getPropertyDocumentsByPropertyId(propertyId);
        // handleDialogClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDocumentTypes = async () => {
    return newPropertyService
      .getDocumentTypes()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const types = response.data.recordset.map(
            ({ PropertyDocumentType, PropertyDocumentTypeId }) => ({
              label: PropertyDocumentType,
              value: PropertyDocumentTypeId,
            })
          );
          setDocumentTypes(types);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleErrorDialogClose = () => setNoFileErrorOpen(false);

  const getPropertyDocumentsByPropertyId = (propertyId) => {
    return newPropertyService
      .getPropertyDocumentsByPropertyId(propertyId)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const propertyDocuments = response.data.recordset.map(
            ({
              PropertyDocumentId,
              PropertyDocumentType,
              PropertyDocumentName,
              PropertyDocumentLocation,
            }) => ({
              id: PropertyDocumentId,
              propertyDocumentType: PropertyDocumentType,
              propertyDocumentName: PropertyDocumentName,
              propertyDocumentLocation: PropertyDocumentLocation,
            })
          );
          setDocuments(propertyDocuments);
        } else {
          setDocuments([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleConfirmationOpen}>
        Delete
      </Button> */}
      <PopupWithClose
        title="No File Category Chosen"
        content="Please choose a file category before uploading a file"
        open={noFileErrorOpen}
        handleClose={handleErrorDialogClose}
      />
      <BootstrapDialog
        className="upload-popup"
        open={dialogOpen}
        onClose={handleUploadDocumentDialogClose}
        maxWidth="lg"
        fullWidth
        aria-labelledby="upload-dialog-title"
        aria-describedby="upload-dialog-description"
      >
        <DialogTitle id="upload-dialog-title">{"Upload Documents"}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleUploadDocumentDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="upload-dialog-form">
            <Form>
              <Row>
                <Col md="6">
                  <Label>File Category</Label>
                  {/* <FormGroup > */}
                  <div className="upload-select-category">
                    <Select
                      name=""
                      className="react-select select-option-control"
                      placeholder="Select File Category"
                      classNamePrefix="react-select"
                      value={selectedDocumentType}
                      onChange={handleDocumentTypeChange}
                      options={documentTypes}
                    />
                  </div>
                  {/* </FormGroup> */}
                </Col>
                <Col md="6">
                  <Label for="exampleFile">File Upload</Label>
                  <br />
                  <PDFUpload
                    handleFilenameChange={handleFilenameChange}
                    handleBeforeSelectingFile={validateFileCategory}
                    foldername={propertyAddress}
                    filename={selectedDocumentType?.label}
                  />
                </Col>
                <Col md="12">
                  <Label>File History</Label>
                  <FileHistoryTable
                    propertyId={propertyId}
                    data={documents}
                    fetchData={getPropertyDocumentsByPropertyId}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadDocumentDialogClose}>Close</Button>
          {/* <Button onClick={handleSave}>Save</Button> */}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
