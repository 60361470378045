import { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import TextareaAutosize from '@mui/base/TextareaAutosize';
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/system";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";

import Typography from "@mui/material/Typography";

const SecondEducationDetails = ({
  formData,
  setFormData,
  defaultAssumptions,
}) => {
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const secondEducationFeeFrequency = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
  ];
  const secondEducationYear = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
  ];
  const yearSecondEducationExpenseStops = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
  ];
  // const [secondEducationCycleSpending, setSecondEducationCycleSpending] = useState(0);

  const changeInPurposeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setSecondEducationFeeFrequency = (secondEducationFeeFrequency) => {
    setFormData({
      ...formData,
      secondEducationFeeFrequency,
      isDefaultSecondEducationFeeFrequency: "0",
    });
  };

  const setSecondEducationYear = (secondEducationExpenseYear) => {
    setFormData({
      ...formData,
      secondEducationExpenseYear,
      isDefaultSecondEducationExpenseYear: "0",
    });
  };

  const setYearSecondEducationExpenseStops = (
    yearSecondEducationExpenseStops
  ) => {
    setFormData({
      ...formData,
      yearSecondEducationExpenseStops,
      isDefaultYearSecondEducationExpenseStops: "0",
    });
  };

  const setSecondEducationSpending = (e) => {
    console.log("e: ", e.target.value);
    setFormData({
      ...formData,
      secondEducationCycleSpending: e.target.value,
      isDefaultSecondEducationCycleSpending: "0",
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4" className="card-margin">
            Set your property plan for {clientState.firstName}{" "}
            {clientState.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="12">
                <Label>
                  How frequently do you pay for education? University, School
                  Fees (Years)
                </Label>
                <FormGroup>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>
                      {defaultAssumptions.SecondEducationFeeFrequency}
                    </strong>{" "}
                    would be selected as default )
                  </Typography>

                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {secondEducationFeeFrequency.map((often) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={often}
                      >
                        <Box
                          onClick={() => setSecondEducationFeeFrequency(often)}
                          key={often}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.secondEducationFeeFrequency === often
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {often}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>Which year will the next education expense be?</Label>
                <FormGroup>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>
                      {new Date().getFullYear() +
                        defaultAssumptions.SecondEducationExpenseYear}
                    </strong>{" "}
                    would be selected as default )
                  </Typography>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {secondEducationYear.map((year) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={year}
                      >
                        <Box
                          onClick={() => setSecondEducationYear(year)}
                          key={year}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.secondEducationExpenseYear === year
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {year}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>Which year will the next education expense stop?</Label>
                <FormGroup>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>
                      {new Date().getFullYear() +
                        defaultAssumptions.YearSecondEducationExpenseStops}
                    </strong>{" "}
                    would be selected as default )
                  </Typography>

                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {yearSecondEducationExpenseStops.map((year) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={year}
                      >
                        <Box
                          onClick={() =>
                            setYearSecondEducationExpenseStops(year)
                          }
                          key={year}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.yearSecondEducationExpenseStops === year
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {year}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Label>How much would you spend per education cycle?</Label>
                <br />
                <Typography variant="caption" gutterBottom>
                  (Note: If not selected{" "}
                  <strong>
                    {defaultAssumptions.SecondEducationCycleSpending}
                  </strong>{" "}
                  would be selected as default spending )
                </Typography>

                <FormGroup className="left-m fg-margin">
                  {/* <Slider defaultValue={0} value={formData.secondEducationCycleSpending} step={100} min={0}
                                        max={500000} valueLabelDisplay='auto' onChange={(e) => { setSecondEducationSpending(e) }} /> */}
                  <Slider
                    defaultValue={0}
                    value={formData.secondEducationCycleSpending}
                    step={100}
                    min={0}
                    max={500000}
                    valueLabelDisplay="auto"
                    onChange={(e) => {
                      setSecondEducationSpending(e);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default SecondEducationDetails;
