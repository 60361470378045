import { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";

const PropertyDetails = ({ formData, setFormData }) => {
  const [selectedChangeInNext5Years, setSelectedChangeInNext5Years] =
    useState("");
  const [state, setState] = useAtom(selectedClientAtom);
  const dwellingType = [
    "Home",
    "Semi-detached",
    "Townhouse",
    "Duplex",
    "Unit/flat",
    "Apartment",
    "Renovation",
    "Development site",
    "Block of units",
    "Block of land",
    "Shopfront",
    "Strip of shops",
    "Shopping mall",
    "Warehouse",
    "Factory",
  ];
  const noOfBeds = ["0", "1", "2", "3", "4", "5+"];
  const noOfBaths = ["0", "1", "2", "3", "4", "5+"];
  const noOfCarSpaces = ["0", "1", "2", "3", "4", "5+"];
  const existingOrNew = ["Existing", "New"];

  const changingCircumstancesBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: '20px 20px 20px 32px',
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const noOfBedsBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setDwellingType = (dwellingType) => {
    setFormData({ ...formData, dwellingType });
  };

  const setNoOfBedRooms = (noOfBeds) => {
    setFormData({ ...formData, noOfBeds });
  };

  const setNoOfBathRooms = (noOfBaths) => {
    setFormData({ ...formData, noOfBaths });
  };

  const setNoOfCarSpaces = (noOfCarSpaces) => {
    setFormData({ ...formData, noOfCarSpaces });
  };

  const setExistingOrNew = (newOrExisting) => {
    setFormData({ ...formData, newOrExisting });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Setting up Asset Selection for {state.firstName} {state.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="12">
                <Label>What type of dwelling would you prefer?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {dwellingType.map((dt) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={dt}
                      >
                        <Box
                          onClick={() => setDwellingType(dt)}
                          key={dt}
                          sx={changingCircumstancesBoxSx}
                          style={{
                            backgroundColor:
                              formData.dwellingType === dt
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {dt}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>How many beds?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {noOfBeds.map((nb) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={nb}
                      >
                        <Box
                          onClick={() => setNoOfBedRooms(nb)}
                          key={nb}
                          sx={noOfBedsBoxSx}
                          style={{
                            backgroundColor:
                              formData.noOfBeds === nb ? "#e61a4b" : "#114253",
                          }}
                        >
                          {nb}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>How many baths?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {noOfBaths.map((nb) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={nb}
                      >
                        <Box
                          onClick={() => setNoOfBathRooms(nb)}
                          key={nb}
                          sx={noOfBedsBoxSx}
                          style={{
                            backgroundColor:
                              formData.noOfBaths === nb ? "#e61a4b" : "#114253",
                          }}
                        >
                          {nb}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>How many car spaces?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {noOfCarSpaces.map((nc) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={nc}
                      >
                        <Box
                          onClick={() => setNoOfCarSpaces(nc)}
                          key={nc}
                          sx={noOfBedsBoxSx}
                          style={{
                            backgroundColor:
                              formData.noOfCarSpaces === nc
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {nc}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <Label>Land size?</Label>
                <FormGroup>
                  <Input
                    placeholder="Land size"
                    type="number"
                    min="0"
                    oninput="validity.valid||(value='');"
                    value={formData.landSize}
                    onChange={(e) =>
                      setFormData({ ...formData, landSize: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <Label>Would you prefer existing or new property?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {existingOrNew.map((en) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={en}
                      >
                        <Box
                          onClick={() => setExistingOrNew(en)}
                          key={en}
                          sx={noOfBedsBoxSx}
                          style={{
                            backgroundColor:
                              formData.newOrExisting === en
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {en}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default PropertyDetails;
