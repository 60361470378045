/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import FamilyMembersAdd from "ui/scoreboard/family-members/family-members-add";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.1";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "../src/globals.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      {/* <Route exact path="/admin/scoreboard/family-members/add" render={(props) => <FamilyMembersAdd {...props} />} /> */}
      {/* <Route path="/" exact={true}>
        <Redirect to="/step-1" />
      </Route>
      <Route path="/step-1" exact={true}>
        <Step1 />
      </Route>
      <Route path="/step-2" exact={true}>
        <Step2 />
      </Route>
      <Route path="/step-3" exact={true}>
        <Step3 />
      </Route>
      <Route path="/confirmation" exact={true}>
        <Confirmation />
      </Route> */}
      <Redirect to="/auth/login" />
    </Switch>
  </BrowserRouter>
);
