// In src/posts/PostListContent.tsx
import { DragDropContext, OnDragEndResponder } from "@hello-pangea/dnd";
import { Box, Button, IconButton } from "@mui/material";
// import { isEqual } from "lodash";
import { useEffect, useState } from "react";
// import { useListContext } from "react-admin";

import {
  PostsByStatus,
  getPostsByStatus,
  statusId,
  statuses,
} from "./Statuses";
import { PostColumn } from "./PostColumn";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import NewPropertyService from "services/new-property.service";
import clientService from "services/client.service";
import ForReviewForm from "ui/new-properties/Dialogs/for-review/for-review-form";
import ForDealAcceptedForm from "ui/new-properties/Dialogs/for-deal-accepted/for-deal-accepted-form";

import newPropertyService from "services/new-property.service";
import { useAtom } from "jotai";
import { userAtom } from "store";
import propertyStageService from "services/property-stage.service";
import { SourceContext } from "@storybook/blocks";
import DealProposedCheck from "./DealProposedCheck";
import ContractExchange from "./ContractExchange";
import AddClientDialog from "ui/client/add-client-dialog";
import UploadDocumentDialog from "../../ui/new-properties/Dialogs/upload-document/upload-document-dialog";

export const PostListContent = ({
  unorderedPosts,
  fetchProperties,
  deleteProperty,
}) => {
  //   const { data, isLoading } = useListContext();
  // const unorderedPosts = data;

  const [postsByStatus, setPostsByStatus] = useState(getPostsByStatus([]));

  // For Review Dialog START
  const [dialogOpen, setDialogOpen] = useState(false);

  const [previousPost, setPreviousPost] = useState({});

  const [currentPost, setCurrentPost] = useState({});

  const [propertyId, setPropertyId] = useState(0);

  const [userState] = useAtom(userAtom);

  const [updatedProperty, setUpdatedProperty] = useState({});

  const [showDealProposedCheckedDialog, setShowDealProposedCheckedDialog] =
    useState(false);

  const [showContractExchangeDialog, setShowContractExchangeDialog] =
    useState(false);

  const [clientStatus, setClientStatus] = useState(true); // Client Assigned by default

  const [documentStatus, setDocumentStatus] = useState(true); // Documents Defined by default

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const [showDealAcceptedDialog, setShowDealAcceptedDialog] = useState(false);
  const [showDealExchangedDialog, setShowDealExchangeddDialog] =
    useState(false);
  // const [dealAcceptedMandatoryDocNotReceived, setDealAcceptedMandatoryDocNotReceived] = useState(0)

  const handleDialogClose = (e, reason) => {
    if (reason != "backdropClick") handleCancel();
    setDialogOpen(false);
  };

  const handleDealProposedDialogClose = () => {
    setShowDealAcceptedDialog(false);
  };

  const revertPostToPreviousStage = (
    sourcePost,
    source,
    destination,
    postsByStatus
  ) =>
    setPostsByStatus(
      updatePostStatusLocal(sourcePost, source, destination, postsByStatus)
    );

  const handleclientStatus = () => {
    setClientStatus(false);
  };
  const handleDocumentStatus = () => {
    setDocumentStatus(false);
  };

  const handleCancel = () => {
    // revert stage !!
    revertPostToPreviousStage(
      previousPost.sourcePost,
      previousPost.destination,
      previousPost.source,
      postsByStatus
    );
    setDialogOpen(false);
    setShowDealAcceptedDialog(false);
    setShowContractExchangeDialog(false);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  function handlePropertyMoveToProposed(
    sourcePost,
    source,
    destination,
    postsByStatus
  ) {
    console.log("Handle Property - Move to Proposed, Source post", sourcePost);

    const formData = {
      propertyId: sourcePost.id,
      propertyStatusId: statusId[destination.status],
      comments: "",
      userId: userState.userId,
    };

    // update the status in property table
    newPropertyService
      .updateStatus(formData, formData.propertyId)
      .then((response) => {
        // update the record in status history table
        propertyStageService
          .add(formData)
          .then((response) => {
            fetchProperties();
            console.log("moving suceeded: ", response);
          })
          .catch((error) => {
            // revert the property card
            revertPostToPreviousStage(
              sourcePost,
              destination,
              source,
              postsByStatus
            );
            console.log(error);
          });
      })
      .catch((error) => {
        // revert the property card
        revertPostToPreviousStage(
          sourcePost,
          destination,
          source,
          postsByStatus
        );
        console.log(error);
      });
  }

  function handlePropertyMoveToAccepted(
    sourcePost,
    source,
    destination,
    postsByStatus
  ) {
    const formData = {
      propertyId: sourcePost.id,
      propertyStatusId: statusId[destination.status],
      comments: "",
      userId: userState.userId,
    };

    console.log("Handle Property - Move to Accepted, Source post", sourcePost);
    console.log("Handle Property - Move to Accepted, Form Data", formData);

    // update the status in property table
    newPropertyService
      .updateStatus(formData, formData.propertyId)
      .then((response) => {
        // update the record in status history table
        propertyStageService
          .add(formData)
          .then((response) => {
            fetchProperties();
            console.log("moving suceeded: ", response);
          })
          .catch((error) => {
            // revert the property card
            revertPostToPreviousStage(
              sourcePost,
              destination,
              source,
              postsByStatus
            );
            console.log(error);
          });
      })
      .catch((error) => {
        // revert the property card
        revertPostToPreviousStage(
          sourcePost,
          destination,
          source,
          postsByStatus
        );
        console.log(error);
      });
  }

  const handleDrag = (sourcePost, source, destination, postsByStatus) => {
    const data = {
      ...sourcePost,
      statusId: statusId[destination.status],
      userId: userState.userId,
      // currentClient: updatedProperty.currentClient,
    };
    console.log("Handle Drage: Data", data);
    console.log("Handle Drage: Destination Exchange", destination);

    if (
      destination.status === "reviewed" &&
      sourcePost.dueDiligence === false
    ) {
      console.log("Drag to Review:", data);
      setCurrentPost(data);
      console.log("Drag to Review:", currentPost);
      setPropertyId(sourcePost.id);
      setDialogOpen(true);
    } else if (destination.status === "proposed" && data.moveToProposed === 0) {
      // Transfer to Proposed but documents/ clients are missing so showing the dialog to assing client / attached docs

      setCurrentPost(data);
      setPropertyId(sourcePost.id);
      setUpdatedProperty(data);

      setShowDealProposedCheckedDialog(true);
    } else if (destination.status === "proposed" && data.moveToProposed === 1) {
      // Transfer to Proposed

      setCurrentPost(data);
      setPropertyId(sourcePost.id);
      // setUpdatedProperty(sourcePost);
      setUpdatedProperty(data);
      console.log("drag to propsed - data", data);
      console.log("drag to propsed - source Post", sourcePost);

      handlePropertyMoveToProposed(
        sourcePost,
        source,
        destination,
        postsByStatus
      ); // update to db
    } else if (destination.status === "accepted" && data.moveToAccepted === 1) {
      // Transfer to accepted

      setCurrentPost(data);
      setPropertyId(sourcePost.id);

      setUpdatedProperty(data);
      handlePropertyMoveToAccepted(
        sourcePost,
        source,
        destination,
        postsByStatus
      ); // update to db
    } else if (destination.status === "accepted" && data.moveToAccepted === 0) {
      setCurrentPost(data);
      setPropertyId(sourcePost.id);

      setUpdatedProperty(data);
      setShowDealAcceptedDialog(true);
    } else if (
      destination.status === "contract_exchanged" &&
      (!data.purchaseData ||
        !data.settlementData ||
        !data.inspectionDate ||
        !data.finalApprovalDate ||
        data.preSettleDate ||
        !data.purchaseValue)
      // data.contractExchange === 0
    ) {
      console.log("Drag to Contract Exchange - Data", data);
      setCurrentPost(data);
      setPropertyId(sourcePost.id);

      setUpdatedProperty(data);
      setShowContractExchangeDialog(true);
    } else {
      // fire request for other stages
      newPropertyService
        .update(data, sourcePost.id)
        .then((response) => {
          propertyStageService
            .add({
              propertyId: sourcePost.id,
              propertyStatusId: statusId[destination.status],
              comments: "",
              userId: userState.userId,
            })
            .then((response) => {
              fetchProperties();
              console.log("moving suceeded: ", response);
            })
            .catch((error) => {
              // revert the property card
              revertPostToPreviousStage(
                sourcePost,
                destination,
                source,
                postsByStatus
              );
              console.log(error);
              // setDialogOpen(false);
              // setIsDataLoaded(true);
              // setProperties([]);
            });
        })
        .catch((error) => {
          // revert the property card
          revertPostToPreviousStage(
            sourcePost,
            destination,
            source,
            postsByStatus
          );
          console.log(error);
          // setDialogOpen(false);
          // setIsDataLoaded(true);
          // setProperties([]);
        });
    }

    console.log(sourcePost, source, destination, postsByStatus);
  };
  // For Review Dialog END

  useEffect(() => {
    if (unorderedPosts) {
      console.log("posts: ", unorderedPosts);
      const newPostsByStatus = getPostsByStatus(unorderedPosts);
      if (newPostsByStatus != postsByStatus) {
        setPostsByStatus(newPostsByStatus);
      }
    }
  }, [unorderedPosts]);

  //   if (isLoading) return null;

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const sourceStatus = source.droppableId;
    const destinationStatus = destination.droppableId;
    const sourcePost = postsByStatus[sourceStatus][source.index];
    const destinationPost = postsByStatus[destinationStatus][
      destination.index
    ] ?? {
      status: destinationStatus,
      index: undefined, // undefined if dropped after the last item
    };

    // compute local state change synchronously
    setPostsByStatus(
      updatePostStatusLocal(
        sourcePost,
        { status: sourceStatus, index: source.index },
        { status: destinationStatus, index: destination.index },
        postsByStatus
      )
    );

    setPreviousPost({
      sourcePost,
      source: { status: sourceStatus, index: source.index },
      destination: { status: destinationStatus, index: destination.index },
    });

    // locally updated & handleDrag from parent component
    handleDrag(
      sourcePost,
      { status: sourceStatus, index: source.index },
      { status: destinationStatus, index: destination.index },
      postsByStatus
    );

    // trigger the mutation to persist the changes
    // mutation.mutateAsync({
    //   source: sourcePost,
    //   destination: destinationPost,
    // });
  };

  const handleUploadDialogOpen = (e) => {
    setUploadDialogOpen(true);
  };

  const handleUploadDialogClose = (e) => {
    setUploadDialogOpen(false);
  };

  return (
    <>
      <ForReviewForm
        propertyId={propertyId}
        currentPost={currentPost}
        previousPost={previousPost}
        postsByStatus={postsByStatus}
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
        handleCancel={handleCancel}
        revertPostToPreviousStage={revertPostToPreviousStage}
        fetchProperties={fetchProperties}
      />

      {showDealAcceptedDialog && (
        <ForDealAcceptedForm
          propertyId={propertyId}
          currentPost={currentPost}
          previousPost={previousPost}
          postsByStatus={postsByStatus}
          handleCancel={handleCancel}
          revertPostToPreviousStage={revertPostToPreviousStage}
          setShowDealAcceptedDialog={setShowDealAcceptedDialog}
          fetchProperties={fetchProperties}
          updatedProperty={updatedProperty}
          setUpdatedProperty={setUpdatedProperty}
        />
      )}

      {showContractExchangeDialog && (
        <ContractExchange
          property={updatedProperty}
          setUpdatedProperty={setUpdatedProperty}
          setShowContractExchangeDialog={setShowContractExchangeDialog}
          fetchProperties={fetchProperties}
          handleCancel={handleCancel}
          setCurrentPost={setCurrentPost}
        />
      )}

      {showDealProposedCheckedDialog && (
        <DealProposedCheck
          updatedProperty={updatedProperty}
          currentPost={updatedProperty}
          setUpdatedProperty={setUpdatedProperty}
          setShowDealProposedCheckedDialog={setShowDealProposedCheckedDialog}
          fetchProperties={fetchProperties}
          handleCancel={handleCancel}
          handlePropertyMoveToProposed={handlePropertyMoveToProposed}
        />
      )}

      {/* <UploadDocumentDialog
        propertyId={property.id}
        dialogOpen={uploadDialogOpen}
        handleDialogClose={handleUploadDialogClose}
      /> */}

      <DragDropContext onDragEnd={onDragEnd}>
        <Box display="flex">
          {statuses.map((status) => (
            <PostColumn
              status={status}
              posts={postsByStatus[status]}
              key={status}
              deleteProperty={deleteProperty}
              fetchProperties={fetchProperties}
            />
          ))}
        </Box>
      </DragDropContext>
    </>
  );
};

const updatePostStatusLocal = (
  sourcePost,
  source,
  destination,
  postsByStatus
) => {
  if (source.status === destination.status) {
    // moving deal inside the same column
    const column = postsByStatus[source.status];
    column.splice(source.index, 1);
    column.splice(destination.index ?? column.length + 1, 0, sourcePost);
    return {
      ...postsByStatus,
      [destination.status]: column,
    };
  } else {
    // moving deal across columns
    const sourceColumn = postsByStatus[source.status];
    const destinationColumn = postsByStatus[destination.status];
    sourceColumn.splice(source.index, 1);
    destinationColumn.splice(
      destination.index ?? destinationColumn.length + 1,
      0,
      sourcePost
    );
    return {
      ...postsByStatus,
      [source.status]: sourceColumn,
      [destination.status]: destinationColumn,
    };
  }
};
