import { React, useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../store";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import GraphService from "../../../services/graph.service";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, Container } from "@mui/material";
import EnlargeView from "components/EnlargeView/EnlargeView";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

const RentalYield = () => {
  const [growthOptions, setGrowthOptions] = useState({});
  const [rentalYieldOptions, setRentalYieldOptions] = useState({});
  const [cashflowOptions, setCashflowOptions] = useState({});
  const [borrowableEquityOptions, setBorrowableEquityOptions] = useState({});
  const [loanToValueRatioOptions, setLoanToValueRatioOptions] = useState({});
  const [portfolioCashflowOptions, setPortfolioCashflowOptions] = useState({});
  const [savingsAndExpensesOptions, setSavingsAndExpensesOptions] = useState(
    {}
  );

  const [safetyBufferOptions, setSafetyBufferOptions] = useState({});
  const [monthsLeftSavingOptions, setMonthsLeftSavingOptions] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [state, setState] = useAtom(selectedClientAtom);

  let combinePortfolioExpenses = 0;
  let sumOfTotalPortfolioExpenses = 0;

  const hoverBorderColor = ["#fcc468", "#4cbdd7"];

  const useStyles = makeStyles((theme) => ({
    root: {
      boxShadow: "0px 0px 10px #CAD7DC",
      "&:hover": {
        backgroundColor: "#66615B",
        color: "#FFFFFF",
      },
    },
  }));

  const classes = useStyles();

  // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
  const barColors = [
    "#4572A7",
    "#AA4643",
    "#89A54E",
    "#80699B",
    "#3D96AE",
    "#DB843D",
    "#92A8CD",
    "#A47D7C",
    "#B5CA92",
  ];

  const getClientPropertyGrowth = () => {
    GraphService.getPropertyGrowth(state.clientId)
      .then((response) => {
        console.log("response.data: ", response.data);
        if (response.data.recordset.length > 0) {
          const data = response.data.recordsets[0];
          // console.log("graph data: ", data);
          const growthDataValue = data.map((item) =>
            Math.round(item.YearlyGrowth, 2)
          );

          const rentalYieldDataValue = data.map((item) => item.RentalYield);

          const totalPortfolioExpenses = data.map((item) => {
            return (
              item.CurrentYearlyRepayments +
              item.YearlyManagementFee +
              item.PropertyYearlyExpenses
            );
          });

          console.log("totalPortfolioExpenses: ", totalPortfolioExpenses);

          sumOfTotalPortfolioExpenses = totalPortfolioExpenses.reduce(
            (a, b) => +a + b,
            0
          );

          console.log(
            "sumOfTotalPortfolioExpenses: ",
            sumOfTotalPortfolioExpenses
          );

          const cashflowDataValue = data.map(
            (item) =>
              item.YearlyRentalIncome -
              item.CurrentYearlyRepayments -
              item.YearlyManagementFee -
              item.PropertyYearlyExpenses
          );

          // console.log("cashflowDataValue: ", cashflowDataValue);

          const growthDataLabel = data.map((item) =>
            item.Address.substring(0, 18)
          );

          const growthGraphOptions: Options = {
            chart: {
              type: "column",
              height: "280px",
            },
            title: {
              text: "",
            },
            plotOptions: {
              column: {
                colorByPoint: true,
                formatter: function () {
                  return Highcharts.numberFormat(this.x) + "%";
                },
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  this.x +
                  "<br/>" +
                  "Growth: " +
                  Highcharts.numberFormat(this.y, 2, ".", ",") +
                  " %"
                );
              },
            },
            series: [
              {
                // `type: column` is required for type-checking this options as a column series
                type: "column",
                data: growthDataValue,
                showInLegend: false,
                name: "Growth",
                label: { enabled: false },
              },
            ],
            xAxis: {
              categories: growthDataLabel,
              labels: {
                useHTML: true,
                // formatter: () => "",
              },
            },
            yAxis: {
              // min: 0,
              title: {
                text: "Realised growth per annum",
              },
            },
            legend: {
              enabled: false,
            },
          };

          const rentalYieldOptions: Options = {
            chart: {
              type: "column",
              height: "280px",
            },
            title: {
              text: "",
            },
            plotOptions: {
              column: {
                colorByPoint: true,
              },
              dataLabels: {
                enabled: false,
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  this.x +
                  "<br/>" +
                  "Rental Yield: " +
                  Highcharts.numberFormat(this.y, 2, ".", ",") +
                  " %"
                );
              },
            },
            series: [
              {
                // `type: column` is required for type-checking this options as a column series
                showInLegend: false,
                name: "Rental yield",
                type: "column",
                data: rentalYieldDataValue,
              },
            ],
            xAxis: {
              categories: growthDataLabel,
              labels: {
                useHTML: true,
                // formatter: () => "",
              },
            },
            yAxis: {
              // min: 0,
              title: {
                text: "Rental Yield",
              },
            },
            legend: {
              enabled: false,
            },
          };

          const propertyCashflowOptions: Options = {
            chart: {
              type: "column",
              height: "280px",
            },
            title: {
              text: "",
            },
            plotOptions: {
              column: {
                colorByPoint: true,
              },
              dataLabels: {
                enabled: false,
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  this.x +
                  "<br/>" +
                  "Cashflow: $ " +
                  Highcharts.numberFormat(this.y, 0, ".", ",")
                );
              },
            },
            series: [
              {
                // `type: column` is required for type-checking this options as a column series
                type: "column",
                data: cashflowDataValue,
                showInLegend: false,
                name: "Cashflow",
              },
            ],
            xAxis: {
              categories: growthDataLabel,
              labels: {
                useHTML: true,
                // formatter: () => "",
              },
            },
            yAxis: {
              // min: 0,
              title: {
                text: "Cashflow",
              },
            },
            legend: {
              enabled: false,
            },
          };

          setGrowthOptions(growthGraphOptions);
          setRentalYieldOptions(rentalYieldOptions);
          setCashflowOptions(propertyCashflowOptions);
          setIsDataLoaded(true);
        } else {
          setGrowthOptions({});
          setRentalYieldOptions({});
          setCashflowOptions({});
          setIsDataLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setGrowthOptions({});
        setIsDataLoaded(false);
      });
  };

  const getClientSavingsAndExpenses = async () => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));

    GraphService.getClientSavingsAndExpensesSummary(state.clientId)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          // console.log("response: ", response.data.recordset);
          const data = response.data.recordset[0];
          console.log("saving and expenses data: ", data);
          const monthlyAvgSaving = data.MonthlyAvgSaving;
          const monthlyExpenses = data.MonthlyExpenses;
          const grossAnnualIncome = data.GrossAnnualIncome;
          const allocateIncomeToSuper = data.AllocateIncomeToSuper;
          const totalInSuper = data.TotalInSuper;
          let totalLiquidSavings = data.TotalLiquidSavings;
          const monthlyLifeStyleSpending = data.MonthlyLifeStyleSpending;
          const calculatedRepayments = data.CalculatedMonthlyRepayments;
          const currentRepayments = data.CurrentRepayments;
          const portfolioExpenses = data.PortfolioExpenses;
          const managementFee = data.ManagementFee;
          const portoflioIncome = data.RentalIncome;
          const yearlyPortoflioIncome = portoflioIncome * 12;

          if (currentRepayments === 0) {
            combinePortfolioExpenses = +(
              +calculatedRepayments +
              +portfolioExpenses +
              +managementFee
            ).toFixed(0);
          } else {
            combinePortfolioExpenses = +(
              +currentRepayments +
              +portfolioExpenses +
              +managementFee
            ).toFixed(0);
          }

          const yearlyCombinePortfolioExpenses = combinePortfolioExpenses * 12;

          const portfolioCashflow = portoflioIncome - combinePortfolioExpenses;
          const yearlyPortfolioCashflow = portfolioCashflow * 12;

          console.log("currentRepayments: ", currentRepayments);
          console.log("portfolioExpenses: ", portfolioExpenses);
          console.log("managementFee: ", managementFee);
          console.log("combinePortfolioExpenses: ", combinePortfolioExpenses);

          const safetyBufferMonths = [1, 3, 6, 12, 24, 36, 48, 60];
          const savingMonths = [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
          ];
          let monthlySafetyBufferData = [];
          let monthsLeftSavingData = [];
          let liquidSaving = 0;

          const monthlySafetyBuffer = (
            +monthlyExpenses +
            +monthlyLifeStyleSpending +
            +combinePortfolioExpenses
          ).toFixed(2);

          console.log("monthlySafetyBuffer: ", monthlySafetyBuffer);

          safetyBufferMonths.map((month) => {
            monthlySafetyBufferData.push(+month * +monthlySafetyBuffer);
          });

          console.log("monthlySafetyBufferData: ", monthlySafetyBufferData);

          monthsLeftSavingData.push(totalLiquidSavings);
          liquidSaving = totalLiquidSavings;
          savingMonths.map((savMonth) => {
            liquidSaving = +totalLiquidSavings - savMonth * monthlySafetyBuffer;
            monthsLeftSavingData.push(liquidSaving);
          });

          console.log("monthsLeftSavingData: ", monthsLeftSavingData);

          const portfolioCashflowOptions: Options = {
            chart: {
              type: "column",
              height: "280px",
            },
            title: {
              text: "",
            },
            plotOptions: {
              column: {
                colorByPoint: true,
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  this.x +
                  "<br/>" +
                  "$ " +
                  Highcharts.numberFormat(this.y, 0, ".", ",")
                );
              },
            },
            colors: ["#5BC2AE", "#FF4E4E", "#795BD0"],
            series: [
              {
                // `type: column` is required for type-checking this options as a column series
                type: "column",
                data: [
                  yearlyCombinePortfolioExpenses,
                  yearlyPortoflioIncome,
                  yearlyPortfolioCashflow,
                ],
              },
            ],
            xAxis: {
              categories: ["Expense", "Income", "Cashflow"],
              labels: {
                useHTML: true,
                enabled: true,
              },
            },
            yAxis: {
              // min: 0,
              title: {
                text: "",
              },
            },
            legend: {
              enabled: false,
            },
          };

          // need to fix number formatting, remove decimal
          const savingsAndExpenesOptions: Options = {
            chart: {
              type: "pie",
              height: "280px",
            },
            title: {
              text: "",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: false,
                  formatter: function () {
                    return Highcharts.numberFormat(this.y, 0, ".", ",");
                  },
                },
                showInLegend: true,
              },
            },
            tooltip: {
              pointFormat: "$ {point.y}",
              // formatter: function () {
              //   return "{point.name}" + " $ " + Highcharts.numberFormat(this.y);
              // },
            },

            colors: ["#F3BE31", "#5BC2AE", "#142a37", "#795BD0"],
            series: [
              {
                name: "",
                type: "pie",
                data: [
                  {
                    name: "Savings",
                    y: monthlyAvgSaving,
                  },
                  {
                    name: "Expenses",
                    y: monthlyExpenses,
                  },
                  { name: "Lifestyle", y: monthlyLifeStyleSpending },
                  { name: "Portfolio", y: combinePortfolioExpenses },
                ],
              },
            ],

            legend: {
              enabled: true,
            },
          };

          const safetyBufferOptions: Options = {
            chart: {
              type: "column",
              height: "280px",
            },
            title: {
              text: "",
            },
            plotOptions: {
              column: {
                colorByPoint: true,
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  this.x +
                  " months: $ " +
                  Highcharts.numberFormat(this.y, 0, ".", ",")
                );
              },
            },
            colors: ["#142a37"],
            series: [
              {
                // `type: column` is required for type-checking this options as a column series
                type: "column",
                data: monthlySafetyBufferData,
                showInLegend: false,
                name: "$ Amount",
              },
            ],
            xAxis: {
              categories: ["1", "3", "6", "12", "18", "24", "36", "48", "60"],
              labels: {
                useHTML: true,
                enabled: true,
              },
            },
            yAxis: {
              // min: 0,
              title: {
                text: "",
              },
            },
            legend: {
              enabled: false,
            },
          };

          const monthsLeftSavingOptions: Options = {
            chart: {
              type: "column",
              height: "280px",
            },
            title: {
              text: "",
            },
            plotOptions: {
              column: {
                colorByPoint: true,
              },
            },
            tooltip: {
              formatter: function () {
                let prefix = "";
                if (this.x === 1) {
                  prefix = "Current month";
                } else {
                  prefix = this.x + " months";
                }
                return (
                  prefix +
                  " : $ " +
                  Highcharts.numberFormat(this.y, 0, ".", ",")
                );
              },
            },
            colors: ["#795BD0"],
            series: [
              {
                // `type: column` is required for type-checking this options as a column series
                type: "column",
                data: monthsLeftSavingData,
              },
            ],
            xAxis: {
              categories: savingMonths,
              labels: {
                useHTML: true,
                enabled: true,
              },
            },
            yAxis: {
              // min: 0,
              title: {
                text: "",
              },
            },
            legend: {
              enabled: false,
            },
          };
          setPortfolioCashflowOptions(portfolioCashflowOptions);
          setSavingsAndExpensesOptions(savingsAndExpenesOptions);
          setSafetyBufferOptions(safetyBufferOptions);
          setMonthsLeftSavingOptions(monthsLeftSavingOptions);
        } else {
          setPortfolioCashflowOptions({});
          setSavingsAndExpensesOptions({});
          setSafetyBufferOptions({});
          setMonthsLeftSavingOptions({});
        }
      })
      .catch((error) => {
        console.log(error);
        setPortfolioCashflowOptions({});
        setSavingsAndExpensesOptions({});
        setSafetyBufferOptions({});
        setMonthsLeftSavingOptions({});
        setIsDataLoaded(false);
      });
  };

  const getClientPropertyFinancialSummary = async () => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    GraphService.getPropertyFinancailsSummary(state.clientId)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const data = response.data.recordset[0];
          const newDebt = data.NewDebt;
          const currentDebt = data.CurrentDebt;
          const equity = data.Equity;
          const currentDebtPercent = +(
            (currentDebt / (currentDebt + equity)) *
            100
          ).toFixed(2);
          // console.log("currentDebtPercent: ", currentDebtPercent);
          const borrowableEquity = data.BorrowableEquity;

          const equityPercent = +(
            (equity / (currentDebt + equity)) *
            100
          ).toFixed(2);
          // console.log("equityPercent: ", equityPercent);
          // const portoflioIncome = data.TotalIncome;
          //const portoflioCashflow = data.TotalCashflow;
          // const portfolioExpenses = data.TotalExpenses;

          const borrowableEquityOptions: Options = {
            chart: {
              type: "column",
              height: "280px",
            },
            title: {
              text: "",
            },
            plotOptions: {
              column: {
                colorByPoint: true,
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  this.x +
                  "<br/>" +
                  "$ " +
                  Highcharts.numberFormat(this.y, 0, ".", ",")
                );
              },
            },
            colors: ["#5BC2AE", "#F3BE31", "#795BD0"],
            series: [
              {
                // `type: column` is required for type-checking this options as a column series
                type: "column",
                data: [newDebt, currentDebt, borrowableEquity],
                showInLegend: false,
                name: "$ Amount",
              },
            ],
            xAxis: {
              categories: [
                "New Debt @80%",
                "Current Debt",
                "Borrowable Equity",
              ],
              labels: {
                useHTML: true,
                enabled: true,
              },
            },
            yAxis: {
              // min: 0,
              title: {
                text: "",
              },
            },
            legend: {
              enabled: false,
            },
          };

          const loanToValueRatioOptions: Options = {
            chart: {
              type: "pie",
              height: "280px",
            },
            title: {
              text: "",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: false,
                  formatter: function () {
                    return Highcharts.numberFormat(this.y) + "%";
                  },
                },
                showInLegend: true,
              },
            },
            tooltip: {
              formatter: function () {
                return Highcharts.numberFormat(this.y) + "%";
              },
            },
            colors: ["#F3BE31", "#795BD0"],
            series: [
              {
                // `type: column` is required for type-checking this options as a column series
                name: "",
                type: "pie",
                data: [
                  {
                    name: "Debt",
                    y: currentDebtPercent,
                  },
                  {
                    name: "Equity",
                    y: equityPercent,
                  },
                ],
              },
            ],

            legend: {
              enabled: true,
            },
          };

          setBorrowableEquityOptions(borrowableEquityOptions);
          setLoanToValueRatioOptions(loanToValueRatioOptions);

          setIsDataLoaded(true);
        } else {
          setBorrowableEquityOptions({});
          setLoanToValueRatioOptions({});
          setPortfolioCashflowOptions({});
          setIsDataLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setBorrowableEquityOptions({});
        setLoanToValueRatioOptions({});
        setPortfolioCashflowOptions({});
        setIsDataLoaded(false);
      });
  };

  useEffect(() => {
    if (state.clientId) {
      getClientPropertyGrowth();
      getClientSavingsAndExpenses();
      getClientPropertyFinancialSummary();
    }
  }, [state.clientId]);

  return (
    <div className="content map-graphs-content">
      <ClientNotSelectedDialog />
      <Container>
        <h3>
          Portfolio Health Check for {`${state?.firstName} ${state?.lastName}`}
        </h3>

        <div className="map-graph-chart-section">
          <Row>
            <Col lg="4" md="6">
              <div className="card-chart-section">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Property Growth</CardTitle>
                    {/* <p className="card-category">All products including Taxes</p> */}
                  </CardHeader>
                  <CardBody>
                    <EnlargeView>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={growthOptions}
                      />
                    </EnlargeView>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="card-chart-section">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Rental Yield</CardTitle>
                    {/* <p className="card-category">All products including Taxes</p> */}
                  </CardHeader>
                  <CardBody>
                    <EnlargeView>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={rentalYieldOptions}
                      />
                    </EnlargeView>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="card-chart-section">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Property Cashflow</CardTitle>
                    {/* <p className="card-category">All products including Taxes</p> */}
                  </CardHeader>
                  <CardBody>
                    {" "}
                    <EnlargeView>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={cashflowOptions}
                      />
                    </EnlargeView>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </div>
            </Col>

            <Col lg="4" md="6">
              <div className="card-chart-section">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h3">Borrowable Equity</CardTitle>
                    {/* <p className="card-category">All products including Taxes</p> */}
                  </CardHeader>
                  <CardBody>
                    <EnlargeView>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={borrowableEquityOptions}
                      />
                    </EnlargeView>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="card-chart-section">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Loan to Value Ratio</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <EnlargeView>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={loanToValueRatioOptions}
                      />
                    </EnlargeView>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="card-chart-section">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Portfolio Cashflow</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {" "}
                    <EnlargeView>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={portfolioCashflowOptions}
                      />
                    </EnlargeView>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </div>
            </Col>

            <Col lg="4" md="6">
              <div className="card-chart-section">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h3">Buckets</CardTitle>
                    {/* <p className="card-category">All products including Taxes</p> */}
                  </CardHeader>
                  <CardBody>
                    <EnlargeView>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={savingsAndExpensesOptions}
                      />
                    </EnlargeView>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="card-chart-section">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Safety Buffer</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <EnlargeView>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={safetyBufferOptions}
                      />
                    </EnlargeView>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="card-chart-section">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Months Left Savings</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {" "}
                    <EnlargeView>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={monthsLeftSavingOptions}
                      />
                    </EnlargeView>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default RentalYield;
