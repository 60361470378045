import { Container, Typography } from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import Button from "@mui/material/Button";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../store";
import contentAndCourseService from "services/content-and-course.service";
import { styled } from "@mui/material/styles";
import WatchDialogContent from "./watch-dialog-content";
import moment from "moment";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ContentAndCourse = () => {
  const [contentAndCourse, setContentAndCourse] = useState([]);
  const [contentAndCourseModule, setContentAndCourseModule] = useState([]);
  const [contentAndCourseGroup, setContentAndCourseGroup] = useState([]);
  const [clientContentAndCourse, setClientContentAndCourse] = useState([]);
  const [watchDialogOpen, setWatchDialogOpen] = useState(false);
  const [state, setState] = useAtom(selectedClientAtom);

  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    getClientContentAndCourses(state.clientId);
    getContentAndCourseModules();
    getContentAndCourseGroups();
    getContentAndCourses();
  }, [state.clientId]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const getClientContentAndCourses = async (clientId) => {
    contentAndCourseService
      .getByClientId(clientId)
      .then((response) => {
        let clientContentAndCourses = response.data.recordset;
        console.log("clientContentAndCourses: ", clientContentAndCourses);
        setClientContentAndCourse(clientContentAndCourses);
      })
      .catch((error) => {
        console.log(error);
        setContentAndCourse([]);
      });
  };

  const getContentAndCourseModules = async () => {
    contentAndCourseService
      .getModules()
      .then((response) => {
        let contentAndCourseModules = response.data.recordset;
        console.log("contentAndCourseModules: ", contentAndCourseModules);
        setContentAndCourseModule(contentAndCourseModules);
      })
      .catch((error) => {
        console.log(error);
        setContentAndCourseModule([]);
      });
  };

  const getContentAndCourseGroups = async () => {
    contentAndCourseService
      .getGroups()
      .then((response) => {
        let contentAndCourseGroups = response.data.recordset;
        console.log("contentAndCourseGroups: ", contentAndCourseGroups);
        setContentAndCourseGroup(contentAndCourseGroups);
      })
      .catch((error) => {
        console.log(error);
        setContentAndCourseGroup([]);
      });
  };

  const getContentAndCourses = async () => {
    contentAndCourseService
      .getContentAndCourses()
      .then((response) => {
        let contentAndCourses = response.data.recordset;
        console.log("contentAndCourses: ", contentAndCourses);
        setContentAndCourse(contentAndCourses);
      })
      .catch((error) => {
        console.log(error);
        setContentAndCourse([]);
      });
  };

  const handleWatch = (course) => {
    setSelectedCourse(course);
    setWatchDialogOpen(true);
  };

  const handleWatchDialogClose = () => {
    setWatchDialogOpen(false);
  };

  const handleCheckBox = (e, contentAndCourseId) => {
    const data = {
      clientId: state.clientId,
      contentAndCourseId,
      dateStarted: moment().format("YYYY-MM-DD"),
      completed: e.target.checked ? 1 : 0,
      dateCompleted: moment().format("YYYY-MM-DD"),
      userId: state.clientId,
    };
    if (e.target.checked === true) {
      contentAndCourseService
        .addClientContentAndCourse(data)
        .then((response) => {
          let clientContentAndCourses = response.data.recordset;
          console.log("clientContentAndCourses: ", clientContentAndCourses);
          setClientContentAndCourse(clientContentAndCourses);
        })
        .catch((error) => {
          console.log(error);
          setContentAndCourse([]);
        });
    } else if (e.target.checked === false) {
      const deleteId = clientContentAndCourse.find(
        (c) => c.ContentAndCourseId === contentAndCourseId
      )?.ClientContentAndCourseId;
      contentAndCourseService
        .deleteClientContentAndCourse(deleteId, data)
        .then((response) => {
          let clientContentAndCourses = response.data.recordset;
          console.log("clientContentAndCourses: ", clientContentAndCourses);
          setClientContentAndCourse(clientContentAndCourses);
        })
        .catch((error) => {
          console.log(error);
          setContentAndCourse([]);
        });
    }
  };

  const calculateProgressValue = (group) =>
    (clientContentAndCourse.filter(
      (c) =>
        group.ContentAndCourseGroupId === c.ContentAndCourseGroupId &&
        c.Completed
    ).length /
      clientContentAndCourse.filter(
        (c) => group.ContentAndCourseGroupId === c.ContentAndCourseGroupId
      ).length) *
    100;

  return (
    <div className="content content-course-screen">
      <ClientNotSelectedDialog />

      <BootstrapDialog
        open={watchDialogOpen}
        onClose={handleWatchDialogClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="watch-dialog-title"
        aria-describedby="watch-dialog-description"
        className="watch-video-dialog"
      >
        <WatchDialogContent
          title={selectedCourse?.ContentAndCourseName}
          youtubeCode={selectedCourse?.ContentAndCourseLink}
          handleClose={handleWatchDialogClose}
        />
      </BootstrapDialog>
      <Container>
        <Row>
          <Col lg="10" md="12" className="offset-lg-1 offset-md-0">
            <h3>Content and Course</h3>
            {contentAndCourseModule.map((module) => (
              <div
                key={module.ContentAndCourseModuleNo}
                className="content-course-wrapper"
              >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Module {module.ContentAndCourseModuleNo}:{" "}
                      {module.ContentAndCourseModuleName}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {contentAndCourseGroup
                      .filter(
                        (group) =>
                          group.ContentAndCourseModuleId ===
                          module.ContentAndCourseModuleId
                      )
                      .map((group) => (
                        <Fragment key={group.ContentAndCourseGroupId}>
                          <div className="content-progressbar-status">
                            <Row>
                              <Col md="12">
                                <div className="title_bar">
                                  <Typography>
                                    {group.ContentAndCourseGroupName}
                                  </Typography>
                                  <BorderLinearProgress
                                    variant="determinate"
                                    value={calculateProgressValue(group)}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="content-course-checkbox-main">
                            <Row>
                              {contentAndCourse
                                .filter(
                                  (course) =>
                                    course.ContentAndCourseGroupId ===
                                    group.ContentAndCourseGroupId
                                )
                                .map((course, index) => (
                                  <Col key={course.ContentAndCourseId} md="6">
                                    <div className="content-course-checkboxs">
                                      <Row>
                                        <Col md="6" lg="7" className="col-6">
                                          <div>
                                            <Input
                                              checked={
                                                clientContentAndCourse.find(
                                                  (c) =>
                                                    course.ContentAndCourseId ===
                                                    c.ContentAndCourseId
                                                )?.Completed
                                                  ? true
                                                  : false
                                              }
                                              type="checkbox"
                                              onChange={(e) =>
                                                handleCheckBox(
                                                  e,
                                                  course.ContentAndCourseId
                                                )
                                              }
                                            />
                                            <Typography>
                                              {course.ContentAndCourseName}
                                            </Typography>
                                          </div>
                                        </Col>
                                        <Col md="6" lg="5" className="col-6">
                                          <Button
                                            sx={{ float: "right" }}
                                            variant="contained"
                                            color="grey"
                                            size="medium"
                                            onClick={() => handleWatch(course)}
                                          >
                                            Watch Now
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                ))}
                            </Row>
                          </div>
                        </Fragment>
                      ))}
                  </CardBody>
                </Card>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContentAndCourse;
