import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import MortgageStrategyService from "../../../services/mortgage-strategy.service";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import { Container } from "@mui/material";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

const MortgageStrategy = () => {
  const [requiredState, setrequiredState] = useState("");
  const loanRepaymentType = ["Principal & Interest", "Interest only", "Unsure"];
  const loanType = ["Fixed", "Variable", "Both", "Unsure"];
  const offset = ["Yes", "No"];

  let yellow = "#fbc658";
  const history = useHistory();
  const [selectedLoanRepaymentType, setSelectedRepaymentType] = useState("");
  const [selectedLoanType, setSelectedLoanType] = useState("");
  const [selectedOffset, setSelectedOffset] = useState(false);
  const [open, setOpen] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [mortgageStrategyId, setMortgageStrategyId] = useState(0);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);

  const getMortgageStrategyByClientId = (id) => {
    let mortgageStrategy = {};
    MortgageStrategyService.getByClientId(id)
      .then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          mortgageStrategy = {
            id: response.data.recordset[0]?.MortgageStrategyId,
            loanRepaymentType: response.data.recordset[0]?.LoanRepaymentType,
            loanType: response.data.recordset[0]?.LoanType,
            offsetAccount:
              response.data.recordset[0]?.OffsetAccount === "0" ? "Yes" : "No",
          };
          setMortgageStrategy(mortgageStrategy);
          setIsDataLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setMortgageStrategy({});
        setIsDataLoaded(false);
      });
  };

  useEffect(() => {
    getMortgageStrategyByClientId(clientState.clientId);
  }, [clientState.clientId]);

  const typeClick = () => {
    if (requiredState === "") {
      setrequiredState("has-danger");
    }
    if (emailState === "") {
      setemailState("has-danger");
    }
    if (numberState === "") {
      setnumberState("has-danger");
    }
    if (urlState === "") {
      seturlState("has-danger");
    }
    if (sourceState === "" || destinationState === "") {
      setsourceState("has-danger");
      setdestinationState("has-danger");
    }
  };

  const loanRepaymentBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const loanTypeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const offsetBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setLoanRepaymentType = (age) => {
    setSelectedRepaymentType(age);
  };

  const setLoanType = (pi) => {
    setSelectedLoanType(pi);
  };

  const setOffset = (nw) => {
    setSelectedOffset(nw);
  };

  const redirectToTracker = () => {
    const path = "/admin/progress-tracker/index";
    history.push(path);
  };

  const saveMortgageStrategy = () => {
    const mortgageStretegyData = {
      clientId: clientState.clientId,
      loanRepaymentType: selectedLoanRepaymentType,
      loanType: selectedLoanType,
      offsetAccount: selectedOffset,
      userId: userState.userId,
    };
    if (mortgageStrategyId === 0) {
      MortgageStrategyService.add(mortgageStretegyData)
        .then((response) => {
          console.log("response: ", response);
          if (response.data.recordset.length > 0) {
            setMortgageStrategyId(
              response.data.recordset[0].MortgageStrategyId
            );
            setOpen(true);
            setTimeout(() => {
              redirectToTracker();
            }, 3000);
          } else {
            setMortgageStrategyId(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      MortgageStrategyService.update(mortgageStretegyData, mortgageStrategyId)
        .then(() => {
          setOpen(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const setMortgageStrategy = (mortgageStrategy) => {
    setLoanRepaymentType(mortgageStrategy.loanRepaymentType);
    setLoanType(mortgageStrategy.loanType);
    setSelectedOffset(mortgageStrategy.offsetAccount);
    setMortgageStrategyId(mortgageStrategy.id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content mortgage-strategy-content">
      <Container>
        <ClientNotSelectedDialog />
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message="Mortgage Strategy is saved."
          action={action}
        ></Snackbar>
        <Row>
          <Col md="12">
            <Form className="form-horizontal" id="TypeValidation">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Mortgage Strategy for {clientState.firstName}{" "}
                    {clientState.lastName}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="10">
                      <Typography variant="body2" gutterBottom>
                        Do you want to have a Principal & Interest or Interest
                        only loan?
                      </Typography>
                      <FormGroup className={requiredState}>
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                          {loanRepaymentType.map((age) => (
                            <Grid
                              sx={{ flexDirection: "column", padding: "4px" }}
                              key={age}
                            >
                              <Box
                                onClick={() => setLoanRepaymentType(age)}
                                key={age}
                                sx={loanRepaymentBoxSx}
                                style={{
                                  backgroundColor:
                                    selectedLoanRepaymentType === age
                                      ? "#e61a4b"
                                      : "#114253",
                                }}
                              >
                                {age}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="8">
                      <Typography variant="body2" gutterBottom>
                        Do you wish to have a Fixed or Variable loan?
                      </Typography>
                      <FormGroup className={requiredState}>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {loanType.map((pi) => (
                            <Grid sx={{ padding: "4px" }} key={pi}>
                              <Box
                                onClick={() => setLoanType(pi)}
                                key={pi}
                                sx={loanTypeBoxSx}
                                style={{
                                  backgroundColor:
                                    selectedLoanType === pi
                                      ? "#e61a4b"
                                      : "#114253",
                                }}
                              >
                                {pi.toLocaleString(navigator.language, {
                                  minimumFractionDigits: 0,
                                })}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="8">
                      <Typography variant="body2" gutterBottom>
                        Do you wish to use an offset account?
                      </Typography>
                      <FormGroup className={requiredState}>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {offset.map((nw) => (
                            <Grid
                              className="small_box"
                              sx={{ flexDirection: "column", padding: "4px" }}
                              key={nw}
                            >
                              <Box
                                onClick={() => setOffset(nw)}
                                key={nw}
                                sx={offsetBoxSx}
                                style={{
                                  backgroundColor:
                                    selectedOffset === nw
                                      ? "#e61a4b"
                                      : "#114253",
                                }}
                              >
                                {nw.toLocaleString(navigator.language, {
                                  minimumFractionDigits: 0,
                                })}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-right">
                  <Button
                    variant="contained"
                    color="grey"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={() => saveMortgageStrategy()}
                  >
                    SAVE
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MortgageStrategy;
