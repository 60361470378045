import http from "./http-common";

class EmploymentService {
  // http://localhost:5000/api/v1/scoreboard/employment/add
  add(data) {
    const path = "/scoreboard/employment";
    console.log("data: ", data);
    return http.post(`${path}/add`, data);
  }

  // http://localhost:5000/api/v1/scoreboard/employment/get/client/:clientId
  getAllByClientId(clientId) {
    const path = `/scoreboard/employment/get/client/${clientId}`;
    // console.log('path: ', `${path}/get/${clientId}`);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/employment/get/member/:familyMemberId
  getByFamilyMemberId(familyMemberId) {
    const path = `/scoreboard/employment/get/member/${familyMemberId}`;
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/employment/get/:employmentId
  getByEmploymentId(employmentId) {
    const path = `/scoreboard/employment/get/${employmentId}`;
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/family-member/get/:id
  update(id, data) {
    const path = "/scoreboard/employment";
    return http.put(`${path}/update/${id}`, data);
  }

  // http://localhost:5000/api/v1/scoreboard/family-member/delete/:id
  delete(id) {
    const path = "/scoreboard/employment";
    return http.delete(`${path}/delete/${id}`);
  }
}

export default new EmploymentService();
