import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { selectedClientAtom } from "store";
import { useAtom } from "jotai";
import PropertyPortfolioService from "services/property-portfolio.service";

export default function PropertySummary() {
  const [clientState] = useAtom(selectedClientAtom);
  const [propertyDetails, setPropertyDetails] = useState([]);
  useEffect(() => {
    getProperties(clientState.clientId);
  }, [clientState.clientId]);

  const getProperties = async (clientId) => {
    return PropertyPortfolioService.getAllByClientId(clientId).then(
      (response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset.map((row) =>
            convertFirstLetterToLowerCase(row)
          );
          console.log("result : ", result);
          setPropertyDetails(result);
        }
      }
    );
  };

  const convertFirstLetterToLowerCase = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObj[newKey] = obj[key];
      }
    }
    return newObj;
  };

  const convertDateTimeToYear = (dateTime) => moment(dateTime).format("YYYY");
  return (
    <div className="content">
      <h2>Property Summary</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Year</TableCell>
              <TableCell align="right">Property</TableCell>
              <TableCell align="right">SMSF</TableCell>
              <TableCell align="right">Purchase Price</TableCell>
              <TableCell align="right">Compound Growth</TableCell>
              <TableCell align="right">Rental Yield</TableCell>
              <TableCell align="right">Rental Increase</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {propertyDetails.map((row) => (
              <TableRow
                key={row.clientPropertyId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.yearBuild ? convertDateTimeToYear(row.yearBuild) : ""}
                </TableCell>
                <TableCell align="right">{row.address}</TableCell>
                <TableCell align="right">No</TableCell>
                <TableCell align="right">{row.purchasePrice}</TableCell>
                <TableCell align="right">{row.growthRate}</TableCell>
                <TableCell align="right">{row.rentalYield}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
