import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PropertyPlanService from "../../../services/property-plan.service";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Moment from "moment";

import GeneralDetails from "./wizard-steps/general-details";
import CarDetails from "./wizard-steps/car-details";
import HolidayDetails from "./wizard-steps/holiday-details";
import EducationDetails from "./wizard-steps/education-details";
import SecondEducationDetails from "./wizard-steps/second-education-details";
import OtherExpenseDetails from "./wizard-steps/other-expense-details";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import { Container } from "@mui/material";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

import AssumptionService from "../../../services/assumption.service";
import Assumptions from "ui/profile-detail/assumption/assumptions";

const INITIAL_DATA = {
  id: 0,
  placeOfResidence: "",
  purchaseYear: "",
  moneySpending: 200000,
  nextCarPurchaseYear: "",
  isDefaultNextCarPurchaseYear: "1",
  newCarFrequency: 0,
  isDefaultNextCarPurchaseFrequency: "1",
  nextCarCashContribution: 0,
  isDefaultNextCarCashContribution: "1",
  nextHolidayYear: 0,
  isDefaultNextHolidayYear: "1",
  holidayFrequency: 0,
  isDefaultNextHolidayFrequency: "1",
  nextHolidaySpending: 0,
  isDefaultNextHolidaySpending: "1",
  educationFeeFrequency: 0,
  isDefaultEducationFeeFrequency: "1",
  nextEducationExpenseYear: "0",
  isDefaultNextEducationExpenseYear: "1",
  yearEducationExpenseStops: "0",
  isDefaultYearEducationExpenseStops: "1",
  educationCycleSpending: 0,
  isDefaultEducationCycleSpending: "1",
  secondEducationFeeFrequency: 0,
  isDefaultSecondEducationFeeFrequency: "1",
  secondEducationExpenseYear: "0",
  isDefaultSecondEducationExpenseYear: "1",
  yearSecondEducationExpenseStops: "0",
  isDefaultYearSecondEducationExpenseStops: "1",
  secondEducationCycleSpending: 0,
  isDefaultSecondEducationCycleSpending: "1",
  otherExpense: "",
  otherExpenseFrequency: 0,
  isDefaultOtherExpenseFrequency: "1",
  otherExpenseYear: "",
  isDefaultOtherExpenseYear: "1",
  yearOtherExpenseStops: "",
  isDefaultYearOtherExpenseStops: "1",
  otherExpenseSpending: 0,
  isDefaultOtherExpenseSpending: "1",
  clientId: 0,
  userId: 0,
};

const PropertyPlanAdd = () => {
  const [formData, setFormData] = useState(INITIAL_DATA);
  const [propertyPlanId, setPropertyPlanId] = useState(0);
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(0);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  let hasDataLoaded = 0;
  // let isUpdate = 0;

  const [defaultAssumptions, setDefaultAssumptions] = useState({});

  const getDefaultAssumptions = async () => {
    let defaultAssumptions = {};
    AssumptionService.getDefaultAssumption()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          let data = response.data.recordset[0];
          defaultAssumptions = { ...data };
          setDefaultAssumptions(defaultAssumptions);
          setIsDataLoaded(true);
          console.log("Assumption-", defaultAssumptions);
        }
      })
      .catch((error) => {
        console.log(error);
        setDefaultAssumptions(defaultAssumptions);
      });
  };

  const stepLabels = [
    "General Information",
    "Next Vehicle details",
    "Next holiday details",
    "Next Education",
    "Second Education",
    "Other expense details",
  ];

  const steps = [
    isDataLoaded && (
      <GeneralDetails formData={formData} setFormData={setFormData} />
    ),

    <CarDetails
      formData={formData}
      setFormData={setFormData}
      defaultAssumptions={defaultAssumptions}
    />,
    <HolidayDetails
      formData={formData}
      setFormData={setFormData}
      defaultAssumptions={defaultAssumptions}
    />,
    <EducationDetails
      formData={formData}
      setFormData={setFormData}
      defaultAssumptions={defaultAssumptions}
    />,
    <SecondEducationDetails
      formData={formData}
      setFormData={setFormData}
      defaultAssumptions={defaultAssumptions}
    />,
    <OtherExpenseDetails
      formData={formData}
      setFormData={setFormData}
      defaultAssumptions={defaultAssumptions}
    />,
  ];

  useEffect(() => {
    if (!isDataLoaded) {
      getDefaultAssumptions();
    }
  }, [isDataLoaded]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const history = useHistory();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const goToStep = (step) => {
    setActiveStep(step);
  };

  const redirectToList = () => {
    const path = "/admin/scoreboard/property-plan/add";
    history.push(path);
  };

  const redirectToTracker = () => {
    const path = "/admin/progress-tracker/index";
    history.push(path);
  };

  const getClientAssetSelection = async (id) => {
    console.log("Form Data: ", formData);
    // const familyMemberId = getRisk();
    PropertyPlanService.getAllByClientId(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          // isUpdate = 1;
          const propertyPlan = response.data.recordset[0];
          console.log("property plan: ", propertyPlan);
          hasDataLoaded = propertyPlan.PropertyPlanId > 0;
          const propertyPlanData = {
            id: propertyPlan.PropertyPlanId,
            placeOfResidence: propertyPlan.PlaceOfResidence,
            purchaseYear: propertyPlan.PurchaseYear + "",
            moneySpending: propertyPlan.MoneySpending,
            nextCarPurchaseYear: propertyPlan.NextCarPurchaseYear + "",
            isDefaultNextCarPurchaseYear:
              propertyPlan.IsDefaultNextCarPurchaseYear ? "1" : "0",
            newCarFrequency: `${propertyPlan.NewCarFrequency}`,
            isDefaultNextCarPurchaseFrequency:
              propertyPlan.IsDefaultNextCarPurchaseFrequency ? "1" : "0",
            nextCarCashContribution: propertyPlan.NextCarCashContribution,
            isDefaultNextCarCashContribution:
              propertyPlan.IsDefaultNextCarCashContribution ? "1" : "0",

            nextHolidayYear: propertyPlan.NextHolidayYear + "",
            isDefaultNextHolidayYear: propertyPlan.IsDefaultNextHolidayYear
              ? "1"
              : "0",

            holidayFrequency: `${propertyPlan.HolidayFrequency}`,
            isDefaultNextHolidayFrequency:
              propertyPlan.IsDefaultNextHolidayFrequency ? "1" : "0",

            nextHolidaySpending: propertyPlan.NextHolidaySpending,
            isDefaultNextHolidaySpending:
              propertyPlan.IsDefaultNextHolidaySpending ? "1" : "0",

            educationFeeFrequency: `${propertyPlan.EducationFeeFrequency}`,
            isDefaultEducationFeeFrequency:
              propertyPlan.IsDefaultEducationFeeFrequency ? "1" : "0",

            nextEducationExpenseYear:
              propertyPlan.NextEducationExpenseYear + "",
            isDefaultNextEducationExpenseYear:
              propertyPlan.IsDefaultNextEducationExpenseYear ? "1" : "0",

            yearEducationExpenseStops:
              propertyPlan.YearEducationExpenseStops + "",
            isDefaultYearEducationExpenseStops:
              propertyPlan.IsDefaultYearEducationExpenseStops ? "1" : "0",

            educationCycleSpending: propertyPlan.EducationCycleSpending,
            isDefaultEducationCycleSpending:
              propertyPlan.IsDefaultEducationCycleSpending ? "1" : "0",

            secondEducationFeeFrequency: `${propertyPlan.SecondEducationFeeFrequency}`,
            isDefaultSecondEducationFeeFrequency:
              propertyPlan.IsDefaultSecondEducationFeeFrequency ? "1" : "0",

            secondEducationExpenseYear:
              propertyPlan.SecondEducationExpenseYear + "",
            isDefaultSecondEducationExpenseYear:
              propertyPlan.IsDefaultSecondEducationExpenseYear ? "1" : "0",

            yearSecondEducationExpenseStops:
              propertyPlan.YearSecondEducationExpenseStops + "",
            isDefaultYearSecondEducationExpenseStops:
              propertyPlan.IsDefaultYearSecondEducationExpenseStops ? "1" : "0",

            secondEducationCycleSpending:
              propertyPlan.SecondEducationCycleSpending,
            isDefaultSecondEducationCycleSpending:
              propertyPlan.IsDefaultSecondEducationCycleSpending ? "1" : "0",

            otherExpense: propertyPlan.OtherExpense,

            otherExpenseFrequency: `${propertyPlan.OtherExpenseFrequency}`,
            isDefaultOtherExpenseFrequency:
              propertyPlan.IsDefaultOtherExpenseFrequency ? "1" : "0",

            otherExpenseYear: propertyPlan.OtherExpenseYear + "",
            isDefaultOtherExpenseYear: propertyPlan.IsDefaultOtherExpenseYear
              ? "1"
              : "0",

            yearOtherExpenseStops: propertyPlan.YearOtherExpenseStops + "",
            isDefaultYearOtherExpenseStops:
              propertyPlan.IsDefaultYearOtherExpenseStops ? "1" : "0",

            otherExpenseSpending: propertyPlan.OtherExpenseSpending,
            isDefaultOtherExpenseSpending:
              propertyPlan.IsDefaultOtherExpenseSpending ? "1" : "0",
          };
          console.log("Property Plan Data: ", propertyPlanData);
          setFormData(propertyPlanData);
          setIsUpdate(1);
        } else {
          console.log("Form Data: ", formData);
          setFormData({ ...INITIAL_DATA });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Form Data: ", formData);
        setFormData({ ...INITIAL_DATA });
      });
  };

  useEffect(() => {
    getClientAssetSelection(clientState.clientId);
  }, [clientState.clientId]);

  useEffect(() => {
    if (activeStep != 0) {
      document
        ?.getElementById(`step${activeStep}`)
        ?.scrollIntoView({ behavior: "smooth" });
    }

    return () => {};
  }, [activeStep]);

  const updatePropertyPlanData = () => {
    return {
      placeOfResidence: formData.placeOfResidence,

      purchaseYear:
        !formData.purchaseYear || formData.purchaseYear === ""
          ? null
          : formData.purchaseYear,

      moneySpending:
        formData.moneySpending === undefined ? 200000 : formData.moneySpending,

      nextCarPurchaseYear:
        !formData.nextCarPurchaseYear || formData.nextCarPurchaseYear === ""
          ? `${
              new Date().getFullYear() + defaultAssumptions.NextCarPurchaseYear
            }`
          : formData.nextCarPurchaseYear,
      isDefaultNextCarPurchaseYear: formData.isDefaultNextCarPurchaseYear,

      newCarFrequency:
        formData.isDefaultNextCarPurchaseFrequency === "1"
          ? defaultAssumptions.NextCarPurchaseFrequency
          : formData.newCarFrequency,

      isDefaultNextCarPurchaseFrequency:
        formData.isDefaultNextCarPurchaseFrequency,

      nextCarCashContribution:
        formData.isDefaultNextCarCashContribution === "1"
          ? defaultAssumptions.NextCarCashContribution
          : formData.nextCarCashContribution,

      isDefaultNextCarCashContribution:
        formData.isDefaultNextCarCashContribution,

      nextHolidayYear:
        formData.nextHolidayYear === "" || formData.nextHolidayYear === 0
          ? `${new Date().getFullYear() + defaultAssumptions.NextHolidayYear}`
          : formData.nextHolidayYear,

      isDefaultNextHolidayYear: formData.isDefaultNextHolidayYear,

      holidayFrequency:
        formData.isDefaultNextHolidayFrequency === "1"
          ? defaultAssumptions.NextHolidayFrequency
          : formData.holidayFrequency,

      isDefaultNextHolidayFrequency: formData.isDefaultNextHolidayFrequency,

      nextHolidaySpending:
        formData.isDefaultNextHolidaySpending === "1"
          ? defaultAssumptions.NextHolidaySpending
          : formData.nextHolidaySpending,

      isDefaultNextHolidaySpending: formData.isDefaultNextHolidaySpending,

      educationFeeFrequency:
        formData.isDefaultEducationFeeFrequency === "1"
          ? defaultAssumptions.EducationFeeFrequency
          : formData.educationFeeFrequency,

      isDefaultEducationFeeFrequency: formData.isDefaultEducationFeeFrequency,

      nextEducationExpenseYear:
        formData.isDefaultNextEducationExpenseYear === "1"
          ? `${
              new Date().getFullYear() +
              defaultAssumptions.NextEducationExpenseYear
            }`
          : formData.nextEducationExpenseYear,

      isDefaultNextEducationExpenseYear:
        formData.isDefaultNextEducationExpenseYear,

      yearEducationExpenseStops:
        formData.isDefaultYearEducationExpenseStops === "1"
          ? formData.isDefaultNextEducationExpenseYear === "1"
            ? `${
                new Date().getFullYear() +
                defaultAssumptions.YearEducationExpenseStops
              }`
            : formData.nextEducationExpenseYear +
              defaultAssumptions.YearEducationExpenseStops -
              1
          : formData.yearEducationExpenseStops,

      isDefaultYearEducationExpenseStops:
        formData.isDefaultYearEducationExpenseStops,

      educationCycleSpending:
        formData.isDefaultEducationCycleSpending === "1"
          ? defaultAssumptions.EducationCycleSpending
          : formData.educationCycleSpending,

      isDefaultEducationCycleSpending: formData.isDefaultEducationCycleSpending,

      secondEducationFeeFrequency:
        formData.isDefaultSecondEducationFeeFrequency === "1"
          ? defaultAssumptions.SecondEducationFeeFrequency
          : formData.secondEducationFeeFrequency,

      isDefaultSecondEducationFeeFrequency:
        formData.isDefaultSecondEducationFeeFrequency,

      secondEducationExpenseYear:
        formData.isDefaultSecondEducationExpenseYear === "1"
          ? `${
              new Date().getFullYear() +
              defaultAssumptions.SecondEducationExpenseYear
            }`
          : formData.secondEducationExpenseYear,

      isDefaultSecondEducationExpenseYear:
        formData.isDefaultSecondEducationExpenseYear,

      // yearSecondEducationExpenseStops: formData.yearSecondEducationExpenseStops,

      yearSecondEducationExpenseStops:
        formData.isDefaultYearSecondEducationExpenseStops === "1"
          ? formData.isDefaultSecondEducationExpenseYear === "1"
            ? `${
                new Date().getFullYear() +
                defaultAssumptions.YearSecondEducationExpenseStops
              }`
            : formData.secondEducationExpenseYear +
              defaultAssumptions.YearSecondEducationExpenseStops -
              1
          : formData.yearSecondEducationExpenseStops,

      isDefaultYearSecondEducationExpenseStops:
        formData.isDefaultYearSecondEducationExpenseStops,

      secondEducationCycleSpending:
        formData.isDefaultSecondEducationCycleSpending === "1"
          ? defaultAssumptions.SecondEducationCycleSpending
          : formData.secondEducationCycleSpending,

      isDefaultSecondEducationCycleSpending:
        formData.isDefaultSecondEducationCycleSpending,

      otherExpense: formData.otherExpense,

      otherExpenseFrequency:
        formData.isDefaultOtherExpenseFrequency === "1"
          ? defaultAssumptions.OtherExpenseFrequency
          : formData.otherExpenseFrequency,
      isDefaultOtherExpenseFrequency: formData.isDefaultOtherExpenseFrequency,

      otherExpenseYear:
        formData.isDefaultOtherExpenseYear === "1"
          ? `${new Date().getFullYear() + defaultAssumptions.OtherExpenseYear}`
          : formData.otherExpenseYear,
      isDefaultOtherExpenseYear: formData.isDefaultOtherExpenseYear,

      yearOtherExpenseStops:
        formData.isDefaultYearOtherExpenseStops === "1"
          ? formData.isDefaultOtherExpenseYear === "1"
            ? `${
                new Date().getFullYear() +
                defaultAssumptions.YearOtherExpenseStops
              }`
            : formData.otherExpenseYear +
              defaultAssumptions.YearOtherExpenseStops -
              1
          : formData.yearOtherExpenseStops,
      isDefaultYearOtherExpenseStops: formData.isDefaultYearOtherExpenseStops,

      otherExpenseSpending:
        formData.isDefaultOtherExpenseSpending === "1"
          ? defaultAssumptions.OtherExpenseSpending
          : formData.otherExpenseSpending,
      isDefaultOtherExpenseSpending: formData.isDefaultOtherExpenseSpending,

      clientId: clientState.clientId,
      userId: userState.userId,
    };
  };
  const handleNext = () => {
    const propertyPlanId = formData.id;
    console.log("form data: ", formData);
    console.log("property plan id: ", formData.id);
    console.log("Assumption : ", defaultAssumptions);
    const propertyPlanData = updatePropertyPlanData();

    console.log("propertyPlan: ", propertyPlanData);

    if (activeStep === stepLabels.length - 1) {
      if (propertyPlanId === undefined || propertyPlanId === 0) {
        console.log("Property plan Id: ", propertyPlanId);
        PropertyPlanService.add(propertyPlanData)
          .then((response) => {
            console.log("response: ", response);
            if (response.data.recordset.length > 0) {
              setPropertyPlanId(response.data.recordset[0].PropertyPlanId);
              setOpen(true);
              setTimeout(() => {
                redirectToTracker();
              }, 3000);
            } else {
              setPropertyPlanId(0);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        PropertyPlanService.update(propertyPlanId, propertyPlanData)
          .then((response) => {
            setOpen(true);
            setTimeout(() => {
              redirectToTracker();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleFinish = () => {
    const propertyPlanId = formData.id;
    const propertyPlanData = updatePropertyPlanData();
    PropertyPlanService.update(propertyPlanId, propertyPlanData)
      .then((response) => {
        setOpen(true);
        setTimeout(() => {
          redirectToList();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content property-plans-add">
      <Container>
        <ClientNotSelectedDialog />
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message="Property plan is saved."
          action={action}
        ></Snackbar>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {stepLabels.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step
                  id={`step${index}`}
                  key={label}
                  {...stepProps}
                  onClick={() => goToStep(index)}
                  style={{ cursor: "pointer" }}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>{steps[activeStep]}</div>

          {activeStep === stepLabels.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  variant="contained"
                  color="grey"
                  size="medium"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              <div className="back-next-buttons">
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleNext}>
                    {activeStep === stepLabels.length - 1 ? "Finish" : "Next"}
                  </Button>
                  <Button
                    className={`${
                      isUpdate && activeStep !== stepLabels.length - 1
                        ? "hidden-finish-btn"
                        : "show-finish-btn"
                    }`}
                    onClick={handleFinish}
                    sx={{
                      display:
                        isUpdate && activeStep !== stepLabels.length - 1
                          ? "block"
                          : "none",
                    }}
                  >
                    Finish
                  </Button>
                </Box>
              </div>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default PropertyPlanAdd;
