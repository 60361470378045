/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { React, useState, useEffect } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
} from "reactstrap";
import ClientService from "../../services/client.service";
// import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { useAtom } from "jotai";
import { selectedClientAtom, sidebarAtom, userAtom } from "../../store";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";

const AdminNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [color, setColor] = useState("navbar-transparent");
  const [client, setClient] = useState("");
  const [clientList, setClientList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [items, setItems] = useState([]);
  const [inputValue, setValue] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [clientstate, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [sidebarState, setSidebarState] = useAtom(sidebarAtom);
  console.log(sidebarState, "sidebarState");
  const [loginUser, setLoginUser] = useState({});

  const history = useHistory();

  // handle input change event
  const handleInputChange = (value) => {
    setValue(value);
  };

  // handle selection
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const fetchClients = async () => {
    //const user = JSON.parse(window.localStorage.getItem('User'));
    // console.log('user: ', user);
    setIsAdmin(userState?.isAdmin);
    setIsPartner(userState?.isPartner);
    const arr = [];
    if (userState?.isAdmin) {
      console.log("ADMIN");
      ClientService.getAll()
        .then((response) => {
          let result = response.data.recordset;
          result.map((client) => {
            return arr.push({
              value: client.ClientId,
              label: `${client.ClientFirstName} ${client.ClientLastName}`,
            });
          });
          setClientList(arr);
          setIsDataLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setIsDataLoaded(false);
          setClientList([]);
        });
    }
    if (userState?.isPartner) {
      console.log("PARTNER");
      ClientService.getAllByPartnerId(userState?.clientId)
        .then((response) => {
          let result = response.data.recordset;
          console.log("result: ", result);
          result.map((client) => {
            return arr.push({
              value: client.ClientId,
              label: `${client.ClientFirstName} ${client.ClientLastName}`,
            });
          });
          console.log("arr: ", arr);
          setClientList(arr);
          setIsDataLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setClientList([]);
          setIsDataLoaded(false);
        });
    }
  };

  const location = useLocation();
  useEffect(() => {
    window.addEventListener("resize", updateColor);
    const jwtToken = window.sessionStorage.getItem("jwt-token");
    const loginUser = JSON.parse(window.localStorage.getItem("login-user"));
    // const selectedClient = JSON.parse(
    //   window.localStorage.getItem("selected-client")
    // );
    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
    } else {
      setLoginUser(loginUser);
      // if (userState.userId === 0) {
      //   setUserState(loginUser);
      // }
      setIsAdmin(loginUser?.isAdmin === true);
      setIsPartner(loginUser?.userType === "Partner");
      fetchClients();
    }
  }, [userState.userId]);

  useEffect(() => {
    if (
      window.outerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }, [location]);
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setSidebarState({ sidebarOpen: !sidebarState?.sidebarOpen });
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
    setCollapseOpen(!collapseOpen);
  };

  const setSelectedClient = (selectedClient) => {
    console.log("selected client: ", selectedClient);
    setClientState({
      clientId: selectedClient.value,
      firstName: selectedClient.label,
    });
    localStorage.setItem(
      "selected-client",
      JSON.stringify({
        clientId: selectedClient.value,
        firstName: selectedClient.label,
      })
    );

    // window.location.reload(false);
    // window.localStorage.setItem('SelectedClient', JSON.stringify({
    //   clientId: selectedClient.value, name: selectedClient.label
    // }));
  };

  return (
    <>
      <Container>
        <Navbar className={classnames("navbar", color)} expand="lg">
          <div className="top-flex">
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                <Button
                  className="btn-icon btn-round minimize_btn"
                  color="default"
                  id="minimizeSidebar"
                  onClick={props.handleMiniClick}
                >
                  <i className="nc-icon nc-simple-remove text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-align-left-2 text-center visible-on-sidebar-regular" />
                </Button>
              </div>
              <div
                className={classnames("navbar-toggle", {
                  toggled: sidebarState?.sidebarOpen,
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                <span className="d-none d-md-block active-list-bar">
                  The Property Planning App
                </span>
              </NavbarBrand>
            </div>
            {/* Show client/partner in a drop down list if logged in user is admin  */}
            {/* Show client in a drop down list if logged in user is partner  */}
            <button
              aria-controls="navigation-index"
              aria-expanded={collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler list-bar-icon"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>

            <Collapse
              className="justify-content-end"
              navbar
              isOpen={collapseOpen}
            >
              <Form>
                <InputGroup className="no-border">
                  {/* <Input defaultValue="" placeholder="Search..." type="text" /> */}
                  {/* <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="nc-icon nc-zoom-split" />
                  </InputGroupText>
                </InputGroupAddon> */}
                </InputGroup>
              </Form>
              <Nav navbar>
                {/* {isDataLoaded && (userState.isAdmin || userState.isPartner) ? (
                <div style={{ width: "100%" }}>
                  <Select
                    name=""
                    className="react-select"
                    placeholder="Select Client"
                    classNamePrefix="react-select"
                    options={clientList}
                    onChange={(client) => setSelectedClient(client)}
                  />
                </div>
              ) : (
                <div></div>
              )} */}
                {/* <NavItem> */}
                {/* <NavLink
                  className="btn-magnify"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="nc-icon nc-layout-11" />
                  <p>
                    <span className="d-lg-none d-md-block">Stats</span>
                  </p>
                </NavLink> */}
                {/* </NavItem> */}
                <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <i className="nc-icon nc-bell-55" />
                    {/* <p>
                    <span className="d-lg-none d-md-block">Some Actions</span>
                  </p> */}
                  </DropdownToggle>
                  {/* <DropdownMenu
                  persist
                  aria-labelledby="navbarDropdownMenuLink"
                  right
                >
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Action
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Another action
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Something else here
                  </DropdownItem>
                </DropdownMenu> */}
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink
                    className="btn-rotate"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-settings-gear-65" />
                    <p>
                      <span className="d-lg-none d-md-block">Account</span>
                    </p>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </Container>
    </>
  );
};

export default AdminNavbar;
