import { FamilySaving, FamilyIncome } from "./index";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MoneyManagementService from "../../../services/money-management.service";
import AssumptionService from "../../../services/assumption.service";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import { currencyToNumber } from "utils/currency";
import { formatCurrency } from "utils/currency";
import { Container } from "@mui/material";
import Assumptions from "ui/profile-detail/assumption/assumptions";

const INITIAL_DATA = {
  id: 0,
  familyMember: {},
  familyMemberId: 0,
  avgMonthlySaving: formatCurrency(0),
  monthlyExpenses: formatCurrency(0),
  monthlyLifeStyleSpending: formatCurrency(0),
  needHelpWithBudget: 0,
  grossAnnualIncome: formatCurrency(0),
  totalLiquidSaving: formatCurrency(0),
  totalInSuper: 0,
  allocateIncomeToSuper: 0,
  incomeTaxRate: 0,
  clientId: 0,
  userId: 0,
  isDefaultMonthlyAvgSaving: true,
  isDefaultMonthlyExpenses: true,
  isDefaultMonthlyLifeStyleSpending: true,
  isDefaultGrossAnnualIncome: true,
  isDefaultTotalLiquidSavings: true,
  isDefaultIncomeTaxRate: true,
  isDefaultTotalLiquidSavings: true,
  isDefaultAllocateIncomeToSuper: true,
};
//isDefaultTotalInSuper: true,

const MoneyManagementAdd = () => {
  const [formData, setFormData] = useState(INITIAL_DATA);
  const [open, setOpen] = useState(false);
  // const [clientId, setClientId] = useState(0);
  // const [userId, setUserId] = useState(0);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [validationErrorsSavingDetails, setValidationErrorsSavingDetails] =
    useState({});

  // Saving Detail Form Validation
  function validateFormFamilyDetails() {
    const errorsSavingDetails = {};
    if (!formData.familyMember.value)
      errorsSavingDetails.familyMember = "* Select Family Member"; //"Family member is required";
    setValidationErrorsSavingDetails(errorsSavingDetails);
    return Object.keys(errorsSavingDetails).length === 0;
  }

  // const [isDefaultMonthlyAvgSaving, setIsDefaultMonthlyAvgSaving] =
  //   useState(true);
  // const [isDefaultMonthlyExpenses, setIsDefaultMonthlyExpenses] =
  //   useState(true);
  // const [
  //   isDefaultMonthlyLifeStyleSpending,
  //   setIsDefaultMonthlyLifeStyleSpending,
  // ] = useState(true);
  // const [isDefaultGrossAnnualIncome, setIsDefaultGrossAnnualIncome] =
  //   useState(true);
  // const [isDefaultIncomeTaxRate, setIsDefaultIncomeTaxRate] = useState(true);
  // const [isDefaultTotalLiquidSavings, setIsDefaultTotalLiquidSavings] =
  //   useState(true);
  // const [isDefaultTotalInSuper, setIsDefaultTotalInSuper] = useState(true);

  const [defaultAssumptions, setDefaultAssumptions] = useState({});

  const getDefaultAssumptions = async () => {
    let defaultAssumptions = {};
    AssumptionService.getDefaultAssumption()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          let data = response.data.recordset[0];
          defaultAssumptions = { ...data };
          setDefaultAssumptions(defaultAssumptions);
          setIsDataLoaded(true);
          console.log("Assumption-", defaultAssumptions);
        }
      })
      .catch((error) => {
        console.log(error);
        setDefaultAssumptions(defaultAssumptions);
      });
  };

  const history = useHistory();

  const getByFamilyMemberId = () => {
    return window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/")[4];
  };

  const isUpdate =
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/").length === 5
      ? true
      : false;

  const stepLabels = ["Saving Information", "Income Information"];
  const steps = [
    <FamilySaving
      formData={formData}
      setFormData={setFormData}
      defaultAssumptions={defaultAssumptions}
      validationErrorsSavingDetails={validationErrorsSavingDetails}
    />,
    <FamilyIncome
      formData={formData}
      setFormData={setFormData}
      defaultAssumptions={defaultAssumptions}
    />,
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const goToStep = (step) => {
    setActiveStep(step);
  };

  const redirectToList = () => {
    const path = "/admin/scoreboard/money-management/list";
    history.push(path);
  };

  const redirectToTracker = () => {
    const path = "/admin/progress-tracker/index";
    history.push(path);
  };

  const getMoneyManagement = () => {
    const familyMemberId = getByFamilyMemberId();
    MoneyManagementService.getByFamilyMemberId(familyMemberId)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const moneyManagement = response.data.recordset[0];
          console.log("moneyManagement: ", moneyManagement);
          const moneyManagementData = {
            id: moneyManagement.MoneyManagementId,
            familyMember: `${moneyManagement.FirstName} ${moneyManagement.LastName}`,
            familyMemberId: moneyManagement.FamilyMemberId,
            avgMonthlySaving: formatCurrency(moneyManagement.MonthlyAvgSaving),
            monthlyExpenses: formatCurrency(moneyManagement.MonthlyExpenses),
            monthlyLifeStyleSpending: formatCurrency(
              moneyManagement.MonthlyLifeStyleSpending
            ),
            needHelpWithBudget: moneyManagement.NeedHelpWithBudget,
            grossAnnualIncome: formatCurrency(
              moneyManagement.GrossAnnualIncome
            ),
            totalLiquidSaving: formatCurrency(
              moneyManagement.TotalLiquidSavings
            ),
            totalInSuper: moneyManagement.TotalInSuper,
            allocateIncomeToSuper: moneyManagement.AllocateIncomeToSuper,
            incomeTaxRate: moneyManagement.IncomeTaxRate,
            isDefaultMonthlyAvgSaving:
              moneyManagement.IsDefaultMonthlyAvgSaving,
            isDefaultMonthlyExpenses: moneyManagement.IsDefaultMonthlyExpenses,
            isDefaultMonthlyLifeStyleSpending:
              moneyManagement.IsDefaultMonthlyLifeStyleSpending,
            isDefaultGrossAnnualIncome:
              moneyManagement.IsDefaultGrossAnnualIncome,
            isDefaultIncomeTaxRate: moneyManagement.IsDefaultIncomeTaxRate,
            isDefaultTotalInSuper: moneyManagement.IsDefaultTotalInSuper,
            isDefaultTotalLiquidSavings:
              moneyManagement.IsDefaultTotalLiquidSavings,
            isDefaultAllocateIncomeToSuper:
              moneyManagement.IsDefaultAllocateIncomeToSuper,
          };
          setFormData(moneyManagementData);
        } else {
          setFormData([INITIAL_DATA]);
        }
      })
      .catch((error) => {
        console.log(error);
        setFormData([INITIAL_DATA]);
      });
  };

  useEffect(() => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    // console.log('selectedClient: ', selectedClient);
    // setClientId(selectedClient.clientId);
    if (isUpdate) {
      getMoneyManagement();
    }
  }, [clientState.clientId]);

  useEffect(() => {
    if (!isDataLoaded) {
      getDefaultAssumptions();
    }
  }, [isDataLoaded]);

  useEffect(() => {
    if (activeStep != 0) {
      document
        ?.getElementById(`step${activeStep}`)
        ?.scrollIntoView({ behavior: "smooth" });
    }

    return () => {};
  }, [activeStep]);

  const updateMoneyManagementData = () => {
    return {
      familyMemberId: isUpdate
        ? formData.familyMemberId
        : formData.familyMember.value,

      avgMonthlySaving: formData.isDefaultMonthlyAvgSaving
        ? defaultAssumptions.DefaultMonthlyAvgSavings
        : currencyToNumber(formData.avgMonthlySaving),

      monthlyExpenses: formData.isDefaultMonthlyExpenses
        ? defaultAssumptions.DefaultMonthlyExpenses
        : currencyToNumber(formData.monthlyExpenses),

      monthlyLifeStyleSpending: formData.isDefaultMonthlyLifeStyleSpending
        ? defaultAssumptions.DefaultMonthlySpending
        : currencyToNumber(formData.monthlyLifeStyleSpending),

      needHelpWithBudget: formData.needHelpWithBudget,

      grossAnnualIncome: formData.isDefaultGrossAnnualIncome
        ? defaultAssumptions.DefaultGrossAnnualIncome
        : currencyToNumber(formData.grossAnnualIncome),

      totalLiquidSaving: formData.isDefaultTotalLiquidSavings
        ? defaultAssumptions.DefaultTotalSavingsInLiquid
        : currencyToNumber(formData.totalLiquidSaving),

      totalInSuper: formData.isDefaultTotalInSuper
        ? defaultAssumptions.DefaultSuperOrSMSFValue
        : formData.totalInSuper,

      allocateIncomeToSuper: formData.isDefaultAllocateIncomeToSuper
        ? defaultAssumptions.DefaultAllocateIncomeToSuper
        : formData.allocateIncomeToSuper,

      incomeTaxRate: formData.isDefaultIncomeTaxRate
        ? defaultAssumptions.DefaultIncomeTaxRate
        : formData.incomeTaxRate,

      isDefaultMonthlyAvgSaving: formData.isDefaultMonthlyAvgSaving,
      isDefaultMonthlyExpenses: formData.isDefaultMonthlyExpenses,
      isDefaultMonthlyLifeStyleSpending:
        formData.isDefaultMonthlyLifeStyleSpending,
      isDefaultGrossAnnualIncome: formData.isDefaultGrossAnnualIncome,
      isDefaultIncomeTaxRate: formData.isDefaultIncomeTaxRate,
      isDefaultTotalInSuper: formData.isDefaultTotalInSuper,
      isDefaultTotalLiquidSavings: formData.isDefaultTotalLiquidSavings,
      isDefaultTotalInSuper: formData.isDefaultTotalInSuper,
      isDefaultAllocateIncomeToSuper: formData.isDefaultAllocateIncomeToSuper,

      clientId: clientState.clientId,
      userId: userState.userId,
    };
  };
  const handleNext = () => {
    console.log("Handle next , ", formData);
    console.log("Default Assumption , ", Assumptions);

    // if (activeStep === stepLabels.length - 1) {
    const moneyManagementId = formData.id;
    const moneyManagementData = updateMoneyManagementData();

    console.log("money management data: ", moneyManagementData);
    if (activeStep === stepLabels.length - 1) {
      if (moneyManagementData === undefined || moneyManagementId === 0) {
        console.log("Family Money Management Id: ", moneyManagementId);
        MoneyManagementService.add(moneyManagementData)
          .then((response) => {
            setOpen(true);
            setTimeout(() => {
              redirectToTracker();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        MoneyManagementService.update(moneyManagementId, moneyManagementData)
          .then((response) => {
            setOpen(true);
            setTimeout(() => {
              redirectToList();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    if (activeStep === 0) {
      // Saving Detail
      if (!validateFormFamilyDetails()) {
        return;
      }
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleFinish = () => {
    const moneyManagementId = formData.id;
    const moneyManagementData = updateMoneyManagementData();

    console.log("update");
    console.log("money management data: ", moneyManagementData);

    MoneyManagementService.update(moneyManagementId, moneyManagementData)
      .then((response) => {
        setOpen(true);
        setTimeout(() => {
          redirectToList();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content">
      <Container>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message="Money management is saved."
          action={action}
        ></Snackbar>
        <Box className="ff" sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {stepLabels.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step
                  id={`step${index}`}
                  key={label}
                  {...stepProps}
                  onClick={() => goToStep(index)}
                  style={{ cursor: "pointer" }}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>{steps[activeStep]}</div>

          {activeStep === stepLabels.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  variant="contained"
                  color="grey"
                  size="medium"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  variant="contained"
                  color="grey"
                  size="medium"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />

                <Button
                  className="steper-next-button"
                  variant="contained"
                  color="grey"
                  size="medium"
                  onClick={handleNext}
                >
                  {activeStep === stepLabels.length - 1 ? "Finish" : "Next"}
                </Button>
                <Button
                  variant="contained"
                  color="grey"
                  size="medium"
                  onClick={handleFinish}
                  sx={{
                    display:
                      isUpdate && activeStep !== stepLabels.length - 1
                        ? "block"
                        : "none",
                  }}
                >
                  Finish
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default MoneyManagementAdd;
