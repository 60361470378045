import React from 'react';

const BorrowCapacity = () => {
    return (
        <div className="content">
            <h2>Borrow Capacity</h2>
        </div>
    );
};

export default BorrowCapacity;