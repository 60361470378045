import http from "./http-common";

class YearlyPlanSellingService {
  // http://localhost:5000/api/v1/yearly-plan/get/:id
  getAllByYearlyPlanId(yearlyPlanId) {
    const path = `/yearly-plan/property-sell/get/yearly-plan/${yearlyPlanId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/yearly-plan/property-sell/update/:id
  update(sellingId, data) {
    const path = `/yearly-plan/property-sell/update/${sellingId}`;
    console.log("path: ", path);
    return http.put(path, data);
  }

  // http://localhost:5000/api/v1/yearly-plan/property-sell/add
  add(data) {
    const path = "/yearly-plan/property-sell";
    console.log("data: ", data);
    return http.post(`${path}/add`, data);
  }

  // http://localhost:5000/api/v1/yearly-plan/delete/:id
  deleteAllByYearlyPlanId(yearlyPlanId) {
    const path = `/yearly-plan/property-sell/delete/yearly-plan/${yearlyPlanId}`;
    console.log("path: ", path);
    return http.delete(path);
  }
}

export default new YearlyPlanSellingService();
