import { formatCurrency } from "utils/currency";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { useState } from "react";

export default function PropertySummary({ property }) {
  return (
    <Card>
      <span style={{ color: "#114253", fontSize: "20px", fontWeight: "bold" }}>
        Property Details
      </span>

      {/* <CardHeader style={{ color: "darkblue", fontSize: "24px" }}>
        Property Details
      </CardHeader> */}
      <div style={{ color: "black" }}>
        <CardBody className="left-aligned-text">
          <Row>
            <Col md={{ size: 10, offset: 0 }}>
              <div>
                <b>Address</b>: {property?.address}, {property?.suburb},{" "}
                {property?.postcode}, {property?.stateShortName}
              </div>
              <div>
                <b>Configuration</b>: {property.noOfBedRooms}/
                {property.noOfBathRooms}/?
              </div>
              <div>
                <b>Price</b>: {formatCurrency(property.askingPrice)}
              </div>
              <div>
                <b>Land Size</b>: {property.landSize} m<sup>2</sup>
              </div>
              <div>
                <b>Frontage</b>: {property.frontage} m
              </div>
              <div>
                <b>Zone</b>: {property.residentialZone}
              </div>
              <div>
                <b>Rent</b>: {formatCurrency(property.expectedRentalIncome)} per
                week
              </div>
              <div>
                <b>Potential Yield</b>: {property.rentalYieldPercent}%
              </div>
              <div>
                <b>Client Name</b>: {property.currentClient}
              </div>
              <div>
                <b>Buyer Agent</b>: {property.currentBuyerAgent}
              </div>
              <div>
                <b>Documents Uploaded</b>:{" "}
                {property.mandatoryDocumentsNotReceived > 0 ? "No" : "Yes"}
              </div>
            </Col>
          </Row>
        </CardBody>
      </div>
    </Card>
  );
}
