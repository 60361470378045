import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";

import FormatLineSpacingRoundedIcon from "@mui/icons-material/FormatLineSpacingRounded";
import Diversity2RoundedIcon from "@mui/icons-material/Diversity2Rounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ContactsIcon from "@mui/icons-material/Contacts";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import { useHistory } from "react-router-dom";
// import PaymentService from "../../services/payment.service";
import ProgressTrackerService from "../../services/progress-tracker.service";
import ScoreboardService from "../../services/scoreboard.service";
// import { loadStripe } from "@stripe/stripe-js";
// import {
//   EmbeddedCheckoutProvider,
//   EmbeddedCheckout,
// } from "@stripe/react-stripe-js";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
import { CircularProgress, Container } from "@mui/material";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";
import { scoreboardExcel } from "./scoreboard-excel";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const Scoreboard = () => {
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [loginUser, setLoginUser] = useState({});
  const [paymentUrl, setPaymentUrl] = useState("");
  const [progress, setProgress] = useState([]);
  const hasDataLoaded = progress.length > 0;
  const history = useHistory();
  const [scoreboardData, setScoreboardData] = useState([]);

  useEffect(() => {
    getScoreboardData();
  }, []);

  useEffect(() => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    // console.log('selectedClient: ', selectedClient);
    //setClientId(selectedClient.clientId);
    const jwtToken = window.sessionStorage.getItem("jwt-token");
    const loginUser = JSON.parse(window.localStorage.getItem("login-user"));
    const selectedClient = JSON.parse(
      window.localStorage.getItem("selected-client")
    );

    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
    } else {
      setLoginUser(loginUser);
      // if (userState.userId === 0) {
      //   setUserState(loginUser);
      // }
      getClientProgressById(clientState?.clientId);
      getScoreboardData();
    }
  }, [clientState?.clientId]);

  const getScoreboardData = () => {
    ScoreboardService.getClientProfile(clientState?.clientId)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const result = response.data.recordsets;
          setScoreboardData(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getClientProgressById = async (id) => {
    ProgressTrackerService.getProgress(id)
      .then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset;
          setProgress(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const createCheckoutSession = () => {
  //   PaymentService.createCheckoutSession()
  //     .then((response) => {
  //       console.log("response: ", response);
  //       // history.push(response.data.url);
  //       window.location.href = response;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getCardColourById = (id) =>
    progress?.find((p) => p.id === id)?.completed ? "#114253" : "#fff";

  const assinclass = (id) =>
    progress?.find((p) => p.id === id)?.completed ? "active" : "";

  return (
    <>
      <div className="content scoreboard-content">
        <Container>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Scoreboard for {clientState?.firstName}{" "}
                    {clientState?.lastName}
                    <div>
                      <Button
                        variant="contained"
                        color="grey"
                        size="small"
                        onClick={() => scoreboardExcel(scoreboardData)}
                      >
                        Export
                      </Button>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ClientNotSelectedDialog />
                  <Row>
                    <Col md="12">
                      {hasDataLoaded ? (
                        <div className="card_wrapper">
                          <Row>
                            <Col lg="3">
                              <div
                                className={`card_box ${assinclass(1)}`}
                                style={{
                                  backgroundColor: getCardColourById(1),
                                }}
                              >
                                <Link to="../scoreboard/goal-alignment">
                                  <div className="card-icon icon-primary">
                                    <FormatLineSpacingRoundedIcon
                                      sx={{
                                        height: "85px",
                                        width: "85px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "12px",
                                      }}
                                    />
                                  </div>
                                  <h6>Goal Alignment</h6>
                                </Link>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div
                                className={`card_box ${assinclass(2)}`}
                                style={{
                                  backgroundColor: getCardColourById(2),
                                }}
                              >
                                <Link to="../scoreboard/family-members/list">
                                  <div className="card-icon icon-primary">
                                    <Diversity2RoundedIcon
                                      sx={{
                                        height: "85px",
                                        width: "85px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "12px",
                                      }}
                                    />
                                  </div>
                                  <h6>Family Members</h6>
                                </Link>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div
                                className={`card_box ${assinclass(3)}`}
                                style={{
                                  backgroundColor: getCardColourById(3),
                                }}
                              >
                                <Link to="../scoreboard/employment/list">
                                  <div className="card-icon icon-primary">
                                    <BadgeRoundedIcon
                                      sx={{
                                        height: "85px",
                                        width: "85px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "12px",
                                      }}
                                    />
                                  </div>
                                  <h6>Employment</h6>
                                </Link>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div
                                className={`card_box ${assinclass(4)}`}
                                style={{
                                  backgroundColor: getCardColourById(4),
                                }}
                              >
                                <Link to="../scoreboard/property-portfolio/list">
                                  <div className="card-icon icon-primary">
                                    <ApartmentIcon
                                      sx={{
                                        height: "85px",
                                        width: "85px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "12px",
                                      }}
                                    />
                                  </div>
                                  <h6>Portfolio</h6>
                                </Link>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div
                                className={`card_box ${assinclass(5)}`}
                                style={{
                                  backgroundColor: getCardColourById(5),
                                }}
                              >
                                <Link to="../scoreboard/risk-profile/add">
                                  <div className="card-icon icon-primary">
                                    <ContactsIcon
                                      sx={{
                                        height: "85px",
                                        width: "85px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "12px",
                                      }}
                                    />
                                  </div>
                                  <h6>Risk Profile</h6>
                                </Link>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div
                                className={`card_box ${assinclass(6)}`}
                                style={{
                                  backgroundColor: getCardColourById(6),
                                }}
                              >
                                <Link to="../scoreboard/money-management/list">
                                  <div className="card-icon icon-primary">
                                    <MonetizationOnIcon
                                      sx={{
                                        height: "85px",
                                        width: "85px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "12px",
                                      }}
                                    />
                                  </div>
                                  <h6>Money Management</h6>
                                </Link>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div
                                className={`card_box ${assinclass(7)}`}
                                style={{
                                  backgroundColor: getCardColourById(7),
                                }}
                              >
                                <Link to="../scoreboard/mortgage-strategy">
                                  <div className="card-icon icon-primary">
                                    <PriceChangeIcon
                                      sx={{
                                        height: "85px",
                                        width: "85px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "12px",
                                      }}
                                    />
                                  </div>
                                  <h6>Mortgage Strategy</h6>
                                </Link>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div
                                className={`card_box ${assinclass(8)}`}
                                style={{
                                  backgroundColor: getCardColourById(8),
                                }}
                              >
                                <Link to="../scoreboard/asset-selection/add">
                                  <div className="card-icon icon-primary">
                                    <VideogameAssetIcon
                                      sx={{
                                        height: "85px",
                                        width: "85px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "12px",
                                      }}
                                    />
                                  </div>
                                  <h6>Asset Selection</h6>
                                </Link>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div
                                className={`card_box ${assinclass(9)}`}
                                style={{
                                  backgroundColor: getCardColourById(9),
                                }}
                              >
                                <Link to="../scoreboard/portfolio-analysis">
                                  <div className="card-icon icon-primary">
                                    <QueryStatsIcon
                                      sx={{
                                        height: "85px",
                                        width: "85px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "12px",
                                      }}
                                    />
                                  </div>
                                  <h6>Portfolio Analysis</h6>
                                </Link>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div
                                className={`card_box ${assinclass(10)}`}
                                style={{
                                  backgroundColor: getCardColourById(10),
                                }}
                              >
                                <Link to="../scoreboard/property-plan/add">
                                  <div className="card-icon icon-primary">
                                    <MapsHomeWorkIcon
                                      sx={{
                                        height: "85px",
                                        width: "85px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "12px",
                                      }}
                                    />
                                  </div>
                                  <h6>Property Plan</h6>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          {/* <Row>
              <Col>
                <button type="button" onClick={createCheckoutSession}>
                  Checkout
                </button>
              </Col>
            </Row> */}
                          {/* <Row>
          <Col>
            <iframe src={paymentUrl}></iframe>
          </Col>
        </Row> */}
                        </div>
                      ) : (
                        <div className="loader-progress">
                          <CircularProgress />
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Scoreboard;
