/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./add-client.css";
import MultipleInputService from "../../services/multiple.service";
import ClientService from "../../services/client.service";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import MUIButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import bcrypt from "bcryptjs";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
// import { Container } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const RegisterClient = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const history = useHistory();
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [userType, setUserType] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [clientTypes, setClientTypes] = useState([]);
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
  const [isRegistrationInProgress, setIsRegistrationInProgress] =
    useState(false);
  const [clientstate, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [partnerId, setPartnerId] = useState(
    userState.isPartner ? userState.userId : null
  );
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    userName: Yup.string()
      .email("Must be an email address")
      .required("Must be an email address"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|(?=.*[@$!%*?&]))[A-Za-z\d@$!%*?&]{8,16}$/,
        "Passwords must include one upper case letter, one numeric, and special character!"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    mobileNumber: Yup.string().required("Mobile number is required"),
    conditions: Yup.boolean().oneOf([true], ""),
  });

  const showMessageAndRedirect = (redirectUrl) => {
    setIsRegistrationInProgress(false);
    history.push(redirectUrl);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      clientPartnerId: "",
      mobileNumber: "",
      conditions: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        // Client can not add his/her own record, Parnter can add clients and Admin can add partner and client
        // if admin then set clientPartnerId = Null, isAdmin = true, clientTypeId = userType.value
        // if partner then set clientPartnerId = Null, isAdmin = false, clientTypeId = userType.value
        // if client then set clientPartnerId = login user id, isAdmin = false, clientTypeId =userType.value
        // const user = JSON.parse(window.localStorage.getItem("User"));
        console.log("user type: ", userType);
        console.log("login user: ", userState);
        const hashedPassword = bcrypt.hashSync(
          values.password,
          "$2a$10$CwTycUXWue0Thq9StjUM0u7861!0"
        );
        const data = {
          clientFirstName: values.firstName,
          clientLastName: values.lastName,
          clientEmail: values.email,
          clientTypeId: 1,
          userName: values.userName,
          password: hashedPassword,
          clientScore: 0,
          clientAdvisorId: 0,
          fullAccess: 0,
          lastSignIn: null,
          isAdmin: userType.label === "Admin" ? 1 : 0,
          // clientPartnerId: userState.isClient ? userState.clientId : null,
          clientPartnerId: partnerId,
          userId: userState.userId,
          mobileNumber: values.mobileNumber,
          expiryDate: moment().add(3, "months").format("YYYY-MM-DD"),
        };
        console.log("data: ", data);
        setIsRegistrationInProgress(true);
        ClientService.add(data).then((res) => {
          console.log(res);
          setIsRegisterDisabled(true);
          // formik.resetForm();
          // setIsRegistrationInProgress(false);
          if (searchParams.get("uuid")) {
            setTimeout(() => showMessageAndRedirect("/auth/login"), 5000);
          } else {
            setTimeout(
              () => showMessageAndRedirect("/admin/client/list"),
              5000
            );
          }
        });
      } catch (e) {
        setIsRegisterDisabled(false);
        setIsRegistrationInProgress(false);
        console.log(e);
      }
    },
  });

  const handleBlur = (event) => {
    let { name } = event.target;
    formik.setFieldTouched(name, true);
  };

  const handleChange = (event) => {
    let { name } = event.target;
    formik.setFieldValue(name, event.target.value);
  };

  const handleErrorOpen = async () => {
    setErrorOpen(true);
    setIsRegistrationInProgress(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  useEffect(() => {
    // getUserTypes();
    window.localStorage.removeItem("jwt-token");
    window.localStorage.removeItem("login-user");
    window.localStorage.removeItem("selected-client");
    window.sessionStorage.removeItem("jwt-token");
    window.sessionStorage.removeItem("login-user");
    window.sessionStorage.removeItem("selected-client");
    console.log(formik.touched);
    console.log("userState: ", userState);
    setTimeout(() => {
      Object.keys(formik.touched).map((res) =>
        formik.setFieldTouched(res, false)
      );
    }, 15000);
  }, [formik.touched]);

  React.useEffect(() => {
    const uuid = searchParams.get("uuid");
    if (uuid) {
      setIsRegisterDisabled(true);
      getClientByUuid(uuid);
    }
    document.body.classList.toggle("register-page");
    return function cleanup() {
      document.body.classList.toggle("register-page");
    };
  }, []);

  const handleRegisterClick = (e) => {
    formik.setTouched({
      firstName: true,
      lastName: true,
      userName: true,
      email: true,
      password: true,
      confirmPassword: true,
      mobileNumber: true,
      conditions: true,
    });

    formik.handleSubmit();
  };

  const getClientByUuid = async (uuid) => {
    setIsRegistrationInProgress(false);
    ClientService.getClientByUuid(uuid)
      .then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset[0];
          setPartnerId(result.ClientId);
          setIsRegisterDisabled(false);
        } else {
          // show error and disable
          handleErrorOpen(true);
          setIsRegisterDisabled(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="login-page auth-layout-page">
      <BootstrapDialog
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Invalid Link Error"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleErrorClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Error: Invalid code in the link. Please use a valid link with a
            valid code
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={handleErrorClose}>OK</MUIButton>
        </DialogActions>
      </BootstrapDialog>
      <Container>
        <div className="auth-logo-responsive-main d-lg-none">
          <div className="auth-logo-responsive">
            <img
              alt="..."
              className="avatar border-gray"
              src={require("assets/img/main_logo_white.png")}
            />
          </div>
        </div>
        <Row>
          <Col className="" lg="6" md="12">
            <div className="auth-layout-logo">
              <div
                className="auth-navbar-logo"
                style={{
                  backgroundImage: `url(${require("assets/img/auth-logo.png")})`,
                }}
              ></div>
            </div>
          </Col>
          <Col className="mr-auto" lg="6" md="12">
            <Card className="text-left">
              <CardHeader>
                <CardTitle tag="h4">Register Client</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="" className="form" method="">
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        // onChange={formik.handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.firstName}
                      />
                    </div>
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <p className="form-errors">{formik.errors.firstName}</p>
                    ) : (
                      <p className="form-errors"></p>
                    )}
                  </InputGroup>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        name="lastName"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.lastName}
                      />
                    </div>
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <p className="form-errors">{formik.errors.lastName}</p>
                    ) : (
                      <p className="form-errors"></p>
                    )}
                  </InputGroup>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon  nc-circle-10 " />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="User Name (Email address)"
                        type="text"
                        name="userName"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.userName}
                      />
                    </div>
                    {formik.touched.userName && formik.errors.userName ? (
                      <p className="form-errors">{formik.errors.userName}</p>
                    ) : (
                      <p className="form-errors"></p>
                    )}
                  </InputGroup>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        name="email"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.email}
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <p className="form-errors">{formik.errors.email}</p>
                    ) : (
                      <p className="form-errors"></p>
                    )}
                  </InputGroup>

                  <InputGroup className="phone_num_input client-mobile-number">
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-mobile" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Mobile Number"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        name="mobileNumber"
                        value={formik.values.mobileNumber}
                      />
                      {formik.touched.mobileNumber &&
                      formik.errors.mobileNumber ? (
                        <p className="form-errors ">
                          {formik.errors.mobileNumber}
                        </p>
                      ) : (
                        <p className="form-errors  "></p>
                      )}
                    </div>
                  </InputGroup>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.password}
                      />
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <p className="form-errors  ">{formik.errors.password}</p>
                    ) : (
                      <p className="form-errors  "></p>
                    )}
                  </InputGroup>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm Password"
                        type="password"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                      />
                    </div>
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <p className="form-errors ">
                        {formik.errors.confirmPassword}
                      </p>
                    ) : (
                      <p className="form-errors  "></p>
                    )}
                  </InputGroup>
                  <FormGroup check className="text-left">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={formik.values.conditions}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "conditions",
                            event.target.checked
                          );
                          console.log(event.target.checked);
                        }}
                        onBlur={handleBlur}
                      />
                      <span
                        className={`form-check-sign ${
                          formik.touched.conditions &&
                          formik.values.conditions == false
                            ? "checkBox-error"
                            : ""
                        }`}
                      />
                      I agree to the{" "}
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        terms and conditions
                      </a>
                      .
                    </Label>
                  </FormGroup>
                </Form>
                <CardFooter className="text-right">
                  <Button
                    className="btn-round"
                    color="info"
                    // href="#pablo"
                    type="submit"
                    disabled={isRegisterDisabled || isRegistrationInProgress}
                    onClick={(e) => {
                      handleRegisterClick(e);
                    }}
                  >
                    {isRegistrationInProgress === true ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "-5px",
                        }}
                      >
                        <CircularProgress
                          sx={{
                            color: "#ffffff",
                          }}
                          style={{ backgroundPosition: "center" }}
                        />
                        <Typography
                          sx={{
                            marginLeft: "12px",
                            marginTop: "8px",
                            textTransform: "capitalize",
                          }}
                        >
                          Please wait while we register your profile and
                          redirect you ...{" "}
                        </Typography>
                      </Box>
                    ) : (
                      "Register"
                    )}
                  </Button>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/jan-sendereks.jpg")})`,
          }}
        /> */}
    </div>
  );
};

export default RegisterClient;
