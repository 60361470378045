import http from "./http-common";

class ScoreboardService {
  addGoalAlignment(data) {
    const path = "/scoreboard/goal-alignment";
    return http.post($`{path}/add`, data);
  }

  getClientProfile(clientId) {
    const path = `/scoreboard/client-profile/${clientId}`;
    console.log("Path", path);
    return http.get(path);
  }
}

export default new ScoreboardService();
