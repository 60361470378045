import { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { Container } from "@mui/material";
import { currencyToNumber } from "utils/currency";
import { formatCurrency } from "utils/currency";

const PropertyRentalDetails = ({
  formData,
  setFormData,
  defaultAssumptions,
}) => {
  const [state, setState] = useAtom(selectedClientAtom);
  const changeInPurposeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    height: "65px",
    width: "175px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  // useEffect(() => {
  //   console.log("Form Data - ", formData);
  //   setFormData({
  //     ...formData,
  //     rentalIncomePerWeek:
  //       (formData.currentMarketValue * defaultAssumptions.RentalYield) /
  //       100 /
  //       52,
  //   });
  //   console.log("Form Data - ", formData);
  // }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Set your property portfolio for {state.firstName} {state.lastName}{" "}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="6">
                <Label>Current property manager</Label>
                <FormGroup>
                  <Input
                    placeholder="Current property manager"
                    type="text"
                    value={formData.currentPropertyManager}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        currentPropertyManager: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <Label>Management percentage</Label>
                <FormGroup>
                  <Input
                    placeholder="Management percentage"
                    type="number"
                    min="0"
                    oninput="validity.valid||(value='');"
                    value={formData.managementPercentage}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        managementPercentage: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Label>Rental income (per week)</Label>
                <FormGroup>
                  <CurrencyInput
                    placeholder="Rental income"
                    number={formData.rentalIncomePerWeek}
                    handleNumberChange={(value) =>
                      setFormData({ ...formData, rentalIncomePerWeek: value })
                    }
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <Label>External link</Label>
                <FormGroup>
                  <Input
                    placeholder="External link"
                    type="text"
                    value={formData.externalLink}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        externalLink: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default PropertyRentalDetails;
