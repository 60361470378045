import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function DeleteDialog(props) {
  const [errorOpen, setErrorOpen] = React.useState(false);

  const handleDelete = async () => {
    props.handleConfirmationClose();
    try {
      await props.onDelete();
    } catch (error) {
      setErrorOpen(true);
    }
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleConfirmationOpen}>
        Delete
      </Button> */}
      <BootstrapDialog
        className="delete-confirmation-popup"
        open={props.confirmationOpen}
        onClose={props.handleConfirmationClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleConfirmationClose}>No</Button>
          <Button onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <BootstrapDialog
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Error"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Error: Delete Failed. Please make sure you have deleted all its
            references
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose}>OK</Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
