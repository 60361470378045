import http from "./http-common";

class DealAcceptedChecklistService {
  // http://localhost:5000/api/v1/new-property/deal-accepted-cheklist

  getAll() {
    const path = `/new-property/deals-accepted-checklist/all`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/deal-accepted-data/
  addDealAcceptedData(data) {
    const path = `/new-property/deals-accepted`;
    return http.post(path, data);
  }
}

export default new DealAcceptedChecklistService();
