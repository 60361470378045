import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AssetSelectionService from "../../../services/asset-selection.service";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Moment from "moment";

import CurrentCircumstances from "./wizard-steps/current-circumstances";
import PropertyDetails from "./wizard-steps/property-details";
import FinancialDetails from "./wizard-steps/financial-details";
import LocationDetails from "./wizard-steps/location-details";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import { currencyToNumber } from "utils/currency";
import { formatCurrency } from "utils/currency";
import { Container } from "@mui/material";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

const INITIAL_DATA = {
  id: 0,
  nextPropertyTimePeriod: "",
  changeInPurpose: "",
  sellingPlan: "0",
  dwellingType: "",
  noOfBeds: "",
  noOfBaths: 0,
  noOfCarSpaces: 0,
  landSize: 0,
  newOrExisting: "0",
  pricePoint: formatCurrency(0),
  preApproved: "0",
  totalLoanAmount: formatCurrency(0),
  deposit: 0,
  capitalGrowth: 0,
  rentalIncome: 0,
  preferredSuburb: "",
  buyingAreaRadius: "",
  buyingOutsideTheArea: "0",
  buyingInAnotherState: "0",
  notes: "",
  clientId: 0,
  userId: 0,
};

const AssetSelectionAdd = () => {
  const [formData, setFormData] = useState(INITIAL_DATA);
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);

  const stepLabels = [
    "Current circumstances",
    "Property details",
    "Financial details",
    "Location details",
  ];
  const steps = [
    <CurrentCircumstances formData={formData} setFormData={setFormData} />,
    <PropertyDetails formData={formData} setFormData={setFormData} />,
    <FinancialDetails formData={formData} setFormData={setFormData} />,
    <LocationDetails formData={formData} setFormData={setFormData} />,
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const history = useHistory();
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const goToStep = (step) => {
    setActiveStep(step);
  };

  const redirectToList = () => {
    const path = "/admin/scoreboard/asset-selection/add";
    history.push(path);
  };

  const redirectToTracker = () => {
    const path = "/admin/progress-tracker/index";
    history.push(path);
  };

  const getClientAssetSelection = async (id) => {
    // const familyMemberId = getRisk();
    AssetSelectionService.getAllByClientId(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const assetSelection = response.data.recordset[0];
          console.log("asset selection: ", assetSelection);
          const assetSelectionData = {
            id: assetSelection.AssetSelectionId,
            nextPropertyTimePeriod: assetSelection.NextPropertyTimePeriod,
            changeInPurpose: assetSelection.ChangeInPurpose,
            sellingPlan: assetSelection.SellingPlan === true ? "Yes" : "No",
            dwellingType: assetSelection.DwellingType,
            noOfBeds: assetSelection.NoOfBeds,
            noOfBaths: assetSelection.NoOfBaths,
            noOfCarSpaces: assetSelection.NoOfCarSpaces,
            landSize: assetSelection.LandSize,
            newOrExisting: assetSelection.NewOrExisting,
            pricePoint: formatCurrency(assetSelection?.PricePoint),
            preApproved: assetSelection.PreApproved,
            totalLoanAmount: formatCurrency(assetSelection.TotalLoanAmount),
            deposit: assetSelection.Deposit,
            capitalGrowth: assetSelection.CapitalGrowth,
            rentalIncome: assetSelection.RentalIncome,
            preferredSuburb: assetSelection.PreferredSuburb,
            buyingAreaRadius: assetSelection.BuyingAreaRadius,
            buyingOutsideTheArea:
              assetSelection.BuyingOutsideTheArea === true ? "Yes" : "No",
            buyingInAnotherState:
              assetSelection.BuyingInAnotherState === true ? "Yes" : "No",
            notes: assetSelection.Notes,
          };
          setFormData(assetSelectionData);
          setIsUpdate(1);
          setIsDataLoaded(true);
        } else {
          setFormData({ ...INITIAL_DATA });
          setIsDataLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setFormData({ ...INITIAL_DATA });
        setIsDataLoaded(false);
      });
  };

  useEffect(() => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    // setClientId(selectedClient.clientId);
    // setUserId(selectedClient.clientId);
    getClientAssetSelection(clientState.clientId);
  }, [clientState.clientId]);

  useEffect(() => {
    if (activeStep != 0) {
      document
        ?.getElementById(`step${activeStep}`)
        ?.scrollIntoView({ behavior: "smooth" });
    }

    return () => {};
  }, [activeStep]);

  const handleNext = () => {
    if (activeStep === stepLabels.length - 1) {
      const assetSelectionId = formData.id;
      console.log("asses selection id: ", formData.id);
      const assetSelectionData = {
        nextPropertyTimePeriod: formData.nextPropertyTimePeriod,
        changeInPurpose: formData.changeInPurpose,
        sellingPlan: formData.sellingPlan,
        dwellingType: formData.dwellingType,
        noOfBeds: formData.noOfBeds,
        noOfBaths: formData.noOfBaths,
        noOfCarSpaces: formData.noOfCarSpaces,
        landSize: formData.landSize,
        newOrExisting: formData.newOrExisting,
        pricePoint: currencyToNumber(formData?.pricePoint),
        preApproved: formData.preApproved,
        totalLoanAmount: currencyToNumber(formData.totalLoanAmount),
        deposit: formData.deposit,
        capitalGrowth: formData.capitalGrowth,
        rentalIncome: formData.rentalIncome,
        preferredSuburb: formData.preferredSuburb,
        buyingAreaRadius: formData.buyingAreaRadius,
        buyingOutsideTheArea: formData.buyingOutsideTheArea,
        buyingInAnotherState: formData.buyingInAnotherState,
        notes: formData.notes,
        clientId: clientState.clientId,
        userId: userState.userId,
      };
      console.log("asset selection: ", assetSelectionData);
      if (assetSelectionId === undefined || assetSelectionId === 0) {
        console.log("Family Money Management Id: ", assetSelectionId);
        AssetSelectionService.add(assetSelectionData)
          .then((response) => {
            setOpen(true);
            setTimeout(() => {
              redirectToTracker();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        AssetSelectionService.update(assetSelectionId, assetSelectionData)
          .then((response) => {
            setOpen(true);
            setTimeout(() => {
              redirectToList();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleFinish = () => {
    const assetSelectionId = formData.id;
    const assetSelectionData = {
      nextPropertyTimePeriod: formData.nextPropertyTimePeriod,
      changeInPurpose: formData.changeInPurpose,
      sellingPlan: formData.sellingPlan,
      dwellingType: formData.dwellingType,
      noOfBeds: formData.noOfBeds,
      noOfBaths: formData.noOfBaths,
      noOfCarSpaces: formData.noOfCarSpaces,
      landSize: formData.landSize,
      newOrExisting: formData.newOrExisting,
      pricePoint: currencyToNumber(formData.pricePoint),
      preApproved: formData.preApproved,
      totalLoanAmount: currencyToNumber(formData.totalLoanAmount),
      deposit: formData.deposit,
      capitalGrowth: formData.capitalGrowth,
      rentalIncome: formData.rentalIncome,
      preferredSuburb: formData.preferredSuburb,
      buyingAreaRadius: formData.buyingAreaRadius,
      buyingOutsideTheArea: formData.buyingOutsideTheArea,
      buyingInAnotherState: formData.buyingInAnotherState,
      notes: formData.notes,
      clientId: clientState.clientId,
      userId: userState.userId,
    };
    AssetSelectionService.update(assetSelectionId, assetSelectionData)
      .then((response) => {
        setOpen(true);
        setTimeout(() => {
          redirectToList();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content">
      <ClientNotSelectedDialog />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Asset selection is saved."
        action={action}
      ></Snackbar>
      <Container>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {stepLabels.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step
                  id={`step${index}`}
                  key={label}
                  {...stepProps}
                  onClick={() => goToStep(index)}
                  style={{ cursor: "pointer" }}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>{steps[activeStep]}</div>

          {activeStep === stepLabels.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  variant="contained"
                  color="grey"
                  size="medium"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              <div className="back-next-buttons">
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />

                  <Button onClick={handleNext}>
                    {activeStep === stepLabels.length - 1 ? "Finish" : "Next"}
                  </Button>
                  <Button
                    className={`${
                      isUpdate && activeStep !== stepLabels.length - 1
                        ? "hidden-finish-btn"
                        : "show-finish-btn"
                    }`}
                    onClick={handleFinish}
                    sx={{
                      display:
                        isUpdate && activeStep !== stepLabels.length - 1
                          ? "block"
                          : "none",
                    }}
                  >
                    Finish
                  </Button>
                </Box>
              </div>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default AssetSelectionAdd;
