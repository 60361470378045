import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import {
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import "./for-review-form.scss";
import dueDiligenceService from "services/due-diligence.service";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { userAtom } from "store";

import propertyDueDiligenceService from "services/property-due-diligence.service";
import newPropertyService from "services/new-property.service";
import propertyStageService from "services/property-stage.service";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function ForReviewForm({
  dialogOpen,
  handleDialogClose,
  handleCancel,
  revertPostToPreviousStage,
  propertyId,
  currentPost,
  previousPost,
  postsByStatus,
  fetchProperties,
}) {
  const [formData, setFormData] = useState({});
  const [dueDiligence, setDueDiligence] = useState([]);
  const [userState] = useAtom(userAtom);

  useEffect(() => {
    getLowLevelDueDiligence();
  }, []);

  const getLowLevelDueDiligence = async () => {
    dueDiligenceService
      .getByLevel("Low Level")
      .then((response) => {
        if (response.data.recordset.length > 0) {
          setDueDiligence(response.data.recordset);
        }
      })
      .catch((error) => {
        console.log(error);
        // setDueDiligence([]);
      });
  };

  // Function to convert into camel Case
  function camelCase(str) {
    // Using replace method with regEx
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  const handleOnReview = () => {
    // fire request for review checklist
    const data = [...dueDiligence].map((d) => ({
      dueDiligenceId: d.DueDiligenceId,
      propertyId,
      response: formData[camelCase(d.DueDiligenceItem)],
      comments: formData[camelCase(d.DueDiligenceItem) + "Value"] || "",
      userId: userState.userId,
    }));

    console.log("Handel Review - Form Data - ", data);
    const apiCalls = [];
    data.forEach((dataItem) =>
      apiCalls.push(propertyDueDiligenceService.add(dataItem))
    );

    Promise.all(apiCalls)
      .then((response) => {
        // fire request to change the stage
        console.log("Response from all Promise", response);
        const data = {
          ...currentPost,
          dueDiligence: formData.dueDiligence,
        };
        newPropertyService.update(data, propertyId).then((response) => {
          console.log("moving suceeded: ", response);
          propertyStageService
            .add({
              propertyId,
              propertyStatusId: currentPost.statusId,
              comments: "",
              userId: userState.userId,
            })
            .then((response) => {
              setFormData({});
              handleDialogClose();
              fetchProperties();
            });
        });

        // record stage history
      })
      .catch((error) => {
        // revert the property card
        // revertPostToPreviousStage(
        //   previousPost.sourcePost,
        //   previousPost.destination,
        //   previousPost.source,
        //   postsByStatus
        // );
        console.log(error);
        handleDialogClose();

        // setIsDataLoaded(true);
        // setProperties([]);
      });

    console.log("data to submit, ", data);

    // propertyDueDiligenceService
    //   .add(data)
  };

  return (
    <BootstrapDialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="for-review-dialog-title"
      aria-describedby="for-review-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="for-review-dialog-title">{"For Review"}</DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="lab_margin">
        <Box sx={{ width: "100%" }}>
          <div className="for-review-form">
            <Form>
              <Row>
                <Col md="12">
                  <Label>Slope</Label>
                  <FormGroup className="member_type_fields">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formData.slope || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          slope: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                      {formData.slope === "yes" && (
                        <>
                          <FormGroup>
                            <Input
                              placeholder="meters"
                              type="number"
                              value={formData.slopeValue}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  slopeValue: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </>
                      )}
                    </RadioGroup>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <Label>Easement</Label>
                  <FormGroup className="member_type_fields">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formData.easement || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          easement: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                      {formData.easement === "yes" && (
                        <>
                          <FormGroup>
                            <Input
                              placeholder="meters"
                              type="number"
                              value={formData.easementValue}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  easementValue: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </>
                      )}
                    </RadioGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Sewerage</Label>
                  <FormGroup className="member_type_fields">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formData.sewerage || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          sewerage: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Frontage</Label>
                  <FormGroup>
                    <Input
                      placeholder="Frontage"
                      type="number"
                      value={formData.frontage}
                      onChange={(e) =>
                        setFormData({ ...formData, frontage: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Heritage Zone</Label>
                  <FormGroup className="member_type_fields">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formData.heritageZone || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          heritageZone: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Bushfire Zone</Label>
                  <FormGroup className="member_type_fields">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formData.bushfireZone || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          bushfireZone: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Flood Zone</Label>
                  <FormGroup className="member_type_fields">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formData.floodZone || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          floodZone: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Power Lines (Distance)</Label>
                  <FormGroup>
                    <Input
                      placeholder="Distance"
                      type="number"
                      value={formData.powerLines}
                      onChange={(e) =>
                        setFormData({ ...formData, powerLines: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Housing Commission</Label>
                  <FormGroup className="member_type_fields">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formData.housingCommission || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          housingCommission: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormGroup>
                </Col>
                <Col md="8">
                  <Label>House Build Type</Label>
                  <FormGroup className="member_type_fields">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formData.houseBuildType || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          houseBuildType: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="weatherBoard"
                        control={<Radio />}
                        label="Weather Board"
                      />
                      <FormControlLabel
                        value="doubleBrick"
                        control={<Radio />}
                        label="Double Brick"
                      />
                    </RadioGroup>
                  </FormGroup>
                </Col>
                <Col md="8">
                  <Label>Development Potential</Label>
                  <FormGroup className="member_type_fields">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formData.developmentPotential || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          developmentPotential: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="granny"
                        control={<Radio />}
                        label="Granny"
                      />
                      <FormControlLabel
                        value="buildAndRetain"
                        control={<Radio />}
                        label="Build & Retain"
                      />
                      <FormControlLabel
                        value="multiUnit"
                        control={<Radio />}
                        label="Multi unit"
                      />
                      <FormControlLabel
                        value="duplex"
                        control={<Radio />}
                        label="Duplex"
                      />
                    </RadioGroup>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <Label>Zone</Label>
                  <FormGroup>
                    <Input
                      placeholder="Zone"
                      type="text"
                      value={formData.zone}
                      onChange={(e) =>
                        setFormData({ ...formData, zone: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>House Size (in sqm)</Label>
                  <FormGroup>
                    <Input
                      placeholder="sqm"
                      type="number"
                      value={formData.houseSize}
                      onChange={(e) =>
                        setFormData({ ...formData, houseSize: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Council Feedback Sought</Label>
                  <FormGroup className="member_type_fields">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formData.councilFeedbackSought || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          councilFeedbackSought: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Architect Feedback Sought</Label>
                  <FormGroup className="member_type_fields">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formData.architectFeedbackSought || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          architectFeedbackSought: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Due Diligence Completed</Label>
                  <FormGroup>
                    <div className="due-diligence-checkbox">
                      <Input
                        type="checkbox"
                        checked={formData.dueDiligence}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            dueDiligence: !formData.dueDiligence,
                          })
                        }
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleOnReview}>OK</Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
