import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
import PropertySummary from "components/Posts/PropertySummary";
import UploadDocumentDialog from "ui/new-properties/Dialogs/upload-document/upload-document-dialog";

import {
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import "./for-deal-accepted-form.scss";
import dueDiligenceService from "services/due-diligence.service";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { userAtom } from "store";

import propertyDueDiligenceService from "services/property-due-diligence.service";
import newPropertyService from "services/new-property.service";
import propertyStageService from "services/property-stage.service";
import dealAcceptedChecklistService from "services/deal-accepted-checklist.service";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ForDealAcceptedForm({
  handleCancel,
  revertPostToPreviousStage,
  propertyId,
  currentPost,
  previousPost,
  postsByStatus,
  fetchProperties,
  updatedProperty,
  setUpdatedProperty,
  setShowDealAcceptedDialog,
}) {
  // const [formData, setFormData] = useState({
  //   isContractIssuedByPAM: currentPost.isContractIssuedByPAM,
  //   isNameOfPurchaser: currentPost.isNameOfPurchaser,
  //   isReviewedBySolicitor: currentPost.isReviewedBySolicitor,
  //   isSubjectToFinance: currentPost.isSubjectToFinance,
  //   isInspection: currentPost.isInspection,
  // });

  const completeAddress =
    updatedProperty?.address +
    ", " +
    updatedProperty?.suburb +
    "," +
    updatedProperty?.postcode +
    ", " +
    updatedProperty?.stateShortName;

  const [formData, setFormData] = useState({});

  const [dueDiligence, setDueDiligence] = useState([]);
  const [userState] = useAtom(userAtom);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);

  const [open, setOpen] = useState(true);

  const [checkList, setCheckList] = useState([]);

  const getDealAcceptCheckList = async () => {
    dealAcceptedChecklistService
      .getAll()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          console.log("List of Check List", response.data.recordset);
          setCheckList([...response.data.recordset]);
          const initialFormData = {};
          response.data.recordset.forEach((item) => {
            initialFormData[item.id] = item.value;
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDealAcceptCheckList();
    // setFormData([...checkList]);
    // console.log("UseEffect - Form Data", formData);
    // console.log("UseEffect - Check List", checkList);
  }, []);

  // useEffect(() => {
  //   if (
  //     updatedProperty.currentClient &&
  //     updatedProperty.DealAcceptedMandatoryDocNotReceived === 0
  //   ) {
  //     updatePropertyStagetoProposed();
  //   }
  // }, [updatedProperty]);

  useEffect(() => {
    if (!showDocumentDialog) {
      checkDocumentStatusforAccepted();
    }
  }, [showDocumentDialog]);

  const checkDocumentStatusforAccepted = async () => {
    newPropertyService
      .checkDocumentStatusforAccepted(updatedProperty.id)
      .then((response) => {
        setUpdatedProperty({
          ...updatedProperty,
          dealAcceptedMandatoryDocNotReceived: response.data.recordset.length,
        });
        console.log(
          "checkDocumentStatusforProposed,",
          updatedProperty.dealAcceptedMandatoryDocNotReceived
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckboxChange = (DealsAcceptedChecklistId) => {
    setFormData({
      ...formData,
      [DealsAcceptedChecklistId]: !formData[DealsAcceptedChecklistId],
    });
  };

  function checkAllPropertiesTrue(obj) {
    for (let key in obj) {
      if (obj[key] !== true) {
        return false;
      }
    }
    return true;
  }

  function handlePropertyMoveToAccepted() {
    const updateData = {
      propertyId: updatedProperty.id,
      propertyStatusId: 5, //statusId[destination.status],
      comments: "",
      userId: userState.userId,
    };

    console.log("Handle Property - Move to Accepted, Form Data", updateData);

    // update the status in property table
    newPropertyService
      .updateStatus(updateData, updateData.propertyId)
      .then((response) => {
        // update the record in status history table
        propertyStageService
          .add(updateData)
          .then((response) => {
            fetchProperties();
            console.log("moving suceeded: ", response);
          })
          .catch((error) => {
            // revert the property card
            revertPostToPreviousStage();
            console.log(error);
          });
      })
      .catch((error) => {
        // revert the property card
        revertPostToPreviousStage();
        console.log(error);
      });
  }

  const handleOnAcceptance = () => {
    console.log("Check List Data", checkList);
    if (
      Object.keys(formData).length != checkList.length ||
      !checkAllPropertiesTrue(formData)
    )
      return;

    const updateData = Object.keys(formData).map((key) => ({
      DealsAcceptedChecklistId: parseInt(key),
      Value: formData[key],
      userId: userState.userId,
      propertyId: updatedProperty.id,
    }));

    // Update data to DealsAcceptedProperties
    console.log("Update data from Deal Accepted form", updateData);

    dealAcceptedChecklistService
      .addDealAcceptedData(updateData)
      .then((response) => {
        if (updatedProperty.dealAcceptedMandatoryDocNotReceived === 0) {
          handlePropertyMoveToAccepted();
          setFormData({});
          handleDialogClose();
        }
      })

      .catch((error) => {
        console.log(error);
        handleDialogClose();
      });
  };

  const handleUploadDocuments = () => {
    setShowDocumentDialog(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setShowDealAcceptedDialog(false);
    handleCancel();
  };

  return (
    <BootstrapDialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="for-review-dialog-title"
      aria-describedby="for-review-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="for-review-dialog-title">
        {"Deal Acceptance"}
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="lab_margin">
        {/* <Box sx={{ width: "100%" }}> */}
        <Card>
          <CardBody className="new-property-card-body">
            <Container style={{ display: "flex", flexWrap: "wrap" }}>
              <div
                style={{
                  flex: 1,
                  minWidth: "400px",
                  padding: "20px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  marginRight: "5px",
                }}
              >
                <PropertySummary property={updatedProperty} />
              </div>
              <div
                style={{
                  flex: 2,
                  minWidth: "400px",
                  padding: "20px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              >
                {/* <div className="for-review-form"> */}
                {updatedProperty.dealAcceptedMandatoryDocNotReceived > 0 && (
                  <Row>
                    <Col>
                      <Label>Contract is missing....</Label>
                      <DialogActions>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleUploadDocuments}
                        >
                          Upload Contract
                        </Button>
                      </DialogActions>
                    </Col>
                  </Row>
                )}
                <Form>
                  {checkList.map((item) => (
                    <Row>
                      <Col md="12" key={item.id}>
                        <FormGroup key={item.id}>
                          <div className="due-diligence-checkbox">
                            <Input
                              type="checkbox"
                              id={`checklist-${item.DealsAcceptedChecklistId}`}
                              checked={
                                formData[item.DealsAcceptedChecklistId] || false
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  item.DealsAcceptedChecklistId
                                )
                              }
                            />
                            <Label
                              for={`checklist-${item.DealsAcceptedChecklistId}`}
                              style={{ padding: "10px" }}
                            >
                              {item.DealsAcceptedDescription}
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  ))}
                </Form>
              </div>
              {/* </Box> */}
            </Container>
          </CardBody>
        </Card>
        <UploadDocumentDialog
          propertyId={updatedProperty.id}
          dialogOpen={showDocumentDialog}
          propertyAddress={completeAddress}
          handleDialogClose={() => setShowDocumentDialog(false)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button onClick={handleOnAcceptance}>OK</Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
