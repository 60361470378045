import { useState, useEffect } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";

const PropertyExpenses = ({ formData, setFormData, defaultAssumptions }) => {
  const expensesCalculationMethod = ["Summarise", "Breakdown"];
  const expensesFrequency = [
    "Weekly",
    "Fortnightly",
    "Monthly",
    "Quarterly",
    "Annually",
  ];
  const [isBreakdownMethod, setIsBreakdownMethod] = useState(false);
  const [state, setState] = useAtom(selectedClientAtom);
  const isUpdate =
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/").length === 5
      ? true
      : false;

  const changeInPurposeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  useEffect(() => {
    if (isUpdate) {
      setIsBreakdownMethod(
        formData.propertyExpensesCalculationMethod === "Breakdown"
      );
    }
  }, [state.clientId]);

  const setPropertyExpensesCalculationMethod = (
    propertyExpensesCalculationMethod
  ) => {
    if (propertyExpensesCalculationMethod === "Summarise") {
      setIsBreakdownMethod(false);
    }
    if (propertyExpensesCalculationMethod === "Breakdown") {
      setIsBreakdownMethod(true);
    }
    setFormData({
      ...formData,
      propertyExpensesCalculationMethod: propertyExpensesCalculationMethod,
      isDefaultPropertyExpensesCalculationMethod: "0",
    });
    console.log("formData: ", formData);
  };

  const setStrataCostFrequency = (strataCostFrequency) => {
    setFormData({ ...formData, strataCostFrequency });
  };

  const setCouncilCostFrequency = (councilCostFrequency) => {
    setFormData({ ...formData, councilCostFrequency });
  };

  const setInsuranceCostFrequency = (insuranceCostFrequency) => {
    setFormData({ ...formData, insuranceCostFrequency });
  };

  const setMaintenanceCostFrequency = (maintenanceCostFrequency) => {
    setFormData({ ...formData, maintenanceCostFrequency });
  };

  const setLandTaxFrequency = (landTaxAmountFrequency) => {
    setFormData({ ...formData, landTaxAmountFrequency });
  };

  const setAccountCostFrequency = (accountingCostFrequency) => {
    setFormData({ ...formData, accountingCostFrequency });
  };

  const setAsicCostFrequency = (asicCostFrequency) => {
    setFormData({ ...formData, asicCostFrequency });
  };

  const setWaterCostFrequency = (waterCostFequency) => {
    setFormData({ ...formData, waterCostFequency });
  };

  const setElectricalCostFrequency = (electricalCostFrequency) => {
    setFormData({ ...formData, electricalCostFrequency });
  };

  const setGasCostFrequency = (gasCostFrequency) => {
    setFormData({ ...formData, gasCostFrequency });
  };

  const setInternetCostFrequency = (internetCostFrequency) => {
    setFormData({ ...formData, internetCostFrequency });
  };

  const setTotalExpensesFrequency = (totalExpensesFrequency) => {
    setFormData({
      ...formData,
      totalExpensesFrequency,
      isDefaultTotalExpensesFrequency: "0",
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Set your property portfolio for {state.firstName} {state.lastName}{" "}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="6">
                <Label>Expense type</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {expensesCalculationMethod.map((cm) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={cm}
                      >
                        <Box
                          onClick={() =>
                            setPropertyExpensesCalculationMethod(cm)
                          }
                          key={cm}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.propertyExpensesCalculationMethod === cm
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {cm}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected
                    <strong>
                      {" "}
                      {defaultAssumptions.PropertyExpensesCalculationMethod}
                    </strong>{" "}
                    would be selected as default)
                  </Typography>
                </FormGroup>
              </Col>
            </Row>
            {isBreakdownMethod ? (
              <>
                <Row>
                  <Col md="12">
                    <Label>Strata cost</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Strata cost"
                        number={formData.strataCost}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, strataCost: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Strata cost frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setStrataCostFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.strataCostFrequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Council cost</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Council cost"
                        number={formData.councilCost}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, councilCost: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Council cost frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setCouncilCostFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.councilCostFrequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Insurnace cost</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Insurance cost"
                        number={formData.insuranceCost}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, insuranceCost: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Insurance cost frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setInsuranceCostFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.insuranceCostFrequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Maintenance cost</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Maintenance cost"
                        number={formData.maintenanceCost}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, maintenanceCost: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Maintenance cost frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setMaintenanceCostFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.maintenanceCostFrequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Land tax amount</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Land tax amount"
                        number={formData.landTaxAmount}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, landTaxAmount: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Land Tax frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setLandTaxFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.landTaxAmountFrequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Accounting cost</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Accounting cost"
                        number={formData.accountingCost}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, accountingCost: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Accounting cost frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setAccountCostFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.accountingCostFrequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Asic cost</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Asic cost"
                        number={formData.asicCost}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, asicCost: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Asic cost frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setAsicCostFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.asicCostFrequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Water cost</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Water cost"
                        number={formData.waterCost}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, waterCost: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Water cost frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setWaterCostFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.waterCostFequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Electrical cost</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Electrical cost"
                        number={formData.electricalCost}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, electricalCost: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Electrical cost frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setElectricalCostFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.electricalCostFrequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Gas cost</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Gas cost"
                        number={formData.gasCost}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, gasCost: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Gas cost frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setGasCostFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.gasCostFrequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Internet cost</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Internet cost"
                        number={formData.internetCost}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, internetCost: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Internet cost frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setInternetCostFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.internetCostFrequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col md="12">
                    <Label>Total expenses</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Total Expenses"
                        number={formData.totalExpenses}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, totalExpenses: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label>Total Expenses Frequency</Label>
                    <FormGroup>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {expensesFrequency.map((ef) => (
                          <Grid
                            sx={{ flexDirection: "column", padding: "4px" }}
                            key={ef}
                          >
                            <Box
                              onClick={() => setTotalExpensesFrequency(ef)}
                              key={ef}
                              sx={changeInPurposeBoxSx}
                              style={{
                                backgroundColor:
                                  formData.totalExpensesFrequency === ef
                                    ? "#e61a4b"
                                    : "#114253",
                              }}
                            >
                              {ef}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      <Typography variant="caption" gutterBottom>
                        (Note: If not selected
                        <strong>
                          {" "}
                          {defaultAssumptions.TotalExpensesFrequency}
                        </strong>{" "}
                        would be selected as default)
                      </Typography>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default PropertyExpenses;
