import http from "./http-common";

class AssumptionService {
  // http://localhost:5000/api/v1/assumption/default
  getDefaultAssumption() {
    const path = `/assumption/default`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/assumption/:clientId
  getClientAssumptions(clientId) {
    const path = `/assumption/client/${clientId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/assumption/default/:clientId
  getDefaultAndClientAssumptions(clientId) {
    const path = `/assumption/default/${clientId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/assumption/client/apply
  applyAssumption(data) {
    const path = `/assumption/client/apply`;
    console.log("path: ", path);
    return http.post(path, data);
  }

  // http://localhost:5000/api/v1/assumption/client/add
  addClientAssumption(data) {
    const path = `/assumption/client/add`;
    console.log("path: ", path);
    return http.post(path, data);
  }

  // http://localhost:5000/api/v1/assumption/client/add
  updateDefaultAssumption(data) {
    const path = `/assumption/default`;
    console.log("path: ", path);
    return http.put(`${path}/update`, data);
  }

  // http://localhost:5000/api/v1/assumption/client/update/:id
  updateClientAssumption(data, assumptionId) {
    const path = `/assumption/client`;
    console.log("path: ", path);
    return http.put(`${path}/update/${assumptionId}`, data);
  }

  // http://localhost:5000/api/v1/assumption/default/goal-alignment
  getGoalAlignmentDefaults() {
    const path = `/assumption/default/goal-alignment`;
    console.log("path: ", path);
    return http.get(path);
  }
}

export default new AssumptionService();
