import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import AdvisorService from "services/advisor.service";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { Container, Typography } from "@mui/material";
import { CardHeader } from "reactstrap";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

const Advisors = () => {
  const [user, setUser] = useAtom(userAtom);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const history = useHistory();

  const [clientAdvisors, setClientAdvisors] = useState([]);
  const selectedAdvisors = clientAdvisors.filter((a) => a.ClientAdvisorId);
  const notSelectedAdvisors = clientAdvisors.filter((a) => !a.ClientAdvisorId);

  useEffect(() => {
    getClientAdvisors(clientState.clientId);
  }, [clientState.clientId]);

  const getClientAdvisors = async (id) => {
    AdvisorService.getAllByClientId(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset;
          console.log("Advisors: ", result);
          setClientAdvisors(result);
        }
      })
      .catch((error) => {
        console.log(error);
        setClientAdvisors([]);
      });
  };

  const handleAddAdvisor = (typeId) => {
    const path = `/admin/scoreboard/advisors/add/type/${typeId}`;
    history.push(path);
  };

  const handleUpdateAdvisor = (advisorId, typeId) => {
    const path = `/admin/scoreboard/advisors/add/type/${typeId}/id/${advisorId}`;
    history.push(path);
  };

  return (
    <div className="content advisors_list">
      <Container>
        <Row>
          <Col md="12">
            <ClientNotSelectedDialog />
            <h2>
              Advisors - {clientState.firstName} {clientState.lastName}
            </h2>
            <Card>
              {notSelectedAdvisors && notSelectedAdvisors?.length > 0 && (
                <CardHeader>
                  <CardTitle tag="h4">Not Yet Selected</CardTitle>
                </CardHeader>
              )}
              <Row>
                {notSelectedAdvisors.map((advisor) => (
                  <Col xl="4" lg="6" md="6" xs="12">
                    <Card className="advisors">
                      <CardBody>
                        <Row>
                          <Col md="6" xs="6">
                            <Typography>{advisor.AdvisorType + " "}</Typography>
                          </Col>
                          <Col md="6" xs="6">
                            <Button
                              sx={{ float: "right" }}
                              variant="contained"
                              color="grey"
                              size="medium"
                              onClick={() =>
                                handleAddAdvisor(advisor.AdvisorTypeId)
                              }
                            >
                              Add
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card>
            <Card>
              {selectedAdvisors && selectedAdvisors?.length > 0 && (
                <CardHeader>
                  <CardTitle tag="h4">Selected</CardTitle>
                </CardHeader>
              )}
              <Row>
                {selectedAdvisors.map((advisor) => (
                  <Col xl="4" lg="6" md="6" xs="12">
                    <Card className="advisors">
                      <CardBody>
                        <Row>
                          <Col md="6" xs="6">
                            <Typography>{advisor.AdvisorType + " "}</Typography>
                          </Col>
                          <Col md="6" xs="6">
                            <Button
                              sx={{ float: "right" }}
                              variant="contained"
                              color="grey"
                              size="medium"
                              onClick={() =>
                                handleUpdateAdvisor(
                                  advisor.ClientAdvisorId,
                                  advisor.AdvisorTypeId
                                )
                              }
                            >
                              Update
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Advisors;
