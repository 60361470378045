import { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";

import Typography from "@mui/material/Typography";

const HolidayDetails = ({ formData, setFormData, defaultAssumptions }) => {
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const nextHolidayYear = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
  ];
  const howOftenHolidays = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
  ];
  // const [nextHolidaySpending, setNextHolidaySpending] = useState(0);

  const changeInPurposeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setNextHolidayYear = (nextHolidayYear) => {
    setFormData({
      ...formData,
      nextHolidayYear,
      isDefaultNextHolidayYear: "0",
    });
  };

  const setHowOftenHolidays = (holidayFrequency) => {
    setFormData({
      ...formData,
      holidayFrequency,
      isDefaultNextHolidayFrequency: "0",
    });
  };

  const setHolidaySpending = (e) => {
    // setNextHolidaySpending(e.target.value);
    setFormData({
      ...formData,
      nextHolidaySpending: e.target.value,
      isDefaultNextHolidaySpending: "0",
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4" className="card-margin">
            Set your property plan for {clientState.firstName}{" "}
            {clientState.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="12">
                <Label>Which year will your next holiday be?</Label>
                <FormGroup>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>
                      {new Date().getFullYear() +
                        defaultAssumptions.NextHolidayYear}
                    </strong>{" "}
                    would be selected as default )
                  </Typography>

                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {nextHolidayYear.map((year) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={year}
                      >
                        <Box
                          onClick={() => setNextHolidayYear(year)}
                          key={year}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.nextHolidayYear === year
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {year}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>
                  How often would you like to go on a holiday? (Years)
                </Label>
                <FormGroup>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>{defaultAssumptions.NextHolidayFrequency}</strong>{" "}
                    would be selected as default )
                  </Typography>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {howOftenHolidays.map((often) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={often}
                      >
                        <Box
                          onClick={() => setHowOftenHolidays(often)}
                          key={often}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.holidayFrequency === often
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {often}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Label>
                  How much would you spend per holiday? (Only enter if this has
                  not already been considered in your savings.)
                </Label>
                <br />
                <Typography variant="caption" gutterBottom>
                  (Note: If not selected{" "}
                  <strong>{defaultAssumptions.NextHolidaySpending}</strong>{" "}
                  would be selected as default spending )
                </Typography>

                <FormGroup className="left-m fg-margin">
                  <Slider
                    defaultValue={0}
                    value={formData.nextHolidaySpending}
                    step={100}
                    min={0}
                    max={500000}
                    valueLabelDisplay="auto"
                    onChange={(e) => {
                      setHolidaySpending(e);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default HolidayDetails;
