import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import { useHistory } from "react-router-dom";
import {
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Paper,
  Container,
} from "@mui/material";
import ProgressTrackerService from "../../services/progress-tracker.service";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

const ProgressTracker = () => {
  const [clientState] = useAtom(selectedClientAtom);
  const [userState] = useAtom(userAtom);
  const [progress, setProgress] = useState([]);
  const progressIndex = progress.findIndex((p) => !p.completed);
  const activeStep = progressIndex >= 0 ? progressIndex : progress.length;
  const numberOfCompletedSteps = progress.filter((p) => p.completed).length;
  const hasDataLoaded = progress.length > 0;
  const history = useHistory();

  const POINT_PER_MODULE = 30;
  const moduleLocationMap = new Map([
    [1, "goal-alignment"],
    [2, "family-members/add"],
    [3, "employment/add"],
    [4, "property-portfolio/add"],
    [5, "risk-profile/add"],
    [6, "money-management/add"],
    [7, "mortgage-strategy"],
    [8, "asset-selection/add"],
    [9, "portfolio-analysis"],
    [10, "property-plan/add"],
  ]);

  useEffect(() => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    // console.log('selectedClient: ', selectedClient);
    // setClientId(selectedClient.clientId);
    // if (userState.userId === 0) {

    const jwtToken = window.sessionStorage.getItem("jwt-token");
    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
    } else {
      getClientProgressById(clientState.clientId);
    }
  }, [clientState.clientId]);

  const redirectToScoreboard = () => {
    const path = "/admin/scoreboard/index";
    history.push(path);
  };

  const getClientProgressById = async (id) => {
    const pathName = window.location.pathname;
    ProgressTrackerService.getProgress(id)
      .then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset;
          if (
            result.every((p) => p?.completed) &&
            pathName !== "/admin/progress-tracker/index"
          ) {
            redirectToScoreboard();
          } else {
            setProgress(result);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="content">
        <Container>
          <ClientNotSelectedDialog />
          <h3>
            Progress Tracker for {clientState.firstName} {clientState.lastName}
          </h3>
          {hasDataLoaded ? (
            <>
              <h6 className="text-right">
                Points: {numberOfCompletedSteps * POINT_PER_MODULE}/
                {progress.length * POINT_PER_MODULE}
              </h6>
              <Row>
                <Col md="12" xs="12">
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {progress.map((step, index) => (
                      <Step key={step.id} completed={Boolean(step.completed)}>
                        <StepLabel
                          optional={
                            index === progress.length - 1 ? (
                              <Typography variant="caption">
                                Last step
                              </Typography>
                            ) : null
                          }
                        >
                          {step.module}
                        </StepLabel>
                        <StepContent>
                          <Link
                            to={`../scoreboard/${moduleLocationMap.get(
                              step.id
                            )}`}
                          >
                            <Button variant="contained">Complete Step</Button>
                          </Link>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Col>
              </Row>
              {activeStep === progress.length && (
                <Row>
                  <Col md="2" xs="2" />
                  <Col md="10" xs="10">
                    <Typography>
                      All steps completed - you&apos;re finished
                    </Typography>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <div className="loader-progress">
              <CircularProgress />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default ProgressTracker;
