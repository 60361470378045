import http from './http-common';

class PropertyPlanService {

    // http://localhost:5000/api/v1/scoreboard/property-plan/add
    add(data) {
        const path = '/scoreboard/property-plan';
        console.log('data: ', data);
        return http.post(`${path}/add`, data);
    };

    // http://localhost:5000/api/v1/scoreboard/property-plan/get/client/:id
    getAllByClientId(clientId) {
        const path = `/scoreboard/property-plan/get/client/${clientId}`;
        console.log('path: ', path);
        return http.get(path);
    };
    // http://localhost:5000/api/v1/scoreboard/property-plan/get/:id
    update(id, data) {
        const path = `/scoreboard/property-plan/update/${id}`;
        return http.put(path, data);
    }

}

export default new PropertyPlanService();
