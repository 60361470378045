import http from "./http-common";

class ContractExchangeService {
  // http://localhost:5000/api/v1/new-property/contract-exchange-data

  getAll() {
    const path = `/new-property/contract-exchange-data/all`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/new-property/contract-exchange/add
  addContractExchangeData(data) {
    const path = `/new-property/contract-exchange/add`;
    return http.post(path, data);
  }
}

export default new ContractExchangeService();
