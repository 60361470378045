import http from "./http-common";

class AdvisorService {
  // http://localhost:5000/api/v1/advisor/client/add
  add(data) {
    const path = "/advisor/client";
    console.log("data: ", data);
    return http.post(`${path}/add`, data);
  }

  // http://localhost:5000/api/v1/advisor/type/:id
  getTypeByAdvisorTypeId(advisorTypeId) {
    const path = `/advisor/type/${advisorTypeId}`;
    // console.log('path: ', `${path}/get/${clientAdvisorId}`);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/advisor/get/:id
  getByClientAdvisorId(clientAdvisorId) {
    const path = `/advisor/get/${clientAdvisorId}`;
    // console.log('path: ', `${path}/get/${clientAdvisorId}`);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/advisor/client/:clientId
  getAllByClientId(clientId) {
    const path = `/advisor/client/${clientId}`;
    // console.log('path: ', `${path}/get/${clientId}`);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/advisor/update/:id
  update(id, data) {
    const path = "/advisor/client";
    return http.put(`${path}/update/${id}`, data);
  }
}

export default new AdvisorService();
