import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import { IoClose } from "react-icons/io5";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FamilyMemberService from "../../../services/family-member.service";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Moment from "moment";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import YearSpendStep from "./wizard-steps/year-spend-step";
import NewPropertyStep from "./wizard-steps/new-property-step";
import moment from "moment";
import YearlyPlanService from "services/yearly-plan.service";
import RenovationStep from "./wizard-steps/renovation-step";
import AlterSavingsStep from "./wizard-steps/alter-savings-step";
import YearlyPlanRenovationService from "services/yearly-plan-renovation.service";
import LoanSwitchStep from "./wizard-steps/loan-switch-step";
import RefinanceService from "services/refinance.service";
import PurposeSwitchStep from "./wizard-steps/purpose-switch-step";
import YearlyPlanPurposeSwitchService from "services/yearly-plan-purpose-switch.service";
import YearlyPlanSellingService from "services/yearly-plan-selling.service";
import SellingStep from "./wizard-steps/selling-step";
import { currencyToNumber } from "utils/currency";
import "./form-wizard.css";
import { Container } from "reactstrap";
import { Tab, Tabs } from "@mui/material";
import NewSecondPropertyStep from "./wizard-steps/new-second-property-step";
import { styled } from "@mui/material/styles";
const INITIAL_DATA = {
  id: 0,
  clientPropertyId: 0,
  purchasePrice: 0,
  purchaseType: "",
  loanType: "",
  loanToValueRatio: 0,
  compoundGrowth: 0,
  rentalYield: 0,
  rentalIncrease: 0,
  interestRate: 0,
  purchaseName: "",
  placeUnderSMSF: 0,
  motorVehicleSpend: 0,
  holidaySpend: 0,
  educationSpend: 0,
  otherSpend: 0,
  clientId: 0,
  userId: 0,
};

const FormWizard = (props) => {
  const [errorOpen, setErrorOpen] = useState(false);
  const [formData, setFormData] = useState(props.data);
  // const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);

  const stepLabels = [
    "New Property",
    "Renovations",
    "Year Spend",
    "Alter Savings",
    "Loan Switch",
    "Purpose Switch",
    "Selling",
  ];
  const [tabValue, setTabValue] = useState(0);
  const steps = [
    <>
      <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
        <Tab label="Property 1" />
        <Tab label="Property 2" />
      </Tabs>
      {tabValue === 0 ? (
        <NewPropertyStep
          formData={formData || props.data}
          setFormData={setFormData}
        />
      ) : (
        <NewSecondPropertyStep
          formData={formData || props.data}
          setFormData={setFormData}
        />
      )}
    </>,
    <RenovationStep formData={formData} setFormData={setFormData} />,
    <YearSpendStep
      formData={formData || props.data}
      setFormData={setFormData}
    />,
    <AlterSavingsStep formData={formData} setFormData={setFormData} />,
    <LoanSwitchStep formData={formData} setFormData={setFormData} />,
    <PurposeSwitchStep formData={formData} setFormData={setFormData} />,
    <SellingStep formData={formData} setFormData={setFormData} />,
  ];

  const [activeStep, setActiveStep] = React.useState(props.currentStep);
  const [skipped, setSkipped] = React.useState(new Set());

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const goToStep = (step) => {
    setActiveStep(step);
  };

  const setSelectedCountry = (name) => {
    return countries.find((c) => c.label === name);
  };

  const setDateForDisplay = (selectedDate) => {
    return selectedDate.slice(0, 10).split("-").reverse().join("/");
  };

  const setDateForInsert = (selectedDate) => {
    return selectedDate.split("/").reverse().join("-");
  };

  // const getFamilyMember = () => {
  //   const familyMemberId = getByFamilyMemberId();
  //   FamilyMemberService.getByFamilyMemberId(familyMemberId)
  //     .then((response) => {
  //       if (response.data.recordset.length > 0) {
  //         const member = response.data.recordset[0];
  //         console.log("member: ", member);
  //         const familyMemberData = {
  //           id: member.FamilyMemberId,
  //           familyMemberId: member.FamilyMemberId,
  //           firstName: member.FirstName,
  //           middleName: member.MiddleName,
  //           lastName: member.LastName,
  //           phone: member.Phone,
  //           email: member.Email,
  //           isPrimary: member.IsPrimary,
  //           dateOfBirth: setDateForDisplay(member.DateOfBirth),
  //           birthCountry: setSelectedCountry(member.CountryOfBirth),
  //           gender: member.Gender,
  //           maritalStatus: member.MaritalStatus,
  //           address: member.Address,
  //           housingSituation: member.HousingSituation,
  //           dateMoved: setDateForDisplay(member.DateMoved),
  //         };
  //         setFormData(familyMemberData);
  //       } else {
  //         setFormData([]);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setFormData([]);
  //     });
  // };

  // useEffect(() => {
  //   console.log("client State: ", clientState);
  //   console.log("user State: ", userState);
  //   if (isUpdate) {
  //     getFamilyMember();
  //   }
  // }, []);

  const handleNewPropertyDataSave = () => {
    const newPropertyData = {
      ...formData,
      purchasePrice: currencyToNumber(formData.purchasePrice),
      dateUpdated: moment().format("YYYY-MM-DD"),
      clientId: clientState.clientId,
      userId: userState.userId,
    };
    if (
      newPropertyData.propertyName === null ||
      newPropertyData.propertyName === undefined
    ) {
      setErrorOpen(true);
      return;
    }
    console.log("new property data: ", newPropertyData);
    YearlyPlanService.addOrUpdateProperty(newPropertyData)
      .then((response) => {
        if (newPropertyData.propertyNameTwo) {
          const propertyTwoData = {
            ...formData,
            yearlyPlanId: formData.yearlyPlanId,
            // ADD Yearly plan property id two
            yearlyPlanPropertyId: formData.yearlyPlanPropertyIdTwo || 0,
            purchasePrice: currencyToNumber(formData.purchasePriceTwo),
            purchaseType: formData.purchaseTypeTwo,
            loanType: formData.loanTypeTwo,
            loanToValueRatio: formData.loanToValueRatioTwo,
            compoundGrowth: formData.compoundGrowthTwo,
            rentalYield: formData.rentalYieldTwo,
            rentalIncrease: formData.rentalIncreaseTwo,
            interestRate: formData.interestRateTwo,
            propertyName: formData.propertyNameTwo,
            placeUnderSMSF: formData.placeUnderSMSFTwo,
            dateUpdated: moment().format("YYYY-MM-DD"),
            clientId: clientState.clientId,
            userId: userState.userId,
          };
          YearlyPlanService.addOrUpdateProperty(propertyTwoData)
            .then((response) => {
              props.showSuccessDialog();
              proceedStep();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          props.showSuccessDialog();
          proceedStep();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleYearSpendDataSave = async () => {
    const yearSpendData = {
      ...formData,
      motorVehicleSpend: currencyToNumber(formData.motorVehicleSpend),
      holidaySpend: currencyToNumber(formData.holidaySpend),
      educationSpend: currencyToNumber(formData.educationSpend),
      otherSpend: currencyToNumber(formData.otherSpend),
      dateUpdated: moment().format("YYYY-MM-DD"),
      clientId: clientState.clientId,
      userId: userState.userId,
    };
    console.log("yearly plan data: ", yearSpendData);
    return YearlyPlanService.updateSpendings(
      formData.yearlyPlanId,
      yearSpendData
    )
      .then((response) => {
        props.showSuccessDialog();
        proceedStep();
        // props.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRenovationDataSave = async () => {
    const renovationData = {
      ...formData,
      renovationSpend: currencyToNumber(formData.renovationSpend),
      dateUpdated: moment().format("YYYY-MM-DD"),
      dateCreated: moment().format("YYYY-MM-DD"),
      clientId: clientState.clientId,
      userId: userState.userId,
    };
    console.log("renovation data: ", renovationData);
    // if (renovationData.yearlyPlanRenovationId != null) {

    // Deleting all renovations associated to the year
    try {
      await YearlyPlanRenovationService.deleteAllByYearlyPlanId(
        renovationData.yearlyPlanId
      );
    } catch (e) {
      console.log(e);
    }

    // Adding renovations for all selected properties
    const apiCalls = [];
    renovationData.renovateProperties.forEach(({ propertyId }) =>
      apiCalls.push(
        YearlyPlanRenovationService.add({
          ...renovationData,
          renovationClientPropertyId: propertyId,
        })
      )
    );
    Promise.all(apiCalls)
      .then((response) => {
        props.showSuccessDialog();
        proceedStep();
      })
      .catch((error) => {
        console.log(error);
      });

    // } else {
    //   // Add renovation
    //   const insertCalls = [];
    //   renovationData.renovateProperties.forEach((renovationClientPropertyId) =>
    //     insertCalls.push(
    //       YearlyPlanRenovationService.add({
    //         ...renovationData,
    //         renovationClientPropertyId,
    //       })
    //     )
    //   );
    //   Promise.all(insertCalls)
    //     .then((response) => {
    //       props.showSuccessDialog();
    //       proceedStep();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  };

  const handleLoanSwitchDataSave = async () => {
    const loanSwitchData = {
      ...formData,
      // clientPropertyId: formData?.refinanceClientPropertyId,
      reFinanceId: formData?.reFinanceId,
      yearlyPlanId: formData?.yearlyPlanId,
      year: formData?.year,
      refinanceLoanAmount: currencyToNumber(formData?.refinanceLoanAmount),
      loanChangeIndicator: 1, // constant for nsow
      dateUpdated: moment().format("YYYY-MM-DD"),
      dateCreated: moment().format("YYYY-MM-DD"),
      clientId: clientState.clientId,
      userId: userState.userId,
    };
    console.log("loan switch data: ", loanSwitchData);
    // if (loanSwitchData.reFinanceId != null) {

    // Deleting all loan switches associated to the year
    // Need to delete with Client Property Id and Year - There is no YearlyPlanId in Refinance table
    // It was designed much earlier
    // Add YearlyPlanId
    try {
      await RefinanceService.deleteAllByYearlyPlanId(
        loanSwitchData.yearlyPlanId
      );
    } catch (e) {
      console.log(e);
    }

    // Adding all loan switches for all selected properties
    const apiCalls = [];
    loanSwitchData.loanSwitchProperties.forEach(({ propertyId }) =>
      apiCalls.push(
        RefinanceService.add({
          ...loanSwitchData,
          clientPropertyId: propertyId,
        })
      )
    );

    Promise.all(apiCalls)
      .then((response) => {
        props.showSuccessDialog();
        proceedStep();
      })
      .catch((error) => {
        console.log(error);
      });

    // } else {
    //   // Add loan switch
    //   return RefinanceService.add(loanSwitchData)
    //     .then((response) => {
    //       props.showSuccessDialog();
    //       proceedStep();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  };

  const handleAlterSavingsDataSave = async () => {
    const alterSavingData = {
      ...formData,
      changeInSavings: currencyToNumber(formData?.changeInSavings),
      changeInCashflow: currencyToNumber(formData?.changeInCashflow),
      changeInDebt: currencyToNumber(formData?.changeInDebt),
      dateUpdated: moment().format("YYYY-MM-DD"),
      clientId: clientState.clientId,
      userId: userState.userId,
    };
    console.log("yearly plan data: ", alterSavingData);
    return YearlyPlanService.updateSavings(
      formData.yearlyPlanId,
      alterSavingData
    )
      .then((response) => {
        props.showSuccessDialog();
        proceedStep();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPurposeSwitchType = (propertyString) =>
    propertyString.toLowerCase().includes("ppor") ? "PPOR" : "INV";

  const handlePurposeSwitchDataSave = async () => {
    const purposeSwitchData = {
      ...formData,
      // clientPropertyId: formData?.purposeSwitchClientPropertyId,
      dateUpdated: moment().format("YYYY-MM-DD"),
      dateCreated: moment().format("YYYY-MM-DD"),
      clientId: clientState.clientId,
      userId: userState.userId,
    };
    console.log("purposeSwitch data: ", purposeSwitchData);
    // if (purposeSwitchData.yearlyPlanSwitchPurposeId != null) {

    // Deleting all purpose switches associated to the year
    try {
      await YearlyPlanPurposeSwitchService.deleteAllByYearlyPlanId(
        purposeSwitchData.yearlyPlanId
      );
    } catch (e) {
      console.log(e);
    }

    // Adding purpose switches for all selected properties
    const apiCalls = [];
    purposeSwitchData.purposeSwitchProperties.forEach(
      ({ propertyId, propertyName }) =>
        apiCalls.push(
          YearlyPlanPurposeSwitchService.add({
            ...purposeSwitchData,
            clientPropertyId: propertyId,
            purposeSwitchType: getPurposeSwitchType(propertyName),
          })
        )
    );

    Promise.all(apiCalls)
      .then((response) => {
        props.showSuccessDialog();
        proceedStep();
      })
      .catch((error) => {
        console.log(error);
      });
    // } else {
    //   // Add purposeSwitch
    //   return YearlyPlanPurposeSwitchService.add(purposeSwitchData)
    //     .then((response) => {
    //       props.showSuccessDialog();
    //       proceedStep();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  };

  const handleSellingDataSave = async () => {
    const sellingData = {
      ...formData,
      isSelling: 1,
      dateUpdated: moment().format("YYYY-MM-DD"),
      dateCreated: moment().format("YYYY-MM-DD"),
      clientId: clientState.clientId,
      userId: userState.userId,
    };
    console.log("selling data: ", sellingData);
    // if (sellingData.yearlyPlanSellingId != null) {

    // Deleting all selling data associated to the year
    try {
      await YearlyPlanSellingService.deleteAllByYearlyPlanId(
        sellingData.yearlyPlanId
      );
    } catch (e) {
      console.log(e);
    }

    // Adding selling info for all selected properties
    const apiCalls = [];
    sellingData.sellingProperties.forEach(({ propertyId }) =>
      apiCalls.push(
        YearlyPlanSellingService.add({
          ...sellingData,
          sellingPropertyId: propertyId,
        })
      )
    );

    Promise.all(apiCalls)
      .then((response) => {
        props.showSuccessDialog();
        proceedStep();
      })
      .catch((error) => {
        console.log(error);
      });

    // } else {
    // Add selling
    //   return YearlyPlanSellingService.add(sellingData)
    //     .then((response) => {
    //       props.showSuccessDialog();
    //       proceedStep();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  };

  const proceedStep = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        // handleNewPropertyDataSave();
        proceedStep();
        break;
      case 1:
        // handleRenovationDataSave();
        proceedStep();
        break;
      case 2:
        // handleYearSpendDataSave();
        proceedStep();
        break;
      case 3:
        // handleAlterSavingsDataSave();
        proceedStep();
        break;
      case 4:
        // handleLoanSwitchDataSave();
        proceedStep();
        break;
      case 5:
        // handlePurposeSwitchDataSave();
        proceedStep();
        break;
      case 6:
        // handleSellingDataSave();
        proceedStep();
        break;
      default:
        props.handleClose();
    }
  };

  // const handleFinish = () => {
  //   const familyMemberId = formData.id;
  //   const familyMemberData = {
  //     firstName: formData.firstName,
  //     middleName: formData.middleName,
  //     lastName: formData.lastName,
  //     phone: formData.phone,
  //     email: formData.email,
  //     isPrimary: formData.isPrimary,
  //     gender: formData.gender,
  //     maritalStatus: formData.maritalStatus,
  //     address: formData.address,
  //     housingSituation: formData.housingSituation,
  //     birthCountry: formData.birthCountry.label,
  //     dateOfBirth: setDateForInsert(formData.dateOfBirth),
  //     dateMoved: setDateForInsert(formData.dateMoved),
  //     onLoan: "1",
  //     onTitle: "0",
  //     financialDependent: "1",
  //     untilWhatAge: "65",
  //     clientId: clientState.clientId,
  //     userId: userState.userId,
  //   };
  //   console.log("family member data update: ", familyMemberData);
  //   FamilyMemberService.update(familyMemberId, familyMemberData)
  //     .then((response) => {
  //       setSuccessDialogOpen(true);
  //       setTimeout(() => {
  //         redirectToList();
  //       }, 3000);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = () => {
    console.log("active step: ", activeStep);
    // active step 0 is new property
    switch (activeStep) {
      case 0:
        handleNewPropertyDataSave();
        break;
      case 1:
        handleRenovationDataSave();
        break;
      case 2:
        handleYearSpendDataSave();
        break;
      case 3:
        handleAlterSavingsDataSave();
        break;
      case 4:
        handleLoanSwitchDataSave();
        break;
      case 5:
        handlePurposeSwitchDataSave();
        break;
      case 6:
        handleSellingDataSave();
        break;
      default:
        props.handleClose();
    }
  };

  // Handling the Escape key to close the dialog
  const handleEscapeKeyPress = (event) => {
    if (event.key === "Escape") {
      props.handleClose();
    }
  };

  return (
    <div className="content manually-entered-modal">
      {/* <Snackbar
        open={successDialogOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Family member is saved."
        action={action}
      ></Snackbar> */}
      <BootstrapDialog
        className="error-popup"
        open={errorOpen}
        onClose={() => setErrorOpen(false)}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Saving Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ padding: 12 }}>
              Property 2 can't be saved before saving property 1. Please enter
              property 1 details first!
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorOpen(false)}>OK</Button>
        </DialogActions>
      </BootstrapDialog>
      <Dialog
        open={props.open}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            minHeight: "90vh",
            maxHeight: "90vh",
          },
        }}
        onClose={props.handleClose}
        className="manually-entered-dialog-wrapper"
        onKeyDown={handleEscapeKeyPress} // This handles closing on Escape key press
      >
        <DialogTitle className="close_pad">
          <div className="close" onClick={props.handleClose}>
            <IoClose />
          </div>
          Manual Adjustments
        </DialogTitle>

        <DialogContent className="lab_margin">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {stepLabels.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step
                    key={label}
                    {...stepProps}
                    onClick={() => goToStep(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div>{steps[activeStep]}</div>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "block" }}>
          {activeStep === stepLabels.length ? (
            <React.Fragment>
              <Container>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    variant="contained"
                    color="grey"
                    size="medium"
                    onClick={props.handleClose}
                  >
                    Finish
                  </Button>
                </Box>
              </Container>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              <div className="property-modal-btn">
                <Box
                  className="layout-footer"
                  sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                >
                  <div className="layout-footer-back">
                    {activeStep > 0 && (
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                    )}
                  </div>
                  {/* <Box sx={{ flex: "1 1 auto" }} /> */}
                  <div className="layout-footer-actions">
                    <Button onClick={handleNext}>
                      {/* {activeStep === stepLabels.length - 1 ? "Finish" : "Next"} */}
                      Next
                    </Button>
                    <Button onClick={handleSubmit}>Save</Button>
                  </div>
                  {/* <Button
                    onClick={handleFinish}
                    sx={{
                      display:
                        isUpdate && activeStep !== stepLabels.length - 1
                          ? "block"
                          : "none",
                    }}
                  >
                    Finish
                  </Button> */}
                </Box>
              </div>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormWizard;
