import { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";
import { Container } from "@material-ui/core";

const CurrentCircumstances = ({ formData, setFormData }) => {
  const [selectedChangeInNext5Years, setSelectedChangeInNext5Years] =
    useState("");
  const changingCircumstances = [
    "Marital status",
    "Having children",
    "Moving location",
    "Other",
    "None",
  ];
  const riskAppetite = ["Yes", "No"];
  const [state, setState] = useAtom(selectedClientAtom);

  const changingCircumstancesBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: '20px 20px 20px 32px',
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const riskAppetiteBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: '20px 20px 20px 32px',
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setChangingCircumstances = (changeInNext5Years) => {
    setFormData({ ...formData, changeInNext5Years });
  };

  const setRiskAppetite = (isRiskAppetiteChanging) => {
    setFormData({ ...formData, isRiskAppetiteChanging });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Setup Risk Profile for {state.firstName} {state.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="12">
                <Label>
                  Can you see your current circumstances changing in the next 5
                  years?
                </Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {changingCircumstances.map((cc) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={cc}
                      >
                        <Box
                          onClick={() => setChangingCircumstances(cc)}
                          key={cc}
                          sx={changingCircumstancesBoxSx}
                          style={{
                            backgroundColor:
                              formData.changeInNext5Years === cc
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {cc}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <Label>
                  Would your current risk appetite change in these
                  circumstances?
                </Label>
                <FormGroup>
                  <Grid
                    className="small_box"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {riskAppetite.map((ra) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={ra}
                      >
                        <Box
                          onClick={() => setRiskAppetite(ra)}
                          key={ra}
                          sx={riskAppetiteBoxSx}
                          style={{
                            backgroundColor:
                              formData.isRiskAppetiteChanging === ra
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {ra}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default CurrentCircumstances;
