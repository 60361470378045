import http from "./http-common";

class PropertyStageService {
  // http://localhost:5000/api/v1/new-property/property-stage
  add(data) {
    const path = "/new-property/property-stage";
    console.log("data: ", data);
    return http.post(`${path}`, data);
  }
}

export default new PropertyStageService();
