import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";

import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import ClientService from "services/client.service";
import AssignClientToPropertyService from "services/assign-client-property";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import SaveIcon from "@mui/icons-material/Save";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { IconButton, Snackbar } from "@mui/material";
import { Container } from "@mui/material";
import { useHistory } from "react-router-dom";
import PropertySummary from "./PropertySummary";
import AddClientDialog from "ui/client/add-client-dialog";

export default function AssignClient({
  property,
  setUpdatedProperty,
  onHandleSetAssignClientDialog,
  fetchProperties,
}) {
  const [open, setOpen] = useState(true);
  const [userState, setUserState] = useAtom(userAtom);

  // const [updatedProperty, setUpdatedProperty] = useState(property);

  const [rowData, setRowData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedClientState, setSelectedClientState] =
    useAtom(selectedClientAtom);
  const history = useHistory();
  const [loginUser, setLoginUser] = useState({});
  const [searchText, setSearchText] = useState("");
  const [registerUrl, setRegisterUrl] = useState("");
  const [copied, setCopied] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleConfirmationOpen = () => setConfirmationOpen(true);
  const handleConfirmationClose = () => setConfirmationOpen(false);
  const [showAddClientDialog, setShowAddClientDialog] = useState(false);

  const [gridSortModel, setGridSortModel] = useState([]);

  useEffect(() => {
    const jwtToken = window.sessionStorage.getItem("jwt-token");
    const loginUser = JSON.parse(window.localStorage.getItem("login-user"));
    const selectedClient = JSON.parse(
      window.localStorage.getItem("selected-client")
    );

    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
    } else {
      // read from user state
      // if userId === 0  means it has lost the value
      // then push the value from localstorage to user state
      setLoginUser(loginUser);
      // if (userState.userId === 0) {
      //   setUserState(loginUser);
      // }

      console.log("Use Effer, Show Client", showAddClientDialog);
      if (!showAddClientDialog) {
        getClients();
      }
      getUserInfo(userState.userId);
    }
  }, [isDataLoaded, userState.userId, showAddClientDialog]);

  const getClients = async () => {
    // get the clients if userType is Advisor
    // get all the clients if userType is Admin
    // do not show this page if userType is Client
    // const user = JSON.parse(window.localStorage.getItem('User'));
    console.log("getClient, isAdmin", userState.isAdmin);
    console.log("getClient, isPartner", userState.isPartner);

    if (userState.isAdmin) {
      setIsAdmin(true);
      setIsPartner(false);
      ClientService.getAllByClientType("client")
        .then((response) => {
          let clients;
          if (response.data.recordset.length > 0) {
            clients = response.data.recordset.map((client) => {
              return {
                id: client.ClientId,
                firstName: client.ClientFirstName,
                lastName: client.ClientLastName,
                email: client.ClientEmail,
                clientType: client.ClientTypeDesc,
                score: client.ClientScore || 0,
                mobileNumber: client.MobileNumber,
                fullAccess: client.FullAccess === true ? "Yes" : "No",
                dateCreated: client.DateCreated
                  ? moment(client.DateCreated).format("DD MMM YY")
                  : "",
              };
            });
          }
          if (response.data.recordsets.length > 0) {
            setClientData(clients);
            setRowData(clients);
            console.log("client data: ", clients);
            setIsDataLoaded(true);
            handleGridSorting();
          } else {
            setIsDataLoaded(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setClientData([]);
          setRowData([]);
        });
    } else if (userState.isPartner) {
      setIsAdmin(false);
      setIsPartner(true);
      ClientService.getAllByClientType("client")
        .then((response) => {
          let clients = response.data.recordset.map((client) => {
            return {
              id: client.ClientId,
              firstName: client.ClientFirstName,
              lastName: client.ClientLastName,
              email: client.ClientEmail,
              clientType: client.ClientTypeDesc,
              score: client.ClientScore || 0,
              mobileNumber: client.MobileNumber,
              fullAccess: client.FullAccess === true ? "Yes" : "No",
              dateCreated: client.DateCreated
                ? moment(client.DateCreated).format("DD MMM YY")
                : "",
            };
          });
          if (response.data.recordsets.length > 0) {
            setClientData(clients);
            setRowData(clients);
            console.log("response: ", response);
            setIsDataLoaded(true);
          } else {
            setIsDataLoaded(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setClientData([]);
          setRowData([]);
        });
    } else {
      // just return
    }
  };

  function handleGridSorting() {
    setGridSortModel([{ field: "id", sort: "desc" }]);
  }

  const getUserInfo = async (userId) => {
    ClientService.getByClientId(userId)
      .then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset[0];
          setRegisterUrl(
            `${window.location.origin}/auth/client/register-client?uuid=${result.Uuid}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setClientData([]);
        setRowData([]);
      });
  };

  const columns = [
    { field: "firstName", headerName: "First name", width: 150 },
    { field: "lastName", headerName: "Last name", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    // { field: "clientType", headerName: "Client Type", width: 140 },
    { field: "mobileNumber", headerName: "Mobile Number", width: 160 },
    { field: "id", headerName: "ID", width: 70, hide: true },
  ];

  // Update Client to the DB
  const assingCleintToProperty = () => {
    const formData = {
      newPropertyId: property.id,
      clientId: selectedRow.id,
      userId: userState.userId,
    };

    AssignClientToPropertyService.assignClient(formData)
      .then(() => {
        // handleConfirmationClose();
        // setOpen(false);
        setSnackBarOpen(true);
        // onHandleSetBuyerAgentDialog(false);
        fetchProperties();
        setUpdatedProperty({
          ...property,
          currentClient: selectedRow.firstName + " " + selectedRow.lastName,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowDoubleClick = (params, event) => {
    event.preventDefault();
    console.log("Row Double clicked:", params.row.id);

    setSelectedRow(params.row);
    setConfirmationOpen(true);
  };

  const handleRowClick = (client) => {
    console.log("row: ", client.row);
    const selectedClient = {
      clientId: client.row.id,
      firstName: client.row.firstName,
      lastName: client.row.lastName,
      fullAccess: client.row.fullAccess === "Yes",
    };

    setSelectedClientState({
      clientId: selectedClient.clientId,
      firstName: selectedClient.firstName,
      lastName: selectedClient.lastName,
      fullAccess: selectedClient.fullAccess,
    });
    // window.localStorage.setItem('SelectedClient', JSON.stringify(selectedClient));
    history.push("/admin/scoreboard/index");
  };

  const handleAdd = () => {
    // const path = "/admin/client/add-client";
    // const path = "/admin/client/add-client-dialog";
    // history.push(path);
    setShowAddClientDialog(true);
  };

  const handleSearch = (text) => {
    // const textToSearch = text !== undefined ? text : searchText;
    // console.log("text ", text, searchText, textToSearch);
    setRowData(
      clientData.filter(
        (client) =>
          client.firstName.toLowerCase().includes(text) ||
          client.lastName.toLowerCase().includes(text)
      )
    );
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setCopied(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  function handleAssingClientDialogClose() {
    setOpen(false);
    onHandleSetAssignClientDialog(false);
  }

  function handleAssignClientDialogSubmit(event) {
    event.preventDefault();

    console.log("Handle Submit:", property);

    // if (
    //   property.currentBuyerAgentId === selectedNewBuyerAgentId.value ||
    //   !selectedNewBuyerAgentId.value
    // )
    //   return;

    // const formData = {
    //   newPropertyId: property.id,
    //   existingBuyerAgentId: property.currentBuyerAgentId,
    //   newBuyerAgentId: selectedNewBuyerAgentId.value,
    //   userId: userState.userId,
    // };
    // console.log("Form Data:", formData);
    // NewPropertyService.updateBuyerAgent(formData)
    //   .then(() => {
    //     setOpen(false);
    //     onHandleSetBuyerAgentDialog(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  function handleAddClientDialogClose() {
    setShowAddClientDialog(false);
    // getClients();
  }

  return (
    <Dialog
      open={open}
      onClose={handleAssingClientDialogClose}
      PaperProps={{
        style: { width: "80%", maxWidth: "none" },
      }}
      disableEscapeKeyDown
    >
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleAssingClientDialogClose}
        message="Client has been Assigned"
      ></Snackbar>

      {showAddClientDialog && (
        <AddClientDialog onHandleClose={handleAddClientDialogClose} />
      )}

      <DialogTitle>
        <span
          style={{ color: "#114253", fontSize: "20px", fontWeight: "bold" }}
        >
          Assign Client to Property
        </span>

        <IconButton
          aria-label="close"
          onClick={handleAssingClientDialogClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleAssignClientDialogSubmit}>
        <DialogContent>
          <Card>
            <CardHeader>
              <CardBody className="new-property-card-body">
                <ConfirmationDialog
                  open={confirmationOpen}
                  title="Client Update Confirmation"
                  message="Are you sure you to assign client to the property?"
                  onClose={handleConfirmationClose}
                  onConfirm={assingCleintToProperty}
                  onCancel={handleConfirmationClose}
                  confirmText="Yes"
                  cancelText="No"
                />
                <div className="content client-list-content">
                  {(isAdmin || isPartner) && (
                    <Container style={{ display: "flex", flexWrap: "wrap" }}>
                      <div
                        style={{
                          flex: 1,
                          minWidth: "200px",
                          padding: "20px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          marginRight: "5px",
                        }}
                      >
                        <PropertySummary property={property} />
                      </div>
                      <div
                        style={{
                          flex: 2,
                          minWidth: "600px",
                          padding: "20px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                        }}
                      >
                        <Row>
                          <Col md="12">
                            <Card>
                              {/* <CardTitle tag="h3">Client List</CardTitle> */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginBottom: "20px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#114253",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Client List
                                </span>

                                <div className="card_bt">
                                  <Button
                                    sx={{ float: "right" }}
                                    variant="contained"
                                    color="grey"
                                    size="medium"
                                    startIcon={<SaveIcon />}
                                    onClick={handleAdd}
                                  >
                                    ADD
                                  </Button>
                                </div>
                              </div>
                              {/* <CardHeader></CardHeader> */}
                              <CardBody>
                                <Row>
                                  <Col>
                                    <div className="client-lost-header-fields">
                                      <div
                                        className={`client-list-search ${
                                          userState.isPartner
                                            ? ""
                                            : "client-list-url-hidden"
                                        }`}
                                      >
                                        <TextField
                                          sx={{ width: "25%" }}
                                          id="search-text"
                                          label="Search"
                                          variant="standard"
                                          value={searchText}
                                          onChange={(e) => {
                                            setSearchText(e.target.value);
                                            handleSearch(
                                              e.target.value.toLowerCase()
                                            );
                                          }}
                                        />
                                        <Button
                                          // style={{ top: 7, left: 5 }}
                                          variant="contained"
                                          color="grey"
                                          size="medium"
                                          startIcon={<SearchIcon />}
                                          onClick={(e) => {
                                            handleSearch(
                                              searchText.toLowerCase()
                                            );
                                          }}
                                        >
                                          Search
                                        </Button>
                                      </div>
                                    </div>

                                    <div
                                      className={`${
                                        userState.isPartner
                                          ? "clinet-list-row-data"
                                          : ""
                                      }`}
                                    >
                                      <Row>
                                        <Col md="12">
                                          <div
                                            style={{
                                              height: "auto",
                                              width: "100%",
                                            }}
                                          >
                                            <Tooltip
                                              title="Double click to assign new client to the property."
                                              arrow
                                            >
                                              <Box>
                                                <DataGrid
                                                  rows={rowData}
                                                  columns={columns}
                                                  initialState={{
                                                    pagination: {
                                                      paginationModel: {
                                                        page: 0,
                                                        pageSize: 5,
                                                      },
                                                    },
                                                  }}
                                                  pageSizeOptions={[5, 10]}
                                                  onRowDoubleClick={
                                                    handleRowDoubleClick
                                                  }
                                                  sortModel={gridSortModel}
                                                  onSortModelChange={(
                                                    newSortModel
                                                  ) =>
                                                    setGridSortModel(
                                                      newSortModel
                                                    )
                                                  }
                                                />
                                              </Box>
                                            </Tooltip>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </Container>
                  )}
                </div>
              </CardBody>
            </CardHeader>
          </Card>
        </DialogContent>
      </form>
    </Dialog>
  );
}
