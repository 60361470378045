import http from "./http-common";

class DueDiligenceService {
  // http://localhost:5000/api/v1/new-property/due-diligence/level/:level
  getByLevel(level) {
    const path = `/new-property/due-diligence/level/${level}`;
    console.log("path: ", path);
    return http.get(path);
  }
}

export default new DueDiligenceService();
