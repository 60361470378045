import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";
import { atomWithStorage, RESET } from "jotai/utils";
import { userAtom } from "store";
import { selectedClientAtom } from "store";

function Logout() {
  const history = useHistory();
  const [clientstate, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);

  useEffect(() => {
    window.localStorage.removeItem("jwt-token");
    window.localStorage.removeItem("login-user");
    window.localStorage.removeItem("selected-client");
    window.sessionStorage.removeItem("jwt-token");
    window.sessionStorage.removeItem("login-user");
    window.sessionStorage.removeItem("selected-client");
    setClientState(RESET);
    setUserState(RESET);
    history.push("/auth/login");
  }, []);

  return;
}

export default Logout;
