import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import AdvisorService from "services/advisor.service";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Checkbox from "@mui/material/Checkbox";
import ReactDatetime from "react-datetime";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Container } from "@mui/material";

const AdvisorsAdd = () => {
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const history = useHistory();
  const isUpdate =
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/").length === 8
      ? true
      : false;

  const [clientAdvisor, setClientAdvisor] = useState({});
  const [advisorTypeDetails, setAdvisorTypeDetails] = useState({});
  const vowels = new Array("a", "e", "i", "o", "u");

  useEffect(() => {
    const advisorTypeId = getAdvisorTypeId();
    if (isUpdate) {
      getClientAdvisor(getByClientAdvisorId());
    }
    if (advisorTypeId != null) getClientAdvisorType(advisorTypeId);
  }, [window.location.pathname]);

  const getAdvisorTypeId = () => {
    return window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/")[5];
  };

  const getByClientAdvisorId = () => {
    return window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/")[7];
  };

  const getClientAdvisorType = async (id) => {
    AdvisorService.getTypeByAdvisorTypeId(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset[0];
          console.log("Advisors: ", result);
          setAdvisorTypeDetails(result);
        }
      })
      .catch((error) => {
        console.log(error);
        setAdvisorTypeDetails([]);
      });
  };

  const getClientAdvisor = async (id) => {
    AdvisorService.getByClientAdvisorId(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset[0];
          console.log("Advisors: ", result);
          setClientAdvisor({
            ...result,
            WorkedSince: moment(result.WorkedSince).format("DD/MM/YYYY"),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setClientAdvisor([]);
      });
  };

  const addClientAdvisor = (data) => {
    AdvisorService.add(data)
      .then(() => {
        setSaveDialogOpen(true);
        setTimeout(() => {
          redirectToList();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateClientAdvisor = (clientAdvisorId, data) => {
    AdvisorService.update(clientAdvisorId, data)
      .then((response) => {
        setSaveDialogOpen(true);
        setTimeout(() => {
          redirectToList();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        setClientAdvisor([]);
      });
  };

  const redirectToList = () => {
    const path = "/admin/scoreboard/advisors/list";
    history.push(path);
  };

  const getRightArticle = () =>
    vowels.some((v) => advisorTypeDetails?.AdvisorType?.startsWith(v))
      ? "an"
      : "a";

  const setDateForInsert = (selectedDate) => {
    return selectedDate.split("/").reverse().join("-");
  };

  const handleSave = () => {
    let workedSinceDate = null;
    if (clientAdvisor.WorkedSince) {
      workedSinceDate =
        clientAdvisor.WorkedSince.split("/").length === 3
          ? setDateForInsert(clientAdvisor.WorkedSince)
          : clientAdvisor.WorkedSince;
      console.log("worked since: ", workedSinceDate);
    }

    const newAdvisor = {
      clientAdvisorTypeId: advisorTypeDetails.AdvisorTypeId,
      clientUserId: clientAdvisor.ClientUserId,
      needAdvisor: clientAdvisor.NeedAdvisor ? 1 : 0,
      isNoConversationRequired: clientAdvisor.IsNoConversationRequired ? 1 : 0,
      companyName: clientAdvisor.CompanyName,
      contactName: clientAdvisor.ContactName,
      contactEmail: clientAdvisor.ContactEmail,
      contactPhone: clientAdvisor.ContactPhone,
      businessAddress: clientAdvisor.BusinessAddress || null,
      workedSince: workedSinceDate,
      isAdviced: clientAdvisor.IsAdviced ? 1 : 0,
      dateUpdated: moment().format("YYYY-MM-DD"),
      userId: clientState.clientId,
    };
    console.log("new advisor: ", newAdvisor);
    console.log(
      "worked since ",
      clientAdvisor.WorkedSince,
      moment(clientAdvisor.WorkedSince)
    );
    if (!clientAdvisor.ClientAdvisorId) {
      addClientAdvisor(newAdvisor);
    } else {
      updateClientAdvisor(clientAdvisor.ClientAdvisorId, newAdvisor);
    }
  };

  const handleClose = () => {
    setSaveDialogOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content advisor-add-content">
      <Snackbar
        open={saveDialogOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Advisor is saved."
        action={action}
      />
      <Container>
        <Row>
          <Col md="12">
            <Form className="form-horizontal" id="TypeValidation">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    {advisorTypeDetails.AdvisorType}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="advisor-add-checboxs">
                    <Row>
                      <Col md="12">
                        <div className="Checkbox">
                          <FormGroup check className="text-left">
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={
                                  clientAdvisor?.NeedAdvisor ? true : false
                                }
                                onChange={(e) =>
                                  setClientAdvisor({
                                    ...clientAdvisor,
                                    NeedAdvisor: e.target.checked ? 1 : 0,
                                  })
                                }
                              />
                              <span className={`form-check-sign`} />
                              Don't have {getRightArticle()}{" "}
                              {advisorTypeDetails.AdvisorType?.toLowerCase()}?
                              Click here and we will find a recommendation for
                              you
                            </Label>
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="Checkbox">
                          <FormGroup check className="text-left">
                            {/* <Checkbox
                        checked={
                          clientAdvisor?.IsNoConversationRequired ? true : false
                        }
                        onChange={(e) =>
                          setClientAdvisor({
                            ...clientAdvisor,
                            IsNoConversationRequired: e.target.checked ? 1 : 0,
                          })
                        }
                      /> */}

                            <Label check>
                              <Input
                                type="checkbox"
                                checked={
                                  clientAdvisor?.IsNoConversationRequired
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  setClientAdvisor({
                                    ...clientAdvisor,
                                    IsNoConversationRequired: e.target.checked
                                      ? 1
                                      : 0,
                                  })
                                }
                              />
                              <span className={`form-check-sign`} />
                              Don't want to speak to {getRightArticle()}{" "}
                              {advisorTypeDetails.AdvisorType?.toLowerCase()}?
                              Click here
                            </Label>
                          </FormGroup>
                          {/* <Label>
                      Don't want to speak to {getRightArticle()}{" "}
                      {advisorTypeDetails.AdvisorType?.toLowerCase()}? Click
                      here
                    </Label> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="advisors-fields">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>Company name</Label>
                          <Input
                            placeholder="Company name"
                            type="text"
                            value={clientAdvisor?.CompanyName}
                            onChange={(e) =>
                              setClientAdvisor({
                                ...clientAdvisor,
                                CompanyName: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {/* First Input */}
                      <Col md="4">
                        <FormGroup>
                          <Label>Contact name</Label>
                          <Input
                            placeholder="Contact name"
                            type="text"
                            value={clientAdvisor?.ContactName}
                            onChange={(e) =>
                              setClientAdvisor({
                                ...clientAdvisor,
                                ContactName: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      {/* Second Input */}
                      <Col md="4">
                        <FormGroup>
                          <Label>Contact email</Label>
                          <Input
                            placeholder="Contact email"
                            type="email"
                            value={clientAdvisor?.ContactEmail}
                            onChange={(e) =>
                              setClientAdvisor({
                                ...clientAdvisor,
                                ContactEmail: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* Third Input */}
                      <Col md="4">
                        <FormGroup>
                          <Label>Contact phone</Label>
                          <Input
                            placeholder="Contact phone"
                            type="text"
                            value={clientAdvisor?.ContactPhone}
                            onChange={(e) =>
                              setClientAdvisor({
                                ...clientAdvisor,
                                ContactPhone: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col md="4">
                        <FormGroup>
                          <Label>Contact phone</Label>
                          <Input
                            placeholder="Contact phone"
                            type="text"
                            value={clientAdvisor?.ContactPhone}
                            onChange={(e) =>
                              setClientAdvisor({
                                ...clientAdvisor,
                                ContactPhone: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col> */}

                      <Col md="4">
                        <FormGroup>
                          <Label>Business address</Label>
                          <Input
                            placeholder="Business address"
                            type="text"
                            value={clientAdvisor?.BusinessAddress}
                            onChange={(e) =>
                              setClientAdvisor({
                                ...clientAdvisor,
                                BusinessAddress: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>Worked with since</Label>
                          <ReactDatetime
                            locale="en-gb"
                            inputProps={{
                              className: "form-control",
                              placeholder: "Worked with since",
                            }}
                            value={
                              clientAdvisor?.WorkedSince
                                ? clientAdvisor?.WorkedSince
                                : ""
                            }
                            onChange={(e) => {
                              console.log("date change ", e);
                              setClientAdvisor({
                                ...clientAdvisor,
                                WorkedSince: moment(e).format("DD/MM/YYYY"),
                              });
                            }}
                            timeFormat={false}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col md="12">
                      <div className="Checkbox">
                        <FormGroup check className="text-left">
                          {/* <Checkbox
                        checked={clientAdvisor?.IsAdviced ? true : false}
                        onChange={(e) =>
                          setClientAdvisor({
                            ...clientAdvisor,
                            IsAdviced: e.target.checked ? 1 : 0,
                          })
                        }
                      /> */}
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={clientAdvisor?.IsAdviced ? true : false}
                              onChange={(e) =>
                                setClientAdvisor({
                                  ...clientAdvisor,
                                  IsAdviced: e.target.checked ? 1 : 0,
                                })
                              }
                            />
                            <span className={`form-check-sign`} />
                            Have you seeked advice regarding property investing?
                          </Label>
                        </FormGroup>
                        {/* <Label>
                      Have you seeked advice regarding property investing?
                    </Label> */}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-right">
                  <Button
                    variant="contained"
                    color="grey"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={handleSave}
                  >
                    SAVE
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdvisorsAdd;
