import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import { Container } from "@mui/material";

const UserDetails = ({
  formData,
  setFormData,
  validationErrorsFamilyMemberDetails,
  isPrimaryMember,
}) => {
  // console.log('user details firstName: ', firstName);
  // console.log('user details middleName: ', middleName);
  // console.log('user details lastName: ', lastName);

  const handleChange = (e) => {
    setFormData({ ...formData, memberType: e.target.value });
  };

  return (
    <>
      {/* <TextField id="firstName" label="First Name" variant="outlined" autoFocus required
                value={formData.firstName}
                onChange={e => setFormData({ ...formData, firstName: e.target.value })} />
            <TextField id="middleName" label="Middle Name" variant="outlined" value={formData.middleName}
                onChange={e => setFormData({ ...formData, middleName: e.target.value })} />
            <TextField id="lastName" label="Last Name" variant="outlined" value={formData.lastName} required
                onChange={e => setFormData({ ...formData, lastName: e.target.value })} /> */}

      <Card>
        <CardHeader>
          <CardTitle tag="h4">Add Family Member</CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    placeholder="First Name"
                    type="text"
                    value={formData.firstName}
                    autoFocus
                    required
                    disabled={formData.memberType === "Primary"}
                    onChange={(e) =>
                      setFormData({ ...formData, firstName: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Middle Name</Label>
                  <Input
                    placeholder="Middle Name"
                    type="text"
                    value={formData.middleName}
                    onChange={(e) =>
                      setFormData({ ...formData, middleName: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Label>Last Name</Label>
                <FormGroup>
                  <Input
                    placeholder="Last Name"
                    type="text"
                    disabled={formData.memberType === "Primary"}
                    value={formData.lastName}
                    onChange={(e) =>
                      setFormData({ ...formData, lastName: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <Label>Member Type</Label>
                <FormGroup className="member_type_fields">
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={formData.memberType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Primary"
                      control={<Radio />}
                      disabled={true}
                      label="Primary"
                    />
                    <FormControlLabel
                      value="Secondary"
                      control={<Radio />}
                      disabled={true}
                      label="Secondary"
                    />
                  </RadioGroup>
                </FormGroup>
              </Col>
            </Row>
            {/* <Row>
               <div className="Checkbox">
                          <FormGroup check className="text-left">
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={
                                  clientAdvisor?.NeedAdvisor ? true : false
                                }
                                onChange={(e) =>
                                  setClientAdvisor({
                                    ...clientAdvisor,
                                    NeedAdvisor: e.target.checked ? 1 : 0,
                                  })
                                }
                              />
                              <span className={`form-check-sign`} />
                              Don't have {getRightArticle()}{" "}
                              {advisorTypeDetails.AdvisorType?.toLowerCase()}?
                              Click here and we will find a recommendation for
                              you
                            </Label>
                          </FormGroup>
                        </div> 
            </Row> */}
            <Row>
              <Col md="6">
                <FormGroup>
                  {validationErrorsFamilyMemberDetails.phone ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: 600,
                        padding: "0px 0px 10px 0px",
                      }}
                    >
                      {validationErrorsFamilyMemberDetails.phone} Phone
                    </span>
                  ) : (
                    <Label>Phone</Label>
                  )}

                  <Input
                    placeholder="Phone"
                    disabled={formData.memberType === "Primary"}
                    type="text"
                    value={formData.phone}
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  {validationErrorsFamilyMemberDetails.email ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: 600,
                        padding: "0px 0px 10px 0px",
                      }}
                    >
                      {validationErrorsFamilyMemberDetails.email} Email
                    </span>
                  ) : (
                    <Label>Email</Label>
                  )}
                  <Input
                    placeholder="Email"
                    type="email"
                    value={formData.email}
                    disabled={formData.memberType === "Primary"}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default UserDetails;
