import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import "react-datetime/css/react-datetime.css";
import "dayjs/locale/en-gb";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Moment from "moment";
import "moment/locale/en-gb";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import { useHistory } from "react-router-dom";

import EmploymentService from "services/employment.service";
import defaultImage from "assets/img/default-avatar.png";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../store";
import DeleteDialog from "components/Dialog/DeleteDialog";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

const Employment = () => {
  const [requiredState, setrequiredState] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const employmentTypes = [
    "Full time",
    "Part time",
    "Self employed",
    "Director",
    "Partner",
    "Retired",
  ];
  const history = useHistory();

  let yellow = "#fbc658";
  const [familyEmployment, setFamilyEmployment] = useState([]);
  // const [clientId, setClientId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [open, setOpen] = useState(false);
  const [employmentId, setEmploymentId] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [confirmationOpen, setConfirmationOpen] = useState(
    Array(familyEmployment.length).fill(false)
  );

  useEffect(() => {
    //const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    //console.log('selectedClient: ', selectedClient);
    // setClientId(state?.clientId);
    getFamilyEmployments(clientState?.clientId);
  }, [isDataLoaded, clientState?.clientId]);

  const getFamilyEmployments = async (id) => {
    console.log("id: ", id);
    return EmploymentService.getAllByClientId(id)
      .then((response) => {
        console.log("response: ", response);
        if (response.data.recordset.length > 0) {
          console.log("response: ", response.data.recordset[0]);
          const employment = response.data.recordset;
          console.log(employment);
          setFamilyEmployment(employment);
          setIsDataLoaded(true);
        } else {
          setFamilyEmployment([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setFamilyEmployment([]);
        setIsDataLoaded(false);
      });
  };

  const employmentTypeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "5%",
    height: "65px",
    width: "190px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const handleConfirmationOpen = (index) => {
    const tempOpens = [...confirmationOpen];
    tempOpens[index] = true;
    setConfirmationOpen(tempOpens);
  };

  const handleConfirmationClose = (index) => {
    const tempOpens = [...confirmationOpen];
    tempOpens[index] = false;
    setConfirmationOpen(tempOpens);
  };

  const setEmploymentType = (et) => {
    setSelectedEmploymentType(et);
  };

  const handleDateChange = (e) => {
    Moment.locale("en-au");
    const selectedDate = Moment(e).format("DD/MM/yyyy");
    setStartDate(selectedDate);
  };

  const handleJobSecurityChange = (e) => {
    setJobSecurityRating(e.target.value);
  };

  const handleStillWorkHere = () => {
    // console.log('still work here: ', e.target.checked);
    // const stillWorkHere = e.target.checked === 'true' ? true : false;
    setStillWorkHere(!stillWorkHere);
  };

  const setDateForDisplay = (selectedDate) => {
    return selectedDate.slice(0, 10).split("-").reverse().join("/");
  };

  const setDateForInsert = (selectedDate) => {
    return selectedDate.split("/").reverse().join("-");
  };

  const handleFamilyMemberChange = (e) => {
    console.log(e);
    setSelectedFamilyMemberId(e);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    const path = "/admin/scoreboard/employment/add";
    history.push(path);
  };

  const handleClick = (e, employmentId) => {
    console.log("employment Id: ", employmentId);
    e.preventDefault();
    const path = `/admin/scoreboard/employment/add/${employmentId}`;
    history.push(path);
  };

  const handleDelete = async (employmentId) => {
    return EmploymentService.delete(employmentId).then((response) => {
      console.log("response: ", response.data.recordset);
      getFamilyEmployments(clientState?.clientId).then(() =>
        setSuccessDialogOpen(true)
      );
    });
  };

  const handleSuccessDialogClose = () => setSuccessDialogOpen(false);

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSuccessDialogClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <div className="content employment-list-content">
      <ClientNotSelectedDialog />
      <Snackbar
        open={successDialogOpen}
        autoHideDuration={5000}
        onClose={handleSuccessDialogClose}
        message="Employment is deleted."
        action={action}
      />
      <Container>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Employment for {clientState?.firstName}{" "}
                  {clientState?.lastName}
                </CardTitle>
                <div className="card_bt">
                  <Button
                    sx={{ float: "right" }}
                    variant="contained"
                    color="grey"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={handleAdd}
                  >
                    ADD
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  {familyEmployment.map((employment, index) => (
                    <Col lg="3" md="6" key={index}>
                      <Card className="employment_card member_card card-pricing">
                        <CardHeader className="card-cross-btn">
                          <IconButton
                            aria-label="delete"
                            style={{
                              marginLeft: "auto",
                              padding: 0,
                              color: "#e61a4b",
                            }}
                            // onClick={() => handleConfirmationOpen(index)}
                          >
                            <CloseSharpIcon />
                          </IconButton>
                        </CardHeader>
                        <Link to={`./add/${employment.EmploymentId}`}>
                          <CardBody>
                            <div className="picture-container">
                              <div className="picture">
                                <img
                                  src={imagePreviewUrl}
                                  className="picture-src"
                                  alt="..."
                                />
                              </div>
                            </div>
                            <ul>
                              <li>
                                {" "}
                                {employment?.firstName} {employment?.lastName}
                              </li>
                              <li>{employment.OccupationTitle}</li>
                              <li>{employment.EmploymentType}</li>
                              <li>{employment.StartDate.slice(0, 10)}</li>
                            </ul>
                          </CardBody>
                        </Link>
                        <CardFooter>
                          {/* <div className="btn_group"> */}
                          {/* <Button
                              className="btn-round"
                              color="primary"
                              href="#pablo"
                              onClick={(e) =>
                                handleClick(e, employment.EmploymentId)
                              }
                            >
                              Edit
                            </Button> */}
                          <DeleteDialog
                            className="family_member_dialog"
                            onDelete={() =>
                              handleDelete(employment.EmploymentId)
                            }
                          />
                          {/* </div> */}
                        </CardFooter>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Employment;
