import http from "./http-common";

class AssignClientToPropertyService {
  // http://localhost:5000/api/v1/new-property/assign-client/add
  assignClient(data) {
    const path = `/new-property/assign-client/add`;
    console.log(path);
    return http.post(`${path}`, data);
  }

  // // http://localhost:5000/api/v1/new-property/current-cleint/
  // getCurrentClient(newPropertyId) {
  //   const path = `/new-property/current-client/${newPropertyId}`;
  //   console.log(path);
  //   return http.get(`${path});
  // }
}

export default new AssignClientToPropertyService();
