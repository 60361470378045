import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
} from "reactstrap";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import FamilyMemberService from "../../../../services/family-member.service";
import "moment/locale/en-gb";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";
import { Container } from "@material-ui/core";

const RiskPersonality = ({ formData, setFormData }) => {
  const [selectedFamilyMember, setSelectedFamilyMember] = useState("");
  const [familyMemberNames, setFamilyMemberNames] = useState([]);
  const [selectedFamilyMemberId, setSelectedFamilyMemberId] = useState({});
  const [clientId, setClilentId] = useState(1);
  const [userId, setUserId] = useState(1);
  const [selectedWealthPurpose, setSelectedWealthPurpose] = useState(0);
  const [state, setState] = useAtom(selectedClientAtom);
  const weathPurpose = [
    "Financial independence",
    "Financial freedom",
    "Financial abundance",
  ];
  const capitalGrowthOrCashflow = [
    "Captial growth",
    "Cashflow",
    "Mixed portfolio",
  ];
  const investorType = ["Renovator", "Buy & sell", "Sit & hold"];

  const wealthPurposeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const capitalGrowthBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const investorTypeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setWealthPurpose = (wealthPurpose) => {
    setFormData({ ...formData, wealthPurpose });
  };

  const setCapitalGrowth = (capitalGrowthOrCashflow) => {
    setFormData({ ...formData, capitalGrowthOrCashflow });
  };

  const setInvestorType = (investorType) => {
    setFormData({ ...formData, investorType });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Card className="cccard">
        <CardHeader>
          <CardTitle tag="h4">
            Setup Risk Profile for {state.firstName} {state.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="8">
                <Label>What would best describe wealth to you?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {weathPurpose.map((wp) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={wp}
                      >
                        <Box
                          onClick={() => setWealthPurpose(wp)}
                          key={wp}
                          sx={wealthPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.wealthPurpose === wp
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {wp}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Label>Would you prefer capital growth or cashflow?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {capitalGrowthOrCashflow.map((cg) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={cg}
                      >
                        <Box
                          onClick={() => setCapitalGrowth(cg)}
                          key={cg}
                          sx={capitalGrowthBoxSx}
                          style={{
                            backgroundColor:
                              formData.capitalGrowthOrCashflow === cg
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {cg}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Label>Which type of investor best describes you?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {investorType.map((it) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={it}
                      >
                        <Box
                          onClick={() => setInvestorType(it)}
                          key={it}
                          sx={investorTypeBoxSx}
                          style={{
                            backgroundColor:
                              formData.investorType === it
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {it}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="4" />
            <Col md="8"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default RiskPersonality;
