import http from "./http-common";

class ProgressTrackerService {
  // http://localhost:5000/api/v1/progress-tracker/progress/:clientId
  getProgress(clientId) {
    const path = "/progress-tracker/progress";
    return http.get(`${path}/${clientId}`);
  }
}

export default new ProgressTrackerService();
