import { FamilySaving, FamilyIncome, RiskPersonality } from "./index";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import RiskProfileService from "../../../services/risk-profile.service";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Moment from "moment";
import CurrentCircumstances from "./wizard-steps/current-circumstances";
import RiskAppetite from "./wizard-steps/risk-appetite";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import { Container } from "@mui/material";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";
import AssumptionService from "../../../services/assumption.service";

const INITIAL_DATA = {
  id: 0,
  wealthPurpose: "",
  familyMemberId: 0,
  capitalGrowthOrCashflow: "",
  investorType: "",
  changeInNext5Years: "",
  isRiskAppetiteChanging: "",
  expensivePrice: 200000,
  preferredLVR: 0,
  monthsOfSafetyBuffer: 0,
  clientId: 0,
  userId: 0,
  isDefaultSafetyBufferMonths: "1",
};

const RiskProfileAdd = () => {
  const [formData, setFormData] = useState(INITIAL_DATA);
  const [open, setOpen] = useState(false);
  const [clientId, setClientId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [isUpdate, setIsUpdate] = useState(0);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const history = useHistory();

  const [defaultAssumptions, setDefaultAssumptions] = useState({});

  const getDefaultAssumptions = async () => {
    let defaultAssumptions = {};
    AssumptionService.getDefaultAssumption()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          let data = response.data.recordset[0];
          defaultAssumptions = { ...data };
          setDefaultAssumptions(defaultAssumptions);
          setIsDataLoaded(true);
          console.log("Assumption-", defaultAssumptions);
        }
      })
      .catch((error) => {
        console.log(error);
        setDefaultAssumptions(defaultAssumptions);
      });
  };

  // const getRiskProfileId = () => {
  //     return window.location.pathname
  //         .substr(1, window.location.pathname.length)
  //         .split('/')[4];
  // }

  console.log(
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/").length
  );

  const stepLabels = [
    "Risk Personality",
    "Current Circusmtances",
    "Risk Appetite",
  ];

  const steps = [
    // isDataLoaded &&
    <RiskPersonality formData={formData} setFormData={setFormData} />,
    <CurrentCircumstances formData={formData} setFormData={setFormData} />,

    <RiskAppetite
      formData={formData}
      setFormData={setFormData}
      defaultAssumptions={defaultAssumptions}
    />,
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const goToStep = (step) => {
    setActiveStep(step);
  };

  const redirectToList = () => {
    const path = "/admin/scoreboard/risk-profile/add";
    history.push(path);
  };

  const redirectToTracker = () => {
    const path = "/admin/progress-tracker/index";
    history.push(path);
  };

  const getClientRiskProfile = async (id) => {
    // const familyMemberId = getRisk();
    RiskProfileService.getAllByClientId(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const riskProfile = response.data.recordset[0];
          console.log("risk profile: ", riskProfile);
          const riskProfileData = {
            id: riskProfile.RiskProfileId,
            wealthPurpose: riskProfile.WealthPurpose,
            capitalGrowthOrCashflow: riskProfile.CapitalGrowthOrCashFlow,
            investorType: riskProfile.InvestorType,
            changeInNext5Years: riskProfile.ChangeInNext5Years,
            isRiskAppetiteChanging:
              riskProfile.IsRiskAppetiteChanging === true ? "Yes" : "No",
            expensivePrice: riskProfile.ExpensivePrice,
            preferredLVR: riskProfile.PreferredLVR,
            monthsOfSafetyBuffer: riskProfile.SafetyBufferMonths,
            isDefaultSafetyBufferMonths:
              riskProfile.IsDefaultSafetyBufferMonths === true ? "1" : "0",
          };
          console.log("risk profile: ", riskProfileData);
          setFormData(riskProfileData);
          setIsUpdate(1);
        } else {
          setFormData({ ...INITIAL_DATA });
          setIsUpdate(0);
        }
      })
      .catch((error) => {
        console.log(error);
        setFormData({ ...INITIAL_DATA });
      });
  };

  useEffect(() => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    // setClientId(selectedClient.clientId);
    // setUserId(selectedClient.clientId);
    getClientRiskProfile(clientState.clientId);
  }, [clientState.clientId]);

  useEffect(() => {
    if (!isDataLoaded) {
      getDefaultAssumptions();
    }
  }, [isDataLoaded]);

  useEffect(() => {
    if (activeStep != 0) {
      document
        ?.getElementById(`step${activeStep}`)
        ?.scrollIntoView({ behavior: "smooth" });
    }

    return () => {};
  }, [activeStep]);

  const handleNext = () => {
    const riskProfileId = formData.id;
    console.log("risk profile id: ", riskProfileId);
    const riskProfileData = {
      wealthPurpose: formData.wealthPurpose,
      capitalGrowthOrCashflow: formData.capitalGrowthOrCashflow,
      investorType: formData.investorType,
      changeInNext5Years: formData.changeInNext5Years,
      isRiskAppetiteChanging: formData.isRiskAppetiteChanging,
      expensivePrice:
        formData.expensivePrice === undefined
          ? 200000
          : formData.expensivePrice,
      preferredLVR: formData.preferredLVR,
      monthsOfSafetyBuffer:
        formData.isDefaultSafetyBufferMonths === "1"
          ? defaultAssumptions.SafetyBufferMonths
          : formData.monthsOfSafetyBuffer,
      isDefaultSafetyBufferMonths: formData.isDefaultSafetyBufferMonths,
      clientId: clientState.clientId,
      userId: userState.userId,
    };
    console.log("Risk profile data: ", riskProfileData);
    if (activeStep === stepLabels.length - 1) {
      if (riskProfileId === undefined || riskProfileId === 0) {
        console.log("Risk profile Id: ", riskProfileId);
        RiskProfileService.add(riskProfileData)
          .then((response) => {
            setOpen(true);
            setTimeout(() => {
              redirectToTracker();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        RiskProfileService.update(riskProfileId, riskProfileData)
          .then((response) => {
            setOpen(true);
            setTimeout(() => {
              redirectToList();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleFinish = () => {
    const riskProfileId = formData.id;
    const riskProfileData = {
      wealthPurpose: formData.wealthPurpose,
      capitalGrowthOrCashflow: formData.capitalGrowthOrCashflow,
      investorType: formData.investorType,
      changeInNext5Years: formData.changeInNext5Years,
      isRiskAppetiteChanging: formData.isRiskAppetiteChanging,
      expensivePrice: formData.expensivePrice,
      preferredLVR: formData.preferredLVR,
      monthsOfSafetyBuffer:
        formData.monthsOfSafetyBuffer === 0
          ? defaultAssumptions.SafetyBufferMonths
          : formData.monthsOfSafetyBuffer,
      isDefaultSafetyBufferMonths: formData.isDefaultSafetyBufferMonths,
      clientId: clientState.clientId,
      userId: userState.userId,
    };
    RiskProfileService.update(riskProfileId, riskProfileData)
      .then((response) => {
        setOpen(true);
        setTimeout(() => {
          redirectToList();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content">
      <ClientNotSelectedDialog />
      <Container>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message="Risk profile is saved."
          action={action}
        ></Snackbar>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {stepLabels.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step
                  id={`step${index}`}
                  key={label}
                  {...stepProps}
                  onClick={() => goToStep(index)}
                  style={{ cursor: "pointer" }}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>{steps[activeStep]}</div>

          {activeStep === stepLabels.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  variant="contained"
                  color="grey"
                  size="medium"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              <Box
                className="ff layout-footer"
                sx={{ display: "flex", flexDirection: "row", pt: 2 }}
              >
                <div className="layout-footer-back">
                  <Button
                    variant="contained"
                    color="grey"
                    size="medium"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
                {/* <Box sx={{ flex: "1 1 auto" }} /> */}
                <div className="layout-footer-actions">
                  <Button
                    variant="contained"
                    color="grey"
                    size="medium"
                    onClick={handleNext}
                  >
                    {activeStep === stepLabels.length - 1 ? "Finish" : "Next"}
                  </Button>
                  <Button
                    className={`${
                      isUpdate && activeStep !== stepLabels.length - 1
                        ? "hidden-finish-btn"
                        : "show-finish-btn"
                    }`}
                    variant="contained"
                    color="grey"
                    size="medium"
                    onClick={handleFinish}
                    sx={{
                      display:
                        isUpdate && activeStep !== stepLabels.length - 1
                          ? "block"
                          : "none",
                    }}
                  >
                    Finish
                  </Button>
                </div>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default RiskProfileAdd;
