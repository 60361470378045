import http from './http-common';

class MultipleInputService {
  getThreeInputsForList(data) {
    return http.post('/multipleInput/list',data);
  }
  addMultipleInputs(data) {
    return http.post('/multipleInput/add',data);
  }
  editMultipleInputs(data) {
    return http.put('/multipleInput/update',data);
  }
  
  getDataByCondition(data) {
    return http.post('/multipleInput/list/byCondition',data);
  }
  
}

export default new MultipleInputService();
