/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useAtom } from "jotai";
import { userAtom } from "../../store";
import ClientService from "../../services/security";
import { Container } from "@mui/material";

function UserProfile() {
  const [userState, setUserState] = useAtom(userAtom);

  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    if (userState.userId) {
      getClientDetails(userState.userId);
    }
  }, [userState]);

  const getClientDetails = async (clientId) => {
    ClientService.getClientDetails(clientId)
      .then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset[0];
          setUserDetails(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="content my-profile-contnet">
        <Container>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">My Profile</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>Username</label>
                          <Input
                            value={userDetails?.UserName}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Email address
                          </label>
                          <Input
                            type="email"
                            value={userDetails?.ClientEmail}
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            value={userDetails?.ClientFirstName}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Last Name</label>
                          <Input
                            value={userDetails?.ClientLastName}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label>Client Type</label>
                          <Input
                            value={userDetails.ClientTypeDesc}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label>Mobile Number</label>
                          <Input
                            value={userDetails.MobileNumber}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default UserProfile;
