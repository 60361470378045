import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import SaveIcon from "@mui/icons-material/Save";

import assumptionService from "../../../services/assumption.service";
import Button from "@mui/material/Button";
import { useAtom } from "jotai";
import { userAtom, selectedClientAtom } from "../../../store";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import Select from "react-select";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";
import { currencyToNumber } from "utils/currency";
import { formatCurrency } from "utils/currency";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";

const ClientAssumptions = () => {
  const [clientAssumptions, setClientAssumptions] = useState([]);
  const [assumptions, setAssumptions] = useState([]);
  const [selectedAssumption, setSelectedAssumption] = useState({});
  const [state, setState] = useAtom(userAtom);
  const [clientstate, setClientState] = useAtom(selectedClientAtom);
  const [open, setOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const jwtToken = window.sessionStorage.getItem("jwt-token");
    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
    } else {
      getClientAssumptions(clientstate.clientId);
    }
  }, [clientstate]);

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const redirectToCompass = () => {
    const path = "/admin/compass/compass";
    history.push(path);
  };

  const redirectToAddAssumption = () => {
    const path = "/admin/assumption/add";
    history.push(path);
  };

  const getAssumptionId = () => {
    return window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/")[3];
  };

  const getClientAssumptions = async (clientId) => {
    const assumptionId = getAssumptionId();
    assumptionService
      .getClientAssumptions(clientId)
      .then((response) => {
        const assumptions = response.data.recordset.map((a) => ({
          ...a,
          SuperContributionCapPerPerson: formatCurrency(
            a.SuperContributionCapPerPerson
          ),
        }));
        console.log("assumptions: ", assumptions);
        let optionItems = assumptions.map((item) => ({
          label: `${item.AssumptionName}`,
          value: item.AssumptionId,
        }));
        setClientAssumptions(optionItems);
        setAssumptions(assumptions);
        console.log("assumption id ", assumptionId);
        if (assumptionId) {
          const currAssumption = assumptions.find(
            (a) => parseInt(a.AssumptionId) === parseInt(assumptionId)
          );
          if (currAssumption) {
            setSelectedAssumption(currAssumption);
          }
        } else {
          setSelectedAssumption(assumptions[0]);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setFamilyMembers([]);
      });
  };

  const updateClientAssumption = async () => {
    console.log("selected client: ");
    console.log("assumption: ", selectedAssumption);
    const clientAssumptionData = {
      assumptionId: selectedAssumption.AssumptionId,
      assumptionName: selectedAssumption.AssumptionName,
      assumptionTypeId: 1,
      userId: state.userId,
      growthRate: selectedAssumption.GrowthRate,
      rentalYield: selectedAssumption.RentalYield,
      rentalIncreasePA: selectedAssumption.RentalIncreasePA,
      occupancyRate: selectedAssumption.OccupancyRate,
      propertyManagement: selectedAssumption.PropertyManagement,
      interestRate: selectedAssumption.InterestRate,
      inflation: selectedAssumption.Inflation,
      newLoanAmount: selectedAssumption.NewLoanAmount,
      acquisitionCost: selectedAssumption.AcquisitionCost,
      baExpenses: selectedAssumption.BAExpenses,
      renovationReturn: selectedAssumption.RenovationReturn,
      borrowingCapacity: selectedAssumption.BorrowingCapacity,
      wageGrowth: selectedAssumption.WageGrowth,
      offsetSavings: selectedAssumption.OffsetSavings,
      newPurchaseRefinance: selectedAssumption.NewPurchaseRefinance,
      sellingCost: selectedAssumption.SellingCost,
      cgtRate: selectedAssumption.CGTRate,
      cgtDiscount: selectedAssumption.CGTDiscount,
      costOfHandling: selectedAssumption.CostOfHandling,
      costOfHandlingIncrease: selectedAssumption.CostOfHandlingIncrease,
      commercialGrowthRate: selectedAssumption.CommercialGrowthRate,
      commercialNetRentalYield: selectedAssumption.CommercialNetRentalYield,
      commercialInterestRate: selectedAssumption.CommercialInterestRate,
      commercialNewLoanAmount: selectedAssumption.CommercialNewLoanAmount,
      commercialPropertyManagement:
        selectedAssumption.CommercialPropertyManagement,
      clientPropertyId: selectedAssumption.ClientPropertyId,
      clientId: clientstate.ClientId,
      savingsPlacedInOffset: selectedAssumption.SavingsPlacedInOffset,
      reFinanceOnNewPurchase: selectedAssumption.ReFinanceOnNewPurchase,
      payLMIOnFuturePurchases: selectedAssumption.PayLMIOnFuturePurchases,
      superContributionCapPerPerson: currencyToNumber(
        selectedAssumption.SuperContributionCapPerPerson
      ),
      superSavingsPoolGrowthRate: selectedAssumption.SuperSavingsPoolGrowthRate,
      superInterestRate: selectedAssumption.SuperInterestRate,
      superTransferAge: selectedAssumption.SuperTransferAge,
    };
    console.log("update client assumption: ", clientAssumptionData);
    assumptionService
      .updateClientAssumption(
        clientAssumptionData,
        selectedAssumption.AssumptionId
      )
      .then((response) => {
        setOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = () => {
    updateClientAssumption();
  };

  const handleAssumptionChange = (e) => {
    console.log(e);
    console.log("assumptions: ", assumptions);
    const selectedAssumption = assumptions.find(
      (i) => i.AssumptionName === e.label
    );
    console.log("selected assumption: ", selectedAssumption);
    setSelectedAssumption(selectedAssumption);
    // applyAssumption(state.clientId, selectedAssumption.AssumptionId);
    // getCompassGraphData(state.clientId, selectedAssumption.AssumptionId);
  };

  return (
    <div className="content assumption">
      <ClientNotSelectedDialog />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Assumption is saved."
        action={action}
      ></Snackbar>

      <Container>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Client Assumptions</CardTitle>
              </CardHeader>
              {assumptions?.length > 0 ? (
                <>
                  <CardBody>
                    <Form className="form-horizontal">
                      <Row>
                        <Col md="12" lg="12">
                          <Label style={{ paddingLeft: 0 }} md="3">
                            Select Assumption
                          </Label>
                          <FormGroup>
                            <Select
                              name=""
                              className="react-select select-option-control compass-select"
                              placeholder=""
                              classNamePrefix="react-select"
                              value={{
                                label: selectedAssumption?.AssumptionName,
                                value: selectedAssumption?.AssumptionId,
                              }}
                              onChange={handleAssumptionChange}
                              options={clientAssumptions}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>Growth Rate</Label>
                          <FormGroup>
                            <Input
                              placeholder="Growth Rate"
                              type="number"
                              min="0"
                              value={selectedAssumption?.GrowthRate}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  GrowthRate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Interest Rate</Label>
                          <FormGroup>
                            <Input
                              placeholder="Interest Rate"
                              type="number"
                              min="0"
                              value={selectedAssumption?.InterestRate}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  InterestRate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Borrowing capacity</Label>
                          <FormGroup>
                            <Input
                              placeholder="Borrowing capacity"
                              type="number"
                              min="0"
                              value={selectedAssumption?.BorrowingCapacity}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  BorrowingCapacity: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Savings placed in offset</Label>
                          <FormGroup>
                            <Input
                              placeholder="Savings placed in offset	"
                              type="number"
                              min="0"
                              value={selectedAssumption?.SavingsPlacedInOffset}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  SavingsPlacedInOffset: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4" sx={{ paddingTop: "8px" }}>
                          <Label>Re-Finance on New Purchase</Label>
                          <FormGroup>
                            <Input
                              placeholder="Re-Finance on New Purchase"
                              type="number"
                              min="0"
                              value={selectedAssumption?.ReFinanceOnNewPurchase}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  ReFinanceOnNewPurchase: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Inflation</Label>
                          <FormGroup>
                            <Input
                              placeholder="Inflation"
                              type="number"
                              min="0"
                              value={selectedAssumption?.Inflation}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  Inflation: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Occupancy Rate</Label>
                          <FormGroup>
                            <Input
                              placeholder="Occupancy Rate"
                              type="number"
                              min="0"
                              value={selectedAssumption?.OccupancyRate}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  OccupancyRate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Rental Yield</Label>
                          <FormGroup>
                            <Input
                              placeholder="Rental Yield"
                              type="number"
                              min="0"
                              value={selectedAssumption?.RentalYield}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  RentalYield: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label>Rental Increase PA</Label>
                          <FormGroup>
                            <Input
                              placeholder="Rental Increase PA"
                              type="number"
                              min="0"
                              value={selectedAssumption?.RentalIncreasePA}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  RentalIncreasePA: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Property Management</Label>
                          <FormGroup>
                            <Input
                              placeholder="Property Management"
                              type="number"
                              min="0"
                              value={selectedAssumption?.PropertyManagement}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  PropertyManagement: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Acquisition Costs</Label>
                          <FormGroup>
                            <Input
                              placeholder="Acquisition Costs"
                              type="number"
                              min="0"
                              value={selectedAssumption?.AcquisitionCost}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  AcquisitionCost: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Pay LMI on future Purchases</Label>
                          <FormGroup>
                            <Input
                              placeholder="Pay LMI on future Purchases"
                              type="checkbox"
                              style={{
                                marginLeft: 5,
                              }}
                              //   min="0"
                              checked={
                                selectedAssumption?.PayLMIOnFuturePurchases
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  PayLMIOnFuturePurchases: e.target.checked
                                    ? 1
                                    : 0,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Renovation Return</Label>
                          <FormGroup>
                            <Input
                              placeholder="Renovation Return"
                              type="number"
                              min="0"
                              value={selectedAssumption?.RenovationReturn}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  RenovationReturn: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>BA Expenses</Label>
                          <FormGroup>
                            <Input
                              placeholder="BA Expenses"
                              type="number"
                              min="0"
                              value={selectedAssumption?.BAExpenses}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  BAExpenses: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Cost of Holding</Label>
                          <FormGroup>
                            <Input
                              placeholder="Cost of Holding"
                              type="number"
                              min="0"
                              value={selectedAssumption?.CostOfHandling}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  CostOfHandling: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>New Loan Amount</Label>
                          <FormGroup>
                            <Input
                              placeholder="New Loan Amount"
                              type="number"
                              min="0"
                              value={selectedAssumption?.NewLoanAmount}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  NewLoanAmount: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Cost of holding Increase</Label>
                          <FormGroup>
                            <Input
                              placeholder="Cost of holding Increase"
                              type="number"
                              min="0"
                              value={selectedAssumption?.CostOfHandlingIncrease}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  CostOfHandlingIncrease: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Wage Growth</Label>
                          <FormGroup>
                            <Input
                              placeholder="Wage Growth"
                              type="number"
                              min="0"
                              value={selectedAssumption?.WageGrowth}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  WageGrowth: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Selling Costs</Label>
                          <FormGroup>
                            <Input
                              placeholder="Selling Costs"
                              type="number"
                              min="0"
                              value={selectedAssumption?.SellingCost}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  SellingCost: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>CGT Rate</Label>
                          <FormGroup>
                            <Input
                              placeholder="CGT Rate"
                              type="number"
                              min="0"
                              value={selectedAssumption?.CGTRate}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  CGTRate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>CGT Discount</Label>
                          <FormGroup>
                            <Input
                              placeholder="CGT Discount"
                              type="number"
                              min="0"
                              value={selectedAssumption?.CGTDiscount}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  CGTDiscount: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Commercial Growth Rate</Label>
                          <FormGroup>
                            <Input
                              placeholder="Commercial Growth Rate"
                              type="number"
                              min="0"
                              value={selectedAssumption?.CommercialGrowthRate}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  CommercialGrowthRate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Commercial Net Rental Yield</Label>
                          <FormGroup>
                            <Input
                              placeholder="Commercial Net Rental Yield"
                              type="number"
                              min="0"
                              value={
                                selectedAssumption?.CommercialNetRentalYield
                              }
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  CommercialNetRentalYield: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Commercial Interest Rate</Label>
                          <FormGroup>
                            <Input
                              placeholder="Commercial Interest Rate"
                              type="number"
                              min="0"
                              value={selectedAssumption?.CommercialInterestRate}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  CommercialInterestRate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Commercial New Loan Amount</Label>
                          <FormGroup>
                            <Input
                              placeholder="Commercial New Loan Amount"
                              type="number"
                              min="0"
                              value={
                                selectedAssumption?.CommercialNewLoanAmount
                              }
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  CommercialNewLoanAmount: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Commercial Property Management</Label>
                          <FormGroup>
                            <Input
                              placeholder="Commercial Property Management"
                              type="number"
                              min="0"
                              value={
                                selectedAssumption?.CommercialPropertyManagement
                              }
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  CommercialPropertyManagement: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Super Contribution Cap Per-Person</Label>
                          <FormGroup>
                            <CurrencyInput
                              placeholder="Super Contribution Cap Per-Person"
                              // type="number"
                              // min="0"
                              // value={
                              //   selectedAssumption?.SuperContributionCapPerPerson
                              // }
                              // onChange={(e) =>
                              //   setSelectedAssumption({
                              //     ...selectedAssumption,
                              //     SuperContributionCapPerPerson: e.target.value,
                              //   })
                              // }
                              number={
                                selectedAssumption?.SuperContributionCapPerPerson
                              }
                              handleNumberChange={(value) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  SuperContributionCapPerPerson: value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Super Savings Pool Growth Rate</Label>
                          <FormGroup>
                            <Input
                              placeholder="Super Savings Pool Growth Rate"
                              type="number"
                              min="0"
                              value={
                                selectedAssumption?.SuperSavingsPoolGrowthRate
                              }
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  SuperSavingsPoolGrowthRate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Super Interest Rate</Label>
                          <FormGroup>
                            <Input
                              placeholder="Super Interest Rate"
                              type="number"
                              min="0"
                              value={selectedAssumption?.SuperInterestRate}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  SuperInterestRate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          {" "}
                          <Label>Super Transfer Age</Label>
                          <FormGroup>
                            <Input
                              placeholder="Super Transfer Age"
                              type="number"
                              min="0"
                              value={selectedAssumption?.SuperTransferAge}
                              onChange={(e) =>
                                setSelectedAssumption({
                                  ...selectedAssumption,
                                  SuperTransferAge: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Row className="butttton">
                      <Col md="3" />
                      <Col md="9">
                        <Button
                          sx={{ float: "right" }}
                          onClick={handleSave}
                          variant="contained"
                          color="grey"
                          size="medium"
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </>
              ) : (
                <>
                  <CardBody>No Client Assumptions</CardBody>
                  <CardFooter>
                    <Row className="butttton">
                      <Col md="3" />
                      <Col md="9">
                        <Button
                          sx={{ float: "right" }}
                          onClick={redirectToAddAssumption}
                          startIcon={<SaveIcon />}
                          variant="contained"
                          color="grey"
                          size="medium"
                        >
                          ADD
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ClientAssumptions;
