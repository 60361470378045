import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";

import Select from "react-select";
import Button from "@mui/material/Button";
import { Button as ReactButton } from "reactstrap";
import { formatCurrency } from "utils/currency";
import ClientService from "services/client.service";
import NewPropertyService from "services/new-property.service";
import PropertySummary from "./PropertySummary";
import { Container } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddBuyerAgentDialog from "ui/client/add-buyer-agent-dialog";

export default function ChangeBuyerAgent({
  property,
  setUpdatedProperty,
  onHandleSetBuyerAgentDialog,
  fetchProperties,
}) {
  const [open, setOpen] = useState(true);
  const [newBuyerAgent, setNewBuyerAgent] = useState([]);
  const [selectedNewBuyerAgentId, setSelectedNewBuyerAgentId] = useState({});
  const [userState, setUserState] = useAtom(userAtom);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [error, setError] = useState(null);
  const [SubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const [showAddBuyerAgentDialog, setShowAddBuyerAgentDialog] = useState(false);

  const handleNewBuyerAgentChange = (e) => {
    setSelectedNewBuyerAgentId(e);
  };

  const handleConfirmationOpen = () => setConfirmationOpen(true);
  const handleConfirmationClose = () => setConfirmationOpen(false);

  const [newAddedBuyerAgentId, setNewAddedBuyerAgentId] = useState(0);

  //Fill Buyer Agent Select List
  const getBuyerAgent = async () => {
    ClientService.getAllBuyerAgentsForList()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const buyerAgentData = response.data.recordset;
          let optionItems = buyerAgentData.map((item) => ({
            label: `${item.FullName}`,
            value: item.ClientId,
          }));
          setNewBuyerAgent(optionItems);
        } else {
          setNewBuyerAgent([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setNewBuyerAgent([]);
      });
  };

  function handleBuyerDialogClose() {
    setOpen(false);
    onHandleSetBuyerAgentDialog(false);
    //handleClose();
  }

  function handleAddBuyerAgentDialogClose() {
    setShowAddBuyerAgentDialog(false);
    //handleClose();
  }

  function handleBuyerDialogSubmit(event) {
    event.preventDefault();

    if (
      property.currentBuyerAgentId === selectedNewBuyerAgentId.value ||
      !selectedNewBuyerAgentId.value
    )
      return;

    setConfirmationOpen(true);
  }

  // Update Buyer Agent to DB
  function updateBuyerAgent() {
    handleConfirmationClose();
    setSubmitButtonDisabled(true);
    const formData = {
      newPropertyId: property.id,
      existingBuyerAgentId: property.currentBuyerAgentId,
      newBuyerAgentId: selectedNewBuyerAgentId.value,
      userId: userState.userId,
    };
    console.log("New Buyer Agnet Name", selectedNewBuyerAgentId);
    NewPropertyService.updateBuyerAgent(formData)
      .then(() => {
        // setOpen(false);
        setSnackBarOpen(true);
        // onHandleSetBuyerAgentDialog(false);
        fetchProperties();
        setUpdatedProperty({
          ...property,
          currentBuyerAgent: selectedNewBuyerAgentId.label,
          currentBuyerAgentId: selectedNewBuyerAgentId.value,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (newBuyerAgent.length > 0 && newAddedBuyerAgentId != 0) {
      setSelectedNewBuyerAgentId(
        newBuyerAgent.find((agent) => agent.value === newAddedBuyerAgentId)
      );
      setNewAddedBuyerAgentId(0);
    }
  }, [newBuyerAgent]);

  useEffect(() => {
    if (!showAddBuyerAgentDialog) {
      getBuyerAgent();
    }
  }, [showAddBuyerAgentDialog]);

  const handleAddBuyerAgent = () => {
    // const path = "/admin/client/add-client";
    // const path = "/admin/client/add-client-dialog";
    // history.push(path);
    setShowAddBuyerAgentDialog(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleBuyerDialogClose}
      PaperProps={{
        style: { width: "60%", maxWidth: "none", height: "85%" },
      }}
      disableEscapeKeyDown
    >
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleBuyerDialogClose}
        message="Buyer Agent has been Updated"
      ></Snackbar>
      <ConfirmationDialog
        open={confirmationOpen}
        title="Update Buyer Agent"
        message="Are you sure you to change the buyer agent of the property?"
        onClose={handleConfirmationClose}
        onConfirm={updateBuyerAgent}
        onCancel={handleConfirmationClose}
        confirmText="Yes"
        cancelText="No"
      />

      {showAddBuyerAgentDialog && (
        <AddBuyerAgentDialog
          onHandleClose={handleAddBuyerAgentDialogClose}
          setNewAddedBuyerAgentId={setNewAddedBuyerAgentId}
        />
      )}

      <DialogTitle>
        <span
          style={{ color: "#114253", fontSize: "20px", fontWeight: "bold" }}
        >
          Change / Select Buyer Agent
        </span>
        <IconButton
          aria-label="close"
          onClick={handleBuyerDialogClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleBuyerDialogSubmit}>
        <DialogContent>
          <Card>
            <CardHeader>
              <CardBody className="new-property-card-body">
                <Container style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      flex: 1,
                      minWidth: "400px",
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      marginRight: "5px",
                      height: "430px",
                    }}
                  >
                    <PropertySummary property={property} />
                  </div>

                  <div
                    style={{
                      flex: 2,
                      minWidth: "400px",
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  >
                    <Row>
                      <Col
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div className="card_bt">
                          <Button
                            sx={{ float: "right" }}
                            variant="contained"
                            color="grey"
                            size="medium"
                            startIcon={<SaveIcon />}
                            onClick={handleAddBuyerAgent}
                          >
                            ADD
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>Current Agent</Label>
                        <FormGroup>
                          <Input
                            type="text"
                            value={property.currentBuyerAgent}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>New Agent</Label>
                        <FormGroup>
                          <Select
                            name=""
                            className="react-select select-option-control"
                            placeholder="Select Buyer Agent"
                            classNamePrefix="react-select"
                            value={selectedNewBuyerAgentId}
                            onChange={handleNewBuyerAgentChange}
                            options={newBuyerAgent}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    {/* <div className="card_bt"> */}
                    <Button
                      // sx={{ float: "right" }}
                      sx={{ marginTop: "20px" }}
                      variant="contained"
                      color="grey"
                      size="medium"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Submit
                    </Button>
                    {/* </div> */}
                  </Col>
                </Container>
              </CardBody>
            </CardHeader>
          </Card>
        </DialogContent>
      </form>
    </Dialog>
  );
}
