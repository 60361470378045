import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
  Container,
} from "reactstrap";

import { selectedClientAtom, userAtom } from "../../../../store";
import { useAtom } from "jotai";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "react-datetime/css/react-datetime.css";
import "dayjs/locale/en-gb";
import Moment from "moment";
import "moment/locale/en-gb";
import { useEffect, useState } from "react";
import FamilyMemberService from "services/family-member.service";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";

const AlterSavingsStep = ({ formData, setFormData }) => {
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [familyMembers, setFamilyMembers] = useState([]);
  const primaryFamilyMember = familyMembers.find(
    (member) => member.memberType.toLowerCase() === "primary"
  );
  const secondaryFamilyMember = familyMembers.find(
    (member) => member.memberType.toLowerCase() === "secondary"
  );

  const incomeRange = [-100, -80, -60, -40, -20, -10, 0, 10, 25, 50, 100];
  const portfolioGrowthRange = [
    -40, -30, -25, -20, -10, -5, 0, 10, 15, 20, 30, 40,
  ];

  const portfolioRentalGrowthRange = portfolioGrowthRange;

  const incomeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: '20px 20px 20px 32px',
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  useEffect(() => {
    // getFamilyMembers();
  }, []);

  console.log("form data: ", formData);
  return (
    <div className="alter-saving-step-modal main-pad">
      <Card>
        <CardHeader className="modal-year-pad">
          <CardTitle tag="h4">{formData?.year}</CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Container>
              <Row>
                <Col md="6">
                  <Label>Alter savings (lump sum this year only)</Label>
                  <FormGroup>
                    <CurrencyInput
                      placeholder="Alter savings"
                      number={formData?.changeInSavings}
                      handleNumberChange={(value) =>
                        setFormData({ ...formData, changeInSavings: value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <Label>Alter cashflow (impacts every following year)</Label>
                  <FormGroup>
                    <CurrencyInput
                      placeholder="Alter cashflow"
                      number={formData?.changeInCashflow}
                      handleNumberChange={(value) =>
                        setFormData({ ...formData, changeInCashflow: value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <Label>Alter debt (lump sum this year only)</Label>
                  <FormGroup>
                    <CurrencyInput
                      placeholder="Alter debt"
                      number={formData?.changeInDebt}
                      handleNumberChange={(value) =>
                        setFormData({ ...formData, changeInDebt: value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <Label>Alter description</Label>
                  <FormGroup>
                    <Input
                      placeholder="Alter description"
                      type="text"
                      value={formData?.changeReason || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          changeReason: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Label>
                    Change {primaryFamilyMember?.firstName}'s income (impacts
                    every following year)
                  </Label>
                  <FormGroup>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {incomeRange.map((income) => (
                        <Grid
                          className="small_box"
                          sx={{ flexDirection: "column", padding: "4px" }}
                          key={income}
                        >
                          <Box
                            onClick={() =>
                              setFormData({
                                ...formData,
                                changeInPrimaryIncome: income,
                                primaryFamilyMemberId: primaryFamilyMember?.id,
                              })
                            }
                            key={income}
                            sx={incomeBoxSx}
                            style={{
                              backgroundColor:
                                formData?.changeInPrimaryIncome === income
                                  ? "#e61a4b"
                                  : "#114253",
                            }}
                          >
                            {income.toLocaleString(navigator.language, {
                              minimumFractionDigits: 0,
                            })}
                            %
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Label>
                    Change {secondaryFamilyMember?.firstName}'s income (impacts
                    every following year)
                  </Label>
                  <FormGroup>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {incomeRange.map((income) => (
                        <Grid
                          className="small_box"
                          sx={{ flexDirection: "column", padding: "4px" }}
                          key={income}
                        >
                          <Box
                            onClick={() =>
                              setFormData({
                                ...formData,
                                changeInSecondaryIncome: income,
                                secondaryFamilyMemberId:
                                  secondaryFamilyMember?.id,
                              })
                            }
                            key={income}
                            sx={incomeBoxSx}
                            style={{
                              backgroundColor:
                                formData?.changeInSecondaryIncome === income
                                  ? "#e61a4b"
                                  : "#114253",
                            }}
                          >
                            {income.toLocaleString(navigator.language, {
                              minimumFractionDigits: 0,
                            })}
                            %
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Label>
                    Override entire portfolio growth percentage this year
                  </Label>
                  <FormGroup>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {portfolioGrowthRange.map((growth) => (
                        <Grid
                          className="small_box"
                          sx={{ flexDirection: "column", padding: "4px" }}
                          key={growth}
                        >
                          <Box
                            onClick={() =>
                              setFormData({
                                ...formData,
                                portfolioGrowth: growth,
                              })
                            }
                            key={growth}
                            sx={incomeBoxSx}
                            style={{
                              backgroundColor:
                                formData?.portfolioGrowth === growth
                                  ? "#e61a4b"
                                  : "#114253",
                            }}
                          >
                            {growth.toLocaleString(navigator.language, {
                              minimumFractionDigits: 0,
                            })}
                            %
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Label>
                    Override entire portfolio rental growth percentage this year
                  </Label>
                  <FormGroup>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {portfolioRentalGrowthRange.map((rentalGrowth) => (
                        <Grid
                          className="small_box"
                          sx={{ flexDirection: "column", padding: "4px" }}
                          key={rentalGrowth}
                        >
                          <Box
                            onClick={() =>
                              setFormData({
                                ...formData,
                                portfolioRentalGrowth: rentalGrowth,
                              })
                            }
                            key={rentalGrowth}
                            sx={incomeBoxSx}
                            style={{
                              backgroundColor:
                                formData?.portfolioRentalGrowth === rentalGrowth
                                  ? "#e61a4b"
                                  : "#114253",
                            }}
                          >
                            {rentalGrowth.toLocaleString(navigator.language, {
                              minimumFractionDigits: 0,
                            })}
                            %
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AlterSavingsStep;
