// In src/posts/statuses.ts

export const statuses = [
  "listed",
  "reviewed",
  "approved",
  "proposed",
  "accepted",
  "contract_exchanged",
  "rejected",
];

export const statusNames = {
  listed: "Listed",
  reviewed: "For Review",
  approved: "Approved",
  proposed: "Deal Proposed",
  accepted: "Deal Accepted",
  contract_exchanged: "Contract Exchanged",
  rejected: "Rejected",
};

export const statusId = {
  listed: 1,
  reviewed: 2,
  approved: 3,
  proposed: 4,
  accepted: 5,
  contract_exchanged: 6,
  rejected: 7,
};

export const getPostsByStatus = (unorderedPosts) => {
  console.log("posts: ", unorderedPosts);
  const postsByStatus = unorderedPosts.reduce(
    (acc, post) => {
      console.log("acc: ", acc);
      console.log("post: ", post.status);
      console.log("list to push: ", acc[post.status]);
      acc[post.status]?.push(post);
      return acc;
    },
    statuses.reduce((obj, status) => ({ ...obj, [status]: [] }), {})
  );

  console.log("post by status: ", postsByStatus);
  // order each column by index
  statuses.forEach((status) => {
    postsByStatus[status] = postsByStatus[status].sort(
      (recordA, recordB) => recordA.index - recordB.index
    );
  });
  return postsByStatus;
};
