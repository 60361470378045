/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./register.css";
import MultipleInputService from "../../services/multiple.service";
import registerService from "../../services/security";
import { useHistory } from "react-router-dom";
import AuthWithSocial from "components/AuthWithSocial/AuthWithSocial";

function Register() {
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    userName: Yup.string()
      .email("Must be an email address")
      .required("Must be an email address"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|(?=.*[@$!%*?&]))[A-Za-z\d@$!%*?&]{8,16}$/,
        "Passwords must 1 upper case, numeric, and special character!"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    conditions: Yup.boolean().oneOf([true], ""),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      conditions: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        const data = {
          ClientFirstName: values.firstName,
          ClientLastName: values.lastName,
          ClientEmail: values.email,
          ClientTypeId: 1,
          UserName: values.userName,
          Password: values.password,
          ClientScore: 0,
          ClientAdvisorId: 1,
          FullAccess: 1,
          LastSignIn: null,
        };
        registerService.addRegisterService(data).then((res) => {
          console.log(res);
          formik.resetForm();
          history.push("/auth/login");
        });
      } catch (e) {}
    },
  });
  const handleBlur = (event) => {
    let { name } = event.target;
    formik.setFieldTouched(name, true);
  };

  const handleChange = (event) => {
    let { name } = event.target;
    formik.setFieldValue(name, event.target.value);
  };

  useEffect(() => {
    console.log(formik.touched);
    setTimeout(() => {
      Object.keys(formik.touched).map((res) =>
        formik.setFieldTouched(res, false)
      );
    }, 3000);
  }, [formik.touched]);

  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  return (
    <div className="register-page auth-layout-page">
      <Container>
        <div className="auth-logo-responsive-main d-lg-none">
          <div className="auth-logo-responsive">
            <img
              alt="..."
              className="avatar border-gray"
              src={require("assets/img/main_logo_white.png")}
            />
          </div>
        </div>
        <Row>
          {/* <Col className="ml-auto" lg="6" md="5">
            <div className="info-area info-horizontal mt-5">
              <div className="icon icon-primary">
                <i className="nc-icon nc-tv-2" />
              </div>
              <div className="description">
                <h5 className="info-title">Marketing</h5>
                <p className="description">
                  We've created the marketing campaign of the website. It was a
                  very interesting collaboration.
                </p>
              </div>
            </div>
            <div className="info-area info-horizontal">
              <div className="icon icon-primary">
                <i className="nc-icon nc-html5" />
              </div>
              <div className="description">
                <h5 className="info-title">Fully Coded in HTML5</h5>
                <p className="description">
                  We've developed the website with HTML5 and CSS3. The client
                  has access to the code using GitHub.
                </p>
              </div>
            </div>
            <div className="info-area info-horizontal">
              <div className="icon icon-info">
                <i className="nc-icon nc-atom" />
              </div>
              <div className="description">
                <h5 className="info-title">Built Audience</h5>
                <p className="description">
                  There is also a Fully Customizable CMS Admin Dashboard for
                  this product.
                </p>
              </div>
            </div>
          </Col> */}
          <Col className="" lg="6" md="12">
            <div className="auth-layout-logo">
              <div
                className="auth-navbar-logo"
                style={{
                  backgroundImage: `url(${require("assets/img/auth-logo.png")})`,
                }}
              ></div>
            </div>
          </Col>
          <Col className="" lg="6" md="12">
            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">Sign up</CardTitle>
                {/* <div className="social">
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fa fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="dribbble">
                    <i className="fa fa-dribbble" />
                  </Button>
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fa fa-facebook-f" />
                  </Button>
                  <p className="card-description">or be classical</p>
                </div> */}
              </CardHeader>
              <div>
                <AuthWithSocial type="google" title="Sign up with google" />
                <AuthWithSocial
                  type="facebook"
                  title="Sign up with facebook
"
                />
              </div>
              <div className="auth-option">
                <p>OR</p>
              </div>
              <CardBody>
                <Form action="" className="form" method="">
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        // onChange={formik.handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.firstName}
                      />
                    </div>

                    {/* {formik.touched.firstName && formik.errors.firstName ? (
                      <p className="form-errors">{formik.errors.firstName}</p>
                    ) : (
                      <p className="form-errors"></p>
                    )} */}
                  </InputGroup>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        name="lastName"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.lastName}
                      />
                    </div>
                    {/* {formik.touched.lastName && formik.errors.lastName ? (
                      <p className="form-errors">{formik.errors.lastName}</p>
                    ) : (
                      <p className="form-errors"></p>
                    )} */}
                  </InputGroup>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon  nc-circle-10 " />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="UserName - must be an email address"
                        type="text"
                        name="userName"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.userName}
                      />
                    </div>
                    {/* {formik.touched.userName && formik.errors.userName ? (
                      <p className="form-errors">{formik.errors.userName}</p>
                    ) : (
                      <p className="form-errors"></p>
                    )} */}
                  </InputGroup>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        name="email"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.email}
                      />
                    </div>
                    {/* {formik.touched.email && formik.errors.email ? (
                      <p className="form-errors">{formik.errors.email}</p>
                    ) : (
                      <p className="form-errors"></p>
                    )} */}
                  </InputGroup>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.password}
                      />
                    </div>
                    {/* {formik.touched.password && formik.errors.password ? (
                      <p className="form-errors  ">{formik.errors.password}</p>
                    ) : (
                      <p className="form-errors  "></p>
                    )} */}
                  </InputGroup>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm Password"
                        type="password"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                      />
                    </div>
                    {/* {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <p className="form-errors ">
                        {formik.errors.confirmPassword}
                      </p>
                    ) : (
                      <p className="form-errors  "></p>
                    )} */}
                  </InputGroup>
                  <FormGroup check className="text-left">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={formik.values.conditions}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "conditions",
                            event.target.checked
                          );
                          console.log(event.target.checked);
                        }}
                        onBlur={handleBlur}
                      />
                      <span
                        className={`form-check-sign ${
                          formik.touched.conditions &&
                          formik.values.conditions == false
                            ? "checkBox-error"
                            : ""
                        }`}
                      />
                      I agree to the{" "}
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        terms and conditions.
                      </a>
                    </Label>
                  </FormGroup>
                </Form>
                <CardFooter>
                  <Button
                    className="btn-round theme-btn-layout-red"
                    color="info"
                    // href="#pablo"
                    type="submit"
                    onClick={(e) => {
                      console.log("dfdf");
                      // formik.setFieldsTouched(['firstName','lastName',true)
                      formik.setTouched({
                        firstName: true,
                        lastName: true,
                        userName: true,
                        email: true,
                        password: true,
                        confirmPassword: true,
                        conditions: true,
                      });

                      formik.handleSubmit();
                    }}
                    // onClick={(e) => e.preventDefault()}
                  >
                    Get Started
                  </Button>
                </CardFooter>
                <p
                  style={{ textAlign: "left" }}
                  className="ask-small-text"
                  onClick={(e) => {
                    history.push("/auth/login");
                  }}
                >
                  Already have an account? <span>Sign in</span>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/jan-sendereks.jpg")})`
        }}
      /> */}
    </div>
  );
}

export default Register;
