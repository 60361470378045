import http from "./http-common";

class UploadService {
  // http://localhost:5000/api/v1/upload/file
  uploadFile(data) {
    const path = `/new-property/upload-document`;
    console.log("path: ", path);
    console.log("path: ", data);
    return http.post(path, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        //     "x-rapidapi-host": "file-upload8.p.rapidapi.com",
        //     "x-rapidapi-key": "your-rapidapi-key-here",
      },
    });
  }

  // http://localhost:5000/api/v1/upload/file/:filename
  getFile(foldername, filename, propertyId) {
    const path = `/new-property/upload-document/${foldername}/${filename}/${propertyId}`;
    console.log("path: ", path);
    return http.get(path, {
      responseType: "arraybuffer",
    });
  }

  // http://localhost:5000/api/v1/new-property/property-document/delete/:id
  deletePropertyDocumentById(propertyDocumentId) {
    // const path = `/new-property/property-document/delete/${propertyDocumentId}`;
    const path = `/new-property/upload-document/delete/${propertyDocumentId}`;
    return http.delete(path);
  }
}

export default new UploadService();
