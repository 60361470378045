/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import MUIButton from "@mui/material/Button";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import { IconButton, Snackbar, Typography } from "@mui/material";
import UploadService from "services/upload.service";

const allowedTypes = ["application/pdf"];
function PDFUpload({
  handleFilenameChange,
  handleBeforeSelectingFile,
  foldername,
  filename,
}) {
  const [successOpen, setSuccessOpen] = React.useState(null);
  const [fileState, setFileState] = React.useState(null);
  // const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
  //   props.avatar ? defaultAvatar : defaultImage
  // );
  const fileInput = React.useRef();
  const handleFileChange = (e) => {
    e.preventDefault();
    console.log("event file: ", e);
    let reader = new FileReader();
    let file = e.target.files[0];

    if (file) {
      // file type validation
      if (!allowedTypes.includes(file.type)) {
        alert("Invalid File Type. Please upload a PDF");
        return;
      }
      console.log("file selected: ", file);

      setFileState(file);
    }
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // fileState is the file/image uploaded
  //   // in this function you can save the image (fileState) on form submit
  //   // you have to call it yourself
  // };
  const handleClick = () => {
    const shouldClick = handleBeforeSelectingFile();
    if (shouldClick) {
      fileInput.current.click();
    }
  };
  const handleUpload = () => {
    if (!fileState) {
      alert("No File to Upload");
    }
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", fileState);
    formData.append("foldername", foldername);
    formData.append("filename", filename);
    formData.append("propertyId", 14);

    UploadService.uploadFile(formData)
      .then(async (res) => {
        setSuccessOpen(true);
        console.log("res: ", res);
        const { filename, foldername } = res.data;
        await handleFilenameChange(filename, foldername);
        fileInput.current.value = null;
        setFileState(null);
      })
      .catch((err) => console.log(err));
  };

  const handleSuccessPopupClose = () => setSuccessOpen(false);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSuccessPopupClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div className="fileinput">
      <Snackbar
        open={successOpen}
        autoHideDuration={3000}
        onClose={handleSuccessPopupClose}
        message="File Uploaded."
        action={action}
      />
      <input
        type="file"
        onChange={handleFileChange}
        ref={fileInput}
        accept="application/pdf"
      />
      {/* <div>
      </div> */}
      <div>
        {fileState === null ? (
          <Button className="btn-round" onClick={() => handleClick()}>
            Select File
          </Button>
        ) : (
          <span>
            <Button className="btn-round" onClick={() => handleClick()}>
              Change File
            </Button>
            {/* {props.avatar ? <br /> : null} */}
            <MUIButton
              // sx={{ float: "right" }}
              variant="contained"
              color="grey"
              size="small"
              onClick={() => handleUpload()}
            >
              Upload
            </MUIButton>
          </span>
        )}
        {"   "}
        {/* <Typography variant="" mt={2}> */}
        {fileState ? fileState.name : "No File Selected"}
        {/* </Typography> */}
      </div>
    </div>
  );
}

PDFUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default PDFUpload;
