/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  CardTitle,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./login.css";
import LoginService from "../../services/security";
import { useHistory } from "react-router-dom";
import { WindowSharp } from "@mui/icons-material";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import bcrypt from "bcryptjs";
import MUIButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AuthWithSocial from "components/AuthWithSocial/AuthWithSocial";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
// import jwt from "jsonwebtoken";
// import useAuth from "./hooks/useAuth";

function Login() {
  const history = useHistory();
  const [passworderror, setPasswordError] = useState("");
  const [userNameerror, setUserNameError] = useState("");
  const [userState, setUserState] = useAtom(userAtom);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const { auth, setAuth } = useAuth();

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required(
      "User name is required. Must be an email address."
    ),
    password: Yup.string().required("Password is required."),
  });

  React.useEffect(() => {
    window.localStorage.removeItem("jwt-token");
    window.localStorage.removeItem("login-user");
    window.localStorage.removeItem("selected-client");
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const handleLoginClick = (e) => {
    formik.setTouched({
      userName: true,
      password: true,
    });
    formik.handleSubmit();
  };

  const handleLoginFailed = () => {
    setErrorOpen(true);
    setIsLoading(false);
  };

  const showMessageAndRedirect = (redirectUrl) => {
    setIsLoading(false);
    history.push(redirectUrl);
  };
  // const validationSchema = Yup.object().shape({
  //   userName: Yup.string()
  //     .email("Invalid user name, email required")
  //     .required("User name is required"),
  //   password: Yup.string()
  //     .required("Password is required")
  //     .matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|(?=.*[@$!%*?&]))[A-Za-z\d@$!%*?&]{8,16}$/,
  //       "Passwords must 1 upper case, numeric, and special character!"
  //     ),
  // });
  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        const secretKey = "$2a$10$CwTycUXWue0Thq9StjUM0u7861!0";
        const hashedPassword = bcrypt.hashSync(values.password, secretKey);
        const data = {
          UserName: `${values.userName}`,
          Password: `${hashedPassword}`,
        };
        if (data.UserName !== "" && data.Password !== "") {
          setIsLoading(true);
        }
        LoginService.Login(data)
          .then((response) => {
            const result = response.data;
            console.log("response: ", response);
            if (
              result.accessToken != null &&
              result.accessToken !== undefined &&
              result.recordset.length > 0
            ) {
              const jwtToken = result.accessToken;
              const client = result.recordset[0];
              const isAdmin = client?.ClientTypeDesc === "Admin";
              const isPartner = client?.ClientTypeDesc === "Partner";
              const isClient = client?.ClientTypeDesc === "Client";
              const isExternalPartner =
                client?.ClientTypeDesc === "External Partner";
              const isInternalUser = client?.ClientTypeDesc === "Internal User";

              const tokenData = {
                signInTime: Date.now(),
                username: `'${values.userName}'`,
              };

              sessionStorage.setItem("jwt-token", jwtToken);

              const user = {
                clientId: client.ClientId,
                firstName: client.ClientFirstName,
                lastName: client.ClientLastName,
                isAdmin,
                isPartner,
                isClient,
                isExternalPartner,
                isInternalUser,
                partnerId: client.ClientPartnerId,
                userType: client.ClientTypeDesc,
                companyLogo: client.CompanyLogoBase64,
              };
              // Admin || Client || Advisor || Partner
              // Admin can access entire site and add another Admin/Partner/Client/Advisor
              // Partner can aadd clients only
              // Client can update their own data but can not add more clients
              // window.localStorage.setItem('User', JSON.stringify(user));
              console.log("user: ", user);
              localStorage.setItem("login-user", JSON.stringify(user));
              setUserState({
                userId: user.clientId,
                firstName: user.firstName,
                lastName: user.lastName,
                isAdmin: user.isAdmin,
                isPartner: user.isPartner,
                isClient: user.isClient,
                isExternalPartner: user.isExternalPartner,
                isInternalUser: user.isInternalUser,
                userType: user.userType,
                partnerId: user.partnerId,
                companyLogo: user.companyLogo,
              });
              //  console.log('user :', user?.firstName)

              if (isClient) {
                // window.localStorage.setItem('SelectedClient', JSON.stringify({
                //   clientId: user.clientId,
                //   name: `${client.ClientFirstName} ${client.ClientLastName}`
                // }));
                setClientState({
                  clientId: user.clientId,
                  firstName: user.firstName,
                  lastName: user?.lastName,
                  fullAccess: client.FullAccess,
                });
                localStorage.setItem(
                  "selected-client",
                  JSON.stringify(clientState)
                );
                setTimeout(
                  () => showMessageAndRedirect("/admin/progress-tracker/index"),
                  3000
                );
              } else if (isAdmin) {
                setTimeout(
                  () => showMessageAndRedirect("/admin/client/list"),
                  3000
                );
                // history.push("/admin/client/list");
              } else if (isPartner || isExternalPartner || isInternalUser) {
                setTimeout(
                  () => showMessageAndRedirect("/admin/client/list"),
                  3000
                );
                // history.push("/admin/client/list");
              }

              // If login user is partner then set partner Id
              // set the client id when partner goes to the client list and select a client.
              // window.localStorage.setItem('PartnerId', client?.ClientId);
              // if login user is a client himself then set client Id
              //formik.resetForm();
            } else {
              setIsLoading(false);
              setPasswordError("invalid password");
              setUserNameError("invalid user name");
              setTimeout(() => {
                setPasswordError("");
                setUserNameError("");
              }, 2000);
              formik.setTouched({
                userName: true,
                password: true,
              });
              window.localStorage.removeItem("jwt-token");
              window.localStorage.removeItem("login-user");
              window.localStorage.removeItem("selected-client");
              // history.push("/");
            }
          })
          .catch((e) => {
            if (e?.response?.status === 401) {
              setErrorMessage(e.response.data);
              // Handle unauthorized access
              setTimeout(handleLoginFailed, 3000);
            } else {
              // Handle other types of errors
              setErrorMessage("An unknown error occurred", e);
              setTimeout(handleLoginFailed, 3000);
              console.error("An error occurred:", e);
            }
            // setTimeout(setIsLoading(false), 3000);
          });
      } catch (e) {
        console.log(e);
        window.localStorage.removeItem("jwt-token");
        window.localStorage.removeItem("login-user");
        window.localStorage.removeItem("selected-client");
      }
    },
  });

  const handleBlur = (event) => {
    let { name } = event.target;
    formik.setFieldTouched(name, true);
  };

  const handleChange = (event) => {
    let { name } = event.target;
    formik.setFieldValue(name, event.target.value);
  };

  useEffect(() => {
    console.log(formik.touched);
    console.log(formik.errors);
    setTimeout(() => {
      Object.keys(formik.touched).map((res) =>
        formik.setFieldTouched(res, false)
      );
    }, 2000);
  }, [formik.touched]);

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  return (
    <div className="login-page auth-layout-page">
      <Dialog
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Login Error</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={handleErrorClose}>OK</MUIButton>
        </DialogActions>
      </Dialog>
      <Container>
        <div className="auth-logo-responsive-main d-lg-none">
          <div className="auth-logo-responsive">
            <img
              alt="..."
              className="avatar border-gray"
              src={require("assets/img/main_logo_white.png")}
            />
          </div>
        </div>
        <Row>
          <Col className="" lg="6" md="12">
            <div className="auth-layout-logo">
              <div
                className="auth-navbar-logo"
                style={{
                  backgroundImage: `url(${require("assets/img/auth-logo.png")})`,
                }}
              ></div>
            </div>
          </Col>
          <Col className="" lg="6" md="12">
            <Form action="" className="form login-form" method="">
              <Card className="card-login card-auth-section">
                <CardHeader>
                  <CardTitle tag="h4">Sign in</CardTitle>
                </CardHeader>
                <div>
                  {/* <AuthWithSocial type="google" title="Sign in with google" /> */}
                  <br />
                  <br />
                  {/* <AuthWithSocial
                    type="facebook"
                    title="Sign in with facebook
"
                  /> */}
                </div>
                <div className="auth-option">
                  {/* <p>OR</p> */}
                  <br />
                  <br />
                </div>
                <CardBody>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email Address"
                        type="text"
                        name="userName"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.userName}
                      />
                    </div>
                    {formik.touched.userName && formik.errors.userName ? (
                      <Box>
                        <p className="form-errors login-error">
                          {formik.errors.userName}
                        </p>
                      </Box>
                    ) : (
                      <Box>
                        <p className="form-errors login-error">
                          {userNameerror}
                        </p>
                      </Box>
                    )}
                  </InputGroup>

                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        autoComplete="off"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            // formik.setFieldsTouched(['firstName','lastName',true)
                            formik.setTouched({
                              userName: true,
                              password: true,
                            });
                            formik.handleSubmit();
                          }
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.password}
                      />
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <Box sx={{ marginTop: "8px;" }}>
                        <br />
                        <br />
                        <p className="form-errors login-error">
                          {formik.errors.password}
                        </p>
                      </Box>
                    ) : (
                      <Box>
                        <br />
                        <br />
                        <p className="form-errors login-error">
                          {passworderror}
                        </p>
                      </Box>
                    )}
                  </InputGroup>
                  <div className="auth-change-password">
                    <a
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/auth/changePassword");
                      }}
                    >
                      Forgot Password?
                    </a>
                  </div>

                  {/* <FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input defaultChecked defaultValue="" type="checkbox" />
                        <span className="form-check-sign" />
                        Subscribe to newsletter
                      </Label>
                    </FormGroup>
                  </FormGroup> */}
                </CardBody>
                <CardFooter className="login-btn-custom">
                  <Button
                    block
                    className="btn-round mb-3 theme-btn-layout-red"
                    color="warning"
                    disabled={isLoading}
                    onClick={(e) => {
                      handleLoginClick(e);
                    }}
                    // formik.setFieldsTouched(['firstName','lastName',true)
                  >
                    {isLoading === true ? (
                      <Box display="flex">
                        <CircularProgress
                          sx={{
                            color: "#ffffff",
                          }}
                        />
                        <Typography
                          sx={{
                            marginLeft: "24px",
                            marginTop: "8px;",
                            textTransform: "capitalize",
                          }}
                        >
                          Please wait while we load your profile ...{" "}
                        </Typography>
                      </Box>
                    ) : (
                      "Login"
                    )}
                  </Button>
                  <p
                    className="ask-small-text"
                    onClick={(e) => {
                      history.push("/auth/client/register-client");
                    }}
                  >
                    <a>
                      Don't have an account? <span>Sign up</span>
                    </a>
                  </p>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      {/* <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`,
        }}
      /> */}
    </div>
  );
}

export default Login;
