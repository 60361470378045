/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./add-client.css";
import MultipleInputService from "../../services/multiple.service";
import ClientService from "../../services/client.service";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import bcrypt from "bcryptjs";
import { Container } from "@mui/material";
import RegisterClient from "./register-client";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { Typography } from "@mui/material";
import moment from "moment";

const AddClient = () => {
  const history = useHistory();
  const [selectOptions, setSelectOptions] = useState([]);
  const [userType, setUserType] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [clientTypes, setClientTypes] = useState([]);
  const [clientstate, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [image, setImage] = useState(null);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    userName: Yup.string()
      .email("Invalid username. Must be an email address")
      .required("Username is required. Must be an email address"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|(?=.*[@$!%*?&]))[A-Za-z\d@$!%*?&]{8,16}$/,
        "Passwords must include one upper case letter, one numeric, and special character!"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    mobileNumber: Yup.string().required("Mobile number is required"),
    conditions: Yup.boolean().oneOf([true], ""),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      clientPartnerId: "",
      mobileNumber: "",
      companyName: "",
      conditions: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        // Client can not add his/her own record, Parnter can add clients and Admin can add partner and client
        // if admin then set clientPartnerId = Null, isAdmin = true, clientTypeId = userType.value
        // if partner then set clientPartnerId = Null, isAdmin = false, clientTypeId = userType.value
        // if client then set clientPartnerId = login user id, isAdmin = false, clientTypeId =userType.value
        // const user = JSON.parse(window.localStorage.getItem("User"));
        console.log("user type: ", userType);
        console.log("login user: ", userState);
        const hashedPassword = bcrypt.hashSync(
          values.password,
          "$2a$10$CwTycUXWue0Thq9StjUM0u7861!0"
        );
        console.log("hashed password: ", hashedPassword);
        const data = {
          clientFirstName: values.firstName,
          clientLastName: values.lastName,
          clientEmail: values.email,
          clientTypeId: userType.value,
          userName: values.userName,
          password: hashedPassword,
          clientScore: 0,
          clientAdvisorId: 0,
          fullAccess: 0,
          lastSignIn: null,
          isAdmin: userType.label === "Admin" ? 1 : 0,
          // clientPartnerId: userState.isClient ? userState.clientId : null,
          clientPartnerId:
            userState.isPartner ||
            userState.isExternalPartner ||
            userState.isInternalUser
              ? userState.userId
              : null,
          userId: userState.userId,
          mobileNumber: values.mobileNumber,
          companyName: values.companyName,
          uuid:
            userType.label === "Partner" ? self.crypto.randomUUID() : undefined,
          companyLogo: image,
          expiryDate:
            userType.value === 1
              ? moment().add(3, "months").format("YYYY-MM-DD")
              : null,
        };
        console.log("data: ", data);
        ClientService.add(data).then((res) => {
          console.log(res);
          formik.resetForm();
          history.push("/admin/client/list");
        });
      } catch (e) {
        console.log(e);
      }
    },
  });

  const handleBlur = (event) => {
    let { name } = event.target;
    formik.setFieldTouched(name, true);
  };

  const handleChange = (event) => {
    let { name } = event.target;
    formik.setFieldValue(name, event.target.value);
  };

  const handleImageChange = (image) => {
    setImage(image);
  };

  useEffect(() => {
    getUserTypes();
    console.log(formik.touched);
    console.log("userState: ", userState);
    setTimeout(() => {
      Object.keys(formik.touched).map((res) =>
        formik.setFieldTouched(res, false)
      );
    }, 15000);
  }, [formik.touched]);

  const getUserTypes = async () => {
    // const user = JSON.parse(window.localStorage.getItem("User"));
    // console.log('user from client add: ', user);
    // let types = [];
    //console.log('user: ', user);
    // ClientService.getClientTypes()
    //     .then((response) => {
    //         if (response.data.recordsets.length > 0) {
    //             types = response.data.recordset.map((type) => {
    //                 return {
    //                     label: type.ClientTypeDesc,
    //                     value: `${type.ClientTypeId}`,
    //                 }
    //             });
    //             console.log('types: ', types);
    //             setIsDataLoaded(true);
    //         }
    //         else {
    //             setIsDataLoaded(false);
    //             setClientTypes([]);
    //         }
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         setClientTypes([]);
    //     });

    if (userState.isAdmin) {
      //setClientTypes(types);
      //console.log('clientType: ', clientTypes);
      setSelectOptions([
        { label: "Admin", value: 4 },
        { label: "Partner", value: 3 },
        { label: "Advisor", value: 2 },
        { label: "Client", value: 1 },
        { label: "External Partner", value: 5 },
        { label: "Internal User", value: 6 },
      ]);
    } else if (userState.isPartner) {
      // const filteredTypes = types.filter(t => t.ClientTypeDesc === 'Client');
      // setClientTypes(filteredTypes);
      setSelectOptions([
        { label: "Client", value: 1 },
        { label: "External Partner", value: 5 },
        { label: "Internal User", value: 6 },
      ]);
    } else if (userState.isExternalPartner) {
      setSelectOptions([{ label: "Client", value: 1 }]);
    }
  };

  const setSelectedUserType = (selectedUserType) => {
    console.log("user type: ", selectedUserType);
    setUserType(selectedUserType);
  };

  return (
    <div className="content add-client-content">
      {/* {userState.isPartner ? (
        <RegisterClient />
      ) : ( */}
      <div>
        <Container>
          <Row>
            <Col className="mr-auto" lg="12" md="12">
              <Card className="text-left">
                <CardHeader>
                  <CardTitle tag="h4">Register Client</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="" className="form" method="">
                    <InputGroup>
                      <div className="field-group-icon">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="First Name"
                          type="text"
                          name="firstName"
                          // onChange={formik.handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={formik.values.firstName}
                        />
                      </div>
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <p className="form-errors">{formik.errors.firstName}</p>
                      ) : (
                        <p className="form-errors"></p>
                      )}
                    </InputGroup>
                    <InputGroup>
                      <div className="field-group-icon">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Last Name"
                          type="text"
                          name="lastName"
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={formik.values.lastName}
                        />
                      </div>
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <p className="form-errors">{formik.errors.lastName}</p>
                      ) : (
                        <p className="form-errors"></p>
                      )}
                    </InputGroup>
                    <InputGroup>
                      <div className="field-group-icon">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon  nc-circle-10 " />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="User Name (must be an email address)"
                          type="text"
                          name="userName"
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={formik.values.userName}
                        />
                      </div>
                      {formik.touched.userName && formik.errors.userName ? (
                        <p className="form-errors">{formik.errors.userName}</p>
                      ) : (
                        <p className="form-errors"></p>
                      )}
                    </InputGroup>
                    <InputGroup>
                      <div className="field-group-icon">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          name="email"
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={formik.values.email}
                        />
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                        <p className="form-errors">{formik.errors.email}</p>
                      ) : (
                        <p className="form-errors"></p>
                      )}
                    </InputGroup>
                    <InputGroup>
                      <div className="field-group-icon">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          name="password"
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={formik.values.password}
                        />
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <p className="form-errors  ">
                          {formik.errors.password}
                        </p>
                      ) : (
                        <p className="form-errors  "></p>
                      )}
                    </InputGroup>
                    <InputGroup>
                      <div className="field-group-icon">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Confirm Password"
                          type="password"
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          name="confirmPassword"
                          value={formik.values.confirmPassword}
                        />
                      </div>
                      {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                        <p className="form-errors ">
                          {formik.errors.confirmPassword}
                        </p>
                      ) : (
                        <p className="form-errors  "></p>
                      )}
                    </InputGroup>
                    <InputGroup>
                      <div className="field-group-icon">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon  nc-circle-10 " />
                          </InputGroupText>
                        </InputGroupAddon>

                        <Select
                          name=""
                          className="react-select select-option-control"
                          placeholder="Select user type"
                          classNamePrefix="react-select"
                          options={selectOptions}
                          onChange={(userType) => setSelectedUserType(userType)}
                        />
                      </div>
                    </InputGroup>
                    <InputGroup>
                      <div className="field-group-icon">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon  nc-mobile " />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Mobile Number"
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          name="mobileNumber"
                          value={formik.values.mobileNumber}
                        />
                        {formik.touched.mobileNumber &&
                        formik.errors.mobileNumber ? (
                          <p className="form-errors ">
                            {formik.errors.mobileNumber}
                          </p>
                        ) : (
                          <p className="form-errors  "></p>
                        )}
                      </div>
                    </InputGroup>
                    {userType.value === 3 && (
                      <>
                        <InputGroup>
                          <div className="field-group-icon">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-badge" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Company Name"
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              name="companyName"
                              value={formik.values.companyName}
                            />
                            {formik.touched.companyName &&
                            formik.errors.companyName ? (
                              <p className="form-errors ">
                                {formik.errors.companyName}
                              </p>
                            ) : (
                              <p className="form-errors  "></p>
                            )}
                          </div>
                        </InputGroup>
                        <div></div>
                        <div>
                          <Label>Company Logo</Label>
                          <br />
                          <ImageUpload
                            avatar={false}
                            saveImage={handleImageChange}
                          />
                        </div>
                      </>
                    )}

                    <FormGroup check className="text-left">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={formik.values.conditions}
                          onChange={(event) => {
                            formik.setFieldValue(
                              "conditions",
                              event.target.checked
                            );
                            console.log(event.target.checked);
                          }}
                          onBlur={handleBlur}
                        />
                        <span
                          className={`form-check-sign ${
                            formik.touched.conditions &&
                            formik.values.conditions == false
                              ? "checkBox-error"
                              : ""
                          }`}
                        />
                        I agree to the{" "}
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          terms and conditions
                        </a>
                        .
                      </Label>
                    </FormGroup>
                  </Form>
                  <CardFooter className="text-right">
                    <Button
                      className="btn-round"
                      color="info"
                      // href="#pablo"
                      type="submit"
                      onClick={(e) => {
                        // formik.setFieldsTouched(['firstName','lastName',true)
                        formik.setTouched({
                          firstName: true,
                          lastName: true,
                          userName: true,
                          email: true,
                          password: true,
                          confirmPassword: true,
                          mobileNumber: true,
                          conditions: true,
                        });

                        formik.handleSubmit();
                      }}
                      // onClick={(e) => e.preventDefault()}
                    >
                      Register
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
      {/* )} */}
    </div>
  );
};

export default AddClient;
