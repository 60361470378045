import http from "./http-common";

class PaymentService {
  // http://localhost:5000/api/v1/payment/createcheckoutsession
  // createCheckoutSession() {
  //   const path = `/payment/createcheckoutsession`;
  //   console.log("path: ", path);
  //   return http.post(path);
  // }

  // createCheckoutSession = async (req, res) => {
  //   try {
  //     const YOUR_DOMAIN = "http://localhost:3000";
  //     const stripe = require("stripe")(
  //       "sk_test_51OSbf6LYKrA9eUfTyHfbmEuJVndm0994MD9WisvygMxN8lm3PYyZEHMm1AssZ37ybVUf1qkdwEREKyk8EqPuVptW00sXkIrt4G"
  //     );
  //     const session = await stripe.checkout.sessions.create({
  //       line_items: [
  //         {
  //           // Provide the exact Price ID (for example, pr_1234) of the product you want to sell
  //           price: "price_1OSqa5LYKrA9eUfTQk2wYF89",
  //           quantity: 1,
  //         },
  //       ],
  //       mode: "subscription",
  //       customer_email: "trbadami@yahoo.com",
  //       success_url: `${YOUR_DOMAIN}/admin/detail/orders`,
  //       cancel_url: `${YOUR_DOMAIN}/admin/detail/orders`,
  //       automatic_tax: { enabled: true },
  //     });
  //     console.log("session: ", session);
  //     // res.redirect(303, session.url);
  //     return await session.url;
  //   } catch (e) {
  //     throw new Error(e.message);
  //   }
  // };

  createEmbeddedCheckoutSession = async (req, res) => {
    try {
      // const YOUR_DOMAIN = "http://localhost:3000";
      const YOUR_DOMAIN = "https://financialplanningapp.azurewebsites.net";
      const stripe = require("stripe")(
        "sk_test_51OSbf6LYKrA9eUfTyHfbmEuJVndm0994MD9WisvygMxN8lm3PYyZEHMm1AssZ37ybVUf1qkdwEREKyk8EqPuVptW00sXkIrt4G"
      );
      const session = await stripe.checkout.sessions.create({
        ui_mode: "embedded",
        line_items: [
          {
            // Provide the exact Price ID (for example, pr_1234) of the product you want to sell
            price: "price_1OSqa5LYKrA9eUfTQk2wYF89",
            quantity: 1,
          },
        ],
        mode: "subscription",
        return_url: `${YOUR_DOMAIN}/admin/detail/orders?session_id={CHECKOUT_SESSION_ID}`,
        automatic_tax: { enabled: true },
      });

      return { clientSecret: session.client_secret };
    } catch (e) {
      throw new Error(e.message);
    }
  };

  sessionStatus = async (sessionId) => {
    const stripe = require("stripe")(
      "sk_test_51OSbf6LYKrA9eUfTyHfbmEuJVndm0994MD9WisvygMxN8lm3PYyZEHMm1AssZ37ybVUf1qkdwEREKyk8EqPuVptW00sXkIrt4G"
    );
    const session = await stripe.checkout.sessions.retrieve(sessionId);

    return {
      status: session.status,
      customer_email: session.customer_details.email,
    };
  };
  // http://localhost:5000/api/v1/payment/add
  add(data) {
    const path = "/payment/add";
    console.log("data: ", data);
    return http.post(`${path}`, data);
  }

  // http://localhost:5000/api/v1/payment/client/:id
  getAllByClientId(clientId) {
    const path = `/payment/client/${clientId}`;
    console.log("path: ", path);
    return http.get(path);
  }
}

export default new PaymentService();
