import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
} from "reactstrap";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import FamilyMemberService from "../../../../services/family-member.service";
import "moment/locale/en-gb";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";

const CurrentCircumstances = ({ formData, setFormData }) => {
  const [selectedFamilyMember, setSelectedFamilyMember] = useState("");
  const [familyMemberNames, setFamilyMemberNames] = useState([]);
  const [selectedFamilyMemberId, setSelectedFamilyMemberId] = useState({});
  const [clientId, setClilentId] = useState(1);
  const [userId, setUserId] = useState(1);
  const [selectedWealthPurpose, setSelectedWealthPurpose] = useState(0);
  const [state, setState] = useAtom(selectedClientAtom);
  const nextPropertyTimePeriod = [
    "Now",
    "3 months",
    "6 months",
    "9 months",
    "12 months+",
  ];
  const changeInPurpose = ["Become family home", "Become investment", "No"];
  const selling = ["Yes", "No"];

  const propertyTimeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const changeInPurposeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const sellingBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setNextPropertyTimePeriod = (timePeriod) => {
    setFormData({ ...formData, nextPropertyTimePeriod: timePeriod });
  };

  const setChangeInPurpose = (changeInPurpose) => {
    setFormData({ ...formData, changeInPurpose });
  };

  const setSellingPlan = (sellingPlan) => {
    setFormData({ ...formData, sellingPlan });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Setting up Asset Selection for {state.firstName} {state.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="8">
                <Label>
                  How soon are you ready to buy your next investment property?
                </Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {nextPropertyTimePeriod.map((pt) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={pt}
                      >
                        <Box
                          onClick={() => setNextPropertyTimePeriod(pt)}
                          key={pt}
                          sx={propertyTimeBoxSx}
                          style={{
                            backgroundColor:
                              formData.nextPropertyTimePeriod === pt
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {pt}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Label>
                  Is there a potential for this to change purpose in the future?
                </Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {changeInPurpose.map((cg) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={cg}
                      >
                        <Box
                          onClick={() => setChangeInPurpose(cg)}
                          key={cg}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.changeInPurpose === cg
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {cg}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Label>
                  Are you planning to sell any property in next 10 years?
                </Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {selling.map((s) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={s}
                      >
                        <Box
                          onClick={() => setSellingPlan(s)}
                          key={s}
                          sx={sellingBoxSx}
                          style={{
                            backgroundColor:
                              formData.sellingPlan === s
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {s}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="4" />
            <Col md="8"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default CurrentCircumstances;
