import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
} from "reactstrap";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import "moment/locale/en-gb";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";

const GeneralDetails = ({ formData, setFormData }) => {
  // const [moneySpending, setMoneySpending] = useState(200000);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const purchaseYear = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
  ];

  const propertyTimeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const changeInPurposeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const sellingBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setPurchaseYear = (purchaseYear) => {
    setFormData({ ...formData, purchaseYear });
  };

  const setSpending = (e) => {
    // setMoneySpending(e.target.value);

    setFormData({ ...formData, moneySpending: e.target.value });
  };

  useEffect(() => {
    console.log("Form Data - ", formData);
  }, []);

  return (
    <div className="general-details-step">
      <Card>
        <CardHeader>
          <CardTitle tag="h4" className="card-margin">
            Set your property plan for {clientState.firstName}{" "}
            {clientState.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="12">
                <Label>Which year will this purchase be?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {purchaseYear.map((year) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={year}
                      >
                        <Box
                          onClick={() => setPurchaseYear(year)}
                          key={year}
                          sx={changeInPurposeBoxSx}
                          style={{
                            backgroundColor:
                              formData.purchaseYear === year
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {year}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <FormGroup check className="text-left">
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={formData.placeOfResidence}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          placeOfResidence: !formData.placeOfResidence,
                        });
                      }}
                    />
                    <span className={`form-check-sign`} />
                    Are you looking to buy a principal place of residence in the
                    future?
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Label>How much would you spend?</Label>
                <FormGroup className="fg-margin">
                  <Slider
                    value={formData?.moneySpending}
                    step={1000}
                    min={200000}
                    max={5000000}
                    valueLabelDisplay="auto"
                    onChange={(e) => {
                      setSpending(e);
                      console.log("Form Data", formData);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default GeneralDetails;
