import http from "./http-common";

class PropertyDueDiligenceService {
  // http://localhost:5000/api/v1/new-property/property-due-diligence
  add(data) {
    const path = "/new-property/property-due-diligence";
    console.log("data: ", data);
    return http.post(`${path}`, data);
  }
}

export default new PropertyDueDiligenceService();
