import http from "./http-common";

class ReFinanceService {
  // http://localhost:5000/api/v1/refinance/get/yearly-plan/:id
  getAllByYearlyPlanId(yearlyPlanId) {
    const path = `/refinance/get/yearly-plan/${yearlyPlanId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/refinance/update/:id
  update(reFinanceId, data) {
    const path = `/refinance/update/${reFinanceId}`;
    console.log("path: ", path);
    return http.put(path, data);
  }

  // http://localhost:5000/api/v1/refinance/add
  add(data) {
    const path = "/refinance";
    console.log("data: ", data);
    return http.post(`${path}/add`, data);
  }

  // http://localhost:5000/api/v1/refinance/delete/:id
  deleteAllByYearlyPlanId(yearlyPlanId) {
    const path = "/refinance";
    console.log("id: ", yearlyPlanId);
    return http.delete(`${path}/delete/yearly-plan/${yearlyPlanId}`);
  }

  // http://localhost:5000/api/v1/refinance/delete/:id
  deleteByRefinanceId(refinanceId) {
    const path = "/refinance";
    console.log("refinanceId: ", refinanceId);
    return http.delete(`${path}/delete/${refinanceId}`);
  }
}

export default new ReFinanceService();
