// In src/posts/PostColumn.tsx
import { useEffect } from "react";

import { Droppable } from "@hello-pangea/dnd";
import { Box, Typography } from "@mui/material";

import { statusNames } from "./Statuses";
import { PostCard } from "./PostCard";

export const PostColumn = ({
  status,
  posts,
  deleteProperty,
  fetchProperties,
}) => {
  useEffect(() => {
    console.log("Post-", posts);
  }, []);

  return (
    <Box
      sx={{
        flex: 1,
        paddingTop: "8px",
        paddingBottom: "16px",
        bgcolor: "#eaeaee",
        "&:first-child": {
          paddingLeft: "5px",
          borderTopLeftRadius: 5,
        },
        "&:last-child": {
          paddingRight: "5px",
          borderTopRightRadius: 5,
        },
      }}
    >
      <Typography align="center" variant="subtitle1">
        {statusNames[status]}
      </Typography>
      <Droppable droppableId={status}>
        {(droppableProvided, snapshot) => (
          <Box
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            className={snapshot.isDraggingOver ? " isDraggingOver" : ""}
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: 5,
              padding: "5px",
              "&.isDraggingOver": {
                bgcolor: "#dadadf",
              },
              height: "100%",
            }}
          >
            {posts.map((post, index) => (
              <PostCard
                key={post.id}
                property={post}
                index={index}
                deleteProperty={deleteProperty}
                fetchProperties={fetchProperties}
              />
            ))}
            {droppableProvided.placeholder}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};
