import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ConfirmationDialog(props) {
  const {
    open,
    title,
    message,
    onClose,
    onConfirm,
    onCancel,
    confirmText = "Yes",
    cancelText = "No",
    isInformationDialog = false,
    error,
  } = props;

  return (
    <>
      <BootstrapDialog
        className="confirmation-popup"
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <DialogContentText
              id="alert-dialog-description"
              style={{ padding: "12px" }}
            >
              {message}
            </DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isInformationDialog ? (
            <>
              <Button onClick={onCancel || onClose}>{cancelText}</Button>
              <Button onClick={onConfirm} autoFocus>
                {confirmText}
              </Button>
            </>
          ) : (
            <Button onClick={onClose} autoFocus>
              OK
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
      {error && (
        <BootstrapDialog
          open={Boolean(error)}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>OK</Button>
          </DialogActions>
        </BootstrapDialog>
      )}
    </>
  );
}
