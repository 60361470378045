import { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import TextareaAutosize from '@mui/base/TextareaAutosize';
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";
import { Container } from "@mui/material";

const LocationDetails = ({ formData, setFormData }) => {
  const buyingAreaRadius = ["5", "10", "20", "50", "100", "1000"];
  const buyingOutsideArea = ["Yes", "No"];
  const buyingInAnotherState = ["Yes", "No"];
  const [state, setState] = useAtom(selectedClientAtom);

  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
  };

  // const StyledTextarea = styled(TextareaAutosize)(
  //     ({ theme }) => `
  //     width: 320px;
  //     font-family: IBM Plex Sans, sans-serif;
  //     font-size: 0.875rem;
  //     font-weight: 400;
  //     line-height: 1.5;
  //     padding: 12px;
  //     border-radius: 12px 12px 0 12px;
  //     color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  //     background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  //     border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  //     box-shadow: 0px 2px 24px ${theme.palette.mode === 'dark' ? blue[900] : blue[100]
  //         };

  //     &:hover {
  //       border-color: ${blue[400]};
  //     }

  //     &:focus {
  //       border-color: ${blue[400]};
  //       box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  //     }

  //     // firefox
  //     &:focus-visible {
  //       outline: 0;
  //     }
  //   `,
  // );

  const boxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: '20px 20px 20px 32px',
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const textAreaBoxSx = {
    fontFamily: "IBM Plex Sans, sans-serif",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.5",
    padding: "12px",
    borderRadius: "12px 12px 0 12px",
    color: grey[300],
    background: grey[900],
    border: `1px solid ${grey[700]}`,
    boxShadow: `0px 2px 24px ${blue[900]}`,

    // &:hover {
    //   border-color: ${blue[400]};
    // }

    // &:focus {
    //   border-color: ${blue[400]};
    //   box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    // }
  };

  const setBuyingAreaRadius = (buyingAreaRadius) => {
    setFormData({ ...formData, buyingAreaRadius });
  };

  const setBuyingOutsideTheArea = (buyingOutsideTheArea) => {
    setFormData({ ...formData, buyingOutsideTheArea });
  };

  const setBuyingInAnotherState = (buyingInAnotherState) => {
    setFormData({ ...formData, buyingInAnotherState });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Setting up Asset Selection for {state.firstName} {state.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="9">
                <Label>Do you have a preferred suburb/area??</Label>
                <FormGroup>
                  <Input
                    placeholder="Preferred suburb"
                    type="text"
                    value={formData.preferredSuburb}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        preferredSuburb: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>What radius would you buy within this area? (KM)</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {buyingAreaRadius.map((ar) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={ar}
                      >
                        <Box
                          onClick={() => setBuyingAreaRadius(ar)}
                          key={ar}
                          sx={boxSx}
                          style={{
                            backgroundColor:
                              formData.buyingAreaRadius === ar
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {ar}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <Label>Would you consider buying outside of this area?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {buyingOutsideArea.map((oa) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={oa}
                      >
                        <Box
                          onClick={() => setBuyingOutsideTheArea(oa)}
                          key={oa}
                          sx={boxSx}
                          style={{
                            backgroundColor:
                              formData.buyingOutsideTheArea === oa
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {oa}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <Label>Would you consider buying in another state?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {buyingInAnotherState.map((as) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={as}
                      >
                        <Box
                          onClick={() => setBuyingInAnotherState(as)}
                          key={as}
                          sx={boxSx}
                          style={{
                            backgroundColor:
                              formData.buyingInAnotherState === as
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {as}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <Label>Any other notes for this next property?</Label>
                <FormGroup>
                  <TextField
                    multiline
                    fullWidth
                    rows={3}
                    placeholder="Notes"
                    sx={{ textAreaBoxSx }}
                    value={formData.notes}
                    onChange={(e) =>
                      setFormData({ ...formData, notes: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default LocationDetails;
