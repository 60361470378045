import { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { formatCurrency } from "utils/currency";

const FinancialDetails = ({ formData, setFormData }) => {
  const deposit = ["0", "5", "10", "15", "20", "30+"];
  const captialGrowth = ["5", "10", "15", "20", "25"];
  const rentalIncome = ["0", "1000", "1500", "2000", "2500", "3000+"];
  const [state, setState] = useAtom(selectedClientAtom);

  const lvrBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: '20px 20px 20px 32px',
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setDeposit = (deposit) => {
    setFormData({ ...formData, deposit });
  };

  const setCapitalGrowth = (capitalGrowth) => {
    setFormData({ ...formData, capitalGrowth });
  };

  const setRentalIncome = (rentalIncome) => {
    setFormData({ ...formData, rentalIncome });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Setting up Asset Selection for {state.firstName} {state.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="9">
                <Label>What price point are you thinking?</Label>
                <FormGroup>
                  <CurrencyInput
                    placeholder="Price point"
                    number={formData.pricePoint}
                    handleNumberChange={(value) =>
                      setFormData({ ...formData, pricePoint: value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <FormGroup check className="text-left">
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={formData.preApproved}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          preApproved: !formData.preApproved,
                        })
                      }
                    />
                    <span className={`form-check-sign`} />
                    Are you currently pre-approved?
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <Label>If so, what is the total loan amount?</Label>
                <FormGroup>
                  <CurrencyInput
                    placeholder="Total loan amount"
                    number={formData.totalLoanAmount}
                    handleNumberChange={(value) =>
                      setFormData({ ...formData, totalLoanAmount: value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>
                  How much of a deposit would you be willing to use?
                </Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {deposit.map((dp) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={dp}
                      >
                        <Box
                          onClick={() => setDeposit(dp)}
                          key={dp}
                          sx={lvrBoxSx}
                          style={{
                            backgroundColor:
                              formData.deposit === dp ? "#e61a4b" : "#114253",
                          }}
                        >
                          {dp}%
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>
                  How much capital growth would you like over the next 3 years?
                </Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {captialGrowth.map((cg) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={cg}
                      >
                        <Box
                          onClick={() => setCapitalGrowth(cg)}
                          key={cg}
                          sx={lvrBoxSx}
                          style={{
                            backgroundColor:
                              formData.capitalGrowth === cg
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {cg}%
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>
                  How much rental income do you expect from this property? (Per
                  Month)
                </Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {rentalIncome.map((ri) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={ri}
                      >
                        <Box
                          onClick={() => setRentalIncome(ri)}
                          key={ri}
                          sx={lvrBoxSx}
                          style={{
                            backgroundColor:
                              formData.rentalIncome === ri
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          ${ri}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default FinancialDetails;
