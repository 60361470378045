import { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";
import { Container } from "@material-ui/core";
import Typography from "@mui/material/Typography";

const RiskAppetite = ({ formData, setFormData, defaultAssumptions }) => {
  const [expensivePrice, setExpensivePrice] = useState(200000);
  const [state, setState] = useAtom(selectedClientAtom);
  const LVR = [0, 50, 70, 80, 90, 100];
  const safetyBufferMonths = [1, 3, 6, 12, 24, 60];

  const lvrBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: '20px 20px 20px 32px',
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setPreferredLVR = (lvr) => {
    setFormData({ ...formData, preferredLVR: lvr });
  };

  const setSafetyBufferMonths = (sbm) => {
    setFormData({
      ...formData,
      monthsOfSafetyBuffer: sbm,
      isDefaultSafetyBufferMonths: "0",
    });
  };

  const handleExpensivePrice = (e) => {
    console.log("e: ", e);
    setExpensivePrice(e?.target?.value);
    setFormData({ ...formData, expensivePrice: expensivePrice });
  };

  return (
    <>
      <Card className="cccard">
        <CardHeader>
          <CardTitle tag="h4">
            Setup Risk Profile for {state.firstName} {state.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="6">
                <Label>
                  What price point would you consider too expensive (Per
                  Property)?
                </Label>
                <FormGroup className="fg-margin">
                  <Slider
                    defaultValue={200000}
                    value={formData.expensivePrice}
                    step={25000}
                    min={200000}
                    max={5000000}
                    valueLabelDisplay="auto"
                    onChange={(e) => {
                      handleExpensivePrice(e);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <Label>What LVR would help you sleep better at night?</Label>
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {LVR.map((lvr) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={lvr}
                      >
                        <Box
                          onClick={() => setPreferredLVR(lvr)}
                          key={lvr}
                          sx={lvrBoxSx}
                          style={{
                            backgroundColor:
                              formData.preferredLVR === lvr
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {lvr} %
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <Label>
                  How many months of a safety buffer of expenses would help you
                  sleep better at night?
                </Label>

                <FormGroup>
                  <Typography variant="caption" gutterBottom>
                    (Note: If not selected{" "}
                    <strong>{defaultAssumptions.SafetyBufferMonths}</strong>{" "}
                    would be selected as default )
                  </Typography>

                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {safetyBufferMonths.map((sbm) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={sbm}
                      >
                        <Box
                          onClick={() => setSafetyBufferMonths(sbm)}
                          key={sbm}
                          sx={lvrBoxSx}
                          style={{
                            backgroundColor:
                              formData.monthsOfSafetyBuffer === sbm
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {sbm}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default RiskAppetite;
