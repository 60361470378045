// In src/posts/PostCard.tsx
import { Draggable } from "@hello-pangea/dnd";
import { Avatar, Box, IconButton, Tooltip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { deepOrange, deepPurple } from "@mui/material/colors";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
} from "reactstrap";
import { CardActions } from "@material-ui/core"; // RAK
// import IconButton from "@material-ui/core/IconButton"; // RAK

import MoreVertIcon from "@mui/icons-material/MoreVert"; // RAK
import { formatCurrency } from "utils/currency";
import { Link } from "react-router-dom";
import DeleteDialog from "components/Dialog/DeleteDialog";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ChangeBuyerAgentIcon from "@mui/icons-material/ClearAll";
import AddDocumentsIcon from "@mui/icons-material/PostAdd";
import AssignClientIcon from "@mui/icons-material/AssignmentTurnedIn";
import ChangeDocIcon from "@mui/icons-material/ContentPasteGo";
import { InReviewCard } from "ui/new-properties/Cards/in-review-card";
import { statuses, statusId } from "./Statuses";
import { ApprovedCard } from "ui/new-properties/Cards/approved-card";
// import { ShowButton } from "react-admin";
import { useHistory } from "react-router-dom";
import SubMenu from "./SubMenu";
import ChangeBuyerAgent from "./ChangeBuyerAgent";
import AssignClient from "./AssignClient";
import UploadDocumentDialog from "../../ui/new-properties/Dialogs/upload-document/upload-document-dialog";
import ContractExchange from "./ContractExchange";

function getInitials(name) {
  // Split the name into an array of words
  const nameParts = name?.trim().split(" ");

  // Check if we have at least two parts (first name and last name)
  if (nameParts?.length >= 2) {
    // Get the first letter of the first and last name parts
    const firstInitial = nameParts[0].charAt(0);
    const lastInitial = nameParts[nameParts.length - 1].charAt(0);

    // Combine and return the initials
    return firstInitial + lastInitial;
  }

  // If there's not enough parts, return an empty string or handle accordingly
  return "";
}

export const PostCard = ({
  property,
  index,
  deleteProperty,
  fetchProperties,
}) => {
  const history = useHistory();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isBuyerAgentDialogOpen, setIsBuyerAgentDialogOpen] = useState(false);
  const [isContractExchangeDialogOpen, setIsContractExchangeDialogOpen] =
    useState(false);
  const [isAssignClientDialogOpen, setIsAssignClientDialogOpen] =
    useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [updatedProperty, setUpdatedProperty] = useState(property);

  const handleSubMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleEdit = (event) =>
    history.push(
      `/admin/fulfillment-pipeline/new-properties/add/${property.id}`
    );

  const handleChangeBuyerAgent = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setIsBuyerAgentDialogOpen(true);
  };

  const handleAssignClient = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setIsAssignClientDialogOpen(true);
  };

  const handleUploadDocuments = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    // handleSubMenuClose();
    handleUploadDialogOpen();
  };

  const handleUploadDialogOpen = (e) => {
    setUploadDialogOpen(true);
  };

  const handleUploadDialogClose = (e) => {
    setUploadDialogOpen(false);
  };

  // Working on Submenu
  const subMenuIconStyle = {
    minHeight: "12px",
    fontSize: "20px",
    color: "black",
    padding: 0,
    marginRight: "5px",
  };

  const subMenuTextStyle = {
    fontSize: "12px",
    color: "black",
    display: "flex",
    // justifyContent: "space-between",
  };

  const subMenuItems = [
    {
      text: "Edit",
      onClick: handleEdit,
      Icon: EditIcon,
      iconStyle: subMenuIconStyle,
      style: subMenuTextStyle,
    },
    {
      text: "Delete",
      onClick: (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        // setConfirmationOpen(true);
        setDeleteConfirmationOpen(true);
      },
      Icon: ClearIcon,
      iconStyle: subMenuIconStyle,
      style: subMenuTextStyle,
    },

    {
      text: "Change Buyer Agent",
      onClick: handleChangeBuyerAgent,
      Icon: ChangeBuyerAgentIcon,
      iconStyle: subMenuIconStyle,
      style: subMenuTextStyle,
    },

    {
      text: "Manage Documents",
      onClick: handleUploadDocuments,
      Icon: AddDocumentsIcon,
      iconStyle: subMenuIconStyle,
      style: subMenuTextStyle,
    },

    {
      text: "Assign Client",
      onClick: handleAssignClient,
      Icon: AssignClientIcon,
      iconStyle: subMenuIconStyle,
      style: subMenuTextStyle,
    },
  ];
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleDeleteConfirmationOpen = () => setDeleteConfirmationOpen(true);

  const APPROVED_ELEMENT_ID = 2;
  const completeAddress =
    updatedProperty?.address +
    ", " +
    updatedProperty?.suburb +
    "," +
    updatedProperty?.postcode +
    ", " +
    updatedProperty?.stateShortName;

  return (
    <>
      <DeleteDialog
        confirmationOpen={deleteConfirmationOpen}
        handleConfirmationClose={() => setDeleteConfirmationOpen(false)}
        onDelete={() => deleteProperty(property.id)}
      />

      {isBuyerAgentDialogOpen && (
        <ChangeBuyerAgent
          property={updatedProperty}
          setUpdatedProperty={setUpdatedProperty}
          onHandleSetBuyerAgentDialog={setIsBuyerAgentDialogOpen}
          fetchProperties={fetchProperties}
        />
      )}
      {isAssignClientDialogOpen && (
        <AssignClient
          property={updatedProperty}
          setUpdatedProperty={setUpdatedProperty}
          onHandleSetAssignClientDialog={setIsAssignClientDialogOpen}
          fetchProperties={fetchProperties}
        />
      )}

      <UploadDocumentDialog
        propertyId={property.id}
        propertyAddress={completeAddress}
        dialogOpen={uploadDialogOpen}
        handleDialogClose={handleUploadDialogClose}
      />

      <Draggable draggableId={String(property.id)} index={index}>
        {(provided, snapshot) => (
          <Box
            sx={{ marginBottom: 1 }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Card
              className="new-property-card"
              style={{
                opacity: snapshot.isDragging ? 0.9 : 1,
                transform: snapshot.isDragging ? "rotate(-2deg)" : "",
                cursor: "pointer",
              }}
              elevation={snapshot.isDragging ? 3 : 1}
              onClick={(e) =>
                history.push(
                  `/admin/fulfillment-pipeline/new-properties/add/${property.id}`
                )
              }
            >
              <CardHeader className="card-cross-btn">
                <div className="protfolio-list-header">
                  <IconButton aria-label="settings">
                    <MoreVertIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEl(e.currentTarget);
                      }}
                    />
                  </IconButton>
                  <SubMenu
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleSubMenuClose}
                    menuItems={subMenuItems}
                  />
                </div>
              </CardHeader>
              <CardBody className="new-property-card-body">
                <div className="new-property-card-content">
                  <Row>
                    <Col md="10">
                      <div>
                        <i className="new-property-icon nc-icon nc-bank"></i>{" "}
                        <b>Property ID: {updatedProperty.id}</b>
                      </div>
                      <div>
                        <b>Address</b>: {updatedProperty?.address}
                        {", "}
                        {updatedProperty?.suburb} , {updatedProperty?.postcode}
                        {", "}
                        {updatedProperty?.stateShortName}
                      </div>
                      <div>
                        <b>Configuration</b>: {updatedProperty.noOfBedRooms}/
                        {updatedProperty.noOfBathRooms}/?
                      </div>
                      <div>
                        <b>Price</b>:{" "}
                        {formatCurrency(updatedProperty.askingPrice)}
                      </div>
                      <div>
                        <b>Land Size</b>: {updatedProperty.landSize} m
                        <sup>2</sup>
                      </div>
                      <div>
                        <b>Frontage</b>: {updatedProperty.frontage} m
                      </div>
                      <div>
                        <b>Zone</b>: {updatedProperty.residentialZone}
                      </div>
                      <div>
                        <b>Rent</b>:{" "}
                        {formatCurrency(updatedProperty.expectedRentalIncome)}{" "}
                        per week
                      </div>
                      <div>
                        <b>Potential Yield</b>:{" "}
                        {updatedProperty.rentalYieldPercent}%
                      </div>
                      <div>
                        <b>Client Name</b>: {updatedProperty.currentClient}
                      </div>
                      <div>
                        <b>Documents Uploaded</b>:{" "}
                        {updatedProperty.mandatoryDocumentsNotReceived > 0
                          ? "No"
                          : "Yes"}
                      </div>
                    </Col>
                    <Col md="2" style={{ padding: 0 }}>
                      {updatedProperty.dueDiligence ? (
                        <DoneAllIcon style={{ color: "#0fc80f" }} />
                      ) : (
                        <DoneIcon style={{ color: "#0fc80f" }} />
                      )}
                    </Col>
                  </Row>
                </div>
              </CardBody>
              <CardActions>
                <Tooltip
                  title={updatedProperty.currentBuyerAgent}
                  placement="top"
                >
                  <Avatar
                    sx={{
                      bgcolor: deepOrange[500],
                      width: 30,
                      height: 30,
                      fontSize: "1.15vw",
                    }}
                  >
                    {getInitials(updatedProperty.currentBuyerAgent)}
                  </Avatar>
                </Tooltip>
                <Tooltip
                  title="Tahreem Qureshi"
                  placement="top"
                  // sx={{ position: "absolute", right: 15 }}
                >
                  <Avatar
                    sx={{
                      bgcolor: deepOrange[500],
                      position: "absolute",
                      right: 15,
                      width: 30,
                      height: 30,
                      fontSize: "1.15vw",
                    }}
                  >
                    TQ
                  </Avatar>
                </Tooltip>
              </CardActions>
            </Card>
            {property.status === statuses[APPROVED_ELEMENT_ID]
              ? // <ApprovedCard
                //   property={property}
                //   handleDeleteConfirmationOpen={handleDeleteConfirmationOpen}
                //   snapshot={snapshot}
                // />
                null
              : // <InReviewCard
                //   property={property}
                //   handleDeleteConfirmationOpen={handleDeleteConfirmationOpen}
                //   snapshot={snapshot}
                // />
                null}
          </Box>
        )}
      </Draggable>
    </>
  );
};
