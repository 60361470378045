import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
  Container,
} from "reactstrap";

import "react-datetime/css/react-datetime.css";
import "dayjs/locale/en-gb";
import Moment from "moment";
// import Select from "react-select";
import Select from "@mui/material/Select";
import "moment/locale/en-gb";
import { selectedClientAtom } from "store";
import { useAtom } from "jotai";
import PropertyPortfolioService from "services/property-portfolio.service";
import YearlyPlanRenovationService from "services/yearly-plan-renovation.service";
import YearlyPlanPurposeSwitchService from "services/yearly-plan-purpose-switch.service";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PurposeSwitchStep = ({ formData, setFormData }) => {
  const [clientState] = useAtom(selectedClientAtom);

  const [properties, setProperties] = useState([]);
  const [existingPurposeSwitches, setExistingPurposeSwitches] = useState([]);

  useEffect(() => {
    getProperties(clientState.clientId, formData?.year);
    getPurposeSwitches(formData?.yearlyPlanId);
  }, [clientState.clientId, formData?.yearlyPlanId]);

  const getProperties = async (clientId, year) => {
    return PropertyPortfolioService.getNonExcludedFromCalcForPurposeSwitchDropdownByClientId(
      clientId,
      year
    ).then((response) => {
      console.log("response: ", response.data.recordset);
      if (response.data.recordset.length > 0) {
        const result = response.data.recordset.map((property) => ({
          propertyId: property.value,
          propertyName: property.label,
          purposeSwitchType: getPurposeSwitchType(property.label),
        }));
        console.log("result : ", result);
        setProperties(result);
      }
    });
  };

  const getPurposeSwitches = async (yearlyPlanId) => {
    if (yearlyPlanId) {
      return YearlyPlanPurposeSwitchService.getAllByYearlyPlanId(
        yearlyPlanId
      ).then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset;
          console.log("result : ", result);
          setFormData({
            ...formData,
            purposeSwitchProperties: result.map(
              ({ ClientPropertyId, PropertyLabel, PurposeSwitchType }) => ({
                propertyId: ClientPropertyId,
                propertyName: PropertyLabel,
                purposeSwitchType: PurposeSwitchType,
              })
            ),
          });

          // console.log("form data: ", {
          //   ...formData,
          //   purposeSwitchProperties: result.map((r) => r.ClientPropertyId),
          // });
          setExistingPurposeSwitches(result);
        }
      });
    }
  };

  const getPurposeSwitchType = (propertyString) =>
    propertyString.toLowerCase().includes("ppor") ? "PPOR" : "Investment";

  const handlePropertyChange = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        const currProperty = properties.find(
          (p) => p.propertyName === options[i].value
        );
        value.push({
          propertyId: currProperty.propertyId,
          propertyName: options[i].value,
        });
      }
    }
    console.log("property change:  ", value);
    // const {
    //   target: { value },
    // } = event;
    setFormData({
      ...formData,
      // On autofill we get a stringified value.
      purposeSwitchProperties:
        typeof value === "string" ? value.split(",") : value,
    });

    // console.log("Form data from handle property change: ", {
    //   ...formData,
    //   // On autofill we get a stringified value.
    //   purposeSwitchProperties:
    //     typeof value === "string" ? value.split(",") : value,
    // });
    // if (!(property.value === formData.purposeSwitchClientPropertyId)) {
    //   const existingDataIndex = existingPurposeSwitches.findIndex(
    //     (r) => r.ClientPropertyId === property.value
    //   );
    //   if (existingDataIndex > -1) {
    //     setFormData({
    //       ...formData,
    //       yearlyPlanSwitchPurposeId:
    //         existingPurposeSwitches[existingDataIndex]
    //           .YearlyPlanSwitchPurposeId,
    //       purposeSwitchClientPropertyId: property.value,
    //       purposeSwitchType:
    //         existingPurposeSwitches[existingDataIndex].PurposeSwitchType,
    //       purposeSwitchRentalYield:
    //         existingPurposeSwitches[existingDataIndex].PurposeSwitchRentalYield,
    //     });
    //   } else {
    //     setFormData({
    //       ...formData,
    //       yearlyPlanSwitchPurposeId: null,
    //       purposeSwitchClientPropertyId: property.value,
    //       purposeSwitchType: getPurposeSwitchType(property.label),
    //       purposeSwitchRentalYield: null,
    //     });
    //   }
    // }
  };

  console.log("form data: ", formData);
  return (
    <div className="purpose-switch-modal main-pad">
      <Container>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">{formData?.year}</CardTitle>
          </CardHeader>
          <CardBody>
            <Form className="form-horizontal">
              <Row>
                <Col md="12">
                  <Label>Switch individual property purpose</Label>
                  <FormGroup>
                    {/* <Select
                      name="switch-property-purpose"
                      className="react-select  select-option-control"
                      placeholder=""
                      classNamePrefix="react-select"
                      value={properties.find(
                        (p) =>
                          p.value === formData.purposeSwitchClientPropertyId
                      )}
                      onChange={handlePropertyChange}
                      options={properties}
                    /> */}
                    <Select
                      // labelId="switch-property-label"
                      inputProps={{
                        id: "purpose-switch-property",
                      }}
                      // className="react-select  select-option-control"
                      // classNamePrefix="react-select"
                      // placeholder=""
                      native
                      multiple
                      sx={{
                        width: "70vw",
                      }}
                      value={formData?.purposeSwitchProperties?.map(
                        (p) => p.propertyName
                      )}
                      onChange={handlePropertyChange}
                      // input={<OutlinedInput style={{ width: "100%" }} />}
                    >
                      {properties.map(({ propertyId, propertyName }) => (
                        <option key={propertyId} value={propertyName}>
                          {propertyName}
                        </option>
                        // <MenuItem key={value} value={value}>
                        //   <Checkbox
                        //     checked={
                        //       formData?.loanSwitchProperties.indexOf(value) > -1
                        //     }
                        //   />
                        //   <ListItemText primary={label} />
                        // </MenuItem>
                      ))}
                    </Select>
                    {/* <Autocomplete
                      multiple
                      value={formData?.purposeSwitchProperties}
                      onChange={(_event, value) => {
                        // console.log(value);
                        handlePropertyChange(value);
                        // setValue(typeof value === 'string' ? value.split(',') : value);
                      }}
                      id="purpose-switch-property"
                      options={properties}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.propertyName}
                      isOptionEqualToValue={(option, value) =>
                        option.propertyId === value.propertyId
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.propertyName}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField {...params} label="" placeholder="" />
                      )}
                    /> */}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <Label>If switching, what is the new rental yield?</Label>
                  <FormGroup>
                    <Input
                      placeholder="Rental yield"
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      value={formData?.purposeSwitchRentalYield || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          purposeSwitchRentalYield: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="3" />
              <Col md="9"></Col>
            </Row>
          </CardFooter>
        </Card>
      </Container>
    </div>
  );
};

export default PurposeSwitchStep;
