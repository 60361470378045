import { Container, Typography } from "@mui/material";
import { React, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import goalAlignmentService from "services/goal-alignment.service";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../store";
import riskProfileService from "services/risk-profile.service";
import moneyManagementService from "services/money-management.service";
import familyMembersService from "services/family-member.service";
import assetSelectionService from "services/asset-selection.service";
import propertyPlanService from "services/property-plan.service";
import portfolioAnalysisService from "services/portfolio-analysis.service";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "utils/currency";
import mortgageStrategyService from "services/mortgage-strategy.service";
import propertyPortfolioService from "services/property-portfolio.service";
import Spinner from "utils/spinner";

const setDateForDisplay = (selectedDate) => {
  return selectedDate.slice(0, 10).split("-").reverse().join("/");
};

const Questions = () => {
  const [goalAlignment, setGoalAlignment] = useState({});
  const [riskProfile, setRiskProfile] = useState({});
  const [moneyManagement, setMoneyManagement] = useState([]);
  const [assetSelection, setAssetSelection] = useState({});
  const [propertyPlan, setPropertyPlan] = useState({});
  const [mortgageStrategy, setMortgageStrategy] = useState({});
  const [portfolioAnalysis, setPortfolioAnalysis] = useState({});
  const [familyMembers, setFamilyMembers] = useState([]);
  const [propertyPortfolio, setPropertyPortfolio] = useState([]);
  const [state, setState] = useAtom(selectedClientAtom);
  const [showSpinner, setShowSpinner] = useState(true);
  const history = useHistory();

  const GOAL_ALIGNMENT_PAGE = "goal-alignment";
  const RISK_PROFILE_ADD_PAGE = "risk-profile/add";
  const MONEY_MANAGEMENT_PAGE = "money-management/list";
  const MORTGAGE_STRATEGY_PAGE = "mortgage-strategy";
  const ASSET_SELECTION_ADD_PAGE = "asset-selection/add";
  const PROPERTY_PLAN_ADD_PAGE = "property-plan/add";
  const PORTFOLIO_ANALYSIS_ADD_PAGE = "portfolio-analysis";
  const FAMILY_MEMBER_ADD_PAGE = "family-members/list";
  const PROPERTY_PORTLIO_ADD_PAGE = "property-portfolio/list";

  useEffect(() => {
    getClientGoalAlignment(state?.clientId);
    getClientRiskProfile(state?.clientId);
    getClientMoneyManagement(state?.clientId);
    getClientAssetSelection(state?.clientId);
    getClientPropertyPlan(state?.clientId);
    getClientPortFolioAnalysis(state?.clientId);
    getFamilyMembers(state?.clientId);
    getMortgageStrategy(state?.clientId);
    getPropertyPortfolio(state?.clientId);
  }, [state?.clientId]);

  const getClientGoalAlignment = async (clientId) => {
    goalAlignmentService
      .getByClientId(clientId)
      .then((response) => {
        let goalAlignment = response.data.recordset[0];
        console.log("goalAlignment: ", goalAlignment);
        setGoalAlignment(goalAlignment);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setGoalAlignment({});
      });
  };

  const getClientRiskProfile = async (clientId) => {
    riskProfileService
      .getAllByClientId(clientId)
      .then((response) => {
        let riskProfile = response.data.recordset[0];
        console.log("riskProfile: ", riskProfile);
        setRiskProfile(riskProfile);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setRiskProfile({});
      });
  };

  const getClientMoneyManagement = async (clientId) => {
    moneyManagementService
      .getAllByClientId(clientId)
      .then((response) => {
        let moneyManagement = response.data.recordset;
        console.log("moneyManagement: ", moneyManagement);
        setMoneyManagement(moneyManagement);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setMoneyManagement({});
      });
  };

  const getClientAssetSelection = async (clientId) => {
    assetSelectionService
      .getAllByClientId(clientId)
      .then((response) => {
        let assetSelection = response.data.recordset[0];
        console.log("assetSelection: ", assetSelection);
        setAssetSelection(assetSelection);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setAssetSelection({});
      });
  };

  const getClientPropertyPlan = async (clientId) => {
    propertyPlanService
      .getAllByClientId(clientId)
      .then((response) => {
        let propertyPlan = response.data.recordset[0];
        console.log("propertyPlan: ", propertyPlan);
        setPropertyPlan(propertyPlan);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setPropertyPlan({});
      });
  };

  const getClientPortFolioAnalysis = async (clientId) => {
    portfolioAnalysisService
      .getByClientId(clientId)
      .then((response) => {
        let portfolioAnalysis = response.data.recordset[0];
        console.log("Portfolio Analysis: ", portfolioAnalysis);
        setPortfolioAnalysis(portfolioAnalysis);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setPortfolioAnalysis({});
      });
  };

  const getFamilyMembers = async (clientId) => {
    familyMembersService
      .getAllByClientId(clientId)
      .then((response) => {
        let familyMembers = response.data.recordset;
        console.log("Family Members: ", familyMembers);
        setFamilyMembers(familyMembers);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setFamilyMembers([]);
      });
  };

  const getPropertyPortfolio = async (clientId) => {
    propertyPortfolioService
      .getAllByClientId(clientId)
      .then((response) => {
        let propertyPortfolio = response.data.recordset;
        console.log("propertyPortfolio: ", propertyPortfolio);
        setPropertyPortfolio(propertyPortfolio);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setPropertyPortfolio([]);
      });
    setShowSpinner(false);
  };

  const getMortgageStrategy = async (clientId) => {
    mortgageStrategyService
      .getByClientId(clientId)
      .then((response) => {
        let mortgageStrategy = response.data.recordset[0];
        console.log("Mortgage Strategy: ", mortgageStrategy);
        setMortgageStrategy(mortgageStrategy);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setMortgageStrategy({});
      });
  };

  const redirectToScoreboardItem = (pageName) =>
    history.push(`/admin/scoreboard/${pageName}`);

  if (showSpinner) {
    return <Spinner />;
  }

  return (
    <div className="content default-questions-content">
      <Container>
        {/* {showSpinner && <Spinner />} */}
        <ClientNotSelectedDialog />
        <h3>Questions</h3>
        <Row>
          {/* Goal Allignment */}
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Goal Alignment</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal">
                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(GOAL_ALIGNMENT_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Desired retirement age
                        <span>{goalAlignment?.DesiredRetirementAge}</span>
                        <span>
                          {goalAlignment?.IsDefaultRetirementAge === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(GOAL_ALIGNMENT_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Amount of passive income required for retirement? PA
                        <span>
                          {formatCurrency(goalAlignment?.PassiveIncomeRequired)}
                        </span>
                        <span>
                          {goalAlignment?.IsDefaultPassiveIncome === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(GOAL_ALIGNMENT_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Desired net worth at retirement age?
                        <span>
                          {formatCurrency(goalAlignment?.DesiredNetWorth)}
                        </span>
                        <span>
                          {goalAlignment?.IsDefaultNetWorth === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(GOAL_ALIGNMENT_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How many properties do you want in your portfolio?
                        <span>{goalAlignment?.DesiredNoOfProperties}</span>
                      </Label>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>

          {familyMembers && familyMembers?.length > 0 && (
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Family Members</CardTitle>
                </CardHeader>
                {familyMembers.map((familyMember, i) => (
                  <CardBody>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(FAMILY_MEMBER_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          <span>
                            {i + 1} {" ) "}
                            {familyMember.MemberType} {" Member"}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(FAMILY_MEMBER_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Name:
                          <span>
                            {familyMember?.FirstName}, {familyMember?.LastName}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(FAMILY_MEMBER_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Phone:
                          <span> {familyMember?.Phone}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(FAMILY_MEMBER_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Email:
                          <span>{familyMember?.Email}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(FAMILY_MEMBER_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Date of Birth:
                          <span>
                            {setDateForDisplay(familyMember?.DateOfBirth)}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(FAMILY_MEMBER_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Country of Birth :
                          <span>{familyMember?.CountryOfBirth}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(FAMILY_MEMBER_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Gender :<span>{familyMember?.Gender}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(FAMILY_MEMBER_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Marital Status :
                          <span>{familyMember?.MaritalStatus}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(FAMILY_MEMBER_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Address: <span>{familyMember?.Address}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(FAMILY_MEMBER_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Housing Situation:
                          <span>{familyMember?.HousingSituation}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(FAMILY_MEMBER_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Date Moved:
                          <span>
                            {setDateForDisplay(familyMember?.DateMoved)}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                  </CardBody>
                ))}
                <CardFooter></CardFooter>
              </Card>
            </Col>
          )}

          {propertyPortfolio && propertyPortfolio?.length > 0 && (
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Property List</CardTitle>
                </CardHeader>
                {propertyPortfolio.map((property, i) => (
                  <CardBody>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          <span>
                            {"Property - "}
                            {i + 1}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Address:
                          <span>{property?.Address}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Property Type:
                          <span> {property?.PropertyType}</span>
                          <span>
                            {property?.IsDefaultPropertyType === true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Dwelling Type:
                          <span>{property?.DwellingType}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Beds / Baths / Car Space:{" "}
                          <span>
                            {property?.Beds}
                            {" / "}
                            {property?.Baths}
                            {" / "}
                            {property?.car}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Current Market Value :
                          <span>
                            {formatCurrency(property?.CurrentMarketValue)}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Exclude this property from all calcualtion?{" "}
                          <span>{property?.ExcludeFromCalc}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Year Build
                          <span>
                            {new Date(property?.YearBuild).getFullYear()}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Total Block Size
                          <span>{property?.LandSize}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Total Internal Building Size
                          <span>{property?.FloorSize}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Owner Type: <span>{property?.OwnerType}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Owner Name: <span>{property?.OwnerName}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Combined Ownership Percent:
                          <span>{property?.CombinedOwnershipPercentage}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Place property under separate entity:
                          <span>
                            {property?.PlacePropertyUnderSeparateEntity}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Date settled:
                          <span>
                            {new Date(property?.DateSettled).getFullYear()}
                          </span>
                          <span>
                            {property?.IsDefaultDateSettled === true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Purchase Price:
                          <span>{formatCurrency(property?.PurchasePrice)}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Stamp duty cost:
                          <span>{formatCurrency(property?.StampDutyCost)}</span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Lenders mortgage insurance cost:
                          <span>
                            {formatCurrency(
                              property?.LenderMortgageInsuranceCost
                            )}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Conveyencing cost:
                          <span>
                            {formatCurrency(property?.ConveyencingCost)}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Additional cost:
                          <span>
                            {formatCurrency(property?.AdditionalCost)}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Have depreciation schedule:
                          <span>{property?.DepreciationScheduleAvailable}</span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          What is the annual depreciation?
                          <span>{property?.AnnualDepreciation}</span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Current loan amount:
                          <span>{formatCurrency(property?.CurrentLoan)}</span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          When did your most recent loan commence?
                          <span>
                            {new Date(
                              property?.MostRecentLoanCommencedYear
                            ).getFullYear()}
                          </span>
                          <span>
                            {property?.IsDefaultMostRecentLoanCommencedYear ===
                            true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Lender:
                          <span>{property?.Lender}</span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Current interest rate:
                          <span>{property?.CurrentInterestRate}</span>
                          <span>
                            {property?.IsDefaultInterestRate === true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Original loan term:
                          <span>{property?.OriginalLoanTerm}</span>
                          <span>
                            {property?.IsDefaultOriginalLoanTerm === true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Loan type:
                          <span>{property?.LoanType}</span>
                          <span>
                            {property?.IsDefaultLoanType === true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Interest only term (years):
                          <span>{property?.InterestOnlyTerm}</span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Interest Rate Structure:
                          <span>{property?.InterestRateStructure}</span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Fixed term (years):
                          <span>{property?.FixedTerm}</span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Do you want us to automatically calculate the mortgage
                          repayment?
                          <span>{property?.AutoCalculateMortgageRepyment}</span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Current repayment amount:
                          <span>
                            {formatCurrency(property?.CurrentRepaymentAmount)}{" "}
                            {property?.CurrentRepaymentFrequency}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Loan Split:
                          <span>
                            {property?.SplitLoan === 1 ? "Yes" : "No"}
                          </span>
                          <span>
                            {property?.IsDefaultSplitLoan === true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                    {property?.SplitLoan === 1 && (
                      <>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Split loan portion:
                              <span>
                                formatCurrency( {property?.SplitLoanAmount})
                              </span>
                            </Label>
                          </Col>
                        </Row>

                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Split loan borrowed date:
                              <span>
                                {
                                  new Date(property?.SplitDateBorrowed)
                                    .getFullYear
                                }
                                )
                              </span>
                              <span>
                                {property?.IsDefaultSplitLoanDateBorrowed ===
                                true
                                  ? " (Default) "
                                  : ""}
                              </span>
                            </Label>
                          </Col>
                        </Row>

                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Split current interest rate:
                              <span>{property?.SplitCurrentInterestRate}</span>
                              <span>
                                {property?.IsDefaultSplitLoanCurrentInterestRate ===
                                true
                                  ? " (Default) "
                                  : ""}
                              </span>
                            </Label>
                          </Col>
                        </Row>

                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Split loan term:
                              <span>{property?.SplitOriginalTerm}</span>
                              <span>
                                {property?.IsDefaultSplitLoanTerm === true
                                  ? " (Default) "
                                  : ""}
                              </span>
                            </Label>
                          </Col>
                        </Row>

                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Split Interest only term (years):
                              <span>
                                {property?.SplitOriginalIOPeriodYears}
                              </span>
                            </Label>
                          </Col>
                        </Row>

                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Split Interest Rate Structure:
                              <span>
                                {property?.SplitLoanInterestRateStructure}
                              </span>
                            </Label>
                          </Col>
                        </Row>

                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Split Fixed term (years):
                              <span>
                                {property?.SplitOriginalFixedTermYears}
                              </span>
                            </Label>
                          </Col>
                        </Row>

                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Do you want us to automatically calculate the
                              mortgage repayment?
                              <span>
                                {
                                  property?.AutoCalculateSplitLoanMortgageRepyment
                                }
                              </span>
                            </Label>
                          </Col>
                        </Row>

                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Split Current repayment amount:
                              <span>
                                {property?.SplitCurrentRepaymentAmount}{" "}
                                {property?.SplitRepaymentFrequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Last Renovation Amount:
                          <span>
                            {formatCurrency(property?.LastRenovationAmount)}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Last Renovation Date:
                          <span>
                            {new Date(property?.LastRenovationDate).getFullYear}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Last Renovation Description:
                          <span>{property?.LastRenovationDesc}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Current Property Manager:
                          <span>{property?.CurrentPropertyManager}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Management Percentage:
                          <span>{property?.ManagementPercentage}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Rental Income (Per Week):
                          <span>
                            {formatCurrency(property?.RentalIncomePerWeek)}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          External Link:
                          <span>{property?.ExternalLink}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Expense Type:
                          <span>
                            {property?.PropertyExpensesCalculationMethod}
                          </span>
                          <span>
                            {property?.IsDefaultPropertyExpensesCalculationMethod ===
                            true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    {property?.PropertyExpensesCalculationMethod ===
                      "Breakdown" && (
                      <>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Strata Cost:
                              <span>
                                {formatCurrency(property?.StrataCost)}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Strata Cost:
                              <span>
                                {formatCurrency(property?.StrataCost)}{" "}
                                {property?.StrataCostFrequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Council Cost:
                              <span>
                                {formatCurrency(property?.CouncilCost)}{" "}
                                {property?.CouncilCostFrequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Insurnace Cost:
                              <span>
                                {formatCurrency(property?.InsurnaceCost)}{" "}
                                {property?.InsurnaceCostFrequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Maintenance Cost:
                              <span>
                                {formatCurrency(property?.MaintenanceCost)}{" "}
                                {property?.MaintenanceCostFrequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Land Taxt:
                              <span>
                                {formatCurrency(property?.LandTaxAmount)}{" "}
                                {property?.LandTaxAmountFrequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>

                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Accounting Taxt:
                              <span>
                                {formatCurrency(property?.AccountingCost)}{" "}
                                {property?.AccountingCostFrequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Asic Cost:
                              <span>
                                {formatCurrency(property?.AsicCost)}{" "}
                                {property?.AsicCostFrequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Water Cost:
                              <span>
                                {formatCurrency(property?.WaterCost)}{" "}
                                {property?.WaterCostFequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Electrical Cost:
                              <span>
                                {formatCurrency(property?.ElectricalCost)}{" "}
                                {property?.ElectricalCostFrequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Gas Cost:
                              <span>
                                {formatCurrency(property?.GasCost)}{" "}
                                {property?.GasCostFrequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Internet Cost:
                              <span>
                                {formatCurrency(property?.InternetCost)}{" "}
                                {property?.InternetCostFrequency}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                      </>
                    )}

                    {property?.PropertyExpensesCalculationMethod ===
                      "Summarise" && (
                      <>
                        <Row
                          onClick={() =>
                            redirectToScoreboardItem(PROPERTY_PORTLIO_ADD_PAGE)
                          }
                        >
                          <Col md="12">
                            <Label className="label-row">
                              Total Expense:
                              <span>
                                {formatCurrency(property?.TotalExpenses)}{" "}
                                {property?.ExpensesFrequency}
                              </span>
                              <span>
                                {property?.IsDefaultTotalExpensesFrequency ===
                                true
                                  ? " (Default) "
                                  : ""}
                              </span>
                            </Label>
                          </Col>
                        </Row>
                      </>
                    )}
                  </CardBody>
                ))}
                <CardFooter></CardFooter>
              </Card>
            </Col>
          )}

          {/* Risk Profile*/}
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Risk Profile</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal">
                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(RISK_PROFILE_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        What would best describe wealth to you?
                        <span>{riskProfile?.WealthPurpose}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(RISK_PROFILE_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Would you prefer capital growth or cashflow?
                        <span>{riskProfile?.CapitalGrowthOrCashFlow}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(RISK_PROFILE_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Which type of investor best describes you?
                        <span>{riskProfile?.InvestorType}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(RISK_PROFILE_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How many properties do you want in your portfolio?
                        <span>{}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(RISK_PROFILE_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Can you see your current circumstances changing in the
                        next 5 years?
                        <span>{riskProfile?.ChangeInNext5Years}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(RISK_PROFILE_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Would your current risk appetite change in these
                        circumstances?
                        <span>
                          {riskProfile?.IsRiskAppetiteChanging ? "Yes" : "No"}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(RISK_PROFILE_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        What price point would you consider too expensive (Per
                        Property)?
                        <span>
                          {formatCurrency(riskProfile?.ExpensivePrice)}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(RISK_PROFILE_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        What LVR would help you sleep better at night?
                        <span>{riskProfile?.PreferredLVR}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(RISK_PROFILE_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How many months of a safety buffer of expenses would
                        help you sleep better at night?
                        <span>{riskProfile?.SafetyBufferMonths}</span>
                        <span>
                          {riskProfile?.IsDefaultSafetyBufferMonths === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>

          {moneyManagement && moneyManagement?.length > 0 && (
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Money Management</CardTitle>
                </CardHeader>
                {moneyManagement.map((memberMoneyManagement, i) => (
                  <CardBody>
                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          <span>
                            {i + 1} {" ) "}
                            {memberMoneyManagement.FirstName}{" "}
                            {memberMoneyManagement.LastName}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Gross Annual Income
                          <span>
                            {formatCurrency(
                              memberMoneyManagement.GrossAnnualIncome
                            )}
                          </span>
                          <span>
                            {memberMoneyManagement?.IsDefaultGrossAnnualIncome ===
                            true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Income Tax Rate
                          <span>
                            {formatCurrency(
                              memberMoneyManagement?.IncomeTaxRate
                            )}
                          </span>
                          <span>
                            {memberMoneyManagement?.IsDefaultIncomeTaxRate ===
                            true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          How much does this family member have in total liquid
                          savings? (Savings/Offset/Redraw/Inc Buffers)
                          <span>
                            {" "}
                            {formatCurrency(
                              memberMoneyManagement.TotalLiquidSavings
                            )}
                          </span>
                          <span>
                            {memberMoneyManagement?.IsDefaultTotalLiquidSavings ===
                            true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Superannuation or SMSF Value
                          <span>{memberMoneyManagement.TotalInSuper}</span>
                          <span>
                            {memberMoneyManagement?.IsDefaultTotalInSuper ===
                            true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Allocate Income to Superannuation or SMSF
                          <span>
                            {memberMoneyManagement.AllocateIncomeToSuper}
                          </span>
                          <span>
                            {memberMoneyManagement?.IsDefaultAllocateIncomeToSuper ===
                            true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Rent/Mortgage/Board Per Month<span>{}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Owner Occupier Utilities - Including Land Tax Expenses
                          Per Month<span>{}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Childcare Expenses Per Month<span>{}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Clothing & Personal Care Expenses Per Month
                          <span>{}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Education Expenses Per Month<span>{}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Groceries Expenses Per Month<span>{}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Insurances Total Expenses Per Month<span>{}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Medical & Health Expenses Per Month<span>{}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Entertainment Expenses Per Month<span>{}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Connections (Phone, TV Etc) Expenses Per Month
                          <span>{}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          Additional Transport Expenses Per Month
                          <span>{}</span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          How much does this family member save on average per
                          month?
                          <span>
                            {formatCurrency(
                              memberMoneyManagement.MonthlyAvgSaving
                            )}
                          </span>
                          <span>
                            {memberMoneyManagement?.IsDefaultMonthlyAvgSaving ===
                            true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          How much does this family member spend on expenses per
                          month?
                          <span>
                            {formatCurrency(
                              memberMoneyManagement.MonthlyExpenses
                            )}
                          </span>
                          <span>
                            {memberMoneyManagement?.IsDefaultMonthlyExpenses ===
                            true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          How much does this family member spend on lifestyle
                          per month?
                          <span>
                            {" "}
                            {formatCurrency(
                              memberMoneyManagement.MonthlyLifeStyleSpending
                            )}
                          </span>
                          <span>
                            {memberMoneyManagement?.IsDefaultMonthlyLifeStyleSpending ===
                            true
                              ? " (Default) "
                              : ""}
                          </span>
                        </Label>
                      </Col>
                    </Row>

                    <Row
                      onClick={() =>
                        redirectToScoreboardItem(MONEY_MANAGEMENT_PAGE)
                      }
                    >
                      <Col md="12">
                        <Label className="label-row">
                          I dont know exactly how much I spend or save, help me
                          with a budget!
                          <span>
                            {memberMoneyManagement?.NeedHelpWithBudget === true
                              ? "True"
                              : "False"}
                          </span>
                        </Label>
                      </Col>
                    </Row>
                  </CardBody>
                ))}
                <CardFooter></CardFooter>
              </Card>
            </Col>
          )}

          {/* Mortgage Strategy */}
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Mortgage Strategy</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal">
                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(MORTGAGE_STRATEGY_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Do you wish to have a Principal & Interest or Interest
                        only loan?
                        <span>{mortgageStrategy?.LoanRepaymentType}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(MORTGAGE_STRATEGY_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Do you wish to have a Fixed or Variable loan?
                        <span>{mortgageStrategy?.LoanType}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(MORTGAGE_STRATEGY_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Do you wish to use an offset account?
                        <span>
                          {mortgageStrategy?.LoanRepaymentType ? "Yes" : "No"}
                        </span>
                      </Label>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>

          {/* Asset Selection*/}
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Asset Selection</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal">
                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How soon are you ready to buy your next investment
                        property?
                        <span>{assetSelection?.NextPropertyTimePeriod}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Is there potential for this to change purpose in the
                        future?<span>{assetSelection?.ChangeInPurpose}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Are you planning to sell any property in the next 10
                        years?
                        <span>
                          {" "}
                          {assetSelection?.SellingPlan === true
                            ? "True"
                            : "False"}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        What type of dwelling would you prefer?
                        <span>{assetSelection?.DwellingType}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How many beds?<span>{assetSelection?.NoOfBeds}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How many baths?
                        <span>{assetSelection?.NoOfBaths}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How many car spaces?
                        <span>{assetSelection?.NoOfCarSpaces}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Land size requirements?
                        <span>{assetSelection?.LandSize}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Would you prefer existing or new property?
                        <span>{assetSelection?.NewOrExisting}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        What price point are you thinking?
                        <span>
                          {formatCurrency(assetSelection?.PricePoint)}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Are you currently pre-approved?
                        <span>
                          {" "}
                          {assetSelection?.PreApproved === true
                            ? "True"
                            : "False"}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        If so, what is the total loan amount?
                        <span>
                          {formatCurrency(assetSelection?.TotalLoanAmount)}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How much of a deposit would you be willing to use?
                        <span>{assetSelection?.Deposit}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How much capital growth would you like over the next 3
                        years?<span>{assetSelection?.CapitalGrowth}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How much rental income do you expect from this property?
                        Per Month
                        <span>
                          {formatCurrency(assetSelection?.RentalIncome)}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Do you have a preferred suburb/area?
                        <span>{assetSelection?.PreferredSuburb}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        What radius would you buy within this area? KM
                        <span>{assetSelection?.BuyingAreaRadius}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Would you consider buying outside of this area?
                        <span>
                          {assetSelection?.BuyingOutsideTheArea === true
                            ? "True"
                            : "False"}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Would you consider buying in another state?
                        <span>
                          {assetSelection?.BuyingInAnotherState === true
                            ? "True"
                            : "False"}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(ASSET_SELECTION_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Any other notes for this next property?
                        <span>{assetSelection?.Notes}</span>
                      </Label>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>

          {/* Portfolio Analysis*/}
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Portfolio Analysis</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal">
                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PORTFOLIO_ANALYSIS_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        What plans do you have for your existing investments??
                        <span>{portfolioAnalysis?.InvestmentPlan}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PORTFOLIO_ANALYSIS_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Are you happy with the portfolio so far?
                        <span>{portfolioAnalysis?.PortfolioStatus}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PORTFOLIO_ANALYSIS_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        What areas do you feel need the most attention?
                        <span>{portfolioAnalysis?.AreaOfAttention}</span>
                      </Label>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>

          {/* Property Plan */}
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Property Plan</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal">
                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Which year will this purchase be?
                        <span>{propertyPlan?.PurchaseYear}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Are you looking to buy a principal place of residence in
                        the future?
                        <span>
                          {" "}
                          {propertyPlan?.PlaceOfResidence === true
                            ? "True"
                            : "False"}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How much would you spend?
                        <span>
                          {formatCurrency(propertyPlan?.MoneySpending)}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Which year will your next vehicle purchase be?
                        <span>{propertyPlan?.NextCarPurchaseYear}</span>
                        <span>
                          {propertyPlan?.IsDefaultNextCarPurchaseYear === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How often would you like to purchase a new vehicle?
                        (Years)<span>{propertyPlan?.NewCarFrequency}</span>
                        <span>
                          {propertyPlan?.IsDefaultNextCarPurchaseFrequency ===
                          true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        What would the cash contribution be per vehicle? If you
                        are financing this vehicle purchase, only enter the
                        amount you will be personally contributing outside of
                        what you have already considered in your monthly
                        savings.
                        <span>
                          {formatCurrency(
                            propertyPlan?.NextCarCashContribution
                          )}
                        </span>
                        <span>
                          {propertyPlan?.IsDefaultNextCarCashContribution ===
                          true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Which year will your next holiday be?
                        <span>{propertyPlan?.NextHolidayYear}</span>
                        <span>
                          {propertyPlan?.IsDefaultNextHolidayYear === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How often would you like to go on a holiday? (Years)
                        <span>{propertyPlan?.HolidayFrequency}</span>
                        <span>
                          {propertyPlan?.IsDefaultNextHolidayFrequency === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How much would you spend per holiday? (Only enter if
                        this has not already been considered in your savings.)
                        <span>
                          {formatCurrency(propertyPlan?.NextHolidaySpending)}
                        </span>
                        <span>
                          {propertyPlan?.IsDefaultNextHolidaySpending === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How frequently do you pay for education? University,
                        School Fees (Years)
                        <span>{propertyPlan?.EducationFeeFrequency}</span>
                        <span>
                          {propertyPlan?.IsDefaultEducationFeeFrequency === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Which year will the next education expense be?
                        <span>{propertyPlan?.NextEducationExpenseYear}</span>
                        <span>
                          {propertyPlan?.IsDefaultNextEducationExpenseYear ===
                          true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Which year will the next education expense stop?
                        <span>{propertyPlan?.YearEducationExpenseStops}</span>
                        <span>
                          {propertyPlan?.IsDefaultYearEducationExpenseStops ===
                          true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How much would you spend per education cycle?
                        <span>
                          {formatCurrency(propertyPlan?.EducationCycleSpending)}
                        </span>
                        <span>
                          {propertyPlan?.IsDefaultEducationCycleSpending ===
                          true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How frequently do you pay for a second education?
                        University, School Fees (Years)
                        <span>
                          {" "}
                          {propertyPlan?.SecondEducationFeeFrequency}
                        </span>
                        <span>
                          {propertyPlan?.IsDefaultSecondEducationFeeFrequency ===
                          true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Which year will the next education expense be?
                        <span>{propertyPlan?.SecondEducationExpenseYear}</span>
                        <span>
                          {propertyPlan?.IsDefaultSecondEducationExpenseYear ===
                          true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Which year will the next education expense stop?
                        <span>
                          {" "}
                          {propertyPlan?.YearSecondEducationExpenseStops}
                        </span>
                        <span>
                          {propertyPlan?.IsDefaultYearSecondEducationExpenseStops ===
                          true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How much would you spend per education cycle?
                        <span>
                          {formatCurrency(
                            propertyPlan?.SecondEducationCycleSpending
                          )}
                        </span>
                        <span>
                          {propertyPlan?.IsDefaultSecondEducationCycleSpending ===
                          true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Are there any other large upcoming/ongoing expenses we
                        should account for?
                        <span>{propertyPlan?.OtherExpense}</span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How frequently do you pay for these expenses? (Years)
                        <span>{propertyPlan?.OtherExpenseFrequency}</span>
                        <span>
                          {propertyPlan?.IsDefaultOtherExpenseFrequency === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Which year will this other expense be?
                        <span>{propertyPlan?.OtherExpenseYear}</span>
                        <span>
                          {propertyPlan?.IsDefaultOtherExpenseYear === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        Which year will this other expense stop?
                        <span>{propertyPlan?.YearOtherExpenseStops}</span>
                        <span>
                          {propertyPlan?.IsDefaultYearOtherExpenseStops === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>

                  <Row
                    onClick={() =>
                      redirectToScoreboardItem(PROPERTY_PLAN_ADD_PAGE)
                    }
                  >
                    <Col md="12">
                      <Label className="label-row">
                        How much would you spend per expense cycle?
                        <span>
                          {formatCurrency(propertyPlan?.OtherExpenseSpending)}
                        </span>
                        <span>
                          {propertyPlan?.IsDefaultOtherExpenseSpending === true
                            ? " (Default) "
                            : ""}
                        </span>
                      </Label>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Questions;
