import http from './http-common';

class PortfolioAnalysisService {

    // http://localhost:5000/api/v1/scoreboard/portfolio-analysis/add
    add(data) {
        console.log('data: ', data);
        const path = '/scoreboard/portfolio-analysis';
        console.log('data: ', data);
        return http.post(`${path}/add`, data);
    };

    // http://localhost:5000/api/v1/scoreboard/portfolio-analysis/get/:id
    getByClientId(clientId) {
        const path = '/scoreboard/portfolio-analysis';
        return http.get(`${path}/get/${clientId}`);
    };

    // http://localhost:5000/api/v1/scoreboard/portfolio-analysis/get/:id
    update(data, id) {
        const path = '/scoreboard/portfolio-analysis';
        return http.put(`${path}/update/${id}`, data);
    }

}

export default new PortfolioAnalysisService();
