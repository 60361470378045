import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
  Container,
} from "reactstrap";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../../store";
import YearlyPlanService from "services/yearly-plan.service";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { formatCurrency } from "utils/currency";

const NewPropertyStep = ({ formData, setFormData }) => {
  const [clientState] = useAtom(selectedClientAtom);
  const [userState] = useAtom(userAtom);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [yearlyPlanProperty, setYearlyPlanProperty] = useState({});
  const purchaseType = [
    "Residential",
    "Principal place of residence",
    "Commercial",
  ];
  const loanType = ["Interest only", "Principal & interest"];
  const loanToValueRatio = [0, 25, 50, 60, 70, 80, 85, 90, 100, 105, 110];

  const boxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const lvrBox = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  useEffect(() => {
    // if (userState.userId === 0) {
    const jwtToken = window.sessionStorage.getItem("jwt-token");
    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
    } else {
      console.log("form data: ", formData);
      setIsDataLoading(true);
      getNewPropertyDetailsByYearlyPlanId(formData?.yearlyPlanId);
    }
  }, [userState.userId]);

  const getNewPropertyDetailsByYearlyPlanId = async (id) => {
    YearlyPlanService.getYearlyPlanPropertyByYearlyPlanId(id)
      .then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const yearlyPlanProperty = response.data.recordset[0];
          console.log("result : ", yearlyPlanProperty);
          setFormData({
            ...formData,
            yearlyPlanPropertyId: yearlyPlanProperty.YearlyPlanPropertyId,
            purchasePrice: formatCurrency(yearlyPlanProperty.PurchasePrice),
            purchaseType: yearlyPlanProperty.PurchaseType,
            loanType: yearlyPlanProperty.LoanType,
            loanToValueRatio: yearlyPlanProperty.LoantoValueRatio,
            compoundGrowth: yearlyPlanProperty.CompoundGrowth,
            rentalYield: yearlyPlanProperty.RentalYield,
            rentalIncrease: yearlyPlanProperty.RentalIncreasePA,
            interestRate: yearlyPlanProperty.InterestRate,
            propertyName: yearlyPlanProperty.PropertyName,
            placeUnderSMSF: yearlyPlanProperty.PlaceUnderSMSF,
          });
          setYearlyPlanProperty(yearlyPlanProperty);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  };

  const setPurchaseType = (pt) => {
    // // setSelectedPurchaseType(pt);
    // setYearlyPlanProperty({ ...yearlyPlanProperty, purchaseType: pt });
    // console.log("yearly plan property: ", yearlyPlanProperty);
    setFormData({ ...formData, purchaseType: pt });
  };

  const setLoanType = (lt) => {
    // setSelectedLoanType(lt);
    setFormData({ ...formData, loanType: lt });
  };

  const setLoanToValueRatio = (lvr) => {
    // setSelectedLoanRatio(lvr);
    setFormData({ ...formData, loanToValueRatio: lvr });
  };

  console.log("form data: ", formData);
  return (
    <div className="modal-property-step main-pad">
      <Card>
        <CardHeader className="modal-year-pad">
          <CardTitle tag="h4">{formData.year}</CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Container>
              <Row>
                <Col md="4">
                  <Label>New purchase value</Label>
                  <FormGroup>
                    <CurrencyInput
                      placeholder="New purchase value"
                      number={formData?.purchasePrice}
                      handleNumberChange={(value) =>
                        setFormData({ ...formData, purchasePrice: value })
                      }
                    />
                  </FormGroup>
                </Col>

                <Col md="4">
                  <Label>Custom Compund Growth</Label>
                  <FormGroup>
                    <Input
                      placeholder="Custom Compound Growth"
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      value={formData?.compoundGrowth || 0}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          compoundGrowth: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Custom Rental Yield</Label>
                  <FormGroup>
                    <Input
                      placeholder="Custom Rental Yield"
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      value={formData?.rentalYield || 0}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          rentalYield: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Custom Rental Increase per annum</Label>
                  <FormGroup>
                    <Input
                      placeholder="Custom Rental increaes per annum"
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      value={formData?.rentalIncrease || 0}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          rentalIncrease: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Custom Interest Rate</Label>
                  <FormGroup>
                    <Input
                      placeholder="Custom Interest Rate"
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      value={formData?.interestRate || 0}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          interestRate: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Label>Purchase name</Label>
                  <FormGroup>
                    <Input
                      placeholder="Purchase name"
                      type="text"
                      value={formData?.propertyName || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          propertyName: e.target.value,
                        })
                      }
                    />
                    <span
                      style={{
                        color: "red",
                        fontSize: "10px",
                        paddingLeft: "8px",
                      }}
                    >
                      {" "}
                      Purchase name is required
                    </span>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <Label>Type of Purchase</Label>
                  <FormGroup>
                    <Grid
                      sx={{ display: "flex", flexDirection: "row" }}
                      className="big_box-group"
                    >
                      {purchaseType.map((pt) => (
                        <Grid
                          sx={{ flexDirection: "column", padding: "4px" }}
                          key={pt}
                          className="dialog_big_box"
                        >
                          <Box
                            onClick={() => setPurchaseType(pt)}
                            key={pt}
                            sx={boxSx}
                            style={{
                              backgroundColor:
                                formData.purchaseType === pt
                                  ? "#e61a4b"
                                  : "#114253",
                            }}
                          >
                            {pt}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <Label>Loan Type</Label>
                  <FormGroup>
                    <Grid
                      sx={{ display: "flex", flexDirection: "row" }}
                      className="big_box-group"
                    >
                      {loanType.map((lt) => (
                        <Grid
                          sx={{ flexDirection: "column", padding: "4px" }}
                          key={lt}
                          className="dialog_big_box"
                        >
                          <Box
                            onClick={() => setLoanType(lt)}
                            key={lt}
                            sx={boxSx}
                            style={{
                              backgroundColor:
                                formData.loanType === lt
                                  ? "#e61a4b"
                                  : "#114253",
                            }}
                          >
                            {lt}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <Label>Loan to Value Ratio</Label>
                  <FormGroup>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {loanToValueRatio.map((lvr) => (
                        <Grid
                          className="small_box"
                          sx={{ flexDirection: "column", padding: "4px" }}
                          key={lvr}
                        >
                          <Box
                            onClick={() => setLoanToValueRatio(lvr)}
                            key={lvr}
                            sx={lvrBox}
                            style={{
                              backgroundColor:
                                formData.loanToValueRatio === lvr
                                  ? "#e61a4b"
                                  : "#114253",
                            }}
                          >
                            {lvr}%
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Col>
                <Col md="4">
                  {/* <Input
                    className="modal-check-box"
                    type="checkbox"
                    checked={formData.placeUnderSMSF}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        placeUnderSMSF: !formData.placeUnderSMSF,
                      })
                    }
                  />
                  <Label>Place under SMSF</Label> */}

                  <FormGroup check className="text-left">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={formData.placeUnderSMSF}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            placeUnderSMSF: !formData.placeUnderSMSF,
                          })
                        }
                      />
                      <span className={`form-check-sign`} />
                      Place under SMSF
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="4"></Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
};

export default NewPropertyStep;
