import Highcharts from "highcharts";
export const dummyPropertyCashflowOptions: Options = {
  chart: {
    type: "column",
    height: "280px",
  },
  title: {
    text: "",
  },
  plotOptions: {
    column: {
      colorByPoint: true,
    },
    dataLabels: {
      enabled: false,
    },
  },
  tooltip: {
    formatter: function () {
      return (
        this.x +
        "<br/>" +
        "Cashflow: $ " +
        Highcharts.numberFormat(this.y, 0, ".", ",")
      );
    },
  },
  series: [
    {
      // `type: column` is required for type-checking this options as a column series
      type: "column",
      data: [-200, 300, 100],
      showInLegend: false,
      name: "Cashflow",
    },
  ],
  xAxis: {
    categories: ["12 New St", "30 Bolding St", "10 Fold Rd"],
    labels: {
      useHTML: true,
      // formatter: () => "",
    },
  },
  yAxis: {
    // min: 0,
    title: {
      text: "Cashflow",
    },
  },
  legend: {
    enabled: false,
  },
};

export const dummyRentalYieldGraphOptions: Options = {
  chart: {
    type: "column",
    height: "280px",
  },
  title: {
    text: "",
  },
  plotOptions: {
    column: {
      colorByPoint: true,
    },
    dataLabels: {
      enabled: false,
    },
  },
  tooltip: {
    formatter: function () {
      return (
        this.x +
        "<br/>" +
        "Rental Yield: " +
        Highcharts.numberFormat(this.y, 2, ".", ",") +
        " %"
      );
    },
  },
  series: [
    {
      // `type: column` is required for type-checking this options as a column series
      showInLegend: false,
      name: "Rental yield",
      type: "column",
      data: [4.23, 2.35, 3.35],
    },
  ],
  xAxis: {
    categories: ["12 New St", "30 Bolding St", "10 Fold Rd"],
    labels: {
      useHTML: true,
      // formatter: () => "",
    },
  },
  yAxis: {
    // min: 0,
    title: {
      text: "Rental Yield",
    },
  },
  legend: {
    enabled: false,
  },
};

export const dummyGrowthGraphOptions: Options = {
  chart: {
    type: "column",
    height: "280px",
  },
  title: {
    text: "",
  },
  plotOptions: {
    column: {
      colorByPoint: true,
      formatter: function () {
        return Highcharts.numberFormat(this.x) + "%";
      },
    },
  },
  tooltip: {
    formatter: function () {
      return (
        this.x +
        "<br/>" +
        "Growth: " +
        Highcharts.numberFormat(this.y, 2, ".", ",") +
        " %"
      );
    },
  },
  series: [
    {
      // `type: column` is required for type-checking this options as a column series
      type: "column",
      data: [30.2, 14.5, 40.3],
      showInLegend: false,
      name: "Growth",
      label: { enabled: false },
    },
  ],
  xAxis: {
    categories: ["12 New St", "30 Bolding St", "10 Fold Rd"],
    labels: {
      useHTML: true,
      // formatter: () => "",
    },
  },
  yAxis: {
    // min: 0,
    title: {
      text: "Realised growth per annum",
    },
  },
  legend: {
    enabled: false,
  },
};

export const dummyBorrowableEquityGraphOptions: Options = {
  chart: {
    type: "column",
    height: "280px",
  },
  title: {
    text: "",
  },
  plotOptions: {
    column: {
      colorByPoint: true,
    },
  },
  tooltip: {
    formatter: function () {
      return (
        this.x + "<br/>" + "$ " + Highcharts.numberFormat(this.y, 2, ".", ",")
      );
    },
  },
  colors: ["#5BC2AE", "#F3BE31", "#795BD0"],
  series: [
    {
      // `type: column` is required for type-checking this options as a column series
      type: "column",
      data: [6000000, 1500000, 3400000],
      showInLegend: false,
      name: "$ Amount",
    },
  ],
  xAxis: {
    categories: ["New Debt @80%", "Current Debt", "Borrowable Equity"],
    labels: {
      useHTML: true,
      enabled: true,
    },
  },
  yAxis: {
    // min: 0,
    title: {
      text: "",
    },
  },
  legend: {
    enabled: false,
  },
};

export const dummyLoanToValueRatioGraphOptions: Options = {
  chart: {
    type: "pie",
    height: "280px",
  },
  title: {
    text: "",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
        formatter: function () {
          return Highcharts.numberFormat(this.y) + "%";
        },
      },
      showInLegend: true,
    },
  },
  tooltip: {
    formatter: function () {
      return Highcharts.numberFormat(this.y) + "%";
    },
  },
  colors: ["#F3BE31", "#795BD0"],
  series: [
    {
      // `type: column` is required for type-checking this options as a column series
      name: "",
      type: "pie",
      data: [
        {
          name: "Debt",
          y: 560,
        },
        {
          name: "Equity",
          y: 700,
        },
      ],
    },
  ],

  legend: {
    enabled: true,
  },
};

export const dummyPortfolioCashflowGraphOptions: Options = {
  chart: {
    type: "column",
    height: "280px",
  },
  title: {
    text: "",
  },
  plotOptions: {
    column: {
      colorByPoint: true,
    },
  },
  tooltip: {
    formatter: function () {
      return (
        this.x + "<br/>" + "$ " + Highcharts.numberFormat(this.y, 2, ".", ",")
      );
    },
  },
  colors: ["#5BC2AE", "#FF4E4E", "#795BD0"],
  series: [
    {
      // `type: column` is required for type-checking this options as a column series
      type: "column",
      data: [7000, 40000, 10000],
    },
  ],
  xAxis: {
    categories: ["Expense", "Income", "Cashflow"],
    labels: {
      useHTML: true,
      enabled: true,
    },
  },
  yAxis: {
    // min: 0,
    title: {
      text: "",
    },
  },
  legend: {
    enabled: false,
  },
};

export const dummySavingsAndExpenesGraphOptions: Options = {
  chart: {
    type: "pie",
    height: "280px",
  },
  title: {
    text: "",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  tooltip: {
    pointFormat: "$ {point.y}",
  },

  colors: ["#F3BE31", "#5BC2AE", "#142a37", "#795BD0"],
  series: [
    {
      name: "",
      type: "pie",
      data: [
        { name: "Savings", y: 1000 },
        { name: "Expenses", y: 5000 },
        { name: "Lifestyle", y: 1000 },
        { name: "Portfolio", y: 1000 },
      ],
    },
  ],

  legend: {
    enabled: true,
  },
};

export const dummySafetyBufferGraphOptions: Options = {
  chart: {
    type: "column",
    height: "280px",
  },
  title: {
    text: "",
  },
  plotOptions: {
    column: {
      colorByPoint: true,
    },
  },
  tooltip: {
    formatter: function () {
      return (
        this.x + " years: $ " + Highcharts.numberFormat(this.y, 0, ".", ",")
      );
    },
  },
  colors: ["#142a37"],
  series: [
    {
      // `type: column` is required for type-checking this options as a column series
      type: "column",
      data: [
        50678, 101358, 152127, 203139, 254348, 305807, 357554, 409627, 462054,
        514861, 528074, 550719, 675822, 730411,
      ],
      showInLegend: false,
      name: "$ Amount",
    },
  ],
  xAxis: {
    categories: ["1", "3", "6", "12", "18", "24", "36", "48", "60"],
    labels: {
      useHTML: true,
      enabled: true,
    },
  },
  yAxis: {
    // min: 0,
    title: {
      text: "",
    },
  },
  legend: {
    enabled: false,
  },
};

export const dummyMonthsLeftSavingGraphOptions: Options = {
  chart: {
    type: "column",
    height: "280px",
  },
  title: {
    text: "",
  },
  plotOptions: {
    column: {
      colorByPoint: true,
    },
  },
  tooltip: {
    formatter: function () {
      let prefix = "";
      if (this.x === 1) {
        prefix = "Current month";
      } else {
        prefix = this.x + " months";
      }
      return prefix + " : $ " + Highcharts.numberFormat(this.y, 0, ".", ",");
    },
  },
  colors: ["#795BD0"],
  series: [
    {
      // `type: column` is required for type-checking this options as a column series
      type: "column",
      data: [
        400000, 360000, 320000, 280000, 240000, 200000, 160000, 120000, 80000,
        40000, 0, -40000, -80000, -120000, -140000, -160000, -200000, -240000,
        -280000, -320000, -360000, -400000,
      ],
    },
  ],
  xAxis: {
    categories: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 30,
    ],
    labels: {
      useHTML: true,
      enabled: true,
    },
  },
  yAxis: {
    // min: 0,
    title: {
      text: "",
    },
  },
  legend: {
    enabled: false,
  },
};

export const dummyOffsetGraphOptions: Options = {
  title: {
    text: "",
  },
  yAxis: {
    title: {
      text: "$ Amount",
    },
  },

  xAxis: {
    accessibility: {
      // rangeDescription: "Range: 2010 to 2020",
    },
  },

  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      // pointStart: 2010,
    },
  },
  colors: ["#5BC2AE", "#FF4E4E", "#795BD0"],
  series: [
    {
      type: "area",
      name: "Savings",
      data: [
        [2030, 250000],
        [2031, 259985],
        [2032, 269965],
        [2033, 279937],
        [2034, 289899],
        [2035, 299848],
        [2036, 309782],
        [2037, 319698],
        [2038, 329595],
        [2039, 339469],
      ],
    },
  ],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
        },
      },
    ],
  },
};

export const dummyMarketValueGraphOptions: Options = {
  title: {
    text: "",
  },
  yAxis: {
    title: {
      text: "$ Amount",
    },
  },

  xAxis: {
    accessibility: {
      // rangeDescription: "Range: 2010 to 2020",
    },
  },

  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      // pointStart: 2010,
    },
  },
  colors: ["#795BD0", "#F3BE31", "#142a37", "#FF4E4E"],
  series: [
    {
      type: "area",
      name: "Market Value",
      data: [
        [2030, -500000],
        [2031, -300000],
        [2032, -100000],
        [2033, 100000],
        [2034, 300000],
        [2035, 500000],
        [2036, 700000],
        [2037, 900000],
        [2038, 1100000],
        [2039, 1300000],
      ],
    },
    {
      type: "area",
      name: "Equity",
      data: [
        [2030, -100000],
        [2031, -50000],
        [2032, 20000],
        [2033, 150000],
        [2034, 300000],
        [2035, 450000],
        [2036, 600000],
        [2037, 750000],
        [2038, 900000],
        [2039, 1050000],
      ],
    },
    {
      type: "area",
      name: "Offset Det",
      data: [
        [2030, 100000],
        [2031, 200000],
        [2032, 350000],
        [2033, 500000],
        [2034, 650000],
        [2035, 800000],
        [2036, 950000],
        [2037, 1100000],
        [2038, 1250000],
        [2039, 1400000],
      ],
    },
  ],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
        },
      },
    ],
  },
};

export const dummyPassiveIncomeGraphOptions: Options = {
  title: {
    text: "",
  },
  yAxis: {
    title: {
      text: "$ Amount",
    },
  },

  xAxis: {
    accessibility: {
      // rangeDescription: "Range: 2010 to 2020",
    },
  },

  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      // pointStart: 2010,
    },
  },
  colors: ["#FF4E4E", "#795BD0", "#2bb1ff"],
  series: [
    {
      type: "area",
      name: "Passive Income",
      data: [
        [2030, 180000],
        [2031, 184500],
        [2032, 189000],
        [2033, 194500],
        [2034, 200000],
        [2035, 205500],
        [2036, 211000],
        [2037, 216500],
        [2038, 222000],
        [2039, 227500],
      ],
    },
    {
      type: "area",
      name: "PPOR Passive Income",
      data: [],
    },
    {
      type: "area",
      name: "Target Inflation Adjusted",
      data: [
        [2030, 250000],
        [2031, 253900],
        [2032, 258100],
        [2033, 261700],
        [2034, 265800],
        [2035, 270400],
        [2036, 273200],
        [2037, 278000],
        [2038, 281900],
        [2039, 286500],
      ],
    },
  ],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
        },
      },
    ],
  },
};

export const dummyBorrowingCapacityGraphOptions: Options = {
  title: {
    text: "",
  },
  yAxis: {
    title: {
      text: "$ Amount",
    },
  },
  xAxis: {
    accessibility: {
      // rangeDescription: "Range: 2010 to 2020",
    },
  },

  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      //pointStart: 2010,
    },
  },
  colors: ["#FF4E4E", "#5BC2AE", "#795BD0"],
  //colors: ["#5BC2AE", "#F3BE31", "#795BD0"],
  series: [
    {
      type: "area",
      name: "Borrowing Capacity",
      data: [
        [2030, 172000],
        [2031, 177500],
        [2032, 184000],
        [2033, 190500],
        [2034, 197000],
        [2035, 204500],
        [2036, 211000],
        [2037, 218500],
        [2038, 225000],
        [2039, 232500],
      ],
    },
    {
      type: "area",
      name: "Offset Debt",
      data: [
        [2030, 158000],
        [2031, 164500],
        [2032, 171000],
        [2033, 177500],
        [2034, 184000],
        [2035, 190500],
        [2036, 197000],
        [2037, 203500],
        [2038, 210000],
        [2039, 216500],
      ],
    },
    {
      type: "area",
      name: "Total Debt",
      data: [
        [2030, 180000],
        [2031, 186500],
        [2032, 193000],
        [2033, 199500],
        [2034, 206000],
        [2035, 212500],
        [2036, 219000],
        [2037, 225500],
        [2038, 232000],
        [2039, 238500],
      ],
    },
  ],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
        },
      },
    ],
  },
};

export const dummyStrategyRentalYieldGraphOptions: Options = {
  title: {
    text: "",
  },
  yAxis: {
    title: {
      text: "$ Amount",
    },
  },

  xAxis: {
    accessibility: {
      // rangeDescription: "Range: 2010 to 2020",
    },
  },

  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      // pointStart: 2010,
    },
  },
  colors: ["#F3BE31", "#795BD0"],
  // colors: ["#5BC2AE", "#F3BE31", "#795BD0"],
  series: [
    {
      type: "area",
      name: "Rental Yield",
      data: [
        [2030, 150000],
        [2031, 153000],
        [2032, 156000],
        [2033, 159000],
        [2034, 162000],
        [2035, 165000],
        [2036, 168000],
        [2037, 171000],
        [2038, 174000],
        [2039, 177000],
      ],
    },
    {
      type: "area",
      name: "Rental Yield On Purchase Price",
      data: [
        [2030, 200000],
        [2031, 220000],
        [2032, 240000],
        [2033, 270000],
        [2034, 300000],
        [2035, 330000],
        [2036, 360000],
        [2037, 400000],
        [2038, 450000],
        [2039, 500000],
      ],
    },
  ],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
        },
      },
    ],
  },
};

export const dummyInterestRateMovementGraphOptions: Options = {
  title: {
    text: "",
  },
  yAxis: {
    title: {
      text: "$ Amount",
    },
  },

  xAxis: {
    accessibility: {
      // rangeDescription: "Range: 2010 to 2020",
    },
  },

  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      // pointStart: 2010,
    },
  },
  colors: ["#5BC2AE", "#F3BE31", "#795BD0"],
  series: [
    {
      type: "area",
      name: "Interest Rate up +2%",
      data: [
        [2030, -50000],
        [2031, -40000],
        [2032, -20000],
        [2033, 5000],
        [2034, 30000],
        [2035, 60000],
        [2036, 100000],
        [2037, 150000],
        [2038, 210000],
        [2039, 280000],
      ],
    },
    {
      type: "area",
      name: "Interest Rate down -2%",
      data: [
        [2030, -800000],
        [2031, -600000],
        [2032, -300000],
        [2033, -50000],
        [2034, 100000],
        [2035, 400000],
        [2036, 800000],
        [2037, 1300000],
        [2038, 1900000],
        [2039, 2600000],
      ],
    },
  ],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
        },
      },
    ],
  },
};
