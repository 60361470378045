import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";

import Select from "react-select";
import Button from "@mui/material/Button";
import { formatCurrency } from "utils/currency";
import ClientService from "services/client.service";
import NewPropertyService from "services/new-property.service";
import PropertySummary from "./PropertySummary";
import { Container } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Delete";
import AssignClient from "./AssignClient";
import UploadDocumentDialog from "../../ui/new-properties/Dialogs/upload-document/upload-document-dialog";
import propertyStageService from "services/property-stage.service";
import newPropertyService from "services/new-property.service";

export default function DealProposedCheck({
  updatedProperty,
  setUpdatedProperty,
  currentPost,
  setShowDealProposedCheckedDialog,
  fetchProperties,
  handleCancel,
  handlePropertyMoveToProposed,
}) {
  const [open, setOpen] = useState(true);

  const [userState, setUserState] = useAtom(userAtom);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [error, setError] = useState(null);
  const [SubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const [showAssignClientDialog, setShowAssignClientDialog] = useState(false);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);

  const completeAddress =
    updatedProperty?.address +
    ", " +
    updatedProperty?.suburb +
    "," +
    updatedProperty?.postcode +
    ", " +
    updatedProperty?.stateShortName;

  const handleAssignClient = () => {
    setShowAssignClientDialog(true);
  };

  const handleUploadDocuments = () => {
    setShowDocumentDialog(true);
  };

  const handleConfirmationOpen = () => setConfirmationOpen(true);
  const handleConfirmationClose = () => setConfirmationOpen(false);

  function handleDealProposedDialogClose() {
    setOpen(false);
    setShowDealProposedCheckedDialog(false);
    handleCancel();
    fetchProperties();
  }

  useEffect(() => {
    if (
      updatedProperty.currentClient &&
      updatedProperty.mandatoryDocumentsNotReceived === 0
    ) {
      updatePropertyStagetoProposed();
    }
  }, [updatedProperty]);

  useEffect(() => {
    if (!showDocumentDialog) {
      checkDocumentStatusforProposed();
    }
  }, [showDocumentDialog]);

  const checkDocumentStatusforProposed = async () => {
    newPropertyService
      .checkDocumentStatusforProposed(updatedProperty.id)
      .then((response) => {
        setUpdatedProperty({
          ...updatedProperty,
          mandatoryDocumentsNotReceived: response.data.recordset.length,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updatePropertyStagetoProposed() {
    // handlePropertyMoveToProposed();

    const formData = {
      propertyId: updatedProperty.id,
      propertyStatusId: updatedProperty.statusId,
      comments: "",
      userId: userState.userId,
    };

    console.log("Update data from Deal check form", formData);
    // update the status in property table
    newPropertyService
      .updateStatus(formData, formData.propertyId)
      .then((response) => {
        // update the record in status history table
        propertyStageService.add(formData).then((response) => {
          fetchProperties();
          console.log("moving suceeded: ", response);
        });
      });

    handleDealProposedDialogClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleDealProposedDialogClose}
      PaperProps={{
        style: { width: "60%", maxWidth: "none" },
      }}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <span
          style={{ color: "#114253", fontSize: "20px", fontWeight: "bold" }}
        >
          Assign Client / Update Documents
        </span>
        <IconButton
          aria-label="close"
          onClick={handleDealProposedDialogClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Card>
          <CardHeader>
            <CardBody className="new-property-card-body">
              <Container style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  style={{
                    flex: 1,
                    minWidth: "400px",
                    padding: "20px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    marginRight: "5px",
                  }}
                >
                  <PropertySummary property={updatedProperty} />
                </div>
                <div
                  style={{
                    flex: 2,
                    minWidth: "400px",
                    padding: "20px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                >
                  {/* {!clientStatus && ( */}
                  {!updatedProperty.currentClient && (
                    <Row>
                      <Col>
                        <Label>
                          Client is Missing for the property, Assign Client....
                        </Label>
                        <DialogActions>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={handleAssignClient}
                          >
                            Assign Client
                          </Button>
                        </DialogActions>
                      </Col>
                    </Row>
                  )}
                  {updatedProperty.mandatoryDocumentsNotReceived > 0 && (
                    <Row>
                      <Col>
                        <Label>Mandatory Documents are missing....</Label>
                        <DialogActions>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={handleUploadDocuments}
                          >
                            Update Documents
                          </Button>
                        </DialogActions>
                      </Col>
                    </Row>
                  )}
                </div>
              </Container>
            </CardBody>
          </CardHeader>
        </Card>
        {showAssignClientDialog && (
          <AssignClient
            property={updatedProperty}
            setUpdatedProperty={setUpdatedProperty}
            onHandleSetAssignClientDialog={setShowAssignClientDialog}
            fetchProperties={fetchProperties}
          />
        )}

        <UploadDocumentDialog
          propertyId={updatedProperty.id}
          dialogOpen={showDocumentDialog}
          propertyAddress={completeAddress}
          handleDialogClose={() => setShowDocumentDialog(false)}
          setShowDealProposedCheckedDialog={setShowDealProposedCheckedDialog}
        />
      </DialogContent>
    </Dialog>
  );
}
