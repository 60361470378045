import react from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const WatchDialogContent = (props) => (
  <>
    <DialogTitle id="watch-dialog-title">{props.title}</DialogTitle>
    {/* <IconButton
      aria-label="close"
      onClick={props.handleClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton> */}
    <DialogContent>
      <iframe
        width="100%"
        height="400"
        src={`https://www.youtube.com/embed/${props.youtubeCode}`}
        allowFullScreen
      />
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="grey" onClick={props.handleClose}>
        Close
      </Button>
    </DialogActions>
  </>
);

export default WatchDialogContent;
