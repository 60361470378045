import React from "react";
import { Input } from "reactstrap";
import { formatCurrency } from "utils/currency";

function CurrencyInput({ placeholder, number, handleNumberChange, ...props }) {
  const handleAmountChange = (e) => {
    // Remove non-numeric characters
    const value = e.target.value.replace(/[^0-9.]/g, "");
    handleNumberChange(value);
  };

  return (
    <Input
      {...props}
      type="text"
      placeholder={placeholder}
      value={number}
      onChange={handleAmountChange}
      onBlur={() => handleNumberChange(formatCurrency(number))}
    />
  );
}

export default CurrencyInput;
