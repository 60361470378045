export function currencyToNumber(currency) {
  if (!currency) {
    return 0;
  }
  // Remove currency symbol and other non-numeric characters
  const numericString = currency?.toString().replace(/[^0-9.]/g, "");
  // Parse the numeric string into a number
  return parseFloat(numericString);
}

export const formatCurrency = (value) => {
  // If the input is empty, return a default value
  if (!value) {
    return "$0";
  }

  const currency = value.toString()?.replace(/[^0-9.]/g, "");

  // Format value as currency
  const formattedValue = parseFloat(currency)?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  return formattedValue;
};
