import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const modules = [
  { id: 1, module: "Goal Alignment" },
  { id: 2, module: "Family Members" },
  { id: 3, module: "Employment" },
  { id: 4, module: "Portfolio" },
  { id: 5, module: "Risk Profile" },
  { id: 6, module: "Money Management" },
  { id: 7, module: "Mortgage Strategy" },
  { id: 8, module: "Asset Selection" },
  { id: 9, module: "Portfolio Analysis" },
  { id: 10, module: "Property Plan" },
];

export const scoreboardExcel = (data) => {
  const workbook = XLSX.utils.book_new();

  // data.recordsets.forEach((recordset, index) => {
  data.forEach((recordset, index) => {
    const module = modules.find((m) => m.id === index + 1);
    const sheetName = module ? module.module : `Sheet${index + 1}`;
    const worksheet = XLSX.utils.json_to_sheet(recordset);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, "scoreboard.xlsx");
};
