import House from "@mui/icons-material/MapsHomeWork";

export default function Spinner() {
  return (
    <div className="spinner-container">
      <div className="spinner">
        <div className="center-text">
          <House sx={{ color: "red", fontSize: 48 }} /> {console.log("Spinner")}
          {/* Red and larger House icon */}
        </div>
        <div className="rotating-arc"></div> {/* Rotating Arc */}
      </div>
      <style>
        {`
          .spinner-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
          }

          .spinner {
            position: relative;
            width: 80px;  /* Reduced size to bring arc closer */
            height: 80px;
          }

          .center-text {
            position: absolute;
            top: 53%;
            left: 53%;
            transform: translate(-50%, -50%);
            z-index: 2; /* Keep the House icon on top of the rotating arc */
          }

          .rotating-arc {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 4px solid rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            border-left-color: #00008B; /* Dark blue color for the arc */
            animation: rotate 1s linear infinite;
            z-index: 1; /* Place arc behind the House icon */
          }

          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  );
}
