import { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useAtom } from "jotai";
import { selectedClientAtom } from "../../../../store";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";

const FamilyIncome = ({ formData, setFormData, defaultAssumptions }) => {
  const [selectedAllocation, setSelectedAllocation] = useState(0);
  const allocation = [0, 2, 5, 10, 11, 12, 13, 15, 20, 25];
  const [clientState, setClientState] = useAtom(selectedClientAtom);

  const allocationBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: '20px 20px 20px 32px',
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const setAllocation = (allocation) => {
    setFormData({ ...formData, allocateIncomeToSuper: allocation });
  };

  const handleGrossAnnualIncomeChange = (value) => {
    setFormData({
      ...formData,
      grossAnnualIncome: value,
      isDefaultGrossAnnualIncome: false,
    });
  };

  const handleIncomeTaxRateChange = (e) => {
    setFormData({
      ...formData,
      incomeTaxRate: e.target.value,
      isDefaultIncomeTaxRate: false,
    });
  };

  const handleTotalSavingsInLiquidChange = (value) => {
    setFormData({
      ...formData,
      totalLiquidSaving: value,
      isDefaultTotalLiquidSavings: false,
    });
  };

  const handleSuperOrSMSFValueChange = (e) => {
    setFormData({
      ...formData,
      totalInSuper: e.target.value,
      isDefaultTotalInSuper: false,
    });

    console.log("setformdata from SMSF value change: ", setFormData);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Add Income for {clientState.firstName} {clientState.lastName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Gross Annual Income{" "}
                    <Typography variant="caption" gutterBottom>
                      (Note: If not selected{" "}
                      <strong>
                        {defaultAssumptions.DefaultGrossAnnualIncome}
                      </strong>{" "}
                      would be selected as default)
                    </Typography>
                  </Label>
                  <CurrencyInput
                    placeholder="Average Monthly Savings"
                    number={formData.grossAnnualIncome}
                    handleNumberChange={(value) =>
                      handleGrossAnnualIncomeChange(value)
                    }
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label>
                    Income Tax Rate{" "}
                    <Typography variant="caption" gutterBottom>
                      (Note: If not selected{" "}
                      <strong>{defaultAssumptions.DefaultIncomeTaxRate}</strong>{" "}
                      would be selected as default)
                    </Typography>
                  </Label>
                  <Input
                    placeholder="Income Tax rate"
                    type="number"
                    min="0"
                    oninput="validity.valid||(value='');"
                    value={formData.incomeTaxRate}
                    autoFocus
                    required
                    onChange={(e) => handleIncomeTaxRateChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Total Savings in liquid{" "}
                    <Typography variant="caption" gutterBottom>
                      (Note: If not selected{" "}
                      <strong>
                        {defaultAssumptions.DefaultTotalSavingsInLiquid}
                      </strong>{" "}
                      would be selected as default)
                    </Typography>
                  </Label>
                  <CurrencyInput
                    placeholder="Total Savings in liquid"
                    number={formData.totalLiquidSaving}
                    handleNumberChange={(value) =>
                      handleTotalSavingsInLiquidChange(value)
                    }
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label>
                    Super or SMSF value{" "}
                    <Typography variant="caption" gutterBottom>
                      (Note: If not selected{" "}
                      <strong>
                        {defaultAssumptions.DefaultSuperOrSMSFValue}
                      </strong>{" "}
                      would be selected as default)
                    </Typography>
                  </Label>
                  <Input
                    placeholder="Super or SMSF value"
                    type="number"
                    min="0"
                    oninput="validity.valid||(value='');"
                    value={formData.totalInSuper}
                    onChange={(e) => handleSuperOrSMSFValueChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>
                    Allocate Income to Super or SMSF{" "}
                    <Typography variant="caption" gutterBottom>
                      (Note: If not selected{" "}
                      <strong>
                        {defaultAssumptions.DefaultAllocateIncomeToSuper}
                      </strong>{" "}
                      would be selected as default)
                    </Typography>
                  </Label>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {allocation.map((allocate) => (
                      <Grid
                        className="small_box"
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={allocate}
                      >
                        <Box
                          onClick={() => setAllocation(allocate)}
                          key={allocate}
                          sx={allocationBoxSx}
                          style={{
                            backgroundColor:
                              formData.allocateIncomeToSuper === allocate
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {allocate.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0,
                          })}
                          %
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default FamilyIncome;
