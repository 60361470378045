import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MUIButton from "@mui/material/Button";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../store";
import ReactDatetime from "react-datetime";
import Moment from "moment";
import { Container, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Form,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import NewPropertyService from "services/new-property.service";
import "./add-new-property.scss";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import newPropertyService from "services/new-property.service";
import { convertFirstLetterOfAllKeysToLowerCase } from "utils/helpers";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { formatCurrency } from "utils/currency";
import { currencyToNumber } from "utils/currency";

const INITIAL_DATA = {
  id: 0,
  address: "",
  suburb: "",
  postcode: "",
  stateId: 0,
  saleOptionId: 0,
  noOfBedRooms: 0,
  noOfBathRooms: 0,
  landSize: 0,
  askingPrice: formatCurrency(0),
  expectedRentalIncome: formatCurrency(0),
  rentalYieldPercent: 0,
  statusId: 0,
  firstListed: "",
  frontage: 0,
  tenancyId: 0,
  residentialZone: "",
  developmentPotential: "",
  url: "",
  additionalComments: "",
  dueDiligence: false,
  comparableValueMin: formatCurrency(0),
  comparableValueMax: formatCurrency(0),
  clientId: 0,
  userId: 0,
  isUpdate: false,
  currentBuyerAgentId: 0,
};

const AddNewProperty = () => {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState(INITIAL_DATA);
  const [newPropertyId, setNewPropertyId] = useState(0);
  const [isUpdate, setIsUpdate] = useState(0);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [propertyStatus, setPropertyStatus] = useState([]);
  const [tenancy, setTenancy] = useState([]);
  const [zoning, setZoning] = useState([]);
  const [saleOptions, setSaleOptions] = useState([]);
  const [state, setState] = useState([]);
  const [selectedSaleOptionId, setSelectedSaleOptionId] = useState({});
  const [selectedStateId, setSelectedStateId] = useState({});
  const [selectedStatusId, setSelectedStatusId] = useState({
    label: "Listed",
    value: 1,
  });

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedZoningId, setSelectedZoningId] = useState({});
  const [selectedTenancyId, setSelectedTenancyId] = useState({});
  const isEditMode =
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/").length === 5;

  const history = useHistory();

  const handleError = () => {
    setErrorOpen(true);
  };

  const handleSaleOptionChange = (e) => {
    setSelectedSaleOptionId(e);
  };

  const handleStateChange = (e) => {
    setSelectedStateId(e);
  };

  const handleStatusChange = (e) => {
    setSelectedStatusId(e);
  };

  const handleTenancyChange = (e) => {
    setSelectedTenancyId(e);
  };

  const handleZoningChange = (e) => {
    setSelectedZoningId(e);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const saveNewProperty = () => {
    const newPropertyData = {
      ...formData,
      askingPrice: currencyToNumber(formData.askingPrice),
      expectedRentalIncome: currencyToNumber(formData.expectedRentalIncome),
      comparableValueMin: currencyToNumber(formData.comparableValueMin),
      comparableValueMax: currencyToNumber(formData.comparableValueMax),
      stateId: selectedStateId.value,
      saleOptionId: selectedSaleOptionId.value,
      // zoningId: selectedZoningId.value,
      statusId: selectedStatusId.value,
      tenancyId: selectedTenancyId.value,
      userId: userState.userId,
    };
    console.log("newProperty Data:", newPropertyData);

    NewPropertyService.checkPropertyDuplication(newPropertyData).then(
      (respDupl) => {
        if (respDupl.data.recordset.length > 0) {
          setErrorMessage("Duplicate Property....");
          setTimeout(handleError, 3000);
        } else {
          if (newPropertyId === 0) {
            NewPropertyService.add(newPropertyData)
              .then((response) => {
                if (response.data.recordset.length > 0) {
                  setNewPropertyId(response.data.recordset[0].NewPropertyId);
                  setOpen(true);
                  setTimeout(() => {
                    history.push("/admin/fulfillment-pipeline/list");
                  }, 2000);
                } else {
                  setNewPropertyId(0);
                }
              })
              .catch((error) => {
                console.log("Error - ", error);
                if (error?.response?.status === 401) {
                  setErrorMessage(error.response.data);
                  // Handle unauthorized access
                  setTimeout(handleError, 3000);
                } else {
                  // Handle other types of errors
                  setErrorMessage("An unknown error occurred", error);
                  setTimeout(handleError, 3000);
                  console.error("An error occurred:", error);
                }
                console.log(error);
              });
            // }
            //   }
            // );
          } else {
            NewPropertyService.update(newPropertyData, newPropertyId)
              .then(() => {
                setOpen(true);
                setTimeout(() => {
                  history.push("/admin/fulfillment-pipeline/list");
                }, 2000);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      }
    );
  };

  // const getPropertyStatus = async () => {
  //   NewPropertyService.getPropertyStatus()
  //     .then((response) => {
  //       if (response.data.recordset.length > 0) {
  //         const propertyStatusData = response.data.recordset;
  //         let optionItems = propertyStatusData.map((item) => ({
  //           label: `${item.PropertyStatus}`,
  //           value: item.PropertyStatusId,
  //         }));
  //         setPropertyStatus(optionItems);
  //       } else {
  //         setPropertyStatus([]);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setPropertyStatus([]);
  //     });
  // };

  const getTenancy = async () => {
    NewPropertyService.getTenancy()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const tenancyData = response.data.recordset;
          let optionItems = tenancyData.map((item) => ({
            label: `${item.Tenancy}`,
            value: item.TenancyId,
          }));
          setTenancy(optionItems);
        } else {
          setTenancy([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setTenancy([]);
      });
  };

  const getZoning = async () => {
    NewPropertyService.getZoning()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const zoningData = response.data.recordset;
          let optionItems = zoningData.map((item) => ({
            label: `${item.Zoning}`,
            value: item.ZoningId,
          }));
          setZoning(optionItems);
        } else {
          setZoning([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setZoning([]);
      });
  };

  const getStates = async () => {
    NewPropertyService.getStates()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const stateData = response.data.recordset;
          let optionItems = stateData.map((item) => ({
            label: `${item.State}`,
            value: item.StateId,
          }));
          setState(optionItems);
        } else {
          setState([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setState([]);
      });
  };

  const getSaleOptions = async () => {
    NewPropertyService.getSaleOptions()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const salesOptionsData = response.data.recordset;
          let optionItems = salesOptionsData.map((item) => ({
            label: `${item.SaleOption}`,
            value: item.SaleOptionId,
          }));
          setSaleOptions(optionItems);
        } else {
          setSaleOptions([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setSaleOptions([]);
      });
  };

  const handleClose = async () => {
    setOpen(false);
  };

  const handleFirstListedChange = (e) => {
    Moment.locale("en-au");
    const selectedDate = Moment(e).format("yyyy-MM-DD");
    setFormData({ ...formData, firstListed: selectedDate });
  };

  const getNewPropertyId = () => {
    return window.location.pathname
      .substr(1, window.location.pathname.length)
      .split("/")[4];
  };

  const getNewProperty = (newPropertyId) => {
    newPropertyService
      .getById(newPropertyId)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          const propertyData = {
            ...convertFirstLetterOfAllKeysToLowerCase(
              response.data.recordset[0]
            ),
            askingPrice: formatCurrency(response.data.recordset[0].AskingPrice),
            expectedRentalIncome: formatCurrency(
              response.data.recordset[0].ExpectedRentalIncome
            ),
            comparableValueMin: formatCurrency(
              response.data.recordset[0].ComparableValueMin
            ),
            comparableValueMax: formatCurrency(
              response.data.recordset[0].ComparableValueMax
            ),
            url: response.data.recordset[0].URL || "",
          };
          console.log("propertyData: ", propertyData);

          setFormData(propertyData);
          setNewPropertyId(propertyData.newPropertyId);
          setSelectedSaleOptionId({
            value: propertyData.saleOptionId,
            label: propertyData.saleOption,
          });
          setSelectedStateId({
            value: propertyData.stateId,
            label: propertyData.state,
          });
          setSelectedStatusId({
            value: propertyData.statusId,
            label: propertyData.propertyStatus,
          });
          setSelectedZoningId({
            value: propertyData.zoningId,
            label: propertyData.zoning,
          });
          setSelectedTenancyId({
            value: propertyData.tenancyId,
            label: propertyData.tenancy,
          });
        } else {
          setFormData(INITIAL_DATA);
        }
      })
      .catch((error) => {
        console.log(error);
        setFormData(INITIAL_DATA);
      });
  };

  useEffect(() => {
    // getPropertyStatus();
    getTenancy();
    getZoning();
    getSaleOptions();
    getStates();

    if (isEditMode) {
      const propertyId = getNewPropertyId();
      getNewProperty(propertyId);
    }
  }, []);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content portfolio-add">
      <Dialog
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Error</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={handleErrorClose}>OK</MUIButton>
        </DialogActions>
      </Dialog>

      <Container>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message="Property is saved."
          action={action}
        ></Snackbar>
        <Box sx={{ width: "100%" }}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Add New Property</CardTitle>
            </CardHeader>
            <CardBody>
              <Form className="form-horizontal">
                <Row>
                  <Col md="6" lg="6" xl="4">
                    <Label>Street Address</Label>
                    <FormGroup>
                      <Input
                        placeholder="Street"
                        type="text"
                        value={formData.address}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            address: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>Suburb</Label>
                    <FormGroup>
                      <Input
                        placeholder="Suburb"
                        type="text"
                        value={formData.suburb}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            suburb: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>Post Code</Label>
                    <FormGroup>
                      <Input
                        placeholder="Post Code"
                        type="text"
                        value={formData.postcode}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            postcode: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="6" xl="4">
                    <Label>State</Label>
                    <FormGroup>
                      <Select
                        name=""
                        className="react-select select-option-control"
                        placeholder="Select State"
                        classNamePrefix="react-select"
                        value={selectedStateId}
                        onChange={handleStateChange}
                        options={state}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="6" xl="4">
                    <Label>Sale Option</Label>
                    <FormGroup>
                      <Select
                        name=""
                        className="react-select select-option-control"
                        placeholder="Select Sale Option"
                        classNamePrefix="react-select"
                        value={selectedSaleOptionId}
                        onChange={handleSaleOptionChange}
                        options={saleOptions}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>No. Of Bed Rooms</Label>
                    <FormGroup>
                      <Input
                        placeholder="No of bed rooms"
                        type="number"
                        value={formData.noOfBedRooms}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            noOfBedRooms: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>No. Of Bathrooms</Label>
                    <FormGroup>
                      <Input
                        placeholder="No of bath rooms"
                        type="number"
                        value={formData.noOfBathRooms}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            noOfBathRooms: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="6" xl="4">
                    <Label>Land Size (sqm)</Label>
                    <FormGroup>
                      <Input
                        placeholder="Land size"
                        type="number"
                        value={formData.landSize}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            landSize: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>Asking Price ($)</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Asking price ($)"
                        number={formData?.askingPrice}
                        handleNumberChange={(value) =>
                          setFormData({ ...formData, askingPrice: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>Expected Rental Income (pw)</Label>
                    <FormGroup>
                      <CurrencyInput
                        placeholder="Expected rental income (pw)"
                        number={formData?.expectedRentalIncome}
                        handleNumberChange={(value) =>
                          setFormData({
                            ...formData,
                            expectedRentalIncome: value,
                          })
                        }
                      />
                      {/* <Input
                        placeholder="Expected rental income (pw)"
                        type="number"
                        value={formData.expectedRentalIncome}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            expectedRentalIncome: e.target.value,
                          })
                        }
                      /> */}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="12" xl="8">
                    {/* <Col md="6" lg="6" xl="4"> */}
                    <Label>Comparable Value</Label>
                    <FormGroup className="inline-controls">
                      <Col md="8" lg="8" xl="6" className="inilne-col">
                        <Label>Min</Label>
                        <CurrencyInput
                          placeholder="Min value"
                          number={formData?.comparableValueMin}
                          handleNumberChange={(value) =>
                            setFormData({
                              ...formData,
                              comparableValueMin: value,
                            })
                          }
                        />
                      </Col>
                      <Col md="8" lg="8" xl="6" className="inilne-col">
                        <Label>Max</Label>
                        <CurrencyInput
                          placeholder="Max value"
                          number={formData?.comparableValueMax}
                          handleNumberChange={(value) =>
                            setFormData({
                              ...formData,
                              comparableValueMax: value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>Rental Yield (%)</Label>
                    <FormGroup>
                      <Input
                        placeholder="Rental yield (%)"
                        type="number"
                        value={formData.rentalYieldPercent}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            rentalYieldPercent: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col md="6" lg="6" xl="4">
                    <Label>First Listed</Label>
                    <FormGroup>
                    <ReactDatetime
                    locale="en-gb"
                    inputProps={{
                          className: "form-control",
                          placeholder: "",
                        }}
                        value={formData.firstListed}
                        onChange={(e) => handleFirstListedChange(e)}
                        timeFormat={false}
                      />
                      </FormGroup>
                    </Col> */}
                </Row>
                <Row>
                  <Col md="6" lg="6" xl="4">
                    <Label>Status</Label>
                    <FormGroup>
                      <Select
                        name=""
                        className="react-select select-option-control"
                        placeholder="Select Status"
                        classNamePrefix="react-select"
                        value={selectedStatusId}
                        // onChange={handleStatusChange}
                        // options={propertyStatus}
                        isDisabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>Frontage (m)</Label>
                    <FormGroup>
                      <Input
                        placeholder="Frontage (m)"
                        type="number"
                        value={formData.frontage}
                        onChange={(e) =>
                          setFormData({ ...formData, frontage: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>Tenancy</Label>
                    <FormGroup>
                      <Select
                        name=""
                        className="react-select select-option-control"
                        placeholder="Select Tenancy"
                        classNamePrefix="react-select"
                        value={selectedTenancyId}
                        onChange={handleTenancyChange}
                        options={tenancy}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="6" xl="4">
                    <Label>Residential Zones</Label>
                    <FormGroup>
                      {/* <Select
                        name=""
                        className="react-select select-option-control"
                        placeholder="Select Residential Zones"
                        classNamePrefix="react-select"
                        value={selectedZoningId}
                        onChange={handleZoningChange}
                        options={zoning}
                      /> */}
                      <Input
                        placeholder="Residential Zones"
                        type="text"
                        value={formData.residentialZone}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            residentialZone: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>Development Potential</Label>
                    <FormGroup className="member_type_fields">
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={formData.developmentPotential}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            developmentPotential: e.target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>URL</Label>
                    <FormGroup>
                      <Input
                        placeholder="URL"
                        type="text"
                        value={formData.url}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            url: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="6" xl="4">
                    <Label>Additional Comments</Label>
                    <FormGroup>
                      <Input
                        placeholder="Additional comments"
                        type="text"
                        value={formData.additionalComments}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            additionalComments: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6" xl="4">
                    <Label>Due Diligence</Label>
                    <FormGroup>
                      <div className="due-diligence-checkbox">
                        <Input
                          type="checkbox"
                          checked={formData.dueDiligence}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dueDiligence: !formData.dueDiligence,
                            })
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter className="text-right">
              <div className="footer-btns">
                <Button
                  variant="contained"
                  color="grey"
                  size="medium"
                  // startIcon={<SaveIcon />}
                  onClick={() =>
                    history.push("/admin/fulfillment-pipeline/list")
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="grey"
                  size="medium"
                  startIcon={<SaveIcon />}
                  onClick={() => saveNewProperty()}
                >
                  SAVE
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Box>
      </Container>
    </div>
  );
};

export default AddNewProperty;
