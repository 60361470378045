import React, { useEffect, useState } from "react";
import "./embedded.css";
import CheckoutForm from "./checkout-form";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import SaveIcon from "@mui/icons-material/Save";

import PaymentService from "../../../services/payment.service";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
import paymentService from "../../../services/payment.service";
import { useAtom } from "jotai";
import { userAtom, selectedClientAtom } from "../../../store";
import { DataGrid } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

const Orders = () => {
  const [toggle, setToggle] = useState(true);
  const [amount, setAmount] = useState(0);
  const [product, setProduct] = useState("");
  const [payNow, setPayNow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [orderPayments, setOrderPayments] = useState([]);
  const [userState, setUserState] = useAtom(userAtom);
  const [loginUser, setLoginUser] = useState({});
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
    setToggle(false);
    setPayNow(true);
  };

  const getOrders = async (clientId) => {
    paymentService
      .getAllByClientId(clientId)
      .then((response) => {
        let payments = response.data.recordset.map((payment) => {
          return {
            id: payment.ClientBillingId,
            orderDate: new Date(payment.OrderDate)
              .toISOString()
              .slice(0, 19)
              .replace("T", " "),
            orderEmail: payment.OrderEmail,
            orderService: payment.OrderService,
            message: payment.Message,
            sessionId: payment.SessionId,
          };
        });
        console.log("payments: ", payments);
        setOrderPayments(payments);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "orderDate", headerName: "Order Date", width: 180 },
    { field: "orderEmail", headerName: "Order Email", width: 200 },
    { field: "orderService", headerName: "Order Service", width: 150 },
    { field: "message", headerName: "Order Status", width: 150 },
    { field: "sessionId", headerName: "Payment Id", width: 600 },
  ];

  useEffect(() => {
    // read the session id
    const jwtToken = window.sessionStorage.getItem("jwt-token");
    const loginUser = JSON.parse(window.localStorage.getItem("login-user"));
    console.log("login user: ", loginUser);
    const selectedClient = JSON.parse(
      window.localStorage.getItem("selected-client")
    );
    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
      // due to refresh issue, read it from selected client from local storage
    } else {
      setLoginUser(loginUser);
      // if (userState.userId === 0) {
      //   setUserState(loginUser);
      // }
      const queryString = window.location.search.substring(1);
      if (queryString) {
        const sessionId = queryString.split("=")[1];
        if (sessionId) {
          console.log("session id: ", sessionId);
          // Create a Checkout Session as soon as the page loads
          PaymentService.sessionStatus(sessionId).then((data) => {
            console.log("data:", data);
            const paymentData = {
              clientId: loginUser.clientId,
              orderEmail: data.customer_email,
              orderService: "Full Access",
              orderPrice: 500,
              message: data.status,
              sessionId: `${sessionId}`,
            };
            PaymentService.add(paymentData).then((response) => {
              getOrders(loginUser.clientId);
            });
          });
        }
      } else {
        getOrders(loginUser.clientId);
      }
    }
  }, []);

  return (
    <div className="content detail-order-content">
      <Container>
        <ClientNotSelectedDialog />
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Orders</CardTitle>
                <form
                  onSubmit={handleSubmit}
                  className="text-right rounded-3 mt-3"
                >
                  <div className="card_bt">
                    <Button
                      sx={{ float: "right" }}
                      variant="contained"
                      color="grey"
                      size="medium"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      ADD
                    </Button>
                  </div>
                </form>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <div style={{ height: 800, width: "100%" }}>
                      <DataGrid
                        rows={orderPayments}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 12 },
                          },
                        }}
                        pageSizeOptions={[15]}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"New Order"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="embedded">
              {toggle && <div className="center"></div>}
              {!toggle && (
                <div className="text-center border rounded-3">
                  <CheckoutForm
                    data={{ amount, product }}
                    setToggle={setToggle}
                    payNow={payNow}
                  />
                </div>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="px-3 bg-dark rounded-1" onClick={handleClose}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Orders;
