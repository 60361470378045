/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  CardTitle,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./register.css";
import MUIButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ChangePasswordService from "../../services/security";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../store";
import bcrypt from "bcryptjs";

function ChangePassword() {
  const history = useHistory();
  const [userState, setUserState] = useAtom(userAtom);
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [confirmPassworderror, setConfirmPassworderror] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);

  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|(?=.*[@$!%*?&]))[A-Za-z\d@$!%*?&]{8,16}$/,
        "Passwords must 1 upper case, numeric, and special character!"
      ),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|(?=.*[@$!%*?&]))[A-Za-z\d@$!%*?&]{8,16}$/,
        "Passwords must 1 upper case, numeric, and special character!"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    conditions: Yup.boolean().oneOf([true], ""),
  });
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        console.log(values);
        const secretKey = "$2a$10$CwTycUXWue0Thq9StjUM0u7861!0";
        const hashedNewPassword = bcrypt.hashSync(values.password, secretKey);
        const hashedOldPassword = bcrypt.hashSync(
          values.oldPassword,
          secretKey
        );
        const data = {
          userId: userState.userId,
          password: hashedNewPassword,
          oldPassword: hashedOldPassword,
        };
        ChangePasswordService.ChangePassword(data)
          .then((res) => {
            console.log(res);
            if (res.data.rowsAffected.length > 0) {
              formik.resetForm();
              history.push("/");
            } else {
              setPasswordError("invalid password");
              setOldPasswordError("invalid user name");
              setConfirmPassworderror("invalid old password");
              setTimeout(() => {
                setPasswordError("");
                setOldPasswordError("");
                setConfirmPassworderror("");
              }, 2000);
              formik.setTouched({
                userName: true,
                password: true,
              });
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              // Handle unauthorized access
              setErrorOpen(true);
            } else {
              // Handle other types of errors
              console.error("An error occurred:", e);
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
  });

  const handleBlur = (event) => {
    let { name } = event.target;
    formik.setFieldTouched(name, true);
  };

  const handleChange = (event) => {
    let { name } = event.target;
    formik.setFieldValue(name, event.target.value);
  };

  useEffect(() => {
    console.log(formik.touched);
    console.log(formik.errors);
    setTimeout(() => {
      Object.keys(formik.touched).map((res) =>
        formik.setFieldTouched(res, false)
      );
    }, 5000);
  }, [formik.touched]);

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  return (
    <div className="login-page auth-layout-page">
      <Dialog
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Password Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Error: The old password you entered is incorrect. Please
            double-check your old password and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={handleErrorClose}>OK</MUIButton>
        </DialogActions>
      </Dialog>
      <Container>
        <div className="auth-logo-responsive-main d-lg-none">
          <div className="auth-logo-responsive">
            <img
              alt="..."
              className="avatar border-gray"
              src={require("assets/img/main_logo_white.png")}
            />
          </div>
        </div>
        <Row>
          <Col className="" lg="6" md="12">
            <div className="auth-layout-logo">
              <div
                className="auth-navbar-logo"
                style={{
                  backgroundImage: `url(${require("assets/img/auth-logo.png")})`,
                }}
              ></div>
            </div>
          </Col>
          <Col className="" lg="6" md="12">
            <Form action="" className="form login-form" method="">
              <Card className="card-login card-auth-section">
                <CardHeader>
                  <CardTitle tag="h4">Change Password</CardTitle>
                </CardHeader>
                <CardBody>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Old Password"
                        type="password"
                        name="oldPassword"
                        autoComplete="off"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.oldPassword}
                      />
                    </div>
                    {/* {formik.touched.oldPassword && formik.errors.oldPassword ? (
                      <p className="form-errors login-error">
                        {formik.errors.oldPassword}
                      </p>
                    ) : (
                      <p className="form-errors login-error">
                        {oldPasswordError}
                      </p>
                    )} */}
                  </InputGroup>
                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="New Password"
                        type="password"
                        name="password"
                        autoComplete="off"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.password}
                      />
                    </div>
                    {/* {formik.touched.password && formik.errors.password ? (
                      <p className="form-errors login-error">
                        {formik.errors.password}
                      </p>
                    ) : (
                      <p className="form-errors login-error">{passworderror}</p>
                    )} */}
                  </InputGroup>

                  <InputGroup>
                    <div className="field-group-icon">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm Password"
                        type="password"
                        name="confirmPassword"
                        autoComplete="off"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={formik.values.confirmPassword}
                      />
                    </div>
                    {/* {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <p className="form-errors login-error">
                        {formik.errors.confirmPassword}
                      </p>
                    ) : (
                      <p className="form-errors login-error">
                        {confirmPassworderror}
                      </p>
                    )} */}
                  </InputGroup>
                  <br />
                  {/* <FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input defaultChecked defaultValue="" type="checkbox" />
                        <span className="form-check-sign" />
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        terms and conditions
                      </a>
                      </Label>
                    </FormGroup>
                  </FormGroup>  */}
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="btn-round mb-3 theme-btn-layout-red"
                    color="warning"
                    href="#pablo"
                    onClick={(e) => {
                      // formik.setFieldsTouched(['firstName','lastName',true)
                      formik.setTouched({
                        userName: true,
                        password: true,
                      });

                      formik.handleSubmit();
                    }}
                  >
                    Change Password
                  </Button>
                  <Button
                    block
                    className="btn-round mb-3 theme-btn-layout-red"
                    color="secondary"
                    href="#pablo"
                    onClick={(e) => {
                      history.push("/auth/login");
                    }}
                  >
                    Back to Sign in
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      {/* <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`,
        }}
      /> */}
    </div>
  );
}

export default ChangePassword;
