import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en-gb";
import Select from "react-select";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DateField } from "@mui/x-date-pickers/DateField";
import ReactDatetime from "react-datetime";
import Moment from "moment";
import "moment/locale/en-gb";
import { Container } from "@mui/material";

const UserAddress = ({ formData, setFormData }) => {
  const housingSituation = [
    "Owned outright",
    "Mortgage",
    "Renting",
    "Living with family",
  ];
  const boxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const handleDateMovedChange = (e) => {
    Moment.locale("en-au");
    const formattedDate = Moment(e).format("DD/MM/yyyy");
    setFormData({ ...formData, dateMoved: formattedDate });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Add Family Member</CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal birth-details">
            <Row>
              <Col md="9">
                <FormGroup>
                  <Label>Address</Label>
                  <Input
                    placeholder="Address"
                    type="text"
                    value={formData.address}
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12" sm="6">
                <Label>Housing Situation</Label>
                <FormGroup>
                  <Grid sx={{ display: "flex", flexDirection: "row" }}>
                    {housingSituation.map((hs) => (
                      <Grid
                        sx={{ flexDirection: "column", padding: "4px" }}
                        key={hs}
                      >
                        <Box
                          onClick={() =>
                            setFormData({ ...formData, housingSituation: hs })
                          }
                          key={hs}
                          sx={boxSx}
                          style={{
                            backgroundColor:
                              formData.housingSituation === hs
                                ? "#e61a4b"
                                : "#114253",
                          }}
                        >
                          {hs}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <Label>Date moved</Label>
                <FormGroup>
                  <ReactDatetime
                    locale="en-gb"
                    inputProps={{
                      className: "form-control",
                      placeholder: "",
                    }}
                    value={formData.dateMoved}
                    onChange={(e) => handleDateMovedChange(e)}
                    timeFormat={false}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="9"></Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default UserAddress;
