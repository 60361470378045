import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
  Container,
} from "reactstrap";

import "react-datetime/css/react-datetime.css";
import "dayjs/locale/en-gb";
import Moment from "moment";
// import Select from "react-select";
import Select from "@mui/material/Select";
import "moment/locale/en-gb";
import { selectedClientAtom } from "store";
import { useAtom } from "jotai";
import PropertyPortfolioService from "services/property-portfolio.service";
import YearlyPlanRenovationService from "services/yearly-plan-renovation.service";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { formatCurrency } from "utils/currency";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RenovationStep = ({ formData, setFormData }) => {
  const [clientState] = useAtom(selectedClientAtom);
  // const [renovateProperties, setRenovateProperties] = useState([]);

  const [properties, setProperties] = useState([]);
  const [existingRenovations, setExistingRenovations] = useState([]);

  useEffect(() => {
    getProperties(clientState.clientId);
    getRenovations(formData?.yearlyPlanId);
  }, [clientState.clientId, formData?.yearlyPlanId]);

  const getProperties = async (clientId) => {
    return PropertyPortfolioService.getNonExcludedFromCalcForRenovationDropdownByClientId(
      clientId
    ).then((response) => {
      console.log("response: ", response.data.recordset);
      if (response.data.recordset.length > 0) {
        const result = response.data.recordset.map((property) => ({
          propertyId: property.value,
          propertyName: property.label,
        }));
        console.log("result : ", result);
        setProperties(result);
      }
    });
  };

  const getRenovations = async (yearlyPlanId) => {
    if (yearlyPlanId) {
      return YearlyPlanRenovationService.getAllByYearlyPlanId(
        yearlyPlanId
      ).then((response) => {
        console.log("response: ", response.data.recordset);
        if (response.data.recordset.length > 0) {
          const result = response.data.recordset;
          console.log("result : ", result);
          setFormData({
            ...formData,
            renovateProperties: result.map(
              ({ RenovationClientPropertyId, PropertyLabel }) => ({
                propertyId: RenovationClientPropertyId,
                propertyName: PropertyLabel,
              })
            ),
          });
          setExistingRenovations(result);
        }
      });
    }
  };

  const handlePropertyChange = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        const currProperty = properties.find(
          (p) => p.propertyName === options[i].value
        );
        value.push({
          propertyId: currProperty.propertyId,
          propertyName: options[i].value,
        });
      }
    }
    console.log("property change:  ", value);
    setFormData({
      ...formData,
      // On autofill we get a stringified value.
      renovateProperties: typeof value === "string" ? value.split(",") : value,
    });
    // if (!(property.value === formData.renovationClientPropertyId)) {
    //   const existingDataIndex = existingRenovations.findIndex(
    //     (r) => r.RenovationClientPropertyId === property.value
    //   );
    //   if (existingDataIndex > -1) {
    //     setFormData({
    //       ...formData,
    //       yearlyPlanRenovationId:
    //         existingRenovations[existingDataIndex].YearlyPlanRenovationId,
    //       renovationClientPropertyId: property.value,
    //       renovationSpend: formatCurrency(
    //         existingRenovations[existingDataIndex].RenovationSpend
    //       ),
    //       renovationBorrowing:
    //         existingRenovations[existingDataIndex].RenovationBorrowing,
    //       renovationRentalYield:
    //         existingRenovations[existingDataIndex].RenovationRentalYield,
    //     });
    //   } else {
    //     setFormData({
    //       ...formData,
    //       yearlyPlanRenovationId: null,
    //       renovationClientPropertyId: property.value,
    //       renovationSpend: formatCurrency(0),
    //       renovationBorrowing: null,
    //       renovationRentalYield: null,
    //     });
    //   }
    // }
  };

  console.log("form data: ", formData);
  return (
    <div className="renovation-steps-modal main-pad">
      <Card>
        <CardHeader className="modal-year-pad">
          <CardTitle tag="h4">{formData?.year}</CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Container>
              <Row>
                <Col md="12">
                  <Label>Renovate which property</Label>
                  <FormGroup>
                    {/*<Select
                      name="renovate-property"
                      className="react-select  select-option-control"
                      placeholder=""
                      classNamePrefix="react-select"
                      value={properties.find(
                        (p) => p.value === formData.renovationClientPropertyId
                      )}
                      onChange={handlePropertyChange}
                      options={properties}
                      />*/}
                    <Select
                      // labelId="switch-property-label"
                      inputProps={{
                        id: "renovate-property",
                      }}
                      // className="react-select  select-option-control"
                      // classNamePrefix="react-select"
                      // placeholder=""
                      native
                      multiple
                      sx={{
                        width: "70vw",
                      }}
                      value={formData?.renovateProperties?.map(
                        (p) => p.propertyName
                      )}
                      onChange={handlePropertyChange}
                      // input={<OutlinedInput style={{ width: "100%" }} />}
                    >
                      {properties.map(({ propertyId, propertyName }) => (
                        <option key={propertyId} value={propertyName}>
                          {propertyName}
                        </option>
                        // <MenuItem key={value} value={value}>
                        //   <Checkbox
                        //     checked={
                        //       formData?.loanSwitchProperties.indexOf(value) > -1
                        //     }
                        //   />
                        //   <ListItemText primary={label} />
                        // </MenuItem>
                      ))}
                    </Select>
                    {/* <Autocomplete
                      multiple
                      value={formData?.renovateProperties}
                      onChange={(_event, value) => {
                        // console.log(value);
                        handlePropertyChange(value);
                        // setValue(typeof value === 'string' ? value.split(',') : value);
                      }}
                      id="renovate-property"
                      options={properties}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.propertyName}
                      isOptionEqualToValue={(option, value) =>
                        option.propertyId === value.propertyId
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.propertyName}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField {...params} label="" placeholder="" />
                      )}
                    /> */}
                  </FormGroup>
                </Col>
                <Col lg="4" md="6">
                  <Label>Renovation Spend</Label>
                  <FormGroup>
                    <CurrencyInput
                      placeholder="Renovation spend"
                      number={formData.renovationSpend}
                      handleNumberChange={(value) =>
                        setFormData({ ...formData, renovationSpend: value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="4" md="6">
                  <Label>How much of this renovation would you borrow?</Label>
                  <FormGroup>
                    <Input
                      placeholder="Renovation borrowing"
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      value={formData?.renovationBorrowing || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          renovationBorrowing: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="4" md="6">
                  <Label>Post renovation rental yield</Label>
                  <FormGroup>
                    <Input
                      placeholder="Rental yield"
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      value={formData?.renovationRentalYield || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          renovationRentalYield: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="4"></Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
};

export default RenovationStep;
