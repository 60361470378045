import http from "./http-common";

class PropertyPortfolioService {
  // http://localhost:5000/api/v1/scoreboard/property-portfolio/add
  add(data) {
    const path = "/scoreboard/property-portfolio";
    console.log("data: ", data);
    return http.post(`${path}/add`, data);
  }

  // http://localhost:5000/api/v1/scoreboard/property-portfolio/get/:id
  getByPropertyId(id) {
    const path = `/scoreboard/property-portfolio/get/${id}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/property-portfolio/get/client/:id
  getAllByClientId(clientId) {
    const path = `/scoreboard/property-portfolio/get/client/${clientId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/property-portfolio/get/non-excluded-from-calc/renovation/dropdown
  getNonExcludedFromCalcForRenovationDropdownByClientId(clientId) {
    const path = `/scoreboard/property-portfolio/get/non-excluded-from-calc/renovation/dropdown/client/${clientId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/property-portfolio/get/non-excluded-from-calc/loan-switch/dropdown
  getNonExcludedFromCalcForLoanSwitchDropdownByClientId(clientId) {
    const path = `/scoreboard/property-portfolio/get/non-excluded-from-calc/loan-switch/dropdown/client/${clientId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/property-portfolio/get/non-excluded-from-calc/purpose-switch/dropdown
  getNonExcludedFromCalcForPurposeSwitchDropdownByClientId(clientId, year) {
    const path = `/scoreboard/property-portfolio/get/non-excluded-from-calc/purpose-switch/dropdown/client/${clientId}/year/${year}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/property-portfolio/get/financials/:id
  getFinancials(propertyId) {
    const path = `/scoreboard/property-portfolio/get/financials/${propertyId}`;
    console.log("path: ", path);
    return http.get(path);
  }

  // http://localhost:5000/api/v1/scoreboard/property-portfolio/update/:id
  update(id, data) {
    const path = `/scoreboard/property-portfolio/update/${id}`;
    return http.put(path, data);
  }

  // http://localhost:5000/api/v1/scoreboard/property-portfolio/delete/:id
  delete(id) {
    const path = `/scoreboard/property-portfolio/delete/${id}`;
    return http.delete(path);
  }
}

export default new PropertyPortfolioService();
