import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Row,
  Col,
  Label,
} from "reactstrap";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import GoalAlignmentService from "../../../services/goal-alignment.service";
import AssumptionService from "../../../services/assumption.service";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";
import { formatCurrency } from "utils/currency";

const GoalAlignment = () => {
  const [requiredState, setrequiredState] = useState("");
  const [emailState, setemailState] = useState("");
  const [numberState, setnumberState] = useState("");
  const [urlState, seturlState] = useState("");
  const [sourceState, setsourceState] = useState("");
  const [destinationState, setdestinationState] = useState("");
  const [selectedClientstate, setSelectedClientState] =
    useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);

  const retirementAge = [30, 40, 50, 55, 60, 65];
  const passiveIncome = [
    50000, 65000, 80000, 100000, 125000, 150000, 200000, 250000, 300000, 500000,
    750000, 1000000,
  ];
  const netWorth = [1000000, 2000000, 3000000, 5000000, 75000000, 10000000];
  const noOfProperties = [1, 2, 3, 5, 7, 10];

  let yellow = "#fbc658";
  const [selectedAge, setSelectedAge] = useState(null);
  const [isDefaultRetirementAge, setIsDefaultRetirementAge] = useState(true);
  const [selectedPassiveIncome, setSelectedPassiveIncome] = useState(null);
  const [isDefaultPassiveIncome, setIsDefaultPassiveIncome] = useState(true);
  const [selectedNetWorth, setSelectedNetWorth] = useState(null);
  const [isDefaultNetWorth, setIsDefaultNetWorth] = useState(true);
  const [selectedNoOfProperties, setSelectedNoOfProperties] = useState(0);
  const [open, setOpen] = useState(false);
  const [goalAlignmentId, setGoalAlignmentId] = useState(0);
  const [goalAlignmentDefaults, setGoalAlignmentDefaults] = useState({});
  const [savedGoalAlignment, setSavedGoalAlignment] = useState({});
  const history = useHistory();

  const getGoalAlignmentById = async (id) => {
    let goalAlignment = {};
    GoalAlignmentService.getByClientId(id)
      .then((response) => {
        if (response.data.recordset.length > 0) {
          goalAlignment = {
            id: response.data.recordset[0]?.GoalAlignmentId,
            retirementAge: response.data.recordset[0]?.DesiredRetirementAge,
            isDefaultRetirementAge:
              response.data.recordset[0]?.IsDefaultRetirementAge,
            passiveIncome: response.data.recordset[0]?.PassiveIncomeRequired,
            isDefaultPassiveIncome:
              response.data.recordset[0]?.IsDefaultPassiveIncome,
            netWorth: response.data.recordset[0]?.DesiredNetWorth,
            isDefaultNetWorth: response.data.recordset[0]?.IsDefaultNetWorth,
            noOfProperties: response.data.recordset[0]?.DesiredNoOfProperties,
          };
          setGoalAlignment(goalAlignment);
          setSavedGoalAlignment(goalAlignment);
        }
      })
      .catch((error) => {
        console.log(error);
        setSavedGoalAlignment(goalAlignment);
      });
  };

  const getGoalAlignmentDefaults = async () => {
    let goalAlignmentDefaults = {};
    AssumptionService.getGoalAlignmentDefaults()
      .then((response) => {
        if (response.data.recordset.length > 0) {
          goalAlignmentDefaults = {
            defaultRetirementAge:
              response.data.recordset[0]?.DefaultRetirementAge,
            defaultPassiveIncome:
              response.data.recordset[0]?.DefaultPassiveIncome,
            defaultNetWorth:
              response.data.recordset[0]?.DefaultNetWorthAtRetirement,
          };
          setGoalAlignmentDefaults(goalAlignmentDefaults);
        }
      })
      .catch((error) => {
        console.log(error);
        setGoalAlignmentDefaults(goalAlignmentDefaults);
      });
  };

  useEffect(() => {
    const jwtToken = window.sessionStorage.getItem("jwt-token");
    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
    } else {
      getGoalAlignmentDefaults();
      getGoalAlignmentById(selectedClientstate.clientId);
    }
  }, [selectedClientstate.clientId]);

  const typeClick = () => {
    if (requiredState === "") {
      setrequiredState("has-danger");
    }
    if (emailState === "") {
      setemailState("has-danger");
    }
    if (numberState === "") {
      setnumberState("has-danger");
    }
    if (urlState === "") {
      seturlState("has-danger");
    }
    if (sourceState === "" || destinationState === "") {
      setsourceState("has-danger");
      setdestinationState("has-danger");
    }
  };

  const ageBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const incomeBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    // padding: '20px 20px 20px 32px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const netWorthBoxSx = {
    cursor: "pointer",
    backgroundColor: "#fbc658",
    border: "1px solid #DDDDDD",
    borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: '20px 20px 20px 32px',
    "&:hover": {
      backgroundColor: "#ef8157",
    },
  };

  const handleRetirementAgeClick = (age) => {
    setSelectedAge(age);
    setIsDefaultRetirementAge(false);
  };

  const handlePassiveIncomeClick = (pi) => {
    setSelectedPassiveIncome(pi);
    setIsDefaultPassiveIncome(false);
  };

  const handleNetWorthClick = (nw) => {
    setSelectedNetWorth(nw);
    setIsDefaultNetWorth(false);
  };

  const setNoOfProperties = (np) => {
    setSelectedNoOfProperties(np);
  };

  const redirectToTracker = () => {
    const path = "/admin/progress-tracker/index";
    history.push(path);
  };

  const saveGoalAlignment = () => {
    const goalAlignmentData = {
      clientId: selectedClientstate.clientId,
      desiredRetirementAge:
        selectedAge === null
          ? goalAlignmentDefaults.defaultRetirementAge
          : selectedAge,
      isDefaultRetirementAge: isDefaultRetirementAge === true ? "1" : "0",
      passiveIncomeRequired:
        selectedPassiveIncome === null
          ? goalAlignmentDefaults.defaultPassiveIncome
          : selectedPassiveIncome,
      isDefaultPassiveIncome: isDefaultPassiveIncome === true ? "1" : "0",
      desiredNetWorth:
        selectedNetWorth === null
          ? goalAlignmentDefaults.defaultNetWorth
          : selectedNetWorth,
      isDefaultNetWorth: isDefaultNetWorth === true ? "1" : "0",
      desiredNoOfProperties: selectedNoOfProperties,
      userId: userState.userId,
    };
    if (goalAlignmentId === 0) {
      GoalAlignmentService.add(goalAlignmentData)
        .then((response) => {
          if (response.data.recordset.length > 0) {
            setGoalAlignmentId(response.data.recordset[0].GoalAlignmentId);
            setOpen(true);
            setTimeout(() => {
              redirectToTracker();
            }, 3000);
          } else {
            setGoalAlignmentId(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      GoalAlignmentService.update(goalAlignmentData, savedGoalAlignment.id)
        .then(() => {
          setOpen(true);
          setTimeout(() => {
            redirectToTracker();
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const setGoalAlignment = (goalAlignment) => {
    setSelectedAge(goalAlignment.retirementAge);
    setSelectedPassiveIncome(goalAlignment.passiveIncome);
    setSelectedNetWorth(goalAlignment.netWorth);
    setSelectedNoOfProperties(goalAlignment.noOfProperties);
    setGoalAlignmentId(goalAlignment.id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="content goal-alignment-content">
      <Container>
        {/* <h2>Goal Alignment</h2> */}
        <ClientNotSelectedDialog />
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message="Client goal is saved."
          action={action}
        ></Snackbar>
        <Row>
          <Col md="12">
            <Form className="form-horizontal" id="TypeValidation">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Goal Alignment for {selectedClientstate.firstName}{" "}
                    {selectedClientstate.lastName}{" "}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <Typography variant="body2" gutterBottom>
                        Desired retirement age
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup className={requiredState}>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {retirementAge.map((age) => (
                            <Grid
                              className="small_box"
                              sx={{ flexDirection: "column", padding: "4px" }}
                              key={age}
                            >
                              <Box
                                onClick={() => {
                                  handleRetirementAgeClick(age);
                                }}
                                key={age}
                                sx={ageBoxSx}
                                style={{
                                  backgroundColor:
                                    selectedAge === age ? "#e61a4b" : "#114253",
                                }}
                              >
                                {age}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                        <div style={{ paddingLeft: "8px" }}>
                          <Typography variant="caption" gutterBottom>
                            (Note: If not selected '
                            {goalAlignmentDefaults.defaultRetirementAge}' would
                            be selected as default retirement age.)
                          </Typography>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Typography variant="body2" gutterBottom>
                        Amount of passive income required for retirement?
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup className={requiredState}>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {passiveIncome.map((pi) => (
                            <Grid
                              sx={{ flexDirection: "column", padding: "4px" }}
                              key={pi}
                            >
                              <Box
                                onClick={() => handlePassiveIncomeClick(pi)}
                                key={pi}
                                sx={incomeBoxSx}
                                style={{
                                  backgroundColor:
                                    selectedPassiveIncome === pi
                                      ? "#e61a4b"
                                      : "#114253",
                                }}
                              >
                                {pi.toLocaleString(navigator.language, {
                                  minimumFractionDigits: 0,
                                })}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                        <div style={{ paddingLeft: "8px" }}>
                          <Typography variant="caption" gutterBottom>
                            (Note: If not selected{" "}
                            {formatCurrency(
                              goalAlignmentDefaults.defaultPassiveIncome
                            )}{" "}
                            would be selected as default passive income at
                            retirement.)
                          </Typography>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Typography variant="body2" gutterBottom>
                        Desired net worth at retirement age?
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup className={requiredState}>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {netWorth.map((nw) => (
                            <Grid
                              sx={{ flexDirection: "column", padding: "4px" }}
                              key={nw}
                            >
                              <Box
                                onClick={() => handleNetWorthClick(nw)}
                                key={nw}
                                sx={netWorthBoxSx}
                                style={{
                                  backgroundColor:
                                    selectedNetWorth === nw
                                      ? "#e61a4b"
                                      : "#114253",
                                }}
                              >
                                {nw.toLocaleString(navigator.language, {
                                  minimumFractionDigits: 0,
                                })}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                        <div style={{ paddingLeft: "8px" }}>
                          <Typography variant="caption" gutterBottom>
                            (Note: If not selected{" "}
                            {formatCurrency(
                              goalAlignmentDefaults.defaultNetWorth
                            )}{" "}
                            would be selected as default net worth at
                            retirement.)
                          </Typography>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Typography variant="body2" gutterBottom>
                        How many properties do you want in your portfolio?
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup className={requiredState}>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {noOfProperties.map((np) => (
                            <Grid
                              className="small_box"
                              sx={{ flexDirection: "column", padding: "4px" }}
                              key={np}
                            >
                              <Box
                                onClick={() => setNoOfProperties(np)}
                                key={np}
                                sx={ageBoxSx}
                                style={{
                                  backgroundColor:
                                    selectedNoOfProperties === np
                                      ? "#e61a4b"
                                      : "#114253",
                                }}
                              >
                                {np.toLocaleString(navigator.language, {
                                  minimumFractionDigits: 0,
                                })}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-right">
                  <Button
                    variant="contained"
                    color="grey"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={() => saveGoalAlignment()}
                  >
                    SAVE
                  </Button>
                  {/* <Button
                  variant="contained"
                  color="grey"
                  size="medium"
                  startIcon={<SaveIcon />}
                  onClick={() => saveGoalAlignment()}
                >
                  Pay
                </Button> */}
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GoalAlignment;
