import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import newPropertyService from "services/new-property.service";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Snackbar } from "@mui/material";
import DeleteDialog from "components/Dialog/DeleteDialog";
import PopupWithClose from "components/Dialog/PopupWithClose";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import uploadService from "services/upload.service";

export default function FileHistoryTable({ propertyId, data, fetchData }) {
  // const [data, setData] = React.useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(0);

  // React.useEffect(() => {
  //   getPropertyDocumentsByPropertyId(propertyId);
  // }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "propertyDocumentType", headerName: "File Category", width: 250 },
    { field: "propertyDocumentName", headerName: "Filename", width: 500 },
    //{ field: "propertyDocumentLocation", headerName: "Folder", width: 1000 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="close"
            onClick={() => {
              setDeleteId(params.row.id);
              setDeleteConfirmationOpen(true);
            }}
            sx={{
              // position: "absolute",
              // right: 8,
              // top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <DeleteIcon />
          </IconButton>

          <IconButton
            aria-label="close"
            onClick={() => {
              // setDeleteId(params.row.id);
              // setDeleteConfirmationOpen(true);
              const propertyId = 14;
              downloadDocument(
                params.row.propertyDocumentName,
                params.row.propertyDocumentLocation,
                propertyId
              );
            }}
            sx={{
              // position: "absolute",
              // right: 8,
              // top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <DownloadIcon />
          </IconButton>
          {/* <a href="www.google.com" target="_blank">
            Edit
          </a>{" "}
          <a>Delete</a> */}
        </>
      ),
    },
  ];

  const deleteDocument = (documentId) => {
    uploadService
      .deletePropertyDocumentById(documentId)
      .then(() => {
        fetchData(propertyId);
        setSuccessDialogOpen(true);
      })
      .catch((e) => console.log(e));
  };

  const downloadDocument = (filename, foldername, propertyId) => {
    uploadService
      .getFile(foldername, filename, propertyId)
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      })
      .catch((e) => console.log(e));
  };

  const handleSuccessDialogClose = () => setSuccessDialogOpen(false);

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSuccessDialogClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <>
      <DeleteDialog
        confirmationOpen={deleteConfirmationOpen}
        handleConfirmationClose={() => setDeleteConfirmationOpen(false)}
        onDelete={() => deleteDocument(deleteId)}
      />
      <Snackbar
        open={successDialogOpen}
        autoHideDuration={5000}
        onClose={handleSuccessDialogClose}
        message="Document is deleted."
        action={action}
      />
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        // pageSizeOptions={[5, 8]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
    </>
  );
}
