import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
} from "reactstrap";
import FamilyMemberService from "../../../services/family-member.service";
// import { DataGrid } from '@mui/x-data-grid';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import FamilyMembersAdd from "./family-members-add";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import defaultImage from "assets/img/default-avatar.png";
import { useAtom } from "jotai";
import { selectedClientAtom, userAtom } from "../../../store";
import DeleteDialog from "components/Dialog/DeleteDialog";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ClientNotSelectedDialog from "components/Dialog/ClientNotSelectedDialog";

const FamilyMembersList = () => {
  const [familyMembers, setFamilyMembers] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  // const [clientId, setClientId] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
  const [clientState, setClientState] = useAtom(selectedClientAtom);
  const [userState, setUserState] = useAtom(userAtom);
  const [loginUser, setLoginUser] = useState({});
  const [confirmationOpen, setConfirmationOpen] = useState(
    Array(familyMembers.length).fill(false)
  );
  const history = useHistory();

  useEffect(() => {
    // const selectedClient = JSON.parse(window.localStorage.getItem('SelectedClient'));
    // console.log('selectedClient: ', selectedClient);
    //setClientId(selectedClient.clientId);
    const jwtToken = window.sessionStorage.getItem("jwt-token");
    const loginUser = JSON.parse(window.localStorage.getItem("login-user"));
    const selectedClient = JSON.parse(
      window.localStorage.getItem("selected-client")
    );

    if (jwtToken === undefined || jwtToken === null) {
      history.push("/auth/login");
    } else {
      // read from user state
      // if userId === 0  means it has lost the value
      // then push the value from localstorage to user state
      setLoginUser(loginUser);
      console.log("login user from local storage", loginUser);
      console.log("user state", userState);
      // if (userState.userId === 0) {
      //   setUserState(loginUser);
      // }
      console.log("selected client state: ", clientState);
      // if (clientState?.clientId === 0 && selectedClient) {
      //   console.log("selected client from local storage: ", selectedClient);
      //   setClientState(selectedClient);
      // }
      getFamilyMembers();
    }
  }, [isDataLoaded, clientState?.clientId]);

  const getFamilyMembers = async () => {
    if (clientState?.clientId !== undefined) {
      return FamilyMemberService.getAllByClientId(clientState?.clientId)
        .then((response) => {
          let familyMemberData = response.data.recordset.map((member) => {
            return {
              id: member.FamilyMemberId,
              familyMemberId: member.FamilyMemberId,
              firstName: member.FirstName,
              middleName: member.MiddleName,
              lastName: member.LastName,
              phone: member.Phone,
              email: member.Email,
              dateOfBirth: member.DateOfBirth,
              birthCountry: member.CountryOfBirth,
              gender: member.Gender,
              maritalStatus: member.MaritalStatus,
              address: member.Address,
              housingSituation: member.HousingSituation,
              dateMoved: member.DateMoved,
            };
          });

          setFamilyMembers(familyMemberData);
          if (response.data.recordsets.length > 0) {
            console.log("response: ", response);
            console.log("familyMembers: ", familyMemberData);
            ///setIsDataLoaded(true);
            setIsDataLoaded(true);
          } else {
            setIsDataLoaded(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsDataLoaded(true);
          setFamilyMembers([]);
        });
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 150 },
    { field: "middleName", headerName: "Middle name", width: 150 },
    { field: "lastName", headerName: "Last name", width: 150 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#66615B", //theme.palette.common.darkgray,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleConfirmationOpen = (index) => {
    const tempOpens = [...confirmationOpen];
    tempOpens[index] = true;
    setConfirmationOpen(tempOpens);
  };

  const handleConfirmationClose = (index) => {
    const tempOpens = [...confirmationOpen];
    tempOpens[index] = false;
    setConfirmationOpen(tempOpens);
  };

  const handleClick = (e, familyMemberId) => {
    console.log("family member id: ", familyMemberId);
    e.preventDefault();
    const path = `/admin/scoreboard/family-members/add/${familyMemberId}`;
    history.push(path);
  };

  const handleAdd = () => {
    const path = "/admin/scoreboard/family-members/add";
    history.push(path);
  };

  const handleDelete = async (familyMemberId) => {
    return FamilyMemberService.delete(familyMemberId).then((response) => {
      console.log("response: ", response.data.recordset);
      getFamilyMembers().then(() => setSuccessDialogOpen(true));
    });
  };

  const handleSuccessDialogClose = () => setSuccessDialogOpen(false);

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSuccessDialogClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <div className="content family-member-list-content">
      <ClientNotSelectedDialog />
      <Snackbar
        open={successDialogOpen}
        autoHideDuration={5000}
        onClose={handleSuccessDialogClose}
        message="Family member is deleted."
        action={action}
      />

      <Container>
        <Row>
          <Col md="12">
            <Card className="family-members">
              <CardHeader>
                <CardTitle tag="h4">
                  Family Members for {clientState?.firstName}{" "}
                  {clientState?.lastName}
                </CardTitle>
                <div className="card_bt">
                  <Button
                    sx={{ float: "right" }}
                    variant="contained"
                    color="grey"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={handleAdd}
                  >
                    ADD
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                {/* {isDataLoaded && (
                                <DataGrid
                                    rows={familyMembers}
                                    columns={columns}
                                />
                            )} */}

                <Row>
                  {isDataLoaded &&
                    familyMembers.map((member, index) => (
                      <Col key={member.familyMemberId} md="6" lg="3">
                        <DeleteDialog
                          confirmationOpen={confirmationOpen[index]}
                          handleConfirmationClose={() =>
                            handleConfirmationClose(index)
                          }
                          onDelete={() => handleDelete(member.familyMemberId)}
                        />
                        <Card className="member_card" key={member.id}>
                          <CardHeader className="card-cross-btn">
                            <IconButton
                              aria-label="delete"
                              style={{
                                marginLeft: "auto",
                                padding: 0,
                                color: "#e61a4b",
                              }}
                              onClick={() => handleConfirmationOpen(index)}
                            >
                              <CloseSharpIcon />
                            </IconButton>
                          </CardHeader>
                          <Link to={`./add/${member.familyMemberId}`}>
                            <CardBody>
                              <div className="picture-container">
                                <div className="picture">
                                  <img
                                    src={imagePreviewUrl}
                                    className="picture-src"
                                    alt="..."
                                  />
                                </div>
                              </div>
                              {/* <CardTitle tag="h4">
                                {clientState?.firstName} {clientState?.lastName}
                              </CardTitle> */}
                              <ul>
                                <li>
                                  {member?.firstName} {member?.lastName}
                                </li>
                                <li>{member.phone}</li>
                                <li>{member.email}</li>
                                <li>{member.dateOfBirth.slice(0, 10)}</li>
                              </ul>
                            </CardBody>
                          </Link>
                          <CardFooter>
                            {/* <Button
                              className="btn-round"
                              color="primary"
                              href="#pablo"
                              onClick={(e) =>
                                handleClick(e, member.familyMemberId)
                              }
                            >
                              Edit
                            </Button> */}
                            <DeleteDialog
                              onDelete={() =>
                                handleDelete(member.familyMemberId)
                              }
                            />
                          </CardFooter>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FamilyMembersList;
