import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { selectedClientAtom } from "store";
import { useState } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ClientNotSelectedDialog(props) {
  const history = useHistory();
  const [clientState] = useAtom(selectedClientAtom);
  const [noClientDialogOpen, setNoClientDialogOpen] = useState(false);

  useEffect(() => {
    const clientInStorageString = sessionStorage.getItem("selectedAtomClient");
    const clientInStorage =
      clientInStorageString && JSON.parse(clientInStorageString);
    console.log("client in stto: ", clientInStorage);
    if (
      !clientState.clientId &&
      (!clientInStorage || !clientInStorage?.clientId)
    ) {
      setNoClientDialogOpen(true);
    }
  }, [clientState]);

  const handleOk = async () => {
    history.push("/admin/client/list");
  };

  const handleCancel = async () => {
    setNoClientDialogOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleConfirmationOpen}>
        Delete
      </Button> */}
      <BootstrapDialog
        className="client-not-selected-popup"
        open={noClientDialogOpen}
        onClose={handleCancel}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Client Not Selected"}
        </DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have not selected a client. Click OK to redirect to the client
            list.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleOk} autoFocus>
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
