import http from './http-common';

class GraphService {

    // http://localhost:5000/api/v1/graph/property-growth/:id
    getPropertyGrowth(clientId) {
        const path = `/graph/property-growth/${clientId}`;
        return http.get(path);
    };

    // http://localhost:5000/api/v1/graph/summarise/:id
    getPropertyFinancailsSummary(clientId) {
        const path = `/graph/summarise/${clientId}`;
        return http.get(path);
    };

    // http://localhost:5000/api/v1/graph/savings-and-expenses/:id
    getClientSavingsAndExpensesSummary(clientId) {
        const path = `/graph/savings-and-expenses/${clientId}`;
        return http.get(path);
    };

}

export default new GraphService();
