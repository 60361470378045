import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
  Container,
} from "reactstrap";

import "react-datetime/css/react-datetime.css";
import "dayjs/locale/en-gb";
import Moment from "moment";
import "moment/locale/en-gb";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";

const YearSpendStep = ({ formData, setFormData }) => {
  const gender = ["Male", "Female", "Unspecified"];
  const maritalStatus = ["Single", "Married", "De facto"];

  console.log("form data: ", formData);
  return (
    <div className="year-spend-step-modal main-pad">
      <Card>
        <CardHeader className="modal-year-pad">
          <CardTitle tag="h4">{formData?.year}</CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Container>
              <Row>
                <Col md="6">
                  <Label>Motor vehicle spend</Label>
                  <FormGroup>
                    <CurrencyInput
                      placeholder="Motor vehicle spend"
                      number={formData.motorVehicleSpend}
                      handleNumberChange={(value) =>
                        setFormData({ ...formData, motorVehicleSpend: value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <Label>Holiday Spend</Label>
                  <FormGroup>
                    <CurrencyInput
                      placeholder="Holiday spend"
                      number={formData.holidaySpend}
                      handleNumberChange={(value) =>
                        setFormData({ ...formData, holidaySpend: value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <Label>Education spend</Label>
                  <FormGroup>
                    <CurrencyInput
                      placeholder="Education spend"
                      number={formData.educationSpend}
                      handleNumberChange={(value) =>
                        setFormData({ ...formData, educationSpend: value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <Label>Other spend</Label>
                  <FormGroup>
                    <CurrencyInput
                      placeholder="Other spend"
                      number={formData.otherSpend}
                      handleNumberChange={(value) =>
                        setFormData({ ...formData, otherSpend: value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </Form>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="3" />
            <Col md="6"></Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
};

export default YearSpendStep;
